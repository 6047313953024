import Api from '../../../helpers/api/Api';

export default function postNewProject(data, formMode) {
  return new Promise(function(resolve, reject) {
    const projectObject = {
      status: data.projectStatus ?? formMode.defaultStatus,
      amount: data.projectAmount ? parseInt(Number(data.projectAmount)) : null,
      activities: data.projectActions ?? null,
      createdAt: data.projectCreatedAt ?? new Date(),
      company: getCompanyDataObject(data, formMode),
      sendInvitationAfterCreate: data.userSendEmail,
    };

    if (formMode.isProjectNameAvailable) {
      projectObject.internalName = data.projectName ?? null;
    }

    const userDataObject = getUserDataObject(data, formMode);
    if (typeof projectObject.company === 'string') {
      if (typeof projectObject.projectUsers === 'undefined') {
        projectObject.projectUsers = [];
      }
      projectObject.projectUsers.push({
        user: userDataObject,
      });
    }
    const projectHandler = getUserHandlerIri(data);
    if (typeof projectObject.projectUsers === 'undefined') {
      projectObject.projectUsers = [];
    }
    if (projectHandler) {
      projectObject.projectUsers.push({
        type: 'handler',
        user: projectHandler,
      });
    }

    Api.post(`/projects`, projectObject).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}

function getCompanyDataObject(data, formMode) {
  if (formMode.isFormModeExistingCompany) {
    return data.companyIri;
  } else {
    const company = {
      legalForm: data.companyLegalFormIri,
      employees: [
        {
          user: getUserDataObject(data, formMode),
        },
      ],
    };
    if (typeof data.companyName === 'string' && data.companyName.trim().length > 0 ) {
      company.name = data.companyName.trim();
    }
    return company;
  }
}

function getPhonesDataArray(data, formMode) {
  if (typeof data.userPhoneNumber === 'string' && data.userPhoneNumber.trim().length > 0) {
    return [
      {
        country: data.userPhoneCountry,
        phone: data.userPhoneNumber,
      },
    ];
  } else {
    return [];
  }
}

function getUserDataObject(data, formMode) {
  if (formMode.isFormModeExistingUser) {
    return data.userIri;
  } else {
    return {
      email: data.userEmail,
      firstname: data.userFirstName,
      lastname: data.userLastName,
      userPhones: getPhonesDataArray(data, formMode),
    };
  }
}

function getUserHandlerIri(data) {
  return typeof data.projectHandler ==='string' && data.projectHandler.length > 0 ? data.projectHandler : null;
}
