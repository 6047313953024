import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export default function useInitialTableHeaders(pageScope) {
  const {t} = useTranslation('page_cases');

  const baseHeaders = useMemo(()=>{
    return getHeaders(pageScope, t);
  }, [pageScope, t]);

  return useCallback((fieldName = 'createdAt', order = 'desc') => {
    return baseHeaders.map((header) => {
      if (header.id === fieldName) {
        header.sorted = order === 'asc' ? 'asc' : 'desc';
      } else {
        header.sorted = undefined;
      }
      return header;
    });
  }, [baseHeaders]);
}

function getHeaders(pageScope, t) {
  switch (pageScope) {
    case 'leads':
      return [
        {id: 'id', label: t('table.header.id'), width: '70px', sortable: false},
        {id: 'user', label: t('table.header.fullname'), width: '18%', sortable: true},
        {id: 'company', label: t('table.header.company'), width: '18%', sortable: true},
        {id: 'status', label: t('table.header.status'), width: '130px', sortable: true},
        {id: 'email', label: t('table.header.email'), width: 'auto', sortable: true},
        {id: 'phone', label: t('table.header.phone'), width: 'auto', sortable: true},
        {id: 'createdAt', label: t('table.header.date'), width: '120px', sortable: true},
        {id: 'securityContext', label: t('table.header.context'), width: '120px', sortable: false},
      ];
    case 'opportunities':
      return [
        {id: 'id', label: t('table.header.id'), width: '70px', sortable: false},
        {id: 'name', label: t('table.header.project'), width: 'auto', sortable: true},
        {id: 'company', label: t('table.header.company'), width: 'auto', sortable: true},
        {id: 'status', label: t('table.header.status'), width: 'auto', sortable: true},
        {id: 'handler', label: t('table.header.handler'), width: 'auto', sortable: true},
        {id: 'createdAt', label: t('table.header.date'), width: 'auto', sortable: true},
        {id: 'securityContext', label: t('table.header.context'), width: '120px', sortable: false},
      ];
    case 'accounts':
      return [
        {id: 'id', label: t('table.header.id'), width: '70px', sortable: false},
        {id: 'name', label: t('table.header.project'), width: 'auto', sortable: true},
        {id: 'company', label: t('table.header.company'), width: 'auto', sortable: true},
        {id: 'status', label: t('table.header.status'), width: 'auto', sortable: true},
        {id: 'handler', label: t('table.header.handler'), width: 'auto', sortable: true},
        {id: 'createdAt', label: t('table.header.date'), width: 'auto', sortable: true},
        {id: 'securityContext', label: t('table.header.context'), width: '120px', sortable: false},
      ];
    default:
      return [
        {id: 'id', label: t('table.header.id'), width: '70px', sortable: false},
        {id: 'name', label: t('table.header.project'), width: 'auto', sortable: true},
        {id: 'company', label: t('table.header.company'), width: 'auto', sortable: true},
        {id: 'status', label: t('table.header.status'), width: 'auto', sortable: true},
        {id: 'handler', label: t('table.header.handler'), width: 'auto', sortable: true},
        {id: 'createdAt', label: t('table.header.date'), width: 'auto', sortable: true},
      ];
  }
}
