import {Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import useFirstSlug from '../hooks/useFirstSlug';
import css from './index.module.scss';

function ProjectsPageTitle() {
  const {t} = useTranslation('page_cases');

  const firstSlug = useFirstSlug();

  const pageTitle = useMemo(()=>{
    switch (firstSlug) {
      case 'leads':
        return t('page.leads.title');
      case 'opportunities':
        return t('page.opportunities.title');
      case 'accounts':
        return t('page.accounts.title');
      default:
        return t('page.cases.title');
    }
  }, [firstSlug, t]);

  return (
    <section className={css.ProjectsPageTitle}>
      <Typography variant="h2">{pageTitle}</Typography>
    </section>
  );
}

export default ProjectsPageTitle;
