import './ProjectSummaryBrick.scss';

import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as ArrowRight} from '../../assets/arrow_right.svg';
import {ReactComponent as CaseIcon} from '../../assets/case_icon.svg';
import {ReactComponent as LeadIcon} from '../../assets/lead_icon.svg';
import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import status2string from '../../helpers/formatters/status2string';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';

export default function ProjectSummaryBrick(props) {
  const navigate = useNavigate();


  function onProjectClicked() {
    if (props.allowRedirect) {
      navigate(getFrontendPathToObject(props.project));
    }
  }

  return (
    <div className="ProjectSummaryBrick" onClick={onProjectClicked}>
      <div className="ProjectSummaryBrick-section project-header">
        <LeadIcon />
        <Typography variant="mediumHint">{props.project?.handler?.display || '-'}</Typography>
        <div className="flex-spacer" />
        <ArrowRight />
      </div>
      <div className="ProjectSummaryBrick-section project-name-status">
        <Typography variant="mediumText">
          {props.project?.internalName || '-'}
        </Typography>
        <Typography variant="smallText">
          {status2string(props.project.status)}
        </Typography>
      </div>
      <div className="flex-spacer" />
      <div className="ProjectSummaryBrick-section project-primary-user">
        <CaseIcon />
        <Typography variant="mediumHint">{props.project?.primaryProjectUser?.display}</Typography>
      </div>
      <div className="ProjectSummaryBrick-section project-date">
        <Typography variant="smallHint">{formatDateTimestamp(props.project?.lastUpdatedAt)}</Typography>
      </div>
    </div>
  );
}

ProjectSummaryBrick.propTypes = {
  project: PropTypes.shape({
    'id': PropTypes.number.isRequired,
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']).isRequired,
    'status': PropTypes.string.isRequired,
  }).isRequired,
  allowRedirect: PropTypes.bool,
};
