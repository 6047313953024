import './ProgressIndicator.scss';

import {CircularProgress, LinearProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function ProgressIndicator(props) {
  const styleOverride = props.sx ?? {};
  const variant = Number.isNaN(props.currentProgress) ? 'determinate' : 'indeterminate';
  const value = variant ? props.currentProgress : undefined;

  return (
    <div className={`ProgressIndicator ${props.stretch ? 'stretched' : ''}`} style={styleOverride}>
      {props.type === 'circular' &&
        <CircularProgress sx={styleOverride} variant={variant} value={value} />
      }
      {props.type === 'linear' &&
        <LinearProgress sx={{width: '100%', ...styleOverride}} variant={variant} value={value} />
      }
    </div>
  );
}

ProgressIndicator.propTypes = {
  type: PropTypes.oneOf(['circular', 'linear']),
  stretch: PropTypes.bool,
  sx: PropTypes.object,
  currentProgress: PropTypes.number,
};

ProgressIndicator.defaultProps = {
  type: 'circular',
  currentProgress: null,
};
