import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../../../components/ProgressIndicator/ProgressIndicator';
import {useIsAdministrator} from '../../../../customHooks/useIsAdministrator';
import formatDateShort from '../../../../helpers/formatters/formatDateShort';
import {useStateValue} from '../../../../state/state';
import deleteNote from './api/deleteNote';
import patchNote from './api/patchNote';
import css from './index.module.scss';

function NoteModal({isOpen, requestClose, requestRefresh, project, note}) {
  const {t} = useTranslation('component_notes');
  const [{user}] = useStateValue();
  const [text, setText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const userIsNoteAuthor = user && user?.id && note && note?.author && note?.author?.id && user.id === note.author.id;
  const userIsAdministrator = useIsAdministrator();

  function closeModal() {
    setIsEditing(false);
    requestClose();
  }

  function saveNote() {
    setIsSaving(true);
    setIsEditing(false);
    patchNote(note?.id, text).then(() => {
      setIsSaving(false);
      requestClose();
      requestRefresh();
    });
  }

  function removeNote() {
    if (window.confirm(t('delete_confirmation.text'))) {
      setIsSaving(true);
      setIsEditing(false);
      deleteNote(note?.id, text).then(() => {
        setIsSaving(false);
        requestClose();
        requestRefresh();
      });
    }
  }

  function updateTextField(id, text) {
    setText(text);
  }

  function cancelEdit() {
    setIsEditing(false);
    setText(note?.text ?? '');
  }

  useEffect(()=>{
    setText(note?.text ?? '');
  }, [note?.text]);

  if (!project || !note) return null;

  return (
    <ModalDialog
      open={isOpen}
      onCloseRequest={closeModal}
    >
      <Box className={css.metadata}>
        <Typography variant="mediumHint">
          {t('modal_view.field.creator.description')}: {note?.author?.display} <br />
          {t('modal_view.field.date.description')}: {formatDateShort(note?.createdAt)}
        </Typography>
      </Box>

      <EditField
        className={css.textField}
        id="noteContent"
        name=""
        value={text}
        type="stringTextArea"
        placeholder={t('modal_view.field.text.placeholder')}
        editing={isEditing}
        onChange={updateTextField}
      />


      {note?.author && (userIsAdministrator || userIsNoteAuthor) &&
            <DialogActions
              className={css.actions}
            >
              {isSaving &&
                <ProgressIndicator />
              }
              {!isEditing && !isSaving &&
                <Button onClick={() => setIsEditing(true)}>
                  {t('modal_view.action.edit')}
                </Button>
              }
              {isEditing && !isSaving &&
                <>
                  <Button onClick={removeNote} sx={{color: '#ED143D'}}>
                    {t('modal_view.action.delete')}
                  </Button>
                  <div className={css.spacer} />
                  <Button onClick={cancelEdit}>
                    {t('modal_view.action.cancel')}
                  </Button>
                  <Button onClick={saveNote}>
                    {t('modal_view.action.save')}
                  </Button>
                </>
              }
            </DialogActions>
      }
    </ModalDialog>
  );
}

NoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  requestRefresh: PropTypes.func.isRequired,
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']),
  }),
  note: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Note']),
  }),
};

export default NoteModal;
