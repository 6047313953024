import './ShowSingleTaskModal.scss';

import ClearIcon from '@mui/icons-material/Clear';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {useCompaniesDictionary} from '../../helpers/dictionaries/hooks/useCompaniesDictionary';
import {useExecutorsDictionary} from '../../helpers/dictionaries/hooks/useExecutorsDictionary';
import {useProjectAttachmentsDictionary} from '../../helpers/dictionaries/hooks/useProjectAttachmentsDictionary';
import {useProjectNotesDictionary} from '../../helpers/dictionaries/hooks/useProjectNotesDictionary';
import {useProjectsDictionary} from '../../helpers/dictionaries/hooks/useProjectsDictionary';
import {useProjectTasksDictionary} from '../../helpers/dictionaries/hooks/useProjectTasksDictionary';
import {useTasksDictionary} from '../../helpers/dictionaries/hooks/useTasksDictionary';
import {useUsersDictionary} from '../../helpers/dictionaries/hooks/useUsersDictionary';
import formatDateShort from '../../helpers/formatters/formatDateShort';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';
import hasCapability, {CAPABILITY_TODO_LIST_EDIT} from '../../helpers/security/hasCapability';
import {useStateValue} from '../../state/state';
import {ReferType} from '../AddingTaskModal/AddingTaskModal';
import EditField from '../EditField/EditField';
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';
import ModalDialog from '../ModalDialog/ModalDialog';

export default function ShowSingleTaskModal(props) {
  const {t, i18n} = useTranslation('component_tasks');

  const [{user}, dispatch] = useStateValue();
  const canEditTodoList = useHasCapabilityHere(CAPABILITY_TODO_LIST_EDIT);
  const taskLabelColor = (props.taskInfo?.completedAt !== null) ?
                          '#007900' : (props.taskInfo.deadline && (new Date(props.taskInfo.deadline) < new Date()) ?
                          '#ED143D' : '#1F8CC6');
  const initializePatchData = useCallback(
      function() {
        return {
          taskTitle: props.taskInfo.taskTitle || props.taskInfo.title,
          taskDescription: props.taskInfo.taskText || props.taskInfo.text,
          taskDeadline: props.taskInfo.deadline || props.taskInfo.deadlineAt,
          taskUsers: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'User')) ?? []) : (props.taskInfo.relatedUsers ?? []),
          taskDocuments: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'Attachment')) ?? []) : (props.taskInfo.relatedAttachments ?? []),
          taskCompanies: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'Company')) ?? []) : (props.taskInfo.relatedCompanies ?? []),
          taskNotes: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'Note')) ?? []) : (props.taskInfo.relatedNotes ?? []),
          taskTasks: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'Task')) ?? []) : (props.taskInfo.relatedTasks ?? []),
          taskProjects: props.taskInfo.refers ? (props.taskInfo.refers.find((refer) => refer?.some((referItem) => referItem['@type'] === 'Project')) ?? []) : (props.taskInfo.relatedProjects ?? []),
          taskExecutors: props.taskInfo.executors ?? [],
          completedAt: props.taskInfo.completedAt,
        };
      }, [props.taskInfo],
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [patchData, setPatchData] = useState(initializePatchData());
  const [completedAt, setCompletedAt] = useState(props.taskInfo.completedAt);
  const [refersArray, setRefersArray] = useState([{referType: '', referValue: ''}]);

  const usersList = useUsersDictionary();
  const tasksListHook = useTasksDictionary();
  const projectTasksListHook = useProjectTasksDictionary(props?.taskInfo?.projectId);
  const tasksList = props.taskInfo.projectId ? (projectTasksListHook.length>0 ? projectTasksListHook : props.taskInfo.relatedTasks) : tasksListHook;
  const notesListHook = useProjectNotesDictionary(props?.taskInfo?.projectId);
  const notesList = props.taskInfo.projectId ? notesListHook : props.taskInfo.relatedNotes ? props.taskInfo.relatedNotes : [];
  const documentsListHook = useProjectAttachmentsDictionary(props?.taskInfo?.projectId);
  const documentsList = props.taskInfo.projectId ? documentsListHook : props.taskInfo.relatedAttachments ? props.taskInfo.relatedAttachments : [];
  const projectsList = useProjectsDictionary();
  const companiesList = useCompaniesDictionary();
  const executorsList = useExecutorsDictionary();

  const referTypeDropdownOptions = props.type === 'project' ?
    [
      {name: ReferType.user, display: t('reference.type.user')},
      {name: ReferType.document, display: t('reference.type.document')},
      {name: ReferType.note, display: t('reference.type.note')},
      {name: ReferType.task, display: t('reference.type.task')},
      {name: ReferType.executor, display: t('reference.type.executor')},
      {name: ReferType.moneySuppliers, display: t('reference.type.moneySuppliers')},
      {name: ReferType.intermediares, display: t('reference.type.intermediares')},
      {name: ReferType.client, display: t('reference.type.client')},

    ] :
    [
      {name: ReferType.user, display: t('reference.type.user')},
      {name: ReferType.document, display: t('reference.type.document')},
      {name: ReferType.note, display: t('reference.type.note')},
      {name: ReferType.task, display: t('reference.type.task')},
      {name: ReferType.executor, display: t('reference.type.executor')},
      {name: ReferType.moneySuppliers, display: t('reference.type.moneySuppliers')},
      {name: ReferType.intermediares, display: t('reference.type.intermediares')},
      {name: ReferType.client, display: t('reference.type.client')},
      {name: ReferType.lead, display: t('reference.type.lead')},
      {name: ReferType.opportunity, display: t('reference.type.opportunity')},
      {name: ReferType.account, display: t('reference.type.account')},
    ];

  useEffect(() => {
    setPatchData(initializePatchData());
  }, [props.taskInfo, initializePatchData, setPatchData]);

  function toggleEdit() {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    setPatchData(initializePatchData());
  }

  const newValueTaskUsers = patchData.taskUsers.map((taskUser) => taskUser['@id']);
  const newValueTaskDocuments = patchData.taskDocuments.map((taskDocument) => taskDocument['@id']);
  const newValueTasksProjects = patchData.taskProjects.map((taskProject) => taskProject['@id']);
  const newValueTasksCompanies = patchData.taskCompanies.map((taskCompany) => taskCompany['@id']);
  const newValueTasksNotes = patchData.taskNotes.map((taskNote) => taskNote['@id']);
  const newValueTasksTasks = patchData.taskTasks.map((taskTask) => taskTask['@id']);
  const newValueTasksExecutors = patchData.taskExecutors.map((taskExecutor) => taskExecutor['@id']);

  function toggleSave() {
    setIsEditing(false);
    setIsSaving(true);
    if (refersArray.length > 0) {
      refersArray.map((refer) => {
        if (refer.referType === 'user') {
          newValueTaskUsers.push(refer.referValue);
        } else if (refer.referType === 'document') {
          newValueTaskDocuments.push(refer.referValue);
        } else if (refer.referType === 'note') {
          newValueTasksNotes.push(refer.referValue);
        } else if (refer.referType === 'moneySuppliers') {
          newValueTasksCompanies.push(refer.referValue);
        } else if (refer.referType === 'intermediares') {
          newValueTasksCompanies.push(refer.referValue);
        } else if (refer.referType === 'client') {
          newValueTasksCompanies.push(refer.referValue);
        } else if (refer.referType === 'lead') {
          newValueTasksProjects.push(refer.referValue);
        } else if (refer.referType === 'opportunity') {
          newValueTasksProjects.push(refer.referValue);
        } else if (refer.referType === 'account') {
          newValueTasksProjects.push(refer.referValue);
        } else if (refer.referType === 'executor') {
          newValueTasksExecutors.push(refer.referValue);
        } else if (refer.referType === 'task') {
          newValueTasksTasks.push(refer.referValue);
        }
      });
    }
    setRefersArray([{referType: '', referValue: ''}]);
    props.changeTask(props.taskInfo.taskId || props.taskInfo.id, patchData.taskTitle, patchData.taskDescription, patchData.taskDeadline, newValueTaskUsers, newValueTaskDocuments, newValueTasksProjects, newValueTasksCompanies, newValueTasksNotes, newValueTasksTasks, newValueTasksExecutors, patchData.completedAt);
  }

  function completeTask() {
    const completionDate = new Date().toISOString();
    setCompletedAt(completionDate);
    props.changeTask(
        props.taskInfo.taskId || props.taskInfo.id,
        patchData.taskTitle,
        patchData.taskDescription,
        patchData.taskDeadline,
        newValueTaskUsers,
        newValueTaskDocuments,
        newValueTasksProjects,
        newValueTasksCompanies,
        newValueTasksNotes,
        newValueTasksTasks,
        newValueTasksExecutors,
        completionDate,
    );
  }

  function editFieldChanged(id, value) {
    const data = Object.assign({}, patchData);
    switch (id) {
      case 'taskTitle':
        data.taskTitle = value;
        break;
      case 'taskDescription':
        data.taskDescription = value;
        break;
      case 'taskDeadline':
        data.taskDeadline = value;
        break;
      default:
    }
    setPatchData(data);
  }

  return (
    <ModalDialog
      className="TaskModal"
      open={props.isShowSingleTaskModalOpen}
      onCloseRequest={props.handleShowSingleTaskPopupClose}
    >
      <div className="modal-contents-wrapper">
        <div className="modal-content">
          <EditField
            id="taskTitle"
            name={t('edit_modal.field.name.description')}
            value={patchData.taskTitle}
            type="string"
            placeholder={t('edit_modal.field.name.placeholder')}
            editing={isEditing}
            onChange={editFieldChanged}
            className="task-title"
          />
          <Box sx={{display: 'flex', height: '90%', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '70%'}}>
              <Typography variant="h3" fontSize={20} fontWeight={500} marginTop={5}>{t('edit_modal.field.description.header')}</Typography>
              <EditField
                id="taskDescription"
                name={t('edit_modal.field.description.description')}
                value={patchData.taskDescription}
                type="stringTextArea"
                placeholder={t('edit_modal.field.description.placeholder')}
                editing={isEditing}
                onChange={editFieldChanged}
                className="task-description"
              />
              <Typography variant="h3" fontSize={20} fontWeight={500} marginTop={5}>{t('edit_modal.field.references.header')}</Typography>
              <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {patchData.taskUsers?.map((taskUser, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.user.header')}</Typography>
                  <Box sx={{display: 'flex'}}>
                    <EditField
                      id="taskUser"
                      name={t('edit_modal.field.user.description')}
                      value={taskUser['@id']}
                      type="dropdown"
                      placeholder={t('edit_modal.field.user.placeholder')}
                      options={usersList.map((user) => ({name: user['@id'], display: user['dropdownIdentifier']}))}
                      editing={isEditing}
                      onChange={(fieldId, newTaskUserValue) => {
                        const newTaskUsers = [...patchData.taskUsers];
                        newTaskUsers[index] =
                                usersList.find((usersListItem) => usersListItem['@id'] === newTaskUserValue);
                        setPatchData({...patchData, taskUsers: newTaskUsers});
                      }
                      }
                      className="task-user"
                      status={taskUser.status}
                      href={getFrontendPathToObject(taskUser)}
                    />
                    {isEditing && <IconButton aria-label="delete" color="red" onClick={
                      () => {
                        setPatchData({...patchData, taskUsers: patchData.taskUsers.filter((itemToRemove) => itemToRemove['@id'] !== taskUser['@id'])});
                      }
                    }>
                      <ClearIcon />
                    </IconButton>}
                  </Box>
                </Box>))}
                {patchData.taskDocuments?.map((taskDocument, index) => {
                  return (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                    <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.attachment.header')}</Typography>
                    <Box sx={{display: 'flex'}}>
                      <EditField
                        id="taskDocument"
                        name={t('edit_modal.field.attachment.description')}
                        value={taskDocument['@id']}
                        type="dropdown"
                        placeholder={t('edit_modal.field.attachment.placeholder')}
                        options={documentsList.map((document) => ({name: document['@id'], display: document['dropdownIdentifier'] || document['originalName']}))}
                        editing={isEditing}
                        onChange={(fieldId, newTaskDocumentValue) => {
                          const newTaskDocuments = [...patchData.taskDocuments];
                          newTaskDocuments[index] =
                                documentsList.find((documentsListItem) => documentsListItem['@id'] === newTaskDocumentValue);
                          setPatchData({...patchData, taskDocuments: newTaskDocuments});
                        }
                        }
                        className="task-document"
                        status={taskDocument.status}
                        href={getFrontendPathToObject(documentsList.find((document) => taskDocument['@id'] === document['@id'])?.project)}
                      />
                      {isEditing && <IconButton aria-label="delete" color="red" onClick={
                        () => {
                          setPatchData({...patchData, taskDocuments: patchData.taskDocuments.filter((itemToRemove) => itemToRemove['@id'] !== taskDocument['@id'])});
                        }
                      }>
                        <ClearIcon />
                      </IconButton>}
                    </Box>
                  </Box>);
                })}
                {patchData.taskNotes?.map((taskNote, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.note.header')}</Typography>
                  <Box sx={{display: 'flex'}}>
                    <EditField
                      id="taskNote"
                      name={t('edit_modal.field.note.description')}
                      value={taskNote['@id']}
                      type="dropdown"
                      placeholder={t('edit_modal.field.note.placeholder')}
                      options={notesList.map((note) => ({name: note['@id'], display: note['dropdownIdentifier'] || note['text']}))}
                      editing={isEditing}
                      onChange={(fieldId, newTaskNoteValue) => {
                        const newTaskNotes = [...patchData.taskNotes];
                        newTaskNotes[index] =
                              notesList.find((notesListItem) => notesListItem['@id'] === newTaskNoteValue);
                        setPatchData({...patchData, taskNotes: newTaskNotes});
                      }
                      }
                      className="task-note"
                      status={taskNote.status}
                      href={getFrontendPathToObject(notesList.find((note) => taskNote['@id'] === note['@id'])?.project)}
                    />
                    {isEditing && <IconButton aria-label="delete" color="red" onClick={
                      () => {
                        setPatchData({...patchData, taskNotes: patchData.taskNotes.filter((itemToRemove) => itemToRemove['@id'] !== taskNote['@id'])});
                      }
                    }>
                      <ClearIcon />
                    </IconButton>}
                  </Box>
                </Box>))}
                {patchData.taskTasks?.map((taskTask, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.task.header')}</Typography>
                  <Box sx={{display: 'flex'}}>
                    <EditField
                      id="taskTask"
                      name={t('edit_modal.field.task.description')}
                      value={taskTask['@id']}
                      type="dropdown"
                      placeholder={t('edit_modal.field.task.placeholder')}
                      options={tasksList.filter((taskItem) => taskItem['@id'] !== `/api/tasks/${props.taskInfo.taskId}`).map((task) => ({name: task['@id'], display: task['dropdownIdentifier'] || task['title']}))}
                      editing={isEditing}
                      onChange={(fieldId, newTaskTasksValue) => {
                        const newTaskTasks = [...patchData.taskTasks];
                        newTaskTasks[index] =
                              tasksList.find((tasksListItem) => tasksListItem['@id'] === newTaskTasksValue);
                        setPatchData({...patchData, taskTasks: newTaskTasks});
                      }
                      }
                      className="task-task"
                      status={taskTask.status}
                      href={getFrontendPathToObject(tasksList.find((task) => taskTask['@id'] === task['@id'])?.relatedProjects[0])}
                    />
                    {isEditing && <IconButton aria-label="delete" color="red" onClick={
                      () => {
                        setPatchData({...patchData, taskTasks: patchData.taskTasks.filter((itemToRemove) => itemToRemove['@id'] !== taskTask['@id'])});
                      }
                    }>
                      <ClearIcon />
                    </IconButton>}
                  </Box>
                </Box>))}
                {patchData.taskCompanies?.map((taskCompany, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.company.header')}</Typography>
                  <Box sx={{display: 'flex'}}>
                    <EditField
                      id="taskCompanies"
                      name={t('edit_modal.field.company.description')}
                      value={taskCompany['@id']}
                      type="dropdown"
                      placeholder={t('edit_modal.field.company.placeholder')}
                      options={companiesList.map((company) => ({name: company['@id'], display: company['name']}))}
                      editing={isEditing}
                      onChange={(fieldId, newTaskCompanyValue) => {
                        const newTaskCompanies = [...patchData.taskCompanies];
                        newTaskCompanies[index] =
                              companiesList.find((companiesListItem) => companiesListItem['@id'] === newTaskCompanyValue);
                        setPatchData({...patchData, taskCompanies: newTaskCompanies});
                      }
                      }
                      className="task-company"
                      status={taskCompany.status}
                      href={getFrontendPathToObject(taskCompany)}
                    />
                    {isEditing && <IconButton aria-label="delete" color="red" onClick={
                      () => {
                        setPatchData({...patchData, taskCompanies: patchData.taskCompanies.filter((itemToRemove) => itemToRemove['@id'] !== taskCompany['@id'])});
                      }
                    }>
                      <ClearIcon />
                    </IconButton>}
                  </Box>
                </Box>))}
                {patchData.taskProjects?.map((taskProject, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.project.header')}</Typography>
                  <Box sx={{display: 'flex'}}>
                    <EditField
                      id="taskProject"
                      name={t('edit_modal.field.project.description')}
                      value={taskProject['@id']}
                      type="dropdown"
                      placeholder={t('edit_modal.field.project.placeholder')}
                      options={projectsList.map((project) => ({name: project['@id'], display: project['dropdownIdentifier']}))}
                      editing={isEditing}
                      onChange={(fieldId, newTaskProjectValue) => {
                        const newTaskProjects = [...patchData.taskProjects];
                        newTaskProjects[index] =
                              projectsList.find((projectsListItem) => projectsListItem['@id'] === newTaskProjectValue);
                        setPatchData({...patchData, taskProjects: newTaskProjects});
                      }
                      }
                      className="task-project"
                      status={taskProject.status}
                      href={getFrontendPathToObject(taskProject)}
                    />
                    {isEditing && <IconButton aria-label="delete" color="red" onClick={
                      () => {
                        setPatchData({...patchData, taskProjects: patchData.taskProjects.filter((itemToRemove) => itemToRemove['@id'] !== taskProject['@id'])});
                      }
                    }>
                      <ClearIcon />
                    </IconButton>}
                  </Box>
                </Box>))}
              </Box>

              {/* start adding new referes */}
              {isEditing && <Typography variant="h3" fontSize={18} fontWeight={500} marginTop={5}>{t('edit_modal.field.references.header_new')}</Typography>}
              {isEditing && refersArray.map((refer, index) => {
                return <>
                  <Box className="AddRefer" sx={{display: 'flex', flexDirection: 'row', height: '100px'}}>
                    <EditField
                      id="referType"
                      name={t('edit_modal.field.reference.description_type')}
                      value={refer.referType}
                      type="dropdown"
                      placeholder={t('edit_modal.field.reference.placeholder_type')}
                      options={referTypeDropdownOptions}
                      editing={true}
                      onChange={(fieldId, newReferTypeValue) => {
                        const newRefers = [...refersArray];
                        newRefers[index] = {
                          ...newRefers[index], referType: newReferTypeValue,
                        };
                        setRefersArray(newRefers);
                      }}
                      className="ReferType"
                    />
                    <EditField
                      id="refer"
                      name={t('edit_modal.field.reference.description_object')}
                      value={refer.referValue}
                      type="autocomplete"
                      placeholder={t('edit_modal.field.reference.placeholder_object')}
                      options={refer.referType === ReferType.user ?
                                                      usersList.map((user) => ({name: user['@id'], display: user['dropdownIdentifier']})) : (refer.referType === ReferType.executor) ?
                                                      executorsList.map((executor) => ({name: executor['@id'], display: executor['dropdownIdentifier']})) : (refer.referType === ReferType.document) ?
                                                      documentsList.map((document) => ({name: document['@id'], display: document['dropdownIdentifier']})) : (refer.referType === ReferType.note) ?
                                                      notesList.map((note) => ({name: note['@id'], display: note['dropdownIdentifier']})) : (refer.referType === ReferType.task) ?
                                                      tasksList.filter((taskItem) => taskItem['@id'] !== `/api/tasks/${props.taskInfo.taskId}`).map((task) => ({name: task['@id'], display: task['dropdownIdentifier']})) : (refer.referType === ReferType.moneySuppliers) ?
                                                      companiesList.filter((company) => company['type'] === 'money_supplier').map( (moneySupplier) => ({name: moneySupplier['@id'], display: moneySupplier['name']})) : (refer.referType === ReferType.intermediares) ?
                                                      companiesList.filter((company) => company['type'] === 'intermediary').map( (intermediares) => ({name: intermediares['@id'], display: intermediares['name']})) : (refer.referType === ReferType.client) ?
                                                      companiesList.filter((company) => company['type'] === 'client').map( (client) => ({name: client['@id'], display: client['name']})) : (refer.referType === ReferType.lead) ?
                                                      projectsList.filter((project) => (project['status'] === 'lead_pending' || project['status'] === 'lead_rejected')).map( (lead) => ({name: lead['@id'], display: lead['dropdownIdentifier']})) : (refer.referType === ReferType.opportunity) ?
                                                      projectsList.filter((project) => (project['status'] === 'opportunity_trajectory' || project['status'] === 'opportunity_payment' || project['status'] === 'opportunity_memorandum_creating' || project['status'] === 'opportunity_memorandum_sent' || project['status'] === 'opportunity_resigned')).map( (opportunity) => ({name: opportunity['@id'], display: opportunity['dropdownIdentifier']})) : (refer.referType === ReferType.account) ?
                                                      projectsList.filter((project) => (project['status'] === 'account_in_progress' || project['status'] === 'account_in_progress')).map( (account) => ({name: account['@id'], display: account['dropdownIdentifier']})) : []}
                      editing={true}
                      onChange={(fieldId, newReferValue) => {
                        const newRefers = [...refersArray];
                        newRefers[index] = {
                          ...newRefers[index], referValue: newReferValue,
                        };
                        setRefersArray(newRefers);
                      }}
                      className="Refer"
                    />
                    <IconButton aria-label="delete" color="red" sx={{margin: '50px 0'}} onClick={
                      () => {
                        const newRefersArray = [...refersArray];
                        newRefersArray.splice(index, 1);
                        setRefersArray(newRefersArray);
                      }
                    }>
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </>;
              })
              }
              {isEditing && <Button
                size="small"
                onClick={() => setRefersArray([...refersArray, {referType: '', referValue: ''}])}
                sx={{fontSize: '16px'}}
              >
                {t('edit_modal.action.add')}
              </Button>}
              {/* end adding new referes */}

            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '25%', justifyContent: 'space-between'}}>
              <Box>
                <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.creator.description')}</Typography>
                <Typography variant="p" fontWeight={400} marginTop={3}>{props.taskInfo.creator?.display}</Typography>
                {patchData.taskExecutors?.map((taskExecutor, index) => (<Box key={index} sx={{display: 'flex', flexDirection: 'column', width: '160px'}}>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.executor.header')}</Typography>
                  <EditField
                    id="taskExecutor"
                    name={t('edit_modal.field.executor.description')}
                    value={taskExecutor['@id']}
                    type="dropdown"
                    placeholder={t('edit_modal.field.executor.placeholder')}
                    options={executorsList.map((executor) => ({name: executor['@id'], display: executor['dropdownIdentifier']}))}
                    editing={isEditing}
                    onChange={(fieldId, newTaskExecutorValue) => {
                      const newTaskExecutors = [...patchData.taskExecutors];
                      newTaskExecutors[index] =
                            executorsList.find((executorsListItem) => executorsListItem['@id'] === newTaskExecutorValue);
                      setPatchData({...patchData, taskExecutors: newTaskExecutors});
                    }
                    }
                    className="task-executor"
                  />
                </Box>))}
                {patchData.taskDeadline !== null && <>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.deadline.header')}</Typography>
                  <EditField
                    id="taskDeadline"
                    name={t('edit_modal.field.deadline.description')}
                    value={patchData.taskDeadline}
                    type="datepicker"
                    placeholder={t('edit_modal.field.deadline.placeholder')}
                    editing={isEditing}
                    onChange={editFieldChanged}
                    className="task-deadline"
                  />
                </>}

                {props.taskInfo.completedAt && <>
                  <Typography variant="h3" fontSize={14} fontWeight={400} marginTop={3} color="#9C9FAF">{t('edit_modal.field.completion_date.header')}</Typography>
                  <EditField
                    id="taskCompleted"
                    name={t('edit_modal.field.completion_date.description')}
                    value={patchData.completedAt ? formatDateShort(patchData.completedAt) : ''}
                    type="string"
                    className="task-completed"
                  />
                </>}
                <Box className="TaskItem-label" sx={{width: '40px', margin: '15px 0 0 0', border: `5px solid ${taskLabelColor}`, borderRadius: '4px'}}></Box>
                <HorizontalDivider />
                {canEditTodoList &&<Box>
                  {((user.primaryRole === 'ROLE_SALES' && props.taskInfo.executors?.some((executorItem) => executorItem.id === user.id)) || user.primaryRole !== 'ROLE_SALES') &&<Button variant="contained" disabled={props.taskInfo.completedAt !== null ? true : false} sx={{margin: '24px 0'}} onClick={completeTask}>{t('edit_modal.action.complete')}</Button>}
                </Box>}
              </Box>
              {canEditTodoList &&
                  <Box>
                    <DialogActions sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '0px'}}>
                      {((user.primaryRole === 'ROLE_SALES' && props.taskInfo.executors?.some((executorItem) => executorItem.id === user.id) && props.taskInfo.creator.id === user.id) || user.primaryRole !== 'ROLE_SALES') && (
                        <>
                          {!isEditing && <Button onClick={() => toggleEdit(props.taskInfo.projectId)} sx={{'paddingLeft': '0px', '&:hover': {backgroundColor: '#FAFBFD'}}}>{t('edit_modal.action.edit')}</Button>}
                          {isEditing && <Button onClick={toggleSave} sx={{'paddingLeft': '0px', '&:hover': {backgroundColor: '#FAFBFD'}}}>{t('edit_modal.action.save')}</Button>}
                        </>
                      )}
                      <Button onClick={() => props.removeTask(props.taskInfo.taskId || props.taskInfo.id)} sx={{'color': '#ED143D', 'paddingLeft': '0px', 'marginLeft': '0px !important', '&:hover': {backgroundColor: '#FAFBFD'}}}>{t('edit_modal.action.delete')}</Button>
                    </DialogActions>
                  </Box>}
            </Box>
          </Box>
        </div>
      </div>
    </ModalDialog>
  );
}

ShowSingleTaskModal.propTypes = {
  changeTask: PropTypes.func,
  handleShowSingleTaskPopupClose: PropTypes.func,
  isShowSingleTaskModalOpen: PropTypes.bool.isRequired,
  removeTask: PropTypes.func,
  taskInfo: PropTypes.object,
};
