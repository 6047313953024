import Api from '../../../../helpers/api/Api';

export default function getTasksList(
    urlQueryPart,
) {
  return new Promise((resolve, reject) => {
    const url = [
      '/tasks/excerpts',
      urlQueryPart,
    ].filter((row) => !!row).join('?');

    Api.get(url).then((data)=>{
      resolve({
        tasks: data['hydra:member'],
        totalItems: parseInt(data['hydra:totalItems']),
      });
    }).catch(() => {
      reject();
    });
  });
}
