import {useEffect} from 'react';

import {SERVER_QUERY_LOOP_INTERVAL_MS} from '../config';

export const useIntervalLoop = (func) => {
  useEffect(() => {
    if (typeof func === 'function') {
      const intervalId = setInterval(func, SERVER_QUERY_LOOP_INTERVAL_MS);
      return function() {
        clearInterval(intervalId);
      };
    }
  }, [func]);
};
