import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

export const PARAM_PAGE = 'page';
export const PARAM_ITEMS_PER_PAGE = 'itemsPerPage';
export const PARAM_SEARCH = 'search';
export const PARAM_ORDER_BY = 'orderBy';
export const PARAM_ORDER_DIR = 'orderDir';
export const PARAM_DATE_MIN = 'minDate';
export const PARAM_DATE_MAX = 'maxDate';
export const PARAM_SKIP_COMPLETED = 'skipCompleted';
export const PARAM_SKIP_UNCOMPLETED = 'skipUncompleted';
export const PARAM_SKIP_UNEXPIRED = 'skipUnexpired';
export const PARAM_ONLY_MINE = 'onlyForMe';
export const PARAM_ONLY_FOR_USER_ID = 'onlyForUserId';
export const PARAM_PROJECTS_WITH_STATUS = 'onlyProjectsWithStatus';
export const PARAM_COMPANIES_WITH_TYPE = 'onlyCompaniesWithType';
export const PARAM_WITH_NOTES = 'onlyWithNotes';
export const PARAM_WITH_ATTACHMENTS = 'onlyWithAttachments';
export const PARAM_WITH_USERS = 'onlyWithUsers';
export const PARAM_WITH_TASKS = 'onlyWithTasks';
export const PARAM_ONLY_FOR_COMPANY_ID = 'onlyForCompanyId';


export const PARAMS_DEFAULTS = {
  [PARAM_PAGE]: {default: 1, type: 'int'},
  [PARAM_ITEMS_PER_PAGE]: {default: 15, type: 'int'},
  [PARAM_SEARCH]: {default: ''},
  [PARAM_ORDER_BY]: {default: 'deadlineAt'},
  [PARAM_ORDER_DIR]: {default: 'desc'},
  [PARAM_DATE_MIN]: {default: null},
  [PARAM_DATE_MAX]: {default: null},
  [PARAM_SKIP_COMPLETED]: {default: false, type: 'bool'},
  [PARAM_SKIP_UNCOMPLETED]: {default: false, type: 'bool'},
  [PARAM_SKIP_UNEXPIRED]: {default: false, type: 'bool'},
  [PARAM_ONLY_MINE]: {default: true, type: 'bool'},
  [PARAM_ONLY_FOR_USER_ID]: {default: null, type: 'int'},
  [PARAM_PROJECTS_WITH_STATUS]: {default: null},
  [PARAM_COMPANIES_WITH_TYPE]: {default: null},
  [PARAM_WITH_NOTES]: {default: false, type: 'bool'},
  [PARAM_WITH_ATTACHMENTS]: {default: false, type: 'bool'},
  [PARAM_WITH_USERS]: {default: false, type: 'bool'},
  [PARAM_WITH_TASKS]: {default: false, type: 'bool'},
  [PARAM_ONLY_FOR_COMPANY_ID]: {default: null, type: 'int'},
};
export default function useTasksListFilters() {
  const [urlParams, setUrlParams] = useSearchParams();

  const _PARAM_PAGE = normalizedValue(PARAM_PAGE, urlParams);
  const _PARAM_ITEMS_PER_PAGE = normalizedValue(PARAM_ITEMS_PER_PAGE, urlParams);
  const _PARAM_SEARCH = normalizedValue(PARAM_SEARCH, urlParams);
  const _PARAM_ORDER_BY = normalizedValue(PARAM_ORDER_BY, urlParams);
  const _PARAM_ORDER_DIR = normalizedValue(PARAM_ORDER_DIR, urlParams);
  const _PARAM_DATE_MIN = normalizedValue(PARAM_DATE_MIN, urlParams);
  const _PARAM_DATE_MAX = normalizedValue(PARAM_DATE_MAX, urlParams);
  const _PARAM_SKIP_COMPLETED = normalizedValue(PARAM_SKIP_COMPLETED, urlParams);
  const _PARAM_SKIP_UNCOMPLETED = normalizedValue(PARAM_SKIP_UNCOMPLETED, urlParams);
  const _PARAM_SKIP_UNEXPIRED = normalizedValue(PARAM_SKIP_UNEXPIRED, urlParams);
  const _PARAM_ONLY_MINE = normalizedValue(PARAM_ONLY_MINE, urlParams, PARAMS_DEFAULTS[PARAM_ONLY_MINE].type);
  const _PARAM_ONLY_FOR_USER_ID = normalizedValue(PARAM_ONLY_FOR_USER_ID, urlParams);
  const _PARAM_PROJECTS_WITH_STATUS = normalizedValue(PARAM_PROJECTS_WITH_STATUS, urlParams);
  const _PARAM_COMPANIES_WITH_TYPE = normalizedValue(PARAM_COMPANIES_WITH_TYPE, urlParams);
  const _PARAM_WITH_NOTES = normalizedValue(PARAM_WITH_NOTES, urlParams);
  const _PARAM_WITH_ATTACHMENTS = normalizedValue(PARAM_WITH_ATTACHMENTS, urlParams);
  const _PARAM_WITH_USERS = normalizedValue(PARAM_WITH_USERS, urlParams);
  const _PARAM_WITH_TASKS = normalizedValue(PARAM_WITH_TASKS, urlParams);
  const _PARAM_ONLY_FOR_COMPANY_ID = normalizedValue(PARAM_ONLY_FOR_COMPANY_ID, urlParams);

  const current = useMemo(()=>{
    return {
      [PARAM_PAGE]: _PARAM_PAGE,
      [PARAM_ITEMS_PER_PAGE]: _PARAM_ITEMS_PER_PAGE,
      [PARAM_SEARCH]: _PARAM_SEARCH,
      [PARAM_ORDER_BY]: _PARAM_ORDER_BY,
      [PARAM_ORDER_DIR]: _PARAM_ORDER_DIR,
      [PARAM_DATE_MIN]: _PARAM_DATE_MIN,
      [PARAM_DATE_MAX]: _PARAM_DATE_MAX,
      [PARAM_SKIP_COMPLETED]: _PARAM_SKIP_COMPLETED,
      [PARAM_SKIP_UNCOMPLETED]: _PARAM_SKIP_UNCOMPLETED,
      [PARAM_SKIP_UNEXPIRED]: _PARAM_SKIP_UNEXPIRED,
      [PARAM_ONLY_MINE]: _PARAM_ONLY_MINE,
      [PARAM_ONLY_FOR_USER_ID]: _PARAM_ONLY_FOR_USER_ID,
      [PARAM_PROJECTS_WITH_STATUS]: _PARAM_PROJECTS_WITH_STATUS,
      [PARAM_COMPANIES_WITH_TYPE]: _PARAM_COMPANIES_WITH_TYPE,
      [PARAM_WITH_NOTES]: _PARAM_WITH_NOTES,
      [PARAM_WITH_ATTACHMENTS]: _PARAM_WITH_ATTACHMENTS,
      [PARAM_WITH_USERS]: _PARAM_WITH_USERS,
      [PARAM_WITH_TASKS]: _PARAM_WITH_TASKS,
      [PARAM_ONLY_FOR_COMPANY_ID]: _PARAM_ONLY_FOR_COMPANY_ID,
    };
  }, [_PARAM_COMPANIES_WITH_TYPE, _PARAM_DATE_MAX, _PARAM_DATE_MIN, _PARAM_ITEMS_PER_PAGE, _PARAM_ONLY_FOR_COMPANY_ID, _PARAM_ONLY_FOR_USER_ID, _PARAM_ONLY_MINE, _PARAM_ORDER_BY, _PARAM_ORDER_DIR, _PARAM_PAGE, _PARAM_PROJECTS_WITH_STATUS, _PARAM_SEARCH, _PARAM_SKIP_COMPLETED, _PARAM_SKIP_UNCOMPLETED, _PARAM_SKIP_UNEXPIRED, _PARAM_WITH_ATTACHMENTS, _PARAM_WITH_NOTES, _PARAM_WITH_TASKS, _PARAM_WITH_USERS]);

  const setParams = useCallback((params)=>{
    const newUrlParams = new URLSearchParams(urlParams);
    let changed = false;
    for (const paramName in params) {
      if (current[paramName] !== params[paramName]) {
        if (params[paramName] === PARAMS_DEFAULTS[paramName].default) {
          newUrlParams.delete(paramName);
        } else {
          newUrlParams.set(paramName, params[paramName]);
        }
        changed=true;
      }
    }
    if (changed) {
      setUrlParams(newUrlParams);
    }
  }, [current, setUrlParams, urlParams]);

  const currentFetchUrl = useMemo(()=>{
    function isDefault(paramName) {
      return PARAMS_DEFAULTS[paramName] && current && current[paramName] === PARAMS_DEFAULTS[paramName].default;
    }
    function nullIfDefault(paramName, queryPart) {
      return isDefault(paramName) ? null : queryPart;
    }
    return [
      nullIfDefault(PARAM_PAGE, `${PARAM_PAGE}=${_PARAM_PAGE}`),
      `${PARAM_ITEMS_PER_PAGE}=${_PARAM_ITEMS_PER_PAGE}`,
      nullIfDefault(PARAM_SEARCH, `${PARAM_SEARCH}=${_PARAM_SEARCH}`),
      `order[${_PARAM_ORDER_BY}]=${_PARAM_ORDER_DIR}`,
      nullIfDefault(PARAM_DATE_MIN, `${PARAM_DATE_MIN}=${_PARAM_DATE_MIN}`),
      nullIfDefault(PARAM_DATE_MAX, `${PARAM_DATE_MAX}=${_PARAM_DATE_MAX}`),
      nullIfDefault(PARAM_DATE_MAX, `${PARAM_DATE_MAX}=${_PARAM_DATE_MAX}`),
      nullIfDefault(PARAM_SKIP_COMPLETED, `${PARAM_SKIP_COMPLETED}=true`),
      nullIfDefault(PARAM_SKIP_UNCOMPLETED, `${PARAM_SKIP_UNCOMPLETED}=true`),
      nullIfDefault(PARAM_SKIP_UNEXPIRED, `${PARAM_SKIP_UNEXPIRED}=true`),
      `${PARAM_ONLY_MINE}=${_PARAM_ONLY_MINE}`,
      nullIfDefault(PARAM_ONLY_FOR_USER_ID, `${PARAM_ONLY_FOR_USER_ID}=${_PARAM_ONLY_FOR_USER_ID}`),
      nullIfDefault(PARAM_PROJECTS_WITH_STATUS, `${PARAM_PROJECTS_WITH_STATUS}=${_PARAM_PROJECTS_WITH_STATUS}`),
      nullIfDefault(PARAM_COMPANIES_WITH_TYPE, `${PARAM_COMPANIES_WITH_TYPE}=${_PARAM_COMPANIES_WITH_TYPE}`),
      nullIfDefault(PARAM_WITH_NOTES, `${PARAM_WITH_NOTES}=true`),
      nullIfDefault(PARAM_WITH_ATTACHMENTS, `${PARAM_WITH_ATTACHMENTS}=true`),
      nullIfDefault(PARAM_WITH_USERS, `${PARAM_WITH_USERS}=true`),
      nullIfDefault(PARAM_WITH_TASKS, `${PARAM_WITH_TASKS}=true`),
      nullIfDefault(PARAM_ONLY_FOR_COMPANY_ID, `${PARAM_ONLY_FOR_COMPANY_ID}=${_PARAM_ONLY_FOR_COMPANY_ID}`),
    ].filter((part) => part!== null).join('&');
  }, [_PARAM_COMPANIES_WITH_TYPE, _PARAM_DATE_MAX, _PARAM_DATE_MIN, _PARAM_ITEMS_PER_PAGE, _PARAM_ONLY_FOR_COMPANY_ID, _PARAM_ONLY_FOR_USER_ID, _PARAM_ONLY_MINE, _PARAM_ORDER_BY, _PARAM_ORDER_DIR, _PARAM_PAGE, _PARAM_PROJECTS_WITH_STATUS, _PARAM_SEARCH, current]);

  return {
    current,
    setParams,
    currentFetchUrl,
  };
}

function normalizedValue(paramName, urlParams, type='string') {
  if (typeof PARAMS_DEFAULTS[paramName] === 'undefined') {
    return null;
  } else {
    const strVal = urlParams.has(paramName) ? urlParams.get(paramName) : PARAMS_DEFAULTS[paramName].default;
    switch (type) {
      case 'int':
      case 'integer':
        return !isNaN(parseInt(strVal)) ? parseInt(strVal) : null;
      case 'bool':
      case 'boolean':
        if (strVal==='true' || strVal === '1') {
          return true;
        } else if (strVal==='false' || strVal === '0') {
          return false;
        } else {
          return PARAMS_DEFAULTS[paramName].default;
        }
      default:
        return strVal;
    }
  }
}


