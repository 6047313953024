// @ts-ignore
import Api from '../../api/Api';
import AbstractCachedDictionary from './AbstractCachedDictionary';

const ITEMS_PER_PAGE = 1000000;

class LegalFormsCachedDictionary extends AbstractCachedDictionary {
  static uniqueKey(): string {
    return 'LegalFormsCachedDictionary';
  }

  protected getUniqueKey(): string {
    return LegalFormsCachedDictionary.uniqueKey();
  }

  getTimeToLiveMiliseconds(): number {
    return 600000;
  }

  fetchNewDataset(params: {[key:string]:any}|null = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = AbstractCachedDictionary.joinUrl(
          '/company_legal_forms',
          [
            `itemsPerPage=${ITEMS_PER_PAGE}`,
          ],
      );
      // @ts-ignore
      Api.get(url).then((data)=>{
        resolve(data['hydra:member']);
        // @ts-ignore
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static getInstance(): AbstractCachedDictionary {
    return AbstractCachedDictionary.getOrCreateInstance(
        LegalFormsCachedDictionary.uniqueKey(),
        () => ( new LegalFormsCachedDictionary() ),
    );
  }
}

export default LegalFormsCachedDictionary;
