import Api from '../../../helpers/api/Api';
import prepareProjectsListUrlParameters from '../functions/prepareProjectsListUrlParameters';

export default function getProjectsListExport(
    pageScope,
    search,
    status,
    handler,
    showOnlyMine,
    securityContextId,
    order,
    page,
    itemsPerPage = 10,
) {
  return new Promise(function(resolve, reject) {
    const url = prepareProjectsListUrlParameters(
        '/projects/export',
        pageScope,
        search,
        status,
        handler,
        showOnlyMine,
        securityContextId,
        order,
        page,
        itemsPerPage,
    );

    Api.getBinary(url).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
