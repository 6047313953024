import getProjectNormalizedInternalName from '../../helpers/formatters/getProjectNormalizedInternalName';
import status2string from '../../helpers/formatters/status2string';
import i18n from '../../i18n';

export default function getRecentActivityTemplateVariables(activity) {
  const variables = {
    filename: activity?.relatedAttachment?.originalName ?? activity?.data?.originalName,
    project: activity?.relatedProject ? getProjectNormalizedInternalName(activity.relatedProject) : '',
    secondary: '',
    primary: '',
    company: activity?.relatedCompany?.name ?? activity?.relatedProject?.company?.name,
    projectCompanyRole: activity?.relatedProjectCompany?.roles?.join(', '),
    user: activity?.relatedUser?.display,
    userEmail: activity?.relatedUser ? activity.relatedUser?.display : activity?.data?.email,
    oldProject: '',
    newProject: '',
    oldStatus: '',
    newStatus: '',
    task: activity?.relatedTask?.title ? activity.relatedTask.title : '',
    executor: activity?.relatedTask?.executors ? activity.relatedTask.executors.map((e) => e?.display).join(', ') : '',
    CPUMActionName: '',
  };
  if (activity.name === 'attachment_deleted') {
    variables.filename = activity.data?.name;
  }
  if (activity.name === 'companies_merged') {
    variables.primary = activity.data?.primary?.name ? activity.data.primary.name : activity?.data?.primary;
    variables.secondary = activity.data?.secondary?.name ? activity.data.secondary.name : activity?.data?.secondary;
  }
  if (activity.name === 'projects_merged') {
    variables.primary = activity.data?.primary?.internalName;
    variables.secondary = activity.data?.secondary?.internalName;
  }
  if (activity.name === 'users_merged') {
    variables.primary = activity.data?.primary?.display;
    variables.secondary = activity.data?.secondary?.display;
  }
  if (activity.name === 'project_name_changed') {
    variables.oldProject = activity?.data?.from;
    variables.newProject = activity?.data?.to;
  }
  if (activity.name === 'project_status_changed') {
    variables.oldStatus = status2string(activity?.data?.from);
    variables.newStatus = status2string(activity?.data?.to);
  }
  if (activity.name === 'company_projects_user_map_changed') {
    if (activity?.data?.changes && activity?.data?.changes[0]) {
      const firstChange = activity?.data?.changes[0];
      variables.user = firstChange?.user?.display;
      variables.company = firstChange?.company?.name;
      variables.project = firstChange?.project?.internalName;
      variables.CPUMActionName = firstChange.change ?
        i18n.t('template.company_projects_user_map_changed_action_attach', {ns: 'component_recent_activity'}) :
        i18n.t('template.company_projects_user_map_changed_action_detach', {ns: 'component_recent_activity'});
    }
  }
  return variables;
}
