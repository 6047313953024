export const ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SALES = 'ROLE_SALES';
export const ROLE_VIEWONLY = 'ROLE_VIEWONLY';
export const ROLE_USER = 'ROLE_USER';

export default function listAllRoles() {
  return [
    ROLE_SUPERADMIN,
    ROLE_ADMIN,
    ROLE_SALES,
    ROLE_VIEWONLY,
    ROLE_USER,
  ];
}
