import PropTypes from 'prop-types';
import React from 'react';

import Item from '../../../../components/Item/Item';
import css from './index.module.scss';

function NoteExcerpt({note, withUnderline, requestShowNote}) {
  return (
    <Item
      className={css.NoteExcerpt}
      id={note?.id}
      iri={note['@id']}
      author={note?.author?.display}
      withUnderline={withUnderline}
      content={note?.text}
      onClick={() => requestShowNote(note)}
    />
  );
}

NoteExcerpt.propTypes = {
  note: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Note']),
  }).isRequired,
  withUnderline: PropTypes.bool,
  requestShowNote: PropTypes.func.isRequired,
};

export default NoteExcerpt;
