import Api from '../../../helpers/api/Api';

export default function postMergeUsers(primaryUser, secondaryUser) {
  return new Promise(function(resolve, reject) {
    if (primaryUser === null || secondaryUser === null || primaryUser===secondaryUser) {
      reject();
    }

    Api.post('/merge/users', {
      primary: primaryUser,
      secondary: secondaryUser,
    }).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
