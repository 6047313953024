import Api from '../../../helpers/api/Api';

export default function patchNote(note) {
  return new Promise(function(resolve, reject) {
    let noteId = null;
    if (typeof !isNaN(note.id)) {
      noteId = parseInt(note.id);
      delete note.id;
    }
    if (typeof note['@id'] !== 'undefined') {
      if (!noteId) {
        noteId = parseInt(note['@id'].replace('/api/notes/', ''));
      }
      delete note['@id'];
    }
    if (!noteId) {
      reject(new Error('Unrecognized note'));
    }
    if (typeof note['@type'] !== 'undefined') {
      delete note['@type'];
    }

    Api.patch(`/notes/${noteId}`, note).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
