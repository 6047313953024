import Api from '../../../helpers/api/Api';

export default function postPasswordResetRequest(userEmail) {
  return new Promise(function(resolve, reject) {
    Api.post('/users/password_reset/send_link', {
      email: userEmail,
    }).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
