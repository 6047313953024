import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import useProjectsListFilters from '../../hooks/useProjectsListFilters';
import css from './index.module.scss';
function StatusFilter({pageScope, onChange}) {
  const {t} = useTranslation('page_cases');
  const {current} = useProjectsListFilters();

  const availableStatuses = useMemo(()=>{
    switch (pageScope) {
      case 'leads':
        return [
          {name: '', display: t('all', {ns: 'dictionary_project_status'})},
          {name: 'lead_pending', display: t('lead_pending', {ns: 'dictionary_project_status'})},
          {name: 'lead_rejected', display: t('lead_rejected', {ns: 'dictionary_project_status'})},
        ];
      case 'opportunities':
        return [
          {name: '', display: t('all', {ns: 'dictionary_project_status'})},
          {name: 'opportunity_trajectory', display: t('opportunity_trajectory', {ns: 'dictionary_project_status'})},
          {name: 'opportunity_payment', display: t('opportunity_payment', {ns: 'dictionary_project_status'})},
          {name: 'opportunity_memorandum_creating', display: t('opportunity_memorandum_creating', {ns: 'dictionary_project_status'})},
          {name: 'opportunity_memorandum_sent', display: t('opportunity_memorandum_sent', {ns: 'dictionary_project_status'})},
          {name: 'opportunity_resigned', display: t('opportunity_resigned', {ns: 'dictionary_project_status'})},
        ];
      case 'accounts':
        return [
          {name: '', display: t('all', {ns: 'dictionary_project_status'})},
          {name: 'account_in_progress', display: t('account_in_progress', {ns: 'dictionary_project_status'})},
          {name: 'account_closed', display: t('account_closed', {ns: 'dictionary_project_status'})},
        ];
      default:
        return [];
    }
  }, [pageScope, t]);

  return (
    <div className={css.StatusFilter}>
      <EditField
        id="projectStatus"
        value={current.status}
        type="dropdown"
        placeholder={t('filter.status_placeholder')}
        options={availableStatuses}
        editing={true}
        onChange={(fieldId, newValue) => {
          onChange(newValue);
        }}
      />
    </div>
  );
}

StatusFilter.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};
export default StatusFilter;
