import Api from '../../../helpers/api/Api';
export default function getProject(id) {
  return new Promise(function(resolve, reject) {
    Api.get(`/projects/${id}`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
