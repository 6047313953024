import Api from '../../../helpers/api/Api';

export default function patchTask(id, newTaskTitle, newTaskText, newDeadline, newUsers, newDocuments, newProjects, newCompanies, newNotes, newTasks, newExecutors, completedAt) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/tasks/${id}`, {
      title: newTaskTitle,
      text: newTaskText,
      deadlineAt: newDeadline,
      relatedUsers: newUsers,
      relatedAttachments: newDocuments,
      relatedProjects: newProjects,
      relatedCompanies: newCompanies,
      relatedNotes: newNotes,
      relatedTasks: newTasks,
      executors: newExecutors,
      completedAt: completedAt,
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
