import './ModalDialog.scss';

import {Close} from '@mui/icons-material';
import {Modal} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function ModalDialog(props) {
  return (
    <Modal
      className={`ModalDialog ${props.className ?? ''}`}
      open={props.open}
      onClose={props.onCloseRequest}
      sx={{overflow: 'auto'}}
    >
      <div className="ModalDialog-wrapper">
        <div className="ModalDialog-inner">
          <div className="ModalDialog-close" onClick={props.onCloseRequest}>
            <Close />
          </div>
          {props.children}
        </div>
        <div className="ModalDialog-bottomSpacer"></div>
      </div>
    </Modal>
  );
}

ModalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseRequest: PropTypes.func,
  className: PropTypes.string,
};
