import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import {useCallback} from 'react';

function EditFieldDropdownFilters(props) {
  function onCheckboxChange(event) {
    props.onChange(event.target.value);
  }

  const debouncedRefresh = useCallback(
      debounce(() => {
        props.refresh();
      }, 700),
      [props.refresh],
  );

  return (
    <FormControl style={{minWidth: 120}}>
      <InputLabel
        shrink
        size="small"
        style={{backgroundColor: '#ffffff', padding: 2}}
      >
        {props.placeholder}
      </InputLabel>
      <Select
        className="filters"
        style={{height: 30, fontSize: 14}}
        label={props.placeholder}
        size="small"
        multiple={true}
        MenuProps={{
          PaperProps: {
            className: 'filters',
          },
        }}
        value={props.value}
        onChange={onCheckboxChange}
        onClose={debouncedRefresh}
        renderValue={() =>
          props.options
              .filter((option) => props.value.includes(option.name))
              .map((option) => option.display)
              .join(', ')
        }
      >
        {props.options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            <Checkbox checked={props.value.indexOf(option.name) > -1} />
            <ListItemText style={{fontSize: 10}} primary={option.display} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

EditFieldDropdownFilters.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        display: PropTypes.string.isRequired,
      }),
  ),
  placeholder: PropTypes.string,
  refresh: PropTypes.func,
  value: PropTypes.arrayOf(
      PropTypes.oneOf([
        'uncompleted',
        'myTasks',
        'overdue',
        'nearExpiration',
        'newestFirst',
        'completed',
        'nearDeadline',
      ]),
  ),
};

export default EditFieldDropdownFilters;
