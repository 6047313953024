import {Close} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebounce} from 'use-debounce';

import useUsersListFilters, {PARAM_PAGE, PARAM_SEARCH} from '../../hooks/useUsersListFilters';
import filterCss from './index.module.scss';
import css from './SearchFilter.module.scss';

function SearchFilter() {
  const {t} = useTranslation('page_users');
  const {current, setParams} = useUsersListFilters();

  const [text, setText] = useState('');
  const [value] = useDebounce(text, 700);

  const activeSearch = current[PARAM_SEARCH];

  function onSearchChanged(event) {
    setText(event.target.value);
  }

  function clearSearch() {
    setText('');
  }

  useEffect(()=>{
    if (activeSearch !== value) {
      setParams({
        [PARAM_SEARCH]: value,
        [PARAM_PAGE]: 1,
      });
    }
  }, [activeSearch, setParams, value]);

  return (
    <div className={classNames(filterCss.filterWrapper)}>
      <OutlinedInput
        className={classNames(
            css.Search,
            filterCss.filterWrapperInner,
            filterCss.inputText,
            filterCss.filter,
            css.SearchInput,
        )}
        size="small"
        type="text"
        placeholder={t('list.filters.search.placeholder')}
        onChange={onSearchChanged}
        value={text}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={text!=='' &&
          <InputAdornment position="end" onClick={clearSearch}>
            <Close />
          </InputAdornment>
        }
      />
    </div>
  );
}

export default SearchFilter;
