import Api from '../../../../helpers/api/Api';

export default function getAttachments(projectId) {
  return new Promise(function(resolve, reject) {
    const numericId = typeof projectId === 'string' ?
      parseInt(projectId.replace('/api/projects/', '')) :
      parseInt(projectId);

    Api.get(`/projects/${numericId}/attachments`).then((data)=> {
      resolve({
        attachments: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
