import i18n from '../../i18n';

export default function getUserActivationStatus(user) {
  if (user.isEnabled && user.confirmedAt !== null) {
    return i18n.t('active', {ns: 'dictionary_user_status'});
  } else if (user.isEnabled && user.confirmedAt === null) {
    return i18n.t('pending', {ns: 'dictionary_user_status'});
  } else {
    return i18n.t('inactive', {ns: 'dictionary_user_status'});
  }
}
