import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AddDocumentsModal from '../../../components/AddDocumentsModal/AddDocumentsModal';
import Card from '../../../components/Card/Card';
import DocumentItem from '../../../components/DocumentItem/DocumentItem';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_DOCUMENTS_WRITE} from '../../../helpers/security/hasCapability';
import getAttachments from './api/getAttachments';
import sortCompanyAttachments from './functions/sortCompanyAttachments';
import css from './index.module.scss';
import TitleButtons from './TitleButtons';

function CompanyDocumentsPanel({company}) {
  const {t} = useTranslation('component_documents');

  const [attachments, setAttachments] = useState(false);
  const [attachmentsTotal, setAttachmentsTotal] = useState(0);
  const [addDocumentsModalOpen, setAddDocumentsModalOpen] = useState(false);
  const [sortByDate, setSortByDate] = useState(false);

  const canWriteDocuments = useHasCapabilityHere(CAPABILITY_DOCUMENTS_WRITE);

  const reloadAttachments = useCallback(()=>{
    if (company && company.id) {
      getAttachments(company.id).then((data) => {
        setAttachments(data.attachments);
        setAttachmentsTotal(data.totalItems);
      });
    }
  }, [company]);

  useEffect(()=>{
    reloadAttachments();
  }, [reloadAttachments]);

  const sortedAttachments = attachments ? sortCompanyAttachments(attachments, sortByDate) : [];

  return (
    <Card
      className={css.CompanyDocumentsPanel}
      title={`${t('panel_title')} (${attachmentsTotal})`}
      appendToHeader={canWriteDocuments ?
        <TitleButtons
          requestAddDocuments={()=>setAddDocumentsModalOpen(true)}
          sortBy={sortByDate ? 'date' : 'name'}
          requestSortChange={(val)=>setSortByDate(val==='date')}
        /> :
        undefined
      }
    >
      {sortedAttachments?.map((file) => (
        <DocumentItem
          key={file.id}
          status={file.status}
          fileId={file.id}
          fileName={file.originalName}
          fileDate={file.createdAt}
          fileSizeBytes={file.size}
          onItemDeleted={reloadAttachments}
          onItemNameChanged={reloadAttachments}
          type="companyAttachment"
        />
      ))
      }
      <AddDocumentsModal
        open={addDocumentsModalOpen}
        onClose={() => setAddDocumentsModalOpen(false)}
        refreshList={reloadAttachments}
        type="companyAttachment"
      />
    </Card>
  );
}

CompanyDocumentsPanel.propTypes = {
  company: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Company']),
    'id': PropTypes.number,
  }),
};
export default CompanyDocumentsPanel;
