
export default function guessArrayOfIris(subject) {
  if (Array.isArray(subject)) {
    return subject.map((item) => guessIri(item));
  } else {
    return [];
  }
}

function guessIri(subject) {
  if (subject !== null && typeof subject === 'object' && typeof subject['@id'] === 'string') {
    return subject['@id'];
  } else if (typeof subject === 'string') {
    return subject;
  } else {
    return null;
  }
}
