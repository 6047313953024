import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_DOCUMENTS_READ} from '../../../helpers/security/hasCapability';
import css from './TitleButtons.module.scss';

function TitleButtons({sortBy, requestAddDocuments, requestSortChange}) {
  const {t} = useTranslation('component_documents');

  const canReadDocuments = useHasCapabilityHere(CAPABILITY_DOCUMENTS_READ);

  const isSortedAlphabetically = sortBy === 'name';
  const isSortedNewestFirst = sortBy === 'date';

  return (
    <CardActions className={css.TitleButtons}>
      <Button
        variant="smallerText"
        className={classNames(css.button, {[css.activeButton]: isSortedAlphabetically})}
        onClick={()=>requestSortChange('name')}
      >
        {t('sort_by_name')}
      </Button>
      <Button
        variant="smallerText"
        className={classNames(css.button, {[css.activeButton]: isSortedNewestFirst})}
        onClick={()=>requestSortChange('date')}
      >
        {t('sort_by_date')}
      </Button>
      <div className={css.spacer} />
      {canReadDocuments &&
          <Button
            className={css.button}
            variant="smallText"
            onClick={requestAddDocuments}
          >
            {t('add_document')}
          </Button>
      }
    </CardActions>
  );
}

TitleButtons.propTypes = {
  sortBy: PropTypes.oneOf(['date', 'name']).isRequired,
  requestAddDocuments: PropTypes.func.isRequired,
  requestSortChange: PropTypes.func.isRequired,
};

export default TitleButtons;
