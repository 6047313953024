import './NotificationsPage.scss';

import {Button, Typography} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AlertDialog from '../../components/AlertDialog/AlertDialog';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {setUnreadNotificationsCount} from '../../state/actions';
import {useStateValue} from '../../state/state';
import getNotificationsList from './api/getNotificationsList';
import postNotificationMarkAllAsSeen from './api/postNotificationMarkAllAsSeen';
import postNotificationMarkAsSeen from './api/postNotificationMarkAsSeen';
import NotificationsList from './NotificationsList';

const ITEMS_PER_PAGE = 10;

export default function NotificationsPage() {
  const {t, i18n} = useTranslation('page_notifications');
  const [{user, unseenNotificationsCount}, dispatch] = useStateValue();
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isConfirmingReadAll, setIsConfirmingReadAll] = useState(false);


  const userId = user && user.id;
  useEffect(()=>{
    fetchNotifications();
  }, [page, userId]);

  function fetchNotifications() {
    getNotificationsList(page, ITEMS_PER_PAGE).then((data) => {
      setNotifications(data.notifications);
      setNotificationsCount(data.items);
    });
  }

  function markAsSeen(notification, onDoneCallback) {
    postNotificationMarkAsSeen(notification.id).then((newNotification) => {
      fetchNotifications();
      if (unseenNotificationsCount > 0) {
        dispatch(setUnreadNotificationsCount(unseenNotificationsCount-1));
      }
      if (typeof onDoneCallback === 'function') {
        onDoneCallback(newNotification);
      }
    });
  }

  function markAllAsSeen() {
    postNotificationMarkAllAsSeen().then(() => {
      setIsConfirmingReadAll(false);
      if (unseenNotificationsCount > 0) {
        dispatch(setUnreadNotificationsCount(0));
      }
      fetchNotifications();
    });
  }

  return (
    <DefaultLayout>
      <div className="NotificationsPage">
        <div className="NotificationsPage-section NotificationsPage-header">
          <Typography variant={'h2'}>{t('title')}</Typography>
          <Button className="mark-all-as-read" size={'small'} variant={'smallText'} onClick={()=>setIsConfirmingReadAll(true)}>
            {t('allread.button')}
          </Button>
        </div>
        <div className="NotificationsPage-section NotificationsPage-content">
          <NotificationsList
            notifications={notifications}
            notificationsCount={notificationsCount}
            page={page}
            itemsPerPage={ITEMS_PER_PAGE}
            markAsSeen={markAsSeen}
          />
        </div>
        <AlertDialog
          open={isConfirmingReadAll}
          title=""
          text={t('allread.question')}
          textConfirm={t('allread.confirm')}
          textClose={t('allread.close')}
          onClose={()=>setIsConfirmingReadAll(false)}
          onConfirm={markAllAsSeen}
        />
      </div>
    </DefaultLayout>
  );
}
