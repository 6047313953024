import {MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldDropdown(props) {
  function callback(event) {
    props.onChange(event.target.value);
  }
  return (
    <div className="EditFieldDropdown" style={{position: 'relative'}}>
      {!props.value && props.placeholder &&
      <div
        className="EditFieldDropdown-placeholder"
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          paddingLeft: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          fontSize: '16px',
          fontWeight: 400,
          color: '#9C9FAF',
          borderRadius: '4px',
        }}
      >{props.placeholder}</div>
      }
      <Select
        size="small"
        value={props.value ?? ''}
        placeholder={props.label}
        onChange={callback}
        fullWidth={true}
        error={props.error}
      >
        {props.options.map((option) => (
          <MenuItem key={option.name} value={option.name}>{option.display}</MenuItem>
        ))}
      </Select>

    </div>
  );
}

EditFieldDropdown.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  label: PropTypes.string,
};
