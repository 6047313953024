import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import useTasksListFilters, {PARAM_SKIP_COMPLETED, PARAM_SKIP_UNCOMPLETED, PARAM_SKIP_UNEXPIRED} from '../../hooks/useTasksListFilters';
import css from './index.module.scss';

function StatusFilter() {
  const {t} = useTranslation('page_tasks');
  const {current, setParams} = useTasksListFilters();

  const availableStatuses = useMemo(()=>{
    return [
      {name: '', display: t('statuses.all')},
      {name: 'open', display: t('statuses.open')},
      {name: 'finished', display: t('statuses.finished')},
      {name: 'delayed', display: t('statuses.delayed')},
    ];
  }, [t]);

  function getCurrentStatus() {
    if (current[PARAM_SKIP_COMPLETED] && !current[PARAM_SKIP_UNEXPIRED] && !current[PARAM_SKIP_UNCOMPLETED]) {
      return 'open';
    } else if (!current[PARAM_SKIP_COMPLETED] && !current[PARAM_SKIP_UNEXPIRED] && current[PARAM_SKIP_UNCOMPLETED]) {
      return 'finished';
    } else if (current[PARAM_SKIP_COMPLETED] && current[PARAM_SKIP_UNEXPIRED] && !current[PARAM_SKIP_UNCOMPLETED]) {
      return 'delayed';
    } else {
      return '';
    }
  }
  const currentStatus = getCurrentStatus();

  function onStatusChanged(id, val) {
    if (currentStatus !== val) {
      switch (val) {
        case 'open':
          setParams({[PARAM_SKIP_COMPLETED]: true, [PARAM_SKIP_UNEXPIRED]: false, [PARAM_SKIP_UNCOMPLETED]: false});
          break;
        case 'finished':
          setParams({[PARAM_SKIP_COMPLETED]: false, [PARAM_SKIP_UNEXPIRED]: false, [PARAM_SKIP_UNCOMPLETED]: true});
          break;
        case 'delayed':
          setParams({[PARAM_SKIP_COMPLETED]: true, [PARAM_SKIP_UNEXPIRED]: true, [PARAM_SKIP_UNCOMPLETED]: false});
          break;
        default:
          setParams({[PARAM_SKIP_COMPLETED]: false, [PARAM_SKIP_UNEXPIRED]: false, [PARAM_SKIP_UNCOMPLETED]: false});
      }
    }
  }

  return (
    <div className={css.filterWrapper}>
      <EditField
        className={classNames(css.filter, css.noPadding)}
        typeOptions={{innerClassName: css.inputHeight}}
        id="taskStatus"
        value={currentStatus}
        type="dropdown"
        placeholder={t('filter.status.placeholder')}
        options={availableStatuses}
        editing={true}
        onChange={onStatusChanged}
      />
    </div>
  );
}

export default StatusFilter;
