import './AddNoteModal.scss';

import TextareaAutosize from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import postNote from './api/postNote';

export default function AddNoteModal(props) {
  const {t} = useTranslation('component_notes');
  const [noteText, setNoteText] = useState('');
  const [isSending, setIsSending] = useState(false);

  function handleModalClose() {
    setNoteText('');
    props.closeModal();
  }

  function addNote() {
    if (props.parentIri) {
      setIsSending(true);
      postNote(props.parentIri, {text: noteText}).then((newNote) => {
        setIsSending(false);
        if (typeof props.onNoteChanged === 'function') {
          props.onNoteChanged(newNote);
        }
        handleModalClose();
      });
    }
  }

  return (
    <>
      <ModalDialog
        className="AddNoteModal"
        open={props.isModalOpened}
        onCloseRequest={handleModalClose}
      >
        <div className="Title">{t('modal_add.title')}</div>
        <div className="FormField">
          <TextareaAutosize
            aria-label="minimum height"
            minRows={20}
            maxRows={20}
            placeholder={t('modal_add.placeholder')}
            style={{width: 400, fontFamily: '"Barlow", "Roboto", "Helvetica", "Arial", sans-serif', fontSize: '16px'}}
            value={noteText}
            onChange={((e) => setNoteText(e.target.value))}
          />
        </div>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '14px',
            height: '44px',
          }}
        >
          {!isSending &&
          <Button
            variant="contained"
            sx={{width: '164px', height: '100%', textTransform: 'capitalize', marginBotton: '92px'}}
            onClick={addNote}
            className="CreateButton"
          >
            {t('modal_add.add_button')}
          </Button>
          }
          {isSending &&
          <ProgressIndicator />
          }
        </Box>
      </ModalDialog>
    </>
  );
}

AddNoteModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  parentIri: PropTypes.string.isRequired,
  onNoteChanged: PropTypes.func,
};
