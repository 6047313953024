import Api from '../../../../helpers/api/Api';

export default function deleteTask(taskIri) {
  return new Promise(function(resolve, reject) {
    let id = null;
    if (typeof taskIri === 'string' && taskIri.includes('/api/tasks/')) {
      id = parseInt(taskIri.replace('/api/tasks/', ''));
    } else if (!isNaN(parseInt(taskIri))) {
      id = parseInt(taskIri);
    } else {
      reject('Malformed Task ID');
    }

    Api.delete(`/tasks/${id}`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
