import Api from '../../../helpers/api/Api';

export default function getProjectTasks(projectId, page, itemsPerPage, params='order%5BdeadlineAt%5D=asc&skipCompleted=true') {
  return new Promise(function(resolve, reject) {
    const url = `/tasks/company/${projectId}?page=${page}&itemsPerPage=${itemsPerPage}&${params}`;

    Api.get(url)
        .then((data) => {
          if (Array.isArray(data['hydra:member'])) {
            resolve({
              tasks: data['hydra:member'],
              totalItems: data['hydra:totalItems'],
            });
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
  });
}
