import React from 'react';

import ShortenedText from '../../../../components/ShortenedText';
import formatDateShort from '../../../../helpers/formatters/formatDateShort';
import statusTask2String from '../../../../helpers/formatters/statusTask2String';

export default function taskToTableRow(task, executorsDictionary) {
  const executors = task?.executors?.map((executor)=>{
    if (typeof executor === 'object' && executor['@type']==='User') {
      return `${executor.firstname} ${executor.lastname}`;
    } else if (typeof executor === 'string') {
      const entry = executorsDictionary.find((e)=>e['@id']===executor);
      return entry ? `${entry.firstname} ${entry.lastname}` : executor;
    } else {
      return executor.toString();
    }
  });
  return [
    {label: <ShortenedText text={`#${task.id}`} />},
    {label: <ShortenedText text={task?.title} />},
    {label: <ShortenedText text={task?.relatedProjects?.map((p)=>p.internalName).join(', ')} />},
    {label: <ShortenedText text={executors.join(', ')} />},
    {label: <ShortenedText text={task?.deadlineAt && formatDateShort(task.deadlineAt)} />},
    {label: <ShortenedText text={task?.completedAt && formatDateShort(task.completedAt)} />},
    {label: <ShortenedText text={statusTask2String(task?.completedAt, task?.deadlineAt)} />},
  ];
}
