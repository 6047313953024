import './SingleEntityLayout.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LeftMenu from '../../components/LeftMenu/LeftMenu';
import NavBar from '../../components/NavBar/NavBar';

export default function SingleEntityLayout(props) {
  return (
    <div className="SingleEntityLayout">
      <div className="SingleEntityLayout-left">
        <LeftMenu />
      </div>
      {props.leftPanel &&
        <div className="SingleEntityLayout-leftpanel">
          {props.leftPanel}
        </div>
      }
      <div className={`SingleEntityLayout-right ${props.rightClassName}`}>
        <NavBar skipAction={true}/>
        <div className="SingleEntityLayout-contents">
          {props.children}
        </div>
      </div>
    </div>
  );
}

SingleEntityLayout.propTypes = {
  leftPanel: PropTypes.any,
  children: PropTypes.any,
  rightClassName: PropTypes.string,
};
