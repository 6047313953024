import Api from '../../../helpers/api/Api';

export default function getUnseenNotificationsCount() {
  return new Promise(function(resolve, reject) {
    const url = `/notifications/unseen_count`;

    Api.get(url).then((data)=>{
      resolve(data.unseenCount);
    }).catch((error) => {
      reject(error);
    });
  });
}
