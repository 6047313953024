import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useHandlersDictionary} from '../../../../helpers/dictionaries/hooks/useHandlersDictionary';
import useProjectsListFilters, {PARAM_HANDLER_NAME} from '../../hooks/useProjectsListFilters';
import css from './index.module.scss';

function HandlerFilter({pageScope, onChange}) {
  const {t} = useTranslation('page_cases');
  const {current} = useProjectsListFilters();

  const handlersDictionary = useHandlersDictionary();

  const currentDropdownValue = current[PARAM_HANDLER_NAME] !== null && !isNaN(parseInt(current[PARAM_HANDLER_NAME])) ?
    `/api/users/${current[PARAM_HANDLER_NAME]}` :
    '';

  const handlersDropdownList = handlersDictionary ?
    [
      {name: '', display: t('filter.all')},
      ...handlersDictionary.map((exe) => (
        {name: exe['@id'], display: `${exe.firstname} ${exe.lastname}`}
      )),
    ] :
    [];

  function setHandler(id, val) {
    const selectedId = typeof val === 'string' && val.length > 0 ?
      parseInt(val.replace('/api/users/', '')) :
      null;

    onChange(selectedId);
  }

  return (
    <div className={css.HandlerFilter}>
      <EditField
        id="handlersList"
        value={currentDropdownValue}
        type="autocomplete"
        placeholder={t('filter.handler_placeholder')}
        options={handlersDropdownList}
        editing={true}
        onChange={setHandler}
      />
    </div>
  );
}

HandlerFilter.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};
export default HandlerFilter;
