import './CompanyDetails.scss';

import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import AlertDialog from '../../components/AlertDialog/AlertDialog';
import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTable from '../../components/IndexTable/IndexTable';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import ProjectSummaryBrick from '../../components/ProjectSummaryBrick/ProjectSummaryBrick';
import Tasks from '../../components/TasksViewer/Tasks';
import hasCapability, {CAPABILITY_EDIT} from '../../helpers/security/hasCapability';
import SingleEntityLayout from '../../layouts/SingleEntityLayout/SingleEntityLayout';
import NotesPanel from '../../panels/NotesPanel/NotesPanel';
import {useStateValue} from '../../state/state';
import AddContactModal from './AddContactModal';
import getCompany from './api/getCompany';
import getCompanyClientProjects from './api/getCompanyClientProjects';
import getCompanyIntermediaryProjects from './api/getCompanyIntermediaryProjects';
import getCompanyLegalForms from './api/getCompanyLegalForms';
import getCompanyMoneySupplierProjects from './api/getCompanyMoneySupplierProjects';
import getHandlers from './api/getHandlers';
import patchEmployeeStatus from './api/patchEmployeeStatus';
import postPasswordResetRequest from './api/postPasswordResetRequest';
import postSendInvitationRequest from './api/postSendInvitationRequest';
import postSetEmployeeAsPrimaryCompanyUser from './api/postSetEmployeeAsPrimaryCompanyUser';
import CardCompanyPurge from './CardCompanyPurge';
import CompanyEdit from './CompanyEdit';
import employeeToTableData from './convert/employeeToTableData';
import CompanyDocumentsPanel from './CompanyDocumentsPanel';

export default function CompanyDetailsPage(props) {
  const {t, i18n} = useTranslation('page_companies');
  const [{user}, dispatch] = useStateValue();
  const [companyData, setCompanyData] = useState(null);
  const [companyLegalForms, setCompanyLegalForms] = useState([]);
  const [companyProjects, setCompanyProjects] = useState([]);
  const [handlers, setHandlers] = useState([]);
  const [leadFilter, setLeadFilter] = useState('all');
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);

  const [isReSendActivationModalOpen, setReSendActivationModalOpen] = useState(false);
  const [reSendActivationEmail, setReSendActivationEmail] = useState(null);
  const [isSendPasswordResetModalOpen, setSendPasswordResetModalOpen] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState(null);

  const location = useLocation();
  const currentCompanyId = location.pathname.split('/').filter((i) => i.length > 0)[1];
  const navigate = useNavigate();

  function refreshCompanyData() {
    setCompanyData(null);
    setCompanyProjects([]);
    getCompany(currentCompanyId).then((company)=>{
      setCompanyData(company);
      switch (company.type) {
        case 'client':
          getCompanyClientProjects(currentCompanyId).then((data) => {
            setCompanyProjects(data);
          });
          break;
        case 'intermediary':
          getCompanyIntermediaryProjects(currentCompanyId).then((data) => {
            setCompanyProjects(data);
          });
          break;
        case 'money_supplier':
          getCompanyMoneySupplierProjects(currentCompanyId).then((data) => {
            setCompanyProjects(data);
          });
          break;
        default:
          console.error('Company type cannot be:', company.type);
      }
    });
  }

  function onMoreAction(actionId, rowId) {
    const foundEmployee = companyData?.employees?.find((e) => e.email === rowId);
    switch (actionId) {
      case 'activate':
        patchEmployeeStatus(foundEmployee.user.id, true).then(()=>{
          refreshCompanyData();
        });
        break;
      case 'deactivate':
        patchEmployeeStatus(foundEmployee.user.id, false).then(()=>{
          refreshCompanyData();
        });
        break;
      case 'resetpassword':
        setPasswordResetEmail(foundEmployee.user.email);
        setSendPasswordResetModalOpen(true);
        break;
      case 'reinvite':
        setReSendActivationEmail(foundEmployee.user.email);
        setReSendActivationModalOpen(true);
        break;
      case 'setAsPrimary':
        postSetEmployeeAsPrimaryCompanyUser(foundEmployee.id).then(()=>{
          refreshCompanyData();
        });
        break;
      default:
        console.error(actionId, rowId);
    }
  }

  function executePasswordResetRequest() {
    if (passwordResetEmail) {
      const email = passwordResetEmail;
      setPasswordResetEmail(null);
      setSendPasswordResetModalOpen(false);
      postPasswordResetRequest(email).then(()=>{
        refreshCompanyData();
      });
    }
  }

  function executeInvitationRequest() {
    if (reSendActivationEmail) {
      const email = reSendActivationEmail;
      setReSendActivationEmail(null);
      setReSendActivationModalOpen(false);
      postSendInvitationRequest(email).then(()=>{
        refreshCompanyData();
      });
    }
  }

  useEffect(() => {
    getCompanyLegalForms().then((data) => setCompanyLegalForms(data['hydra:member']));
    getHandlers().then((data) => setHandlers(data['hydra:member']));
  }, []);

  useEffect(function() {
    refreshCompanyData();
  }, [currentCompanyId]);

  const tableRows = companyData?.employees?.map((employee) => employeeToTableData(employee));

  const tableActions = companyData?.employees?.map((user)=>{
    const actions = [];
    if (user?.isEnabled) {
      actions.push(
          {id: 'deactivate', text: t('action.deactivate', {ns: 'dictionary_user_status'})},
      );
    } else {
      actions.push(
          {id: 'activate', text: t('action.activate', {ns: 'dictionary_user_status'})},
      );
    }
    if (user?.confirmedAt) {
      actions.push(
          {id: 'resetpassword', text: t('page.contact_list.table.more_menu.resetpassword')},
      );
    } else {
      actions.push(
          {id: 'reinvite', text: t('page.contact_list.table.more_menu.reinvite')},
      );
    }
    if (user && !user.isPrimary) {
      actions.push(
          {id: 'setAsPrimary', text: t('page.contact_list.table.more_menu.set_as_primary')},
      );
    }
    return actions;
  });

  function onRowClick(rowId) {
    const foundEmployee = companyData?.employees.find((e) => e.email === rowId);
    if (foundEmployee) {
      navigate(`/companies/${companyData.id}/users/${foundEmployee.user.id}`);
    }
  }

  const filterLeads = (leads) => {
    if (leadFilter === 'lead') return leads.filter((lead) => lead && (lead.status === 'lead_pending' || lead.status === 'lead_rejected'));
    if (leadFilter === 'opportunity') return leads.filter((lead) => lead && (lead.status === 'opportunity_trajectory' || lead.status === 'opportunity_payment' || lead.status === 'opportunity_memorandum_creating' || lead.status === 'opportunity_memorandum_sent' || lead.status === 'opportunity_resigned'));
    if (leadFilter === 'account') return leads.filter((lead) => lead && (lead.status === 'account_in_progress' || lead.status === 'account_closed'));
    return leads;
  };

  const view = props.view ?? 'default';


  return (
    <SingleEntityLayout
      leftPanel={companyData ?
        <CompanyEdit
          companyData={companyData}
          handlers={handlers}
          companyLegalForms={companyLegalForms}
          onRefreshRequest={refreshCompanyData}
          view={view}
        /> :
        <ProgressIndicator />
      }
      rightClassName={`type-${companyData?.type.replace('_', '-')} ${companyData?.legalForm?.isPersonal ? 'type-is-personal' : ''}`}
    >
      <>
        {view === 'default' &&
          <div className="CompanyDetails">

            <section className="section-contacts">
              <div className="header-line">
                <IndexPageHeader text={t('page.contact_list.title')}/>
                {hasCapability(user, CAPABILITY_EDIT, 'companies') && <Button
                  variant="contained"
                  onClick={() => setIsAddContactModalOpen(true)}
                >
                  {t('page.add_button.text')}
                </Button>}
              </div>
              <IndexTable
                headers={[
                  {sortField: 'name', label: t('page.contact_list.table.header.name')},
                  {sortField: 'status', label: t('page.contact_list.table.header.status')},
                  {sortField: 'email', label: t('page.contact_list.table.header.email')},
                  {sortField: 'phone', label: t('page.contact_list.table.header.phone')},
                  {sortField: 'createdAt', label: t('page.contact_list.table.header.date')},
                ]}
                rows={tableRows || []}
                rowsKeyIndex={2}
                rowsPerPage={10}
                page={1}
                rowActions={hasCapability(user, CAPABILITY_EDIT, 'users') && tableActions}
                onAction={onMoreAction}
                onRowClicked={onRowClick}
              />
            </section>

            <section className="section-notes">
              <div className="notes">
                {companyData &&
                  <NotesPanel
                    parentIri={companyData['@id']}
                    previewEntriesLimit={6}
                    presentationMode="bricks"
                    canDisplaySeeMore={true}
                  />
                }
              </div>
            </section>

            <section className="section-tasks">
              <div className="tasks">
                {companyData && <Tasks entity={companyData}/>}
              </div>
            </section>

            <section className="section-attachments">
              <div className="attachments">
                {companyData && <CompanyDocumentsPanel company={companyData} />}
              </div>
            </section>

            <section className="section-projects">
              <div className="projects">
                <div className="filters">
                  <div className={`filter ${leadFilter === 'all' && 'filter-active'}`}
                    onClick={() => setLeadFilter('all')}>{t('all', {ns: 'dictionary_project_type'})}</div>
                  <div className={`filter ${leadFilter === 'lead' && 'filter-active'}`}
                    onClick={() => setLeadFilter('lead')}>{t('lead', {ns: 'dictionary_project_type'})}</div>
                  <div className={`filter ${leadFilter === 'opportunity' && 'filter-active'}`}
                    onClick={() => setLeadFilter('opportunity')}>{t('opportunity', {ns: 'dictionary_project_type'})}</div>
                  <div className={`filter ${leadFilter === 'account' && 'filter-active'}`}
                    onClick={() => setLeadFilter('account')}>{t('account', {ns: 'dictionary_project_type'})}</div>
                </div>
                <div className="projects-grid">
                  {companyProjects && filterLeads(companyProjects).map((lead) => {
                    if (lead) {
                      return (
                        <ProjectSummaryBrick
                          allowRedirect={true}
                          key={lead['@id']}
                          project={lead}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </section>

            <AddContactModal
              open={isAddContactModalOpen}
              onClose={() => setIsAddContactModalOpen(false)}
              companyId={companyData?.id}
              employees={companyData?.employees?.map((e) => e['@id'])}
              refreshCompanyData={refreshCompanyData}
              forbidInvitationEmail={companyData?.type !== 'client'}
            />
            <AlertDialog
              open={isReSendActivationModalOpen}
              title=""
              text={t('page.contact_list.confirmation_modal.re_send_activation')}
              onClose={()=>setReSendActivationModalOpen(false)}
              onConfirm={executeInvitationRequest}
            />
            <AlertDialog
              open={isSendPasswordResetModalOpen}
              title=""
              text={t('page.contact_list.confirmation_modal.send_password_reset')}
              onClose={()=>setSendPasswordResetModalOpen(false)}
              onConfirm={executePasswordResetRequest}
            />
          </div>
        }
        {view === 'purge' &&
          <CardCompanyPurge company={companyData} goBackTo="/companies" />
        }
      </>
    </SingleEntityLayout>
  );
}

CompanyDetailsPage.propTypes = {
  view: PropTypes.oneOf(['default', 'purge']),
};
