import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useHandlersDictionary} from '../../../../helpers/dictionaries/hooks/useHandlersDictionary';
import postDisableUserWithAssetsReassign from './api/postDisableUserWithAssetsReassign';
import css from './index.module.scss';

export default function DisableUserModal({isOpen, subject, onSuccess, onAbort}) {
  const {t} = useTranslation('page_users');

  const [currentValue, setCurrentValue] = useState('');

  const handlersDictionary = useHandlersDictionary(
      {name: '@id', display: 'dropdownIdentifier'},
  ) ?? [];

  function setHandler(id, val) {
    setCurrentValue(val);
  }

  function onConfirmClicked() {
    postDisableUserWithAssetsReassign(
        subject['@id'],
        currentValue ?? null,
    ).then(()=>{
      onSuccess();
    }).catch(()=>{
      onAbort();
    });
  }

  return (
    <Dialog
      className={css.DisableUserModal}
      open={isOpen}
      onClose={onAbort}
    >
      <DialogTitle>
        {t('disable_user_modal.text', {email: subject?.email})}
      </DialogTitle>
      <DialogContent className={css.tall}>
        <DialogContentText>
          {t('disable_user_modal.hint')}
        </DialogContentText>
        <EditField
          id="optionalHandlersList"
          value={currentValue}
          type="autocomplete"
          placeholder={t('disable_user_modal.placeholder')}
          options={handlersDictionary}
          editing={true}
          onChange={setHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'error'} onClick={onAbort}>{t('disable_user_modal.action.cancel')}</Button>
        <Button variant={'contained'} onClick={onConfirmClicked} autoFocus>{t('disable_user_modal.action.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}

DisableUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  subject: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['User']).isRequired,
    'email': PropTypes.string.isRequired,
  }),
  onAbort: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
