import './Error404.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import error_icon from '../../assets/informer_error.svg';
import AccessDeniedPopUp from '../../components/Popups/AccessDeniedPopUp';
import ErrorLayout from '../../layouts/ErrorLayout/ErrorLayout';

export default function Error404Page() {
  const navigate = useNavigate();

  function redirectToLogin() {
    const currentLocation = document.location.pathname;
    if (currentLocation !== '/login') {
      document.location = `${document.location.origin}/login?redirectTo=${currentLocation}`;
    } else {
      document.location = `${document.location.origin}/login`;
    }
  }

  function historyGoBack() {
    navigate(-1);
  }

  return (
    <ErrorLayout>
      <div className="Error404Page">
        <div className="Error404Page-title">
          <img className="errorIcon" src={error_icon} alt="info" />
          <Typography variant="h2">Error 404</Typography>
        </div>
        <Typography>Either this resource doesn't exist, or You don't have sufficient permissions.</Typography>
        <div className="Error404Page-actions">
          <Button onClick={historyGoBack} variant="text" color={'error'}>Go back</Button>
          <Button onClick={redirectToLogin} autoFocus={true} variant="contained" color="error">Log in as another user</Button>
        </div>
      </div>
    </ErrorLayout>
  );
}
