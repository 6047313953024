import {Base64} from 'js-base64';
import storage from 'local-storage-fallback';

const ACCESS_TOKEN_NAME = 'API_ACCESS_TOKEN';
const ACCESS_TOKEN_EXPIRATION_NAME = 'API_ACCESS_TOKEN_EXPIRATION';
const REFRESH_TOKEN_NAME = 'API_REFRESH_TOKEN';
const JWT_EXPIRATION_LOOKAHEAD_MILLISECONDS = 60000;

export function getAccessToken() {
  return storage.getItem(ACCESS_TOKEN_NAME);
}

export function setAccessToken(jwtToken) {
  if (jwtToken) {
    storage.setItem(ACCESS_TOKEN_NAME, jwtToken);
    const claims = JSON.parse(Base64.decode(jwtToken.split('.')[1]));
    const milliseconds = parseInt(claims.exp) * 1000;
    storage.setItem(ACCESS_TOKEN_EXPIRATION_NAME, milliseconds.toString());
  } else {
    storage.removeItem(ACCESS_TOKEN_NAME);
    storage.removeItem(ACCESS_TOKEN_EXPIRATION_NAME);
  }
}

export function isAccessTokenSet() {
  const token = getAccessToken();
  return typeof token === 'string' && token.length > 0;
}

export function isAccessTokenValid() {
  const now = new Date();
  const expiration = new Date(parseInt(storage.getItem(ACCESS_TOKEN_EXPIRATION_NAME)));
  return isAccessTokenSet() && expiration-now >= JWT_EXPIRATION_LOOKAHEAD_MILLISECONDS;
}

export function getRefreshToken() {
  return storage.getItem(REFRESH_TOKEN_NAME);
}

export function setRefreshToken(refreshToken) {
  if (refreshToken) {
    storage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  } else {
    storage.removeItem(REFRESH_TOKEN_NAME);
  }
}

export function removeAccessToken() {
  storage.removeItem(ACCESS_TOKEN_NAME);
}

export function removeRefreshToken() {
  storage.removeItem(REFRESH_TOKEN_NAME);
}
