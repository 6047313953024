import React from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useUserSecurityContext} from '../../../../customHooks/useUserSecurityContext';
import useUsersListFilters, {PARAM_PAGE, PARAM_SECURITY_CONTEXT} from '../../hooks/useUsersListFilters';
import css from './index.module.scss';

function SecurityContextFilter() {
  const {t} = useTranslation('login');
  const userSecurityContexts = useUserSecurityContext();
  const {current, setParams} = useUsersListFilters();

  function onSecurityContextChange(id, val) {
    if (current[PARAM_SECURITY_CONTEXT] !== val) {
      setParams({
        [PARAM_SECURITY_CONTEXT]: val,
        [PARAM_PAGE]: 1,
      });
    }
  }

  const dropdownOptions = [
    {id: null, name: '', display: t(`security_context.unset`, {ns: 'login'})},
    ...userSecurityContexts.map((ctx)=>(
      {id: ctx.id, name: ctx.id, display: t(`security_context.name.${ctx.name}`, {ns: 'login'})}
    )),
  ];

  const selectedDropdownOption = dropdownOptions.find((o)=>(o.id===current[PARAM_SECURITY_CONTEXT]));

  return (
    <div>
      <EditField
        value={selectedDropdownOption?.name}
        id="userSecurityContext"
        type="dropdown"
        placeholder={t('security_context.placeholder')}
        options={dropdownOptions}
        editing={true}
        onChange={onSecurityContextChange}
      />
    </div>
  );
}

export default SecurityContextFilter;
