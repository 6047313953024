import './AllActivitiesModal.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import Item from '../Item/Item';
import ModalDialog from '../ModalDialog/ModalDialog';
import getRecentActivityTemplateVariables from './getRecentActivityTemplateVariables';
import RecentActivityItem from './RecentActivityItem';


export default function AllActivitiesModal(props) {
  const {t, i18n} = useTranslation('component_recent_activity');

  function onClick(activityId) {
    props.onClick(activityId);
  }

  return (
    <ModalDialog
      className="AllActivitiesModal"
      open={props.isOpen}
      onCloseRequest={props.onClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('modal_title')}</Typography>
        </div>
        <div className="modal-content">
          {props.activities.map((activity, activityIdx) => {
            const isNotLast = activityIdx < (props.activities.length-1);
            const databaseId = parseInt(activity['@id'].replace('/api/audit_logs/', ''));
            const content = t(`template.${activity.name}`, getRecentActivityTemplateVariables(activity));
            return (
              <RecentActivityItem
                id={databaseId}
                activity={activity}
                key={activity['@id']}
                iri={activity['@id']}
                author={activity.createdBy ? activity.createdBy.display : 'unknown'}
                content={content}
                time={formatDateTimestamp(activity.createdAt)}
                withUnderline={isNotLast}
                goTo={props.disableGoTo !== true && activity.relatedProject !== null && typeof activity.relatedProject === 'object'}
                onClick={onClick}
                transparent={true}
              />
            );
          })}
        </div>
        <div className="modal-footer">
          {props.showSeeMore &&
            <Button size={'small'} onClick={props.onSeeMoreClicked}>{t('load_more')}</Button>
          }
        </div>
      </>
    </ModalDialog>
  );
}

AllActivitiesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  onSeeMoreClicked: PropTypes.func.isRequired,
  disableGoTo: PropTypes.bool,
  onClick: PropTypes.func,
};
