import './ProjectStatusBanner.scss';

import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import isProjectActive from '../../helpers/dictionaries/isProjectActive';
import status2type from '../../helpers/formatters/status2type';
import MoreButton from '../MoreButton/MoreButton';

export default function ProjectStatusBanner(props) {
  const {t, i18n} = useTranslation('dictionary_project_status');

  const type = status2type(props.project.status);
  const statusClass = `status-${props.project.status.replaceAll('_', '-')}`;
  const typeClass = `type-${type.replaceAll('_', '-')}`;
  const isActive = isProjectActive(props.project);

  function onActionClick(action) {
    if (typeof props.onStatusChanged === 'function') {
      props.onStatusChanged(action);
    }
  }

  return (
    <div className={`ProjectStatusBanner ${statusClass} ${typeClass} ${isActive ? 'is-active' : 'is-inactive'}`}>
      <div className="ProjectStatusBanner-inner">
        <div className="banner">
          <div className="banner-left">{t(type, {ns: 'dictionary_project_type'})}</div>
          <div className="banner-right">{t(props.project.status)}</div>
        </div>
        {props.canChangeStatus &&
          <MoreButton
            actions={props.possibleActions}
            onAction={onActionClick}
            rowKey={null}
          />
        }
      </div>
    </div>
  );
}

ProjectStatusBanner.propTypes = {
  project: PropTypes.shape({
    '@type': PropTypes.oneOf(['Project']).isRequired,
    '@id': PropTypes.string.isRequired,
    'id': PropTypes.number.isRequired,
    'status': PropTypes.string.isRequired,
  }).isRequired,
  canChangeStatus: PropTypes.bool.isRequired,
  possibleActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
  })).isRequired,
  onStatusChanged: PropTypes.func.isRequired,
};
