export default function isProjectActive(subject) {
  const status = typeof subject === 'object' ? subject.status : subject;
  switch (status) {
    case 'lead_pending':
    case 'opportunity_trajectory':
    case 'opportunity_payment':
    case 'opportunity_memorandum_creating':
    case 'opportunity_memorandum_sent':
    case 'account_in_progress':
      return true;
    case 'lead_rejected':
    case 'opportunity_resigned':
    case 'account_closed':
      return false;
    default:
      return null;
  }
}
