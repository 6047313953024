import Typography from '@mui/material/Typography';

import formatDateShort from '../../../../helpers/formatters/formatDateShort';
import getUserActivationStatus from '../../../../helpers/formatters/getUserActivationStatus';
import role2display from '../../../../helpers/formatters/role2display';
import user2securityContextsString from '../../../../helpers/formatters/user2securityContextsString';

export default function userToTableFields(user, canEditUsers, translator, canSeeSecurityContext) {
  const fullName = `${user.lastname} ${user.firstname}`;
  const moreMenu = [
    {id: 'details', text: translator('list.table.action.details')},
  ];
  if (canEditUsers) {
    if (user.isEnabled) moreMenu.push( {id: 'deactivate', text: translator('list.table.action.deactivate'), color: 'error'});
    if (!user.isEnabled) moreMenu.push( {id: 'activate', text: translator('list.table.action.activate')});
    if (!user.confirmedAt) moreMenu.push( {id: 'reinvite', text: translator('list.table.action.reinvite')});
  }
  const tableFields = {
    id: `user${user.id}`,
    fields: [
      {label: fullName.length >= 20 ? fullName.substring(0, 19) + '...' : fullName},
      {label: role2display(user.primaryRole)},
      {label: getUserActivationStatus(user)},
      {label: user.email},
      {label: formatFirstPhone(user.userPhones)},
      {label: formatDateShort(user.createdAt)},
    ],
    moreMenu: moreMenu,
  };
  if (canSeeSecurityContext) {
    tableFields.fields.push({label: <Typography variant="smallerText">{user2securityContextsString(user)}</Typography>});
  }
  return tableFields;
}

function formatFirstPhone(phones) {
  if (phones && phones.length>0) {
    return phones[0].display;
  } else {
    return '';
  }
}
