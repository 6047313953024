import Typography from '@mui/material/Typography';
import React from 'react';

import ShortenedText from '../../../../components/ShortenedText';
import formatDateShort from '../../../../helpers/formatters/formatDateShort';
import status2string from '../../../../helpers/formatters/status2string';
import getPossibleStatusActions from '../../../ProjectDetailsPage/ProjectEdit/functions/getPossibleStatusActions';

export default function projectToTableRow(pageScope, project, canEditProject, t) {
  const tableRow = {
    id: project['@id'],
  };
  switch (pageScope) {
    case 'leads':
      tableRow.fields = getLeadFields(project);
      if (canEditProject) {
        tableRow.moreMenu = getLeadMoreMenu(project, t);
      }
      break;
    case 'opportunities':
      tableRow.fields = getOpportunityFields(project);
      if (canEditProject) {
        tableRow.moreMenu = getOpportunityMoreMenu(project, t);
      }
      break;
    case 'accounts':
      tableRow.fields = getAccountFields(project);
      if (canEditProject) {
        tableRow.moreMenu = getAccountMoreMenu(project, t);
      }
      break;
    default:
      tableRow.fields = getCaseFields(project);
      if (canEditProject) {
        tableRow.moreMenu = getCaseMoreMenu(project, t);
      }
  }

  return tableRow;
}

function getLeadFields(project) {
  return [
    {label: <ShortenedText text={`#${project.id}`} />},
    {label: <ShortenedText text={project?.primaryProjectUser?.display} />},
    {label: <ShortenedText text={project?.company?.name} />},
    {label: <ShortenedText text={status2string(project?.status)} />},
    {label: <ShortenedText text={project?.primaryProjectUser?.email} />},
    {label: <ShortenedText text={project?.primaryProjectUser?.primaryPhoneDisplay} />},
    {label: <ShortenedText text={formatDateShort(project?.createdAt)} />},
    {label: <Typography variant="smallerText">{project.securityContexts.map((ctx)=>ctx.name).join(', ')}</Typography>},
  ];
}

function getLeadMoreMenu(project, t) {
  return getPossibleStatusActions(project, t);
}

function getOpportunityFields(project) {
  const handlerName = project.handler ? `${project.handler.lastname} ${project.handler.firstname}` : null;
  return [
    {label: <ShortenedText text={`#${project.id}`} />},
    {label: <ShortenedText text={project?.internalName} />},
    {label: <ShortenedText text={project?.company?.name} />},
    {label: <ShortenedText text={status2string(project?.status)} />},
    {label: <ShortenedText text={handlerName} />},
    {label: <ShortenedText text={formatDateShort(project?.createdAt)} />},
    {label: <Typography variant="smallerText">{project.securityContexts.map((ctx)=>ctx.name).join(', ')}</Typography>},
  ].filter((i)=>!!i);
}

function getOpportunityMoreMenu(project, t) {
  return getPossibleStatusActions(project, t);
}

function getAccountFields(project) {
  const handlerName = project.handler ? `${project.handler.lastname} ${project.handler.firstname}` : null;
  return [
    {label: <ShortenedText text={`#${project.id}`} />},
    {label: <ShortenedText text={project?.internalName} />},
    {label: <ShortenedText text={project?.company?.name} />},
    {label: <ShortenedText text={status2string(project?.status)} />},
    {label: <ShortenedText text={handlerName} />},
    {label: <ShortenedText text={formatDateShort(project?.createdAt)} />},
    {label: <Typography variant="smallerText">{project.securityContexts.map((ctx)=>ctx.name).join(', ')}</Typography>},
  ].filter((i)=>!!i);
}

function getAccountMoreMenu(project, t) {
  return getPossibleStatusActions(project, t);
}

function getCaseFields(project) {
  const handlerName = project.handler ? `${project.handler.lastname} ${project.handler.firstname}` : null;
  return [
    {label: <ShortenedText text={`#${project.id}`} />},
    {label: <ShortenedText text={project?.internalName} />},
    {label: <ShortenedText text={project?.company?.name} />},
    {label: <ShortenedText text={status2string(project?.status)} />},
    {label: <ShortenedText text={handlerName} />},
    {label: <ShortenedText text={formatDateShort(project?.createdAt)} />},
  ].filter((i)=>!!i);
}

function getCaseMoreMenu(project, t) {
  return undefined;
}
