import './ErrorLayout.scss';

import React from 'react';

export default function ErrorLayout(props) {
  return (
    <div className="ErrorLayout">
      {props.children}
    </div>
  );
}
