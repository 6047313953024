import './index.scss';
import './i18n';

import {CssBaseline, ThemeProvider} from '@mui/material';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';
import ProgressIndicator from './components/ProgressIndicator/ProgressIndicator';
import reportWebVitals from './reportWebVitals';
import {initialState} from './state/initialState';
import {reducer} from './state/reducer';
import {StateProvider} from './state/state';
import muiTheme from './theme/theme';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <StateProvider initialState={initialState} reducer={reducer}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Suspense fallback={<ProgressIndicator />}><App /></Suspense>
        </ThemeProvider>
      </StateProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
