import Api from '../../../helpers/api/Api';

const itemsPerPage = 10000;

export default function getNotes(projectId) {
  return new Promise(function(resolve, reject) {
    Api.get(`/notes/dictionary?itemsPerPage=${itemsPerPage}&onlyProjectId=${projectId}`).then((data)=>{
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
