import LoginLayout from '../../layouts/LoginLayout';
import LoginForm from './LoginForm';

export function LoginPage() {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
}
