import {Checkbox, ListItemText, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useUserSecurityContext} from '../../../customHooks/useUserSecurityContext';
import filterActiveUserSecurityContexts from './functions/filterActiveUserSecurityContexs';

export default function EditFieldSecurityContextMultiselect(props) {
  const {t} = useTranslation('login');
  const userSecurityContexts = useUserSecurityContext();
  const [randomComponentId] = useState(`component${Math.random()}`.replace('.', ''));

  const useIriFormat = (props.typeOptions && props.typeOptions.iriFormat) ||
    (Array.isArray(props.value) && props.value.length > 0 && typeof props.value[0] === 'string' && isNaN(parseInt(props.value[0])));

  const currentValue = useIriFormat ? props.value : props.value.map((v) => `/api/security_contexts/${v}`);

  function onCheckboxChange(event) {
    const {target: {value}} = event;

    const newSelection = filterActiveUserSecurityContexts(value, userSecurityContexts);
    if (useIriFormat) {
      props.onChange(newSelection.map((item)=>item['@id']));
    } else {
      props.onChange(newSelection.map((item)=>item.id));
    }
  }

  const selectedSecurityContexts = filterActiveUserSecurityContexts(currentValue, userSecurityContexts);

  if (!!userSecurityContexts) {
    return (
      <Select
        labelId={`${randomComponentId}-label`}
        id={`${randomComponentId}-checkbox`}
        size="small"
        fullWidth={true}
        multiple={true}
        value={currentValue}
        onChange={onCheckboxChange}
        renderValue={() =>
          selectedSecurityContexts
              .map((item)=>t(`security_context.name.${item.name}`, {ns: 'login'}))
              .join(', ')
        }
      >
        {userSecurityContexts.map((ctx) => {
          const isChecked = currentValue.indexOf(ctx['@id']) > -1;
          return (
            <MenuItem key={ctx['@id']} value={ctx['@id']}>
              <Checkbox checked={isChecked}/>
              <ListItemText primary={ctx.name}/>
            </MenuItem>
          );
        })}
      </Select>
    );
  } else {
    return null;
  }
}

EditFieldSecurityContextMultiselect.propTypes = {
  value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  typeOptions: PropTypes.shape({
    iriFormat: PropTypes.bool,
  }),
};

