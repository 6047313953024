import getProjectNormalizedInternalName from '../../helpers/formatters/getProjectNormalizedInternalName';
import status2string from '../../helpers/formatters/status2string';
import status2type from '../../helpers/formatters/status2type';
import status2typeString from '../../helpers/formatters/status2typeString';
import i18n from '../../i18n';

export default function getNotificationText(notification) {
  const variables = {
    project: notification?.related?.project ? getProjectNormalizedInternalName(notification.related.project, notification.related.company) : '',
    company: notification?.related?.company ? notification.related.company?.name : '',
    filename: notification?.related?.attachment ? notification.related.attachment?.originalName : '',
    user: notification?.related?.user ? notification.related.user?.display : '',
    userPrimary: notification?.related?.primary ? notification.related.primary?.display : '',
    userSecondary: notification?.related?.secondary ? notification.related.secondary?.display : '',
    creator: notification?.related?.creator ? notification.related.creator?.display : '',
    phone: notification?.related?.phone ? notification.related.phone : '',
    projectName: notification?.related?.project ? notification.related.project.internalName : '',
    projectType: notification?.related?.project ? status2typeString(notification.related.project.status) : '',
    projectStatus: notification?.related?.project ? status2string(notification.related.project.status) : '',
    ns: 'component_notifications',
  };
  switch (notification.itemType) {
    case 'project_status_changed':
      const isMajorChange = status2type(notification.related.new) === status2type(notification.related.old);
      if (isMajorChange) {
        return i18n.t('template.project_status_changed_major', variables);
      } else {
        return i18n.t('template.project_status_changed_minor', variables);
      }
    default:
      return i18n.t(`template.${notification.itemType}`, variables);
  }
}
