import Api from '../../../helpers/api/Api';

export default function postCompanyDocuments(companyId, data, progressCallback) {
  return new Promise(function(resolve, reject) {
    const options = {};
    if (typeof progressCallback === 'function') {
      options.onUploadProgress = progressCallback;
    }

    Api.postFiles(`/companies/${companyId}/attachments`, data, options).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
