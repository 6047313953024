import {useEffect, useState} from 'react';

import TasksCachedDictionary from '../cachedDictionary/TasksCachedDictionary';

export const useTasksDictionary = () => {
  const [dataSet, setDataSet] = useState({});
  const [validUntilTimestampSet, setValidUntilTimestampSet] = useState(0);

  const now = new Date();
  const isValidNow = validUntilTimestampSet > 0 && validUntilTimestampSet > now.getTime();

  useEffect(()=>{
    if (!isValidNow) {
      const dictionaryInstance = TasksCachedDictionary.getInstance();
      dictionaryInstance.get().then((payload)=> {
        setDataSet(payload);
        const newTimestamp = dictionaryInstance.getFetchedAt().getTime() + dictionaryInstance.getTTL();
        setValidUntilTimestampSet(newTimestamp);
      });
    }
  }, [isValidNow]);

  return dataSet ?? [];
};
