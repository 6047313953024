import './Search.scss';

import {Close} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDebounce} from 'use-debounce';

export default function Search(props) {
  const [text, setText] = useState('');
  const [value] = useDebounce(text, 700);

  function textChange(event) {
    setText(event.target.value);
  }

  function clearSearch(event) {
    setText('');
    props.callback(value);
  }

  const callback = props.callback;
  useEffect(()=>{
    if (callback) {
      callback(value);
    }
  }, [callback, value]);

  return (
    <div className="Search">
      <OutlinedInput
        className="Search-input"
        size="small"
        type="text"
        placeholder={props.placeholder ?? ''}
        onChange={textChange}
        value={text}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={text!=='' &&
          <InputAdornment position="end" onClick={clearSearch}>
            <Close />
          </InputAdornment>
        }
      />
    </div>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string,
  callback: PropTypes.func,
};
