import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ActiveFilter from './components/ActiveFilter';
import SearchFilter from './components/SearchFilter';
import SecurityContextFilter from './components/SecurityContextFilter';
import css from './index.module.scss';

export default function FiltersBlock({className}) {
  return (
    <section className={classNames(css.FiltersBlock, className)}>
      <div><SearchFilter /></div>
      <div><SecurityContextFilter /></div>
      <div><ActiveFilter /></div>
    </section>
  );
}

FiltersBlock.propTypes = {
  className: PropTypes.string,
};

FiltersBlock.defaultProps = {
  className: undefined,
};
