import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useUserSecurityContext} from '../../customHooks/useUserSecurityContext';
import EditField from '../EditField/EditField';
import css from './index.module.scss';

function SecurityContextDropdownSelector({securityContextId, onChangeCallback}) {
  const {t} = useTranslation('login');
  const userSecurityContexts = useUserSecurityContext();
  const [randomComponentId] = useState(`component${Math.random()}`.replace('.', ''));
  const [securityContextsOptionUnset] = useState({id: null, name: '', display: t(`security_context.unset`, {ns: 'login'})});
  const [securityContextsOptions, setSecurityContextsOptions] = useState([]);
  const [canShowSelector, setShowSelector] = useState(false);

  const currentSecurityContextOption = Array.isArray(securityContextsOptions) && securityContextsOptions.length>0 ?
    securityContextsOptions.filter((ctx)=>ctx.id===securityContextId)[0] :
    null;

  function onSecurityContextChange(fieldId, newName) {
    const context = userSecurityContexts.filter((ctx)=>ctx.name===newName);
    if (context) {
      onChangeCallback(context[0]?.id ?? null);
    }
  }

  useEffect(()=>{
    if (!!userSecurityContexts && Array.isArray(userSecurityContexts)) {
      const options = [securityContextsOptionUnset];
      userSecurityContexts.forEach((ctx) => {
        options.push({id: ctx.id, name: ctx.name, display: t(`security_context.name.${ctx.name}`, {ns: 'login'})});
      });
      setSecurityContextsOptions(options);
      setShowSelector(true);
    } else {
      setShowSelector(false);
    }
  }, [userSecurityContexts]);

  if (!canShowSelector) return <></>;

  return (
    <EditField
      className={css.SecurityContextDropdownSelector}
      id={randomComponentId}
      value={currentSecurityContextOption?.name}
      type="dropdown"
      placeholder={t('security_context.placeholder')}
      options={securityContextsOptions}
      editing={true}
      onChange={onSecurityContextChange}
    />
  );
}

SecurityContextDropdownSelector.propTypes = {
  securityContextId: PropTypes.number,
  onChangeCallback: PropTypes.func.isRequired,
};

export default SecurityContextDropdownSelector;
