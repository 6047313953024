import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AddingNoteModal from '../../../components/AddingNoteModal/AddingNoteModal';
import Card from '../../../components/Card/Card';
import Item from '../../../components/Item/Item';
import ShowAllNotesModal from '../../../components/ShowAllNotesModal/ShowAllNotesModal';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {
  CAPABILITY_EDIT_ONLY_NOTES,
} from '../../../helpers/security/hasCapability';
import getProjectNotes from './api/getProjectNotes';
import css from './index.module.scss';
import NoteExcerpt from './NoteExcerpt';
import NoteModal from './NoteModal';

const PREVIEW_LIMIT = 5;

function ProjectNotesPanel({project}) {
  const {t} = useTranslation('page_cases');

  const [notes, setNotes] = useState([]);
  const [singleNote, setSingleNote] = useState(null);
  const [allNotesModalOpes, setAllNotesModalOpen] = useState(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);

  const canEditNotes = useHasCapabilityHere(CAPABILITY_EDIT_ONLY_NOTES);

  function seeMoreNotes() {
    setAllNotesModalOpen(true);
  }

  function requestShowNote(note) {
    if (typeof note === 'number') {
      setSingleNote(notes.find((n)=>n.id===note));
    } else {
      setSingleNote(note);
    }
  }

  const refreshNotesList = useCallback(()=>{
    setNotes([]);
    if (!!project && project?.id) {
      getProjectNotes(project.id).then((notes) => {
        setNotes(notes);
      });
    }
  }, [project]);

  useEffect(()=>{
    refreshNotesList();
  }, [refreshNotesList]);

  const sortedNotes = notes.sort((a, b) => a.id < b.id ? 1 : -1);

  return (
    <Card
      className={css.ProjectNotesPanel}
      title={
        `${t('panel_title', {ns: 'component_notes'})} (${notes.length})`
      }
      appendToHeader={
        <>
          {canEditNotes &&
            <CardActions>
              <Button
                size="small"
                sx={{fontSize: 15, padding: 0}}
                onClick={() => setAddNoteModalOpen(true)}
              >
                {t('add_button', {ns: 'component_notes'})}
              </Button>
            </CardActions>
          }
        </>
      }
      appendToFooter={
        <CardActions
          sx={{marginTop: 'auto', textAlign: 'right'}}
        >
          <Button size={'small'} sx={{paddingLeft: '15px'}} onClick={seeMoreNotes}>
            {t('see_more', {ns: 'component_notes'})}
          </Button>
        </CardActions>}
    >
      <div>
        {sortedNotes.map((note, noteIdx) => {
          if (noteIdx > PREVIEW_LIMIT-1) {
            return null;
          } else {
            return (
              <NoteExcerpt
                key={note['@id']}
                note={note}
                requestShowNote={requestShowNote}
                withUnderline={noteIdx < PREVIEW_LIMIT-1}
              />
            );
          }
        })}
      </div>
      {project &&
        <AddingNoteModal
          projectId={project.id}
          isAddingNoteOpen={addNoteModalOpen}
          handleAddingPopupClose={()=>setAddNoteModalOpen(false)}
          onRefresh={refreshNotesList}
        />
      }
      {project &&
        <NoteModal
          isOpen={!!singleNote}
          requestClose={()=>setSingleNote(null)}
          requestRefresh={refreshNotesList}
          note={singleNote}
          project={project}
        />
      }
      {project &&
        <ShowAllNotesModal
          handleAllNotesPopupClose={()=>setAllNotesModalOpen(false)}
          isPopupAllNotesOpen={allNotesModalOpes}
          notes={notes}
          onNoteClick={(noteId)=>requestShowNote(noteId)}
        />
      }
    </Card>
  );
}

ProjectNotesPanel.propTypes = {
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']),
  }),
};

export default ProjectNotesPanel;
