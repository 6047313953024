import {Input} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldDateString(props) {
  function callback(event) {
    props.onChange(event.target.value);
  }
  return (
    <Input
      className="EditFieldDateString"
      size="small"
      value={props.value}
      onChange={callback}
      fullWidth={true}
    />
  );
}

EditFieldDateString.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};
