import Api from '../../../helpers/api/Api';

export default function patchNote(id, newNoteText) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/notes/${id}`, {
      text: newNoteText,
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
