import Api from '../../../helpers/api/Api';

export default function getCompanyUsersDictionary(companyId) {
  return new Promise(function(resolve, reject) {
    Api.get(`/users/dictionary?order['lastname']=asc&pagination=false&onlyEmployeesOf=${companyId}`).then((data)=>{
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
