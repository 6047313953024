import Semaphore from './Semaphore';

abstract class AbstractCachedDictionary {
  public static payloads: Map<string, IPayload> = new Map<string, IPayload>();
  protected static instances: Map<string, AbstractCachedDictionary> = new Map<string, AbstractCachedDictionary>();
  protected static semaphores: Map<string, Semaphore> = new Map<string, Semaphore>();

  protected abstract fetchNewDataset(params:object|null): Promise<any>
  protected abstract getTimeToLiveMiliseconds(): number
  protected abstract getUniqueKey(): string

  public static getInstance(): AbstractCachedDictionary {
    throw new Error('You have to override AbstractCachedDictionary::getInstance!');
  }

  public get(params : {[key:string]:any}|null = null, force : boolean = false): Promise<any> {
    const getPromise = (params : {[key:string]:any}|null = null, force : boolean = false) => {
      return new Promise((resolve, reject) => {
        const key: string = this.getUniqueKey();
        if (force) {
          this.invalidate();
        }
        if (!this.isPayloadValid(params)) {
          this.fetchNewDataset(params).then((result) => {
            const payload: IPayload = {
              data: result,
              fetchedAt: new Date(),
              params: params,
            };
            AbstractCachedDictionary.payloads.set(key, payload);
            resolve(payload.data);
          }).catch((error) => {
            reject(error);
          });
        } else {
          const cached = AbstractCachedDictionary.payloads.get(key);
          if (cached && cached.data) {
            resolve(cached.data);
          } else {
            reject();
          }
        }
      });
    };
    const semaphore = this.getSemaphoreInstance();
    return semaphore.callFunction(getPromise, params, force);
  }

  public invalidate(): void {
    const key : string = this.getUniqueKey();
    AbstractCachedDictionary.payloads.delete(key);
  }

  public getTTL(): number {
    return this.getTimeToLiveMiliseconds();
  }

  public getFetchedAt(): Date|null {
    const key : string = this.getUniqueKey();
    const payload = AbstractCachedDictionary.payloads.get(key);
    return payload?.fetchedAt ?? null;
  }

  protected static joinUrl(base: string, parts: Array<string|null>): string {
    return `${base}?${parts.filter((item)=>!!item).join('&')}`;
  }

  private isPayloadValid(params : {[key:string]:any}|null = null) : boolean {
    const key : string = this.getUniqueKey();
    if (!AbstractCachedDictionary.payloads.has(key)) {
      return false;
    }
    const payload = AbstractCachedDictionary.payloads.get(key);
    if (
      typeof payload === 'undefined' ||
        !AbstractCachedDictionary.areParamsTheSame(params, payload.params)
    ) {
      return false;
    }
    const now = new Date();
    return payload.fetchedAt.getTime() > now.getTime() - this.getTimeToLiveMiliseconds();
  }

  protected getSemaphoreInstance(): Semaphore {
    const uniqueKey = this.getUniqueKey();
    const instance = AbstractCachedDictionary.semaphores.get(uniqueKey);
    if (instance) {
      return instance;
    } else {
      const newInstance = new Semaphore();
      AbstractCachedDictionary.semaphores.set(uniqueKey, newInstance);
      return newInstance;
    }
  }

  private static areParamsTheSame(params1 : {[key:string]:any}|null, params2 : {[key:string]:any}|null): boolean {
    if (params1 === null && params2 === null) {
      return true;
    } else if ((params1 === null && params2 !== null) || (params1 !== null && params2 === null)) {
      return false;
    } else if (!!params1 && !!params2) {
      for (const key1 in params1) {
        if (params1[key1] !== params2[key1]) {
          return false;
        }
      }
      for (const key2 in params2) {
        if (params1[key2] !== params2[key2]) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  protected static getOrCreateInstance(uniqueKey : string, createCallback : () => AbstractCachedDictionary) {
    const instance = AbstractCachedDictionary.instances.get(uniqueKey);
    if (instance) {
      return instance;
    } else {
      const newInstance = createCallback();
      AbstractCachedDictionary.instances.set(uniqueKey, newInstance);
      return newInstance;
    }
  }
}

export default AbstractCachedDictionary;
