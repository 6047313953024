import './PasswordChanged.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Api from '../../helpers/api/Api';

export default function PasswordChanged() {
  const {t, i18n} = useTranslation('login');
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
      className="password-changed"
    >
      <div className="title">{t('password_changed.title')}</div>
      <div className="content">{t('password_changed.description')}</div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '46px',
        }}
      >
        <Link to="/login" className="link btn">
          {t('password_changed.button')}
        </Link>
      </Box>
    </Box>
  );
}
