import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useExecutorsDictionary} from '../../../../helpers/dictionaries/hooks/useExecutorsDictionary';
import {useStateValue} from '../../../../state/state';
import useTasksListFilters, {
  PARAM_ONLY_FOR_USER_ID, PARAM_ONLY_MINE, PARAM_PAGE,
} from '../../hooks/useTasksListFilters';
import css from './index.module.scss';

function ExecutorFilter() {
  const {t} = useTranslation('page_tasks');
  const {current, setParams} = useTasksListFilters();
  const [{user}] = useStateValue();

  const executorsDictionary = useExecutorsDictionary();

  const currentDropdownValue = !isNaN(parseInt(current[PARAM_ONLY_FOR_USER_ID])) ?
    `/api/users/${current[PARAM_ONLY_FOR_USER_ID]}` :
    '';

  const executorsDropdownList = executorsDictionary ?
    [
      {name: '', display: t('statuses.all')},
      ...executorsDictionary.map((exe) => (
        {name: exe['@id'], display: `${exe.firstname} ${exe.lastname}`}
      )),
    ] :
    [];

  function setExecutor(id, val) {
    const selectedId = typeof val === 'string' && val.length > 0 ?
      parseInt(val.replace('/api/users/', '')) :
      null;

    setParams({
      [PARAM_ONLY_FOR_USER_ID]: selectedId,
      [PARAM_PAGE]: 1,
      [PARAM_ONLY_MINE]: selectedId !== user.id ? false : true,
    });
  }

  return (
    <div className={css.filterWrapper}>
      <EditField
        className={classNames(css.filter, css.noPadding)}
        typeOptions={{innerClassName: css.inputHeight, innerSx: {height: '55px'}}}
        id="executorsList"
        value={currentDropdownValue}
        type="autocomplete"
        placeholder={t('filter.executor.placeholder')}
        options={executorsDropdownList}
        editing={true}
        onChange={setExecutor}
      />
    </div>
  );
}

export default ExecutorFilter;
