import {Typography} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import useUsersListFilters, {GROUP, PARAM_GROUP, PARAM_PAGE} from '../hooks/useUsersListFilters';
import css from './index.module.scss';

const TABS = [
  {id: GROUP.ALL, textKey: 'list.tab.all.text'},
  {id: GROUP.ADMINISTRATORS, textKey: 'list.tab.admins.text'},
  {id: GROUP.SALES, textKey: 'list.tab.sales.text'},
  {id: GROUP.VIEWONLY, textKey: 'list.tab.viewonly.text'},
];

export default function GroupingBlock() {
  const {t} = useTranslation('page_users');

  const {current, setParams} = useUsersListFilters();

  const activeTab = TABS.find((t)=>(t.id===current[PARAM_GROUP])) ?? TABS[0];

  function setTab(newTab) {
    if (newTab.id !== activeTab.id) {
      setParams({
        [PARAM_GROUP]: newTab.id,
        [PARAM_PAGE]: 1,
      });
    }
  }

  return (
    <div className={css.GroupingBlock}>
      {TABS.map((item)=>(
        <Typography
          key={item.id}
          variant="h3"
          onClick={()=>setTab(item)}
          className={classNames(css.GroupingBlockTab, {[css.isActive]: item.id===activeTab.id})}
        >
          {t(item.textKey)}
        </Typography>
      ))}
    </div>
  );
}
