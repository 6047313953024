import Api from '../../../../helpers/api/Api';
import guessArrayOfIris from '../functions/guessArrayOfIris';

export default function patchProject(projectId, projectData) {
  return new Promise(function(resolve, reject) {
    const numericProjectId = typeof projectId === 'string' ?
      parseInt(projectId.replace('/api/projects/', '')) :
      parseInt(projectId);

    if (isNaN(numericProjectId) || numericProjectId<=0) {
      reject('Unrecognized projectId');
    }

    Api.patch(`/projects/${numericProjectId}`, {
      internalName: projectData.internalName,
      amount: isNaN(parseInt(projectData.amount)) ? 0 : parseInt(projectData.amount),
      activities: projectData.activities ?? '',
      securityContexts: guessArrayOfIris(projectData?.securityContexts),
      handler: projectData.handler ?? null,
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
