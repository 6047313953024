import './TaskItem.scss';

import {Badge, Work} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';

export default function TaskItem(props) {
  const {t} = useTranslation('component_tasks');

  const taskLabelColor =
    props.completedAt !== null ?
      '#007900' :
      props.deadlineAt !== null && new Date(props.deadlineAt) < new Date() ?
      '#ED143D' :
      '#1F8CC6';

  function itemClicked() {
    if (typeof props.onClick === 'function' && typeof props.iri === 'string') {
      props.onClick(props.iri);
    }
  }
  const cardStyles = {
    border: 'none',
  };

  if (props.transparent) {
    cardStyles.backgroundColor = 'transparent';
  }

  const creationString =
    [
      formatDateTimestamp(props.createdAt),
      props.author ? `by ${props.author}` : null,
    ]
        .filter((i) => !!i)
        .join(', ') ?? '';

  const completionString = props.completedAt ?
    `${t('edit_modal.completed_at')} ${formatDateTimestamp(
        props.completedAt,
    )}` :
    '';

  return (
    <Card
      className="TaskItem"
      variant="outlined"
      sx={cardStyles}
      onClick={itemClicked}
    >
      <CardContent className="TaskItem-Wrapper">
        <Box className="TaskItem-creator">
          <Typography fontSize="14px">
            <Badge fontSize="22px" className="executor-badge" />
            {props.executors
                .map((item) =>
                item.display ?
                  item.display :
                  `${item.firstname} ${item.lastname}`,
                )
                .join(', ')}
          </Typography>
          <ChevronRightIcon />
        </Box>
        <Box className="TaskItem-text">
          <Typography fontSize={'medium'} className="TaskItem-text-title">
            {props.title}
          </Typography>
          <Typography fontSize={'small'} className="TaskItem-text-content">
            {props.content}
          </Typography>
        </Box>
        <Box className="TaskItem-timestamp">
          {creationString && (
            <Typography fontSize="14px">{creationString}</Typography>
          )}
          {completionString && (
            <Typography fontSize="14px">{completionString}</Typography>
          )}
        </Box>
        <Box
          className="TaskItem-label"
          sx={{
            width: '40px',
            margin: '5px 0 0 0',
            border: `5px solid ${taskLabelColor}`,
            borderRadius: '4px',
          }}
        ></Box>
      </CardContent>
      {props.withUnderline && <HorizontalDivider sx />}
    </Card>
  );
}

TaskItem.propTypes = {
  iri: PropTypes.string,
  author: PropTypes.string,
  executors: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  ).isRequired,
  goTo: PropTypes.bool,
  content: PropTypes.string,
  createdAt: PropTypes.string,
  completedAt: PropTypes.string,
  deadlineAt: PropTypes.string,
  createdAtWithIcon: PropTypes.bool,
  onClick: PropTypes.func,
  withUnderline: PropTypes.bool,
  transparent: PropTypes.bool,
};
