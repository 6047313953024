import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import useTasksListFilters from '../../hooks/useTasksListFilters';
import css from './index.module.scss';
import {createSelectionParams, getCurrentSelection} from './LinkedResourceFilter.selectionFunctions';

function LinkedResourceFilter() {
  const {t} = useTranslation('page_tasks');
  const {current, setParams} = useTasksListFilters();

  const currentSelection = getCurrentSelection(current);

  const dropdownList = [
    {name: '', display: t('filter.linkedResource.options.all')},
    {name: 'user', display: t('filter.linkedResource.options.user')},
    {name: 'document', display: t('filter.linkedResource.options.document')},
    {name: 'note', display: t('filter.linkedResource.options.note')},
    {name: 'task', display: t('filter.linkedResource.options.task')},
    {name: 'money_supplier', display: t('filter.linkedResource.options.moneySuppliers')},
    {name: 'intermediary', display: t('filter.linkedResource.options.intermediares')},
    {name: 'client', display: t('filter.linkedResource.options.client')},
    {name: 'lead', display: t('filter.linkedResource.options.lead')},
    {name: 'opportunity', display: t('filter.linkedResource.options.opportunity')},
    {name: 'account', display: t('filter.linkedResource.options.account')},
  ];

  function setCompanyRelation(id, val) {
    if (val !== currentSelection) {
      const newParams = createSelectionParams(val);
      setParams(newParams);
    }
  }

  return (
    <div className={css.filterWrapper}>
      <EditField
        className={classNames(css.filter, css.noPadding)}
        id="linkedResourcesList"
        value={currentSelection}
        type="dropdown"
        placeholder={t('filter.linkedResource.placeholder')}
        options={dropdownList}
        editing={true}
        onChange={setCompanyRelation}
      />
    </div>
  );
}

export default LinkedResourceFilter;
