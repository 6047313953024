import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Switch from '../../components/Switch/Switch';
import {setUserField} from '../../state/actions';
import {useStateValue} from '../../state/state';
import TotpConfigurationModal from './TotpConfigurationModal';

export default function TotpConfiguration() {
  const {t} = useTranslation('page_settings');
  const [{user}, dispatch] = useStateValue();
  const [tempSwitchState, setTempSwitchState] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  function onSwitchChange() {
    setTempSwitchState(!tempSwitchState);
    setModalVisible(true);
  }

  function modalClose(newTotpState = false) {
    setModalVisible(false);
    if (user?.isTotpEnabled !== newTotpState) {
      dispatch(setUserField('isTotpEnabled', !!newTotpState));
    }
    setTempSwitchState(newTotpState);
  }

  useEffect(()=>{
    if (user && user.id && user?.isTotpEnabled) {
      setTempSwitchState(user.isTotpEnabled);
    }
  }, [user, user?.isTotpEnabled]);

  return (
    <div className="TotpConfiguration">
      <Switch
        label={t('totp_configuration.switch.description')}
        checked={tempSwitchState}
        onChange={onSwitchChange}
      />
      { user &&
        <TotpConfigurationModal
          isOpen={isModalVisible}
          onCloseRequest={modalClose}
          user={user}
        />
      }
    </div>
  );
}

