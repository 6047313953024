import {TextField} from '@mui/material';
import {Autocomplete} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldDropdownAutocomplete(props) {
  function callback(event, option) {
    props.onChange(option ? option.id : '');
  }

  const autocompleteOptions = props.options.map((opt) => ({id: opt.name, label: opt.display}));
  const autocompleteOptionsFilteredByValue = autocompleteOptions.filter((item)=>item.id === props.value);
  const autocompleteValue = autocompleteOptionsFilteredByValue.length > 0 ? autocompleteOptionsFilteredByValue[0] : '';

  return (
    <div className="EditFieldDropdownAutocomplete" style={{position: 'relative'}}>
      <Autocomplete
        size="small"
        disablePortal
        value={autocompleteValue?.id === '' ? null : autocompleteValue}
        options={autocompleteOptions}
        fullWidth={true}
        renderInput={(params) => <TextField {...params} sx={{height: '100%'}} placeholder={props.placeholder}/>}
        onChange={callback}
        renderOption={(optionProps, option) => <li {...optionProps} key={option.id}>{option.label}</li>}
      />
    </div>
  );
}

EditFieldDropdownAutocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  label: PropTypes.string,
  typeOptions: PropTypes.shape({
    innerClassName: PropTypes.string,
    innerSx: PropTypes.object,
  }),
};
