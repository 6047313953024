import './UnseenNotificationsDialog.scss';

import {Dialog, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {setUnreadNotificationsCount} from '../../state/actions';
import {useStateValue} from '../../state/state';
import getUnseenNotificationsList from './api/getUnseenNotificationsList';
import postNotificationMarkAsSeen from './api/postNotificationMarkAsSeen';
import UnseenNotificationsList from './UnseenNotificationsList';

const ITEMS_PER_PAGE = 10;

export default function UnseenNotificationsDialog(props) {
  const [{unseenNotificationsCount}, dispatch] = useStateValue();
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [page, setPage] = useState(1);

  const userId = props.user && props.user.id;

  useEffect(()=>{
    fetchUnseenNotifications();
  }, [page, userId]);

  function fetchUnseenNotifications() {
    getUnseenNotificationsList(page, ITEMS_PER_PAGE).then((data) => {
      setNotifications(data.notifications);
      setNotificationsCount(data.items);
    });
  }

  function markAsSeen(notification, onDoneCallback) {
    postNotificationMarkAsSeen(notification.id).then((newNotification) => {
      if (unseenNotificationsCount > 0) {
        dispatch(setUnreadNotificationsCount(unseenNotificationsCount-1));
      }
      if (typeof onDoneCallback === 'function') {
        onDoneCallback();
      }
      fetchUnseenNotifications();
      props.onClose();
    });
  }

  function createContents() {
    return (
      <Paper
        className="UnseenNotificationsDialog-paper"
      >
        <div className="UnseenNotificationsDialog-triangle" />
        <div className="UnseenNotificationsDialog-inner">
          <UnseenNotificationsList
            notifications={notifications}
            notificationsCount={notificationsCount}
            page={page}
            itemsPerPage={ITEMS_PER_PAGE}
            markAsSeen={markAsSeen}
          />
        </div>
      </Paper>
    );
  }

  return (
    <Dialog
      className="UnseenNotificationsDialog"
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={createContents}
    />
  );
}

UnseenNotificationsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

