import {OpenInNew} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import css from './CompanyName.module.scss';
import useCanShowOutgoingLink from './hooks/useCanShowOutgoingLink';

function CompanyName({projectCompany}) {
  const outgoingLink = `/companies/${projectCompany?.company?.id}`;
  const canShowLink = useCanShowOutgoingLink(outgoingLink);
  return (
    <Typography variant={'h2'}>
      {projectCompany?.company?.name}
      {canShowLink &&
        <Link to={outgoingLink}>
          <OpenInNew className={css.spaced} fontSize="inherit" color="primary"/>
        </Link>
      }
    </Typography>
  );
}

CompanyName.propTypes = {
  projectCompany: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectCompany']).isRequired,
    'company': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['Company']),
    }).isRequired,
  }),
};

export default CompanyName;
