import './ServerErrorPopUp.scss';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import successIcon from '../../assets/informer_success.svg';

export default function SelfRegisteredCorrectlyPopUp(props) {
  return (
    <Dialog
      className="SelfRegisteredCorrectlyPopUp"
      open={props.isPopupOpen}
      onClose={props.handlePopupClose}
      aria-labelledby="success-dialog-title"
      aria-describedby="success-dialog-description"
    >
      <DialogContent>
        <img className="infoIcon" src={successIcon} alt="success" />
        <DialogTitle
          sx={{fontSize: '28px', fontWeight: '500', textAlign: 'center'}}
        >
            Your request has been submitted.
        </DialogTitle>
        <DialogContentText
          id="success-dialog-description"
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '500',
            color: '#252733',
          }}
        >
            We will review Your application and contact You soon.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{margin: '0 auto 48px'}}>
        <Button
          onClick={props.handlePopupClose}
          variant="contained"
          sx={{width: '135px'}}
        >
            Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
