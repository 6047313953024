import './ConfirmationDialog.scss';

import {Modal, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

import informer_error from '../../assets/informer_error.svg';
import informer_info from '../../assets/informer_info.svg';
import informer_success from '../../assets/informer_success.svg';
import informer_warning from '../../assets/informer_warning.svg';

export default function ConfirmationDialog(props) {
  function onCloseRequest() {
    if (props.onCloseRequest) {
      props.onCloseRequest();
    }
  }

  return (
    <Modal
      className={`ConfirmationDialog ${props.className ?? ''}`}
      open={props.open}
      onClose={props.onCloseRequest}
    >
      <>
        <div className="ConfirmationDialog-inner">
          {props.variant &&
              <div
                className="ConfirmationDialog-icon"
                style={{backgroundImage: `url(${getStatusIcon(props.variant)})`}}
              />
          }
          {props.text &&
              <Typography
                className="ConfirmationDialog-text"
                fontSize={28}
                fontWeight={500}
              >
                {props.text}
              </Typography>
          }
          {props.buttonText &&
              <Button
                className="ConfirmationDialog-button"
                variant="contained"
                onClick={onCloseRequest}
              >
                {props.buttonText}
              </Button>
          }
        </div>
      </>
    </Modal>
  );
}

function getStatusIcon(type) {
  switch (type) {
    case 'info':
      return informer_info;
    case 'warning':
      return informer_warning;
    case 'error':
      return informer_error;
    case 'success':
      return informer_success;
    default:
      return '';
  }
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([
    'info',
    'warning',
    'error',
    'success',
  ]),
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onCloseRequest: PropTypes.func,
  className: PropTypes.string,
};
