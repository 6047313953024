import './App.scss';

import {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import ProgressIndicator from './components/ProgressIndicator/ProgressIndicator';
import Api from './helpers/api/Api';
import useLanguageOverride from './helpers/language/useLanguageOverride';
import {ROUTE_ANONYMOUS} from './helpers/navigation/getLoginFrontendPath';
import hasCapability, {CAPABILITY_PURGE} from './helpers/security/hasCapability';
import hasSlugAccess from './helpers/security/hasSlugAccess';
import {AddingLeadsAutomaticallyPage} from './pages/AddingLeads/AddingLeadsAutomaticallyPage';
import CompaniesPage from './pages/Companies/Companies';
import CompanyDetailsPage from './pages/CompanyDetails/CompanyDetails';
import Error404Page from './pages/Error404/Error404';
import {CreateNewPasswordPage} from './pages/LoginLayouts/CreateNewPasswordPage';
import {ForgottenPasswordPage} from './pages/LoginLayouts/ForgottenPasswordPage';
import {LoginPage} from './pages/LoginLayouts/LoginPage';
import {PasswordChangedPage} from './pages/LoginLayouts/PasswordChangedPage';
import {RestorePasswordPage} from './pages/LoginLayouts/RestorePasswordPage';
import NotificationsPage from './pages/Notifications/NotificationsPage';
import OverviewPage from './pages/Overview/Overview';
import ProjectDetailsPage from './pages/ProjectDetailsPage';
import ProjectsPage from './pages/ProjectsPage';
import SearchPage from './pages/Search/Search';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import TasksPage from './pages/TasksTab';
import UserDetailsPage from './pages/UserDetails/UserDetails';
import UserProjectsPage from './pages/UserProjects/UserProjects';
import UsersPage from './pages/Users';
import {setUser} from './state/actions';
import {useStateValue} from './state/state';

function App() {
  const [{user}, dispatch] = useStateValue();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useLanguageOverride();

  useEffect(()=>{
    Api.refreshCredentials().then((data) => {
      dispatch(setUser(data.user));
      setIsChecking(false);
      setIsLoggedIn(true);
    }).catch(() => {
      const currentLocation = document.location.pathname;
      if (!Object.values(ROUTE_ANONYMOUS).find((item)=>currentLocation.startsWith(item))) {
        document.location = `${document.location.origin}/login?redirectTo=${currentLocation}`;
      } else {
        setIsChecking(false);
      }
    });
  }, []);

  if (isChecking) {
    return <ProgressIndicator stretch={true} />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {!isLoggedIn &&
                <Route path="/" element={<LoginPage/>}/>
          }
          {isLoggedIn &&
                <Route path="/" element={<OverviewPage/>}/>
          }
          <Route path={ROUTE_ANONYMOUS.LOGIN} element={<LoginPage/>}/>
          <Route path={ROUTE_ANONYMOUS.FORGOTTEN_PASSWORD} element={<ForgottenPasswordPage />}/>
          <Route path={ROUTE_ANONYMOUS.RESTORE_PASSWORD} element={<RestorePasswordPage />} />
          <Route path={ROUTE_ANONYMOUS.CONFIRMATION_LINK} element={<CreateNewPasswordPage />}/>
          <Route path={ROUTE_ANONYMOUS.PASSWORD_CHANGED} element={<PasswordChangedPage />}/>
          <Route path={ROUTE_ANONYMOUS.LEAD_SELF_REGISTRATION} element={<AddingLeadsAutomaticallyPage />}/>

          {hasSlugAccess(user, 'overview') &&
              <>
                <Route path="/overview" element={<OverviewPage/>}/>
              </>
          }
          {hasSlugAccess(user, 'cases') &&
                <>
                  <Route path="/cases" element={<ProjectsPage pageScope="cases"/>}/>
                  <Route path="/cases/:id" element={<ProjectDetailsPage pageScope="cases" />}/>
                </>
          }
          {hasSlugAccess(user, 'leads') &&
                <>
                  <Route path="/leads" element={<ProjectsPage pageScope="leads"/>}/>
                  <Route path="/leads/:id" element={<ProjectDetailsPage pageScope="leads" />}/>
                  {hasCapability(user, CAPABILITY_PURGE, 'leads') &&
                    <Route path="/leads/:id/purge" element={<ProjectDetailsPage pageScope="leads" view="purge"/>}/>
                  }
                </>
          }
          {hasSlugAccess(user, 'opportunities') &&
                <>
                  <Route path="/opportunities" element={<ProjectsPage pageScope="opportunities"/>}/>
                  <Route path="/opportunities/:id" element={<ProjectDetailsPage pageScope="opportunities" />}/>
                  {hasCapability(user, CAPABILITY_PURGE, 'opportunities') &&
                    <Route path="/opportunities/:id/purge" element={<ProjectDetailsPage pageScope="opportunities" view="purge" />}/>
                  }
                </>
          }
          {hasSlugAccess(user, 'accounts') &&
                <>
                  <Route path="/accounts" element={<ProjectsPage pageScope="accounts"/>}/>
                  <Route path="/accounts/:id" element={<ProjectDetailsPage pageScope="accounts" />}/>
                  {hasCapability(user, CAPABILITY_PURGE, 'accounts') &&
                    <Route path="/accounts/:id/purge" element={<ProjectDetailsPage pageScope="accounts" view="purge"/>}/>
                  }
                </>
          }
          {hasSlugAccess(user, 'users') &&
                <>
                  <Route path="/users" element={<UsersPage/>}/>
                  <Route path="/users/:id" element={<UserDetailsPage />}/>
                  {hasCapability(user, CAPABILITY_PURGE, 'users') &&
                    <Route path="/users/:id/purge" element={<UserDetailsPage view="purge"/>}/>
                  }
                </>
          }
          {hasSlugAccess(user, 'companies') &&
              <>
                <Route path="/companies" element={<CompaniesPage/>}/>
                <Route path="/companies/:id" element={<CompanyDetailsPage/>}/>
                {hasCapability(user, CAPABILITY_PURGE, 'companies') &&
                  <Route path="/companies/:companyId/purge" element={<CompanyDetailsPage view="purge"/>}/>
                }
                <Route path="/companies/:companyId/users/:userId" element={<UserProjectsPage/>}/>
                {hasCapability(user, CAPABILITY_PURGE, 'users') &&
                  <Route path="/companies/:companyId/users/:userId/purge" element={<UserProjectsPage view="purge"/>}/>
                }
              </>
          }
          {hasSlugAccess(user, 'tasks') &&
                <>
                  <Route path="/tasks" element={<TasksPage />}/>
                </>
          }
          {hasSlugAccess(user, 'settings') &&
                <>
                  <Route path="/settings" element={<SettingsPage/>}/>
                </>
          }
          {hasSlugAccess(user, 'notifications') &&
                <>
                  <Route path="/notifications" element={<NotificationsPage />}/>
                </>
          }
          <Route path="*" element={<Error404Page/>}/>
          <Route path="/search" element={<SearchPage />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
