import './MergeUsersModal.scss';

import {Autocomplete, Button, Dialog, DialogActions, DialogTitle, Modal, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHandlersDictionary} from '../../helpers/dictionaries/hooks/useHandlersDictionary';
import postMergeUsers from './api/postMergeUsers';

export default function MergeUsersModal(props) {
  const {t} = useTranslation('page_users');

  const [isMergingNow, setMergingNow] = useState(false);

  const [primaryUser, setPrimaryUser] = useState(null);
  const [secondaryUser, setSecondaryUser] = useState(null);

  const [isFinalWarningOpen, setFinalWarningOpen] = useState(false);

  const usersDictionary = useHandlersDictionary();

  const usersDropdownList = Array.isArray(usersDictionary) && usersDictionary.filter((u)=>(
    u['@id']!==props.subject['@id']
  )).map((u) => (
    {label: u.dropdownIdentifier, id: u['@id'], name: `${u.firstname} ${u.lastname}`}
  ));

  function handlePickPrimary(event, option) {
    setPrimaryUser(option);
    setSecondaryUser(null);
  }

  function handlePickSecondary(event, option) {
    setPrimaryUser(null);
    setSecondaryUser(option);
  }

  function onClickMergePrimary() {
    setFinalWarningOpen(true);
  }

  function onClickMergeSecondary() {
    setFinalWarningOpen(true);
  }

  function onClickFinalMerge() {
    setMergingNow(true);
    const primary = primaryUser !== null ? primaryUser.id : props.subject['@id'];
    const secondary = secondaryUser !== null ? secondaryUser.id : props.subject['@id'];
    postMergeUsers(primary, secondary).then(()=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      props.onClose(primaryUser === null ? true : parseInt(primaryUser.id.replace('/api/users/', '')));
    }).catch((error)=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      console.error(error);
    });
  }

  const selectedPrimaryName = primaryUser !== null ?
    usersDropdownList.find((u)=>u.id===primaryUser.id)?.name :
    props.subject.display
  ;
  const selectedSecondaryName = secondaryUser !== null ?
    usersDropdownList.find((u)=>u.id===secondaryUser.id)?.name :
    props.subject.display
  ;

  return (
    <ModalDialog
      className="MergeUsersModal"
      open={props.isOpen}
      onCloseRequest={props.onClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('merge_modal.title')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="warning">{t('merge_modal.warning')}</Typography>
          <Typography variant="smallHint">{t('merge_modal.hint')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="decision-title" variant="h3">{t('merge_modal.subtitle')}</Typography>
          <Typography fontWeight="600">{t('merge_modal.option1.header', {name: props.subject.display})}</Typography>
          <Typography>{t('merge_modal.option1.body', {name: props.subject.display})}</Typography>
          <div className="user-selection selection-secondary">
            <Autocomplete
              className="users-dropdown-autocomplete"
              disablePortal={true}
              size="small"
              id="merge-users-pick-secondary"
              options={usersDropdownList}
              getOptionLabel={(option)=>option.label}
              renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
              renderInput={(params) => <TextField {...params} label={t('merge_modal.option1.placeholder')} />}
              noOptionsText={t('merge_modal.option1.no_results')}
              value={secondaryUser}
              onChange={handlePickSecondary}
            />
            <Button className="merge-action" disabled={secondaryUser === null} variant="outlined" onClick={onClickMergeSecondary}>{t('merge_modal.option1.button')}</Button>
          </div>
          <Typography fontWeight="600">{t('merge_modal.option2.header', {name: props.subject.display})}</Typography>
          <Typography>{t('merge_modal.option2.body', {name: props.subject.display})}</Typography>
          <div className="user-selection selection-primary">
            <Autocomplete
              className="users-dropdown-autocomplete"
              disablePortal={true}
              size="small"
              id="merge-users-pick-primary"
              options={usersDropdownList}
              getOptionLabel={(option)=>option.label}
              renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
              renderInput={(params) => <TextField {...params} label={t('merge_modal.option2.placeholder')}/>}
              noOptionsText={t('merge_modal.option2.no_results')}
              value={primaryUser}

              onChange={handlePickPrimary}
            />
            <Button className="merge-action" disabled={primaryUser === null} variant="outlined"
              onClick={onClickMergePrimary}>{t('merge_modal.option2.button')}</Button>
          </div>
        </div>
        <Dialog
          open={isFinalWarningOpen}
          onClose={() => setFinalWarningOpen(false)}
        >
          {isMergingNow && <ProgressIndicator />}
          {!isMergingNow &&
            <>
              <DialogTitle>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.secondary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedSecondaryName}</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.primary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedPrimaryName}?</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.primary_suffix')}</Typography>
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setFinalWarningOpen(false)} variant="outlined" color="error">{t('merge_modal.final.button_cancel')}</Button>
                <Button onClick={onClickFinalMerge} variant="outlined">{t('merge_modal.final.button_confirm')}</Button>
              </DialogActions>
            </>
          }
        </Dialog>
      </>
    </ModalDialog>
  );
}

MergeUsersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subject: PropTypes.object.isRequired,
};
