import {createTheme} from '@mui/material';

import palette from '../_variables.scss';

const breakValues = {
  legacy: 0,
  xs: 400,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

let theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
    },
    text: {
      main: palette.text,
    },
    gray: {
      main: palette.gray,
    },
    lightgray: {
      main: palette.lightgray,
    },
    red: {
      main: palette.red,
    },
  },
  breakpoints: {
    values: breakValues,
  },
  typography: {
    fontFamily: `"Barlow", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 32,
          fontWeight: 500,
        },
        h2: {
          fontWeight: 500,
          fontSize: 20,
        },
        h3: {
          fontWeight: 500,
          fontSize: 18,
        },
        textInBackground: {
          fontWeight: 400,
          fontSize: 14,
          color: palette.gray,
        },
        smallHint: {
          fontWeight: 400,
          fontSize: 12,
          color: palette.gray,
        },
        mediumHint: {
          fontWeight: 400,
          fontSize: 14,
          color: palette.gray,
        },
        notification: {
          fontWeight: 400,
          fontSize: 16,
        },
        notificationUnseen: {
          fontWeight: 700,
          fontSize: 16,
        },
        smallerText: {
          fontWeight: 400,
          fontSize: 12,
        },
        smallText: {
          fontWeight: 500,
          fontSize: 14,
        },
        mediumText: {
          fontWeight: 500,
          fontSize: 16,
        },
        tinyTag: {
          fontWeight: 500,
          fontSize: 10,
          lineHeight: 1,
          textTransform: 'uppercase',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 6,
          paddingBottom: 6,
        },
        smallText: {
          color: palette.primary,
          fontSize: 16,
          fontWeight: 600,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 4,
          paddingBottom: 4,
        },
        smallerText: {
          color: palette.primary,
          border: `1px solid ${palette.primary}`,
          fontSize: 14,
          fontWeight: 400,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 4,
          paddingBottom: 4,
        },
        navigateOut: {
          color: palette.gray,
          fontSize: 16,
          fontWeight: 600,
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 6,
          paddingBottom: 6,
        },
        tasksViewerOutlined: {
          'outline': '1px solid rgb(217, 218, 225)',
          'fontSize': 14,
          'fontWeight': 500,
          'padding': '0 15px',
          'marginRight': '10px',
          'borderColor': '#D9DAE1',
          '&:hover': {
            backgroundColor: '#D9DAE1',
            borderColor: '#D9DAE1',
          },
        },
        tasksViewerActive: {
          'fontSize': 14,
          'fontWeight': 500,
          'padding': '0 15px',
          'marginRight': '10px',
          'backgroundColor': '#D9DAE1',
          'borderColor': '#D9DAE1',
          '&:hover': {
            backgroundColor: '#D9DAE1',
            borderColor: '#D9DAE1',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.filters .MuiButtonBase-root': {
            padding: '0 6px',
          },
          '&.filters .MuiTypography-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          color: 'lightgray',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
        },
      },
    },
    TextField: {
      styleOverrides: {
        textArea: {
          fontFamily: `"Barlow", "Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: 126,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: '50px',
        },
      },
    },
  },
});

export default theme;
