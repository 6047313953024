import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import EditField from '../EditField/EditField';
import css from './FilterAndSort.module.scss';
const FilterAndSort = (props) => {
  const {t} = useTranslation('component_filter_dropdown');

  const handleFilterChange = (id, newFilters) => {
    const updatedFilters = removeOppositeFilters(newFilters, {
      completed: 'uncompleted',
      uncompleted: 'completed',
      newestFirst: 'nearDeadline',
      nearDeadline: 'newestFirst',
      // Add more filters and their opposite filters here as needed or convert it to props if reused in the future.
    });
    props.setSelectedFilters(updatedFilters);
    updateEndpointUrl(updatedFilters);
  };

  const removeOppositeFilters = (filters, oppositeFilters) => {
    const latestFilter = filters[filters.length - 1];

    if (oppositeFilters.hasOwnProperty(latestFilter)) {
      const oppositeFilter = oppositeFilters[latestFilter];
      if (filters.includes(oppositeFilter)) {
        filters = filters.filter((filter) => filter !== oppositeFilter);
      }
    }

    return filters;
  };

  const updateEndpointUrl = (filters) => {
    const params = createURLSearchParams(filters);
    return props.setParams(params.toString());
  };

  const createURLSearchParams = (filters) => {
    const params = new URLSearchParams();
    const filterParamMap = createFilterParamMap();

    filters.forEach((filter) => {
      if (filterParamMap.has(filter)) {
        const paramValue = filterParamMap.get(filter);

        switch (filter) {
          case 'newestFirst':
            params.set(paramValue, 'desc');
            break;
          case 'nearDeadline':
            params.set(paramValue, 'asc');
            break;
          default:
            params.set(paramValue, 'true');
        }
      }
    });

    return params;
  };

  const createFilterParamMap = () => {
    return new Map([
      ['completed', 'skipUncompleted'],
      ['uncompleted', 'skipCompleted'],
      ['overdue', 'skipUnexpired'],
      ['nearExpiration', 'skipFuture'],
      ['myTasks', 'onlyForMe'],
      ['newestFirst', 'order[createdAt]'],
      ['nearDeadline', 'order[deadlineAt]'],
      // Add more filter:param pairs here if needed or convert it to props if reused in the future.
    ]);
  };

  return (
    <div className={css.TasksFilterAndSort}>
      <EditField
        refreshTasksList={props.refreshTasksList}
        className={css.EditField}
        editing
        id="dropdownFilter"
        type="dropdownFilter"
        value={props.selectedFilters}
        options={[
          {name: 'completed', display: t('dropdown.filters.completed')},
          {name: 'uncompleted', display: t('dropdown.filters.uncompleted')},
          {name: 'myTasks', display: t('dropdown.filters.myTasks')},
          {name: 'overdue', display: t('dropdown.filters.overdue')},
          {
            name: 'nearExpiration',
            display: t('dropdown.filters.nearExpiration'),
          },
        ]}
        onChange={handleFilterChange}
        placeholder={t('dropdown.filters.placeholder')}
      />
      <EditField
        refreshTasksList={props.refreshTasksList}
        className={css.EditField}
        editing
        id="dropdownSort"
        type="dropdownFilter"
        value={props.selectedFilters}
        options={[
          {name: 'newestFirst', display: t('dropdown.sort.newestFirst')},
          {name: 'nearDeadline', display: t('dropdown.sort.nearDeadline')},
        ]}
        onChange={handleFilterChange}
        placeholder={t('dropdown.sort.placeholder')}
      />
    </div>
  );
};

FilterAndSort.propTypes = {
  refreshTasksList: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(
      PropTypes.oneOf([
        'uncompleted',
        'myTasks',
        'overdue',
        'nearExpiration',
        'newestFirst',
        'completed',
        'nearDeadline',
      ]),
  ),
  setParams: PropTypes.func,
  setSelectedFilters: PropTypes.func,
};

export default FilterAndSort;
