import {useEffect, useState} from 'react';

import IntermediariesCachedDictionary from '../cachedDictionary/IntermediariesCachedDictionary';

export const useIntermediariesDictionary = (transformation = null) => {
  const [data, setData] = useState([]);
  const [validUntilTimestamp, setValidUntilTimestamp] = useState(0);

  const now = new Date();
  const isValidNow = typeof validUntilTimestamp === 'number' &&
    validUntilTimestamp>0 &&
    validUntilTimestamp > now.getTime();

  useEffect(()=>{
    if (!isValidNow) {
      const dictionaryInstance = IntermediariesCachedDictionary.getInstance();
      dictionaryInstance.get().then((payload)=> {
        if (transformation && typeof transformation === 'object') {
          setData(payload.map((row) => {
            const transformed = {};
            for (const key in transformation) {
              transformed[key] = row[transformation[key]];
            }
            return transformed;
          }));
        } else {
          setData(payload);
        }
        setValidUntilTimestamp(
            dictionaryInstance.getFetchedAt().getTime() + dictionaryInstance.getTTL(),
        );
      });
    }
  }, [isValidNow, transformation]);

  return data;
};
