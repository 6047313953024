import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export default function useCreateProjectModalConfiguration(pageScope) {
  const {t} = useTranslation();

  return useMemo(() => {
    switch (pageScope) {
      case 'leads':
        return {
          entityName: t('lead', {ns: 'dictionary_project_type'}),
          isProjectNameAvailable: false,
          isProjectNameRequired: false,
          defaultStatus: 'lead_pending',
          isStatusDropdownAvailable: false,
          isHandlerAvailable: true,
          availableStatuses: ['lead_pending', 'lead_rejected'],
        };
      case 'opportunities':
        return {
          entityName: t('opportunity', {ns: 'dictionary_project_type'}),
          isProjectNameAvailable: true,
          isProjectNameRequired: false,
          defaultStatus: 'opportunity_trajectory',
          isStatusDropdownAvailable: true,
          isHandlerAvailable: true,
          availableStatuses: ['opportunity_trajectory', 'opportunity_payment', 'opportunity_memorandum_creating', 'opportunity_memorandum_sent', 'opportunity_resigned'],
        };
      case 'accounts':
        return {
          entityName: t('account', {ns: 'dictionary_project_type'}),
          isProjectNameAvailable: true,
          isProjectNameRequired: false,
          defaultStatus: 'account_in_progress',
          isStatusDropdownAvailable: false,
          isHandlerAvailable: true,
          availableStatuses: ['account_in_progress', 'account_closed'],
        };
      default:
        return {};
    }
  }, [pageScope, t]);
}
