import './AccessDeniedPopUp.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import error_icon from '../../assets/informer_error.svg';

export default function AccessDeniedPopUp(props) {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation('page_cases');

  function redirectToLogin() {
    const currentLocation = document.location.pathname;
    if (currentLocation !== '/login') {
      document.location = `${document.location.origin}/login?redirectTo=${currentLocation}`;
    } else {
      document.location = `${document.location.origin}/login`;
    }
  }

  function historyGoBack() {
    navigate(-1);
  }

  return (
    <div className="AccessDeniedPopUp">
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="AccessDeniedPopUp-title"
        aria-describedby="AccessDeniedPopUp-description"
      >
        <DialogTitle id="AccessDeniedPopUp-title">
          <img className="errorIcon" src={error_icon} alt="info" />
          <Typography variant="h2">{t('access_denied.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="AccessDeniedPopUp-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button onClick={historyGoBack} variant="text" color={'error'}>{t('access_denied.action.go_back')}</Button>
          <Button onClick={redirectToLogin} autoFocus={true} variant="contained" color="error">{t('access_denied.action.re_login')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AccessDeniedPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
