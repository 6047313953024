import './LeftMenuItem.scss';

import {
  AccountBox,
  BusinessCenter,
  Error,
  Group,
  Inventory, Lightbulb,
  Notifications,
  PieChart,
  Settings,
  Task,
} from '@mui/icons-material';
import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

export default function LeftMenuItem(props) {
  const {t, i18n} = useTranslation('layout');

  const iconColor = props.isActive ? 'primary' : '#D9DAE1';
  const textColor = props.isActive ? 'primary' : '#7C7C7C';

  return (
    <Link to={`/${props.slug}`} className={`LeftMenuItem ${props.isActive ? 'active' : 'inactive'}`}>
      {props.unseenCount &&
              <div className="unseen-count">{props.unseenCount}</div>
      }
      {getIcon(props.icon, iconColor)}
      <Typography color={textColor} className="LeftMenuItem-text">{t(props.textI18nKey)}</Typography>
    </Link>
  );
}

function getIcon(icon, color='disabled') {
  switch (icon) {
    case 'PieChart':
      return <PieChart className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Lightbulb':
      return <Lightbulb className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Inventory':
      return <Inventory className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'AccountBox':
      return <AccountBox className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Group':
      return <Group className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'BusinessCenter':
      return <BusinessCenter className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Tasks':
      return <Task className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Settings':
      return <Settings className="LeftMenuItem-icon" color={color} fontSize="small" />;
    case 'Notifications':
      return <Notifications className="LeftMenuItem-icon" color={color} fontSize="small" />;
    default:
      return <Error className="LeftMenuItem-icon" color={color} fontSize="small" />;
  }
}

LeftMenuItem.propTypes = {
  text: PropTypes.string,
  textI18nKey: PropTypes.string,
  slug: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  isMenuOpened: PropTypes.bool.isRequired,
  unseenCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
