import Api from '../../../helpers/api/Api';

export default function patchCompanyEmployees(id, patchData) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/companies/${id}`, patchData).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
