import './DragAndDrop.scss';

import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import cloudIcon from '../../assets/cloud-icon.svg';
import tickCircleIcon from '../../assets/tick-circle-icon.svg';
import trashIcon from '../../assets/trash-icon.svg';

const MAX_FILE_SIZE = 20000000;
export const ALLOWED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel', 'application/msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/rtf',
  'text/plain',
  'text/csv',
  'application/xml', 'text/xml',
  'image/jpeg', 'image/png', 'image/*',
  'application/zip',
  'application/x-7z-compressed',
];
export const ALLOWED_FILE_EXTENSIONS = [
  '.pdf',
  '.xls', '.xlsx',
  '.doc', '.docx',
  '.ppt', '.pptx',
  '.odt',
  '.ods',
  '.odp',
  '.rtf',
  '.txt',
  '.csv',
  '.xml',
  '.jpg',
  '.jpeg', '.png',
  '.zip',
  '.7z',
];

function DragAndDrop({
  componentId,
  uploadedFiles,
  setUploadedFiles,
  error,
  status,
}) {
  const {t, i18n} = useTranslation('component_documents');
  const [dragActive, setDragActive] = useState(false);
  const [fileUploadWarning, setFileUploadWarning] = useState(null);
  const [dragCounter, setDragCounter] = useState(0);

  const dropRef = useRef(null);

  const inputAcceptString = `${ALLOWED_FILE_EXTENSIONS.join(', ')}, ${ALLOWED_FILE_MIME_TYPES.join(', ')}`;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUpload = (files) => {
    const validFiles = [];
    Array.from(files)?.map((file) => {
      if (file?.name) {
        if (file?.size > MAX_FILE_SIZE) { // validate image size
          setFileUploadWarning(t('add_modal.feedback.too_large'));
        } else if (typeof (file?.type) !== 'string' || ALLOWED_FILE_MIME_TYPES.indexOf(file.type) === -1) { // validate image type
          setFileUploadWarning(t('add_modal.feedback.wrong_type'));
        } else {
          validFiles.push({
            file: file,
            status: status || 'other',
          });
          setFileUploadWarning('');
        }
        if (document.querySelector('#file')) {
          document.querySelector('#file').value = null;
        }
      }
    });
    setUploadedFiles((prevArray) => [...prevArray, ...validFiles]);
  };

  useEffect(() => {
    function handleDragIn(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragCounter(dragCounter+1);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragActive(true);
      }
    }
    function handleDragOut(e) {
      e.preventDefault();
      e.stopPropagation();
      if (dragCounter <= 1) {
        setDragActive(false);
        setDragCounter(0);
      } else {
        setDragCounter(dragCounter-1);
      }
    }
    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleUpload(e.dataTransfer.files);
        setDragCounter(0);
      }
    }
    const dropRefHandler = dropRef.current;
    dropRefHandler.addEventListener('dragenter', handleDragIn);
    dropRefHandler.addEventListener('dragleave', handleDragOut);
    dropRefHandler.addEventListener('dragover', handleDrag);
    dropRefHandler.addEventListener('drop', handleDrop);
    return () => {
      if (dropRefHandler) {
        dropRefHandler.removeEventListener('dragenter', handleDragIn);
        dropRefHandler.removeEventListener('dragleave', handleDragOut);
        dropRefHandler.removeEventListener('dragover', handleDrag);
        dropRefHandler.removeEventListener('drop', handleDrop);
      }
    };
  }, [dragCounter, handleUpload, status]);

  const removeFileFromArray = (index) => {
    const newUploadedFiles = uploadedFiles.filter((value, theIndex) => {
      return index !== theIndex;
    });
    setUploadedFiles(newUploadedFiles);
  };

  return (
    <div className="drag-and-drop">
      <div className={`drag-and-drop-input ${error && 'red-border'}`} ref={dropRef}>
        { dragActive && <div className="drop-active-overlay">{t('add_modal.field.dropper.text')}</div> }
        <span className="browse-button">
          <img src={cloudIcon} alt="icon" />
          <div className="text" dangerouslySetInnerHTML={{__html: t('add_modal.field.dropper.text')}} />
          <input
            type="file"
            multiple={true}
            name={componentId}
            id={componentId}
            className="inputfile"
            accept={inputAcceptString}
            onChange={(e) => handleUpload(e.target.files)}
          />
          <label htmlFor={componentId}>{t('add_modal.field.dropper.button')}</label>
        </span>
      </div>
      { fileUploadWarning && <div className="input-error">{fileUploadWarning}</div>}
      { error && <div className="input-error">{error}</div>}
      <div className="files-list">
        {
          !!uploadedFiles.length && uploadedFiles.map((file, index) => <div className='file' key={index}>
            <div className="left">
              <img src={tickCircleIcon} alt="tick icon" />
              <div className='file-data'>
                <div className='file-name'>{file.file.name}</div>
              </div>
            </div>
            <div className="right">
              <img src={trashIcon} alt="trash" onClick={() => removeFileFromArray(index)} />
            </div>
          </div>)
        }
      </div>
    </div>
  );
}

export default DragAndDrop;
