import './Item.scss';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';

export default function Item(props) {
  function itemClicked() {
    if (typeof props.onClick === 'function' && typeof props.iri === 'string') {
      props.onClick(props.iri);
    }
  }

  const cardStyles = {
    border: 'none',
  };
  if (props.transparent) {
    cardStyles.backgroundColor = 'transparent';
  }
  return (
    <Card
      className={`GenericItem ${props.className}`}
      id={`item-id-${props.id}`}
      variant="outlined"
      sx={cardStyles}
      onClick={itemClicked}
    >
      <CardContent sx={{padding: 0}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', margin: '24px 0 16px'}}>
          <Typography sx={{fontSize: 14, margin: 'auto 0'}} color="#9C9FAF" >
            {props.author ?? 'unknown'}
          </Typography>
          {props.goTo &&
            <Button
              className="Button-goto"
              size="small"
              variant="text"
              onClick={itemClicked}
            >
              <ChevronRightIcon color="disabled" />
            </Button>}
        </Box>
        {typeof props.content === 'string' &&
          <Typography
            variant="body2"
            sx={{fontSize: 14}}
            dangerouslySetInnerHTML={{__html: props.content.replaceAll('\n', '<br />')}}
          />
        }
        {typeof props.content !== 'string' &&
          <Typography variant="body2" sx={{fontSize: 14}}>
            {props.content}
          </Typography>
        }
        {props.rawContent}

        {typeof props.time ==='string' &&
          <Box sx={{display: 'flex', justifyContent: 'flex-start', margin: '16px 0 0'}}>
            {props.timeWithIcon &&
              <AccessTimeIcon sx={{margin: 'auto 7px auto 0', fontSize: 20}}/>
            }
            <Typography sx={{fontSize: 14, margin: 'auto 0'}} color="#9C9FAF" >
              {props.time ?? 'time not saved'}
            </Typography>
          </Box>
        }
      </CardContent>
      {props.withUnderline && <HorizontalDivider sx/>}
    </Card>
  );
}

Item.propTypes = {
  id: PropTypes.number,
  iri: PropTypes.string,
  author: PropTypes.string,
  goTo: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  rawContent: PropTypes.element,
  time: PropTypes.string,
  timeWithIcon: PropTypes.bool,
  onClick: PropTypes.func,
  withUnderline: PropTypes.bool,
  transparent: PropTypes.bool,
  className: PropTypes.string,
};

Item.defaultProps = {
  rawContent: null,
};
