import './TasksViewer.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {
  CAPABILITY_EDIT_ONLY_NOTES,
  CAPABILITY_TODO_LIST_EDIT,
} from '../../helpers/security/hasCapability';
import BasicCard from '../Card/Card';
import FilterAndSort from './FilterAndSort';
import TasksList from './TasksList';
import TasksModals from './TasksModals';

const MAX_RESULTS_ON_SHORT_LIST = 2;

export default function TasksViewer(props) {
  const {t} = useTranslation('component_tasks');
  const [modalState, setModalState] = useState({
    isAddingTaskModalOpen: false,
    isAllTasksModalOpened: false,
    isShowSingleTaskModalOpen: false,
  });
  const [taskInfo, setTaskInfo] = useState({
    projectData: props.projectData,
    projectId: props.projectData.entity.id,
    creator: {},
    taskTitle: '',
    taskText: '',
    deadline: '',
    refers: [],
    completedAt: '',
  });
  const [selectedFilters, setSelectedFilters] = useState(['uncompleted', 'nearDeadline']);

  const CAPABILITY =
    props.projectData.entity['@type'] === 'Company' ?
      CAPABILITY_EDIT_ONLY_NOTES :
      CAPABILITY_TODO_LIST_EDIT;
  const canViewAddTaskButton = useHasCapabilityHere(CAPABILITY);

  const openModal = (modalName) => {
    setModalState((prevState) => ({...prevState, [modalName]: true}));
  };

  const closeModal = (modalName) => {
    setModalState((prevState) => ({...prevState, [modalName]: false}));
  };

  function onAddTaskClicked() {
    openModal('isAddingTaskModalOpen');
  }

  function onTaskItemClicked(task) {
    openModal('isShowSingleTaskModalOpen');
    setTaskInfo({
      projectData: props.projectData,
      projectId: props.projectData.entity.id,
      taskId: task.id,
      creator: task.creator,
      taskTitle: task.title,
      taskText: task.text,
      deadline: task.deadlineAt,
      refers: [
        task.relatedUsers,
        task.relatedAttachments,
        task.relatedTasks,
        task.relatedCompanies,
        task.relatedNotes,
        task.relatedProjects,
      ],
      executors: task.executors,
      completedAt: task.completedAt,
    });
  }

  function onSeeMoreClicked() {
    openModal('isAllTasksModalOpened');
  }

  return (
    <>
      <BasicCard
        className="TasksViewer"
        title={`${t('panel_title')}${
          props.totalTasks ? ` (${props.totalTasks})` : ''
        }`}
        appendToHeader={
          <>
            {(props.showAdd && canViewAddTaskButton ?
              t('add_button') :
              null) && (
              <CardActions>
                <Button
                  size="small"
                  sx={{fontSize: 15, padding: 0}}
                  onClick={() => onAddTaskClicked()}
                >
                  {t('add_button', {ns: 'component_tasks'})}
                </Button>
              </CardActions>
            )}
          </>
        }
        appendToHeaderSecondLine={
          <FilterAndSort
            refreshTasksList={props.refreshTasksList}
            setParams={props.setParams}
            params={props.params}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }
        appendToFooter={
          <CardActions
            className="BasicCard-footer"
            sx={{marginTop: 'auto', textAlign: 'right'}}
          >
            <Button
              size={'small'}
              sx={{paddingLeft: '15px'}}
              onClick={onSeeMoreClicked}
            >
              {props.showSeeMore ||
              props.tasks.length > MAX_RESULTS_ON_SHORT_LIST ?
                t('see_more') :
                null}
            </Button>
          </CardActions>
        }
      >
        <TasksList
          tasks={props.tasks}
          maxTasks={MAX_RESULTS_ON_SHORT_LIST}
          onClick={onTaskItemClicked}
        />
        {!props.isLoading && props.tasks.length === 0 && (
          <Typography
            fontStyle="italic"
            fontSize="small"
            align="center"
            color="lightgray"
            sx={{marginTop: '32px'}}
          >
            {t('no_result')}
          </Typography>
        )}
        {props.isLoading && <ProgressIndicator />}
      </BasicCard>
      <TasksModals
        modalState={modalState}
        closeModal={closeModal}
        taskInfo={taskInfo}
        refreshTasksList={props.refreshTasksList}
        type={props.type}
        projectData={props.projectData}
        tasks={props.tasks}
        showSeeMore={props.showSeeMore}
        onSeeMoreClicked={props.onSeeMoreClicked}
        disableGoTo={props.disableGoTo}
        onClick={onTaskItemClicked}
        setParams={props.setParams}
        params={props.params}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </>
  );
}

TasksViewer.propTypes = {
  disableGoTo: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  onItemAdded: PropTypes.func,
  onItemChanged: PropTypes.func,
  onSeeMoreClicked: PropTypes.func,
  params: PropTypes.string,
  projectData: PropTypes.shape({
    entity: PropTypes.any,
  }),
  refreshTasksList: PropTypes.func,
  setParams: PropTypes.func,
  showAdd: PropTypes.bool.isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalTasks: PropTypes.number,
  type: PropTypes.string,
};
