import './IndexPageHeader.scss';

import {Typography} from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function IndexPageHeader({className, text}) {
  return (
    <div className={classNames('IndexPageHeader', className)}>
      <Typography variant="h2" className="IndexPageHeader-inner">
        {text}
      </Typography>
    </div>
  );
}

IndexPageHeader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
};

IndexPageHeader.defaultProps = {
  className: undefined,
};
