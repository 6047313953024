import LoginLayout from '../../layouts/LoginLayout';
import CreateNewPasswordForm from './CreateNewPasswordForm';

export function CreateNewPasswordPage() {
  return (
    <LoginLayout>
      <CreateNewPasswordForm />
    </LoginLayout>
  );
}
