import Api from '../../../helpers/api/Api';

export default function patchUser(patchData) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/users/${patchData.id}`, patchData).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
