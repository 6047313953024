import Api from '../../../helpers/api/Api';

export default function getCompanyAttachmentBinary(id) {
  return new Promise(function(resolve, reject) {
    Api.getBinary(`/company_attachments/${id}/file`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
