import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../components/EditField/EditField';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import {useIntermediariesDictionary} from '../../../helpers/dictionaries/hooks/useIntermediariesDictionary';
import {useInvestorsDictionary} from '../../../helpers/dictionaries/hooks/useInvestorsDictionary';
import {useMoneySuppliersDictionary} from '../../../helpers/dictionaries/hooks/useMoneySuppliersDictionary';
import css from './EditProjectCompanyModal.module.scss';
function EditProjectCompanyModal({selectedCompany, selectedRelationType, isOpen, onClose, onSave, onDelete}) {
  const {t} = useTranslation('page_cases');
  const [companyIri, setCompanyIri] = useState(null);
  const [companyAmount, setCompanyAmount] = useState(0);

  const moneySuppliersDictionary = useMoneySuppliersDictionary(
      {name: '@id', display: 'dropdownIdentifier'},
  );
  const intermediariesDictionary = useIntermediariesDictionary(
      {name: '@id', display: 'dropdownIdentifier'},
  );
  const investorsDictionary = useInvestorsDictionary(
      {name: '@id', display: 'dropdownIdentifier'},
  );

  function editFieldChanged(id, val) {
    setCompanyIri(val);
  }

  function amountFieldChanged(id, val) {
    if (!isNaN(parseInt(val))) {
      setCompanyAmount(parseInt(val));
    }
  }

  function saveClicked() {
    onSave(companyIri, selectedRelationType, companyAmount);
  }

  function deleteClicked() {
    onDelete(companyIri, selectedRelationType);
  }

  useEffect(()=>{
    if (isOpen) {
      setCompanyIri(selectedCompany?.company ? selectedCompany.company['@id'] : null);
      setCompanyAmount(selectedCompany?.amount ?? 0);
    }
  }, [isOpen, selectedCompany]);


  let companiesDictionary = [];
  let modalTitle = '';
  let editFieldName = '';
  switch (selectedRelationType) {
    case 'intermediary':
      companiesDictionary = intermediariesDictionary;
      modalTitle = t('projectCompanies.addIntermediaryModal.title');
      editFieldName = t('projectCompanies.intermediary');
      break;
    case 'money_supplier':
      companiesDictionary = moneySuppliersDictionary;
      modalTitle = t('projectCompanies.addMoneySupplierModal.title');
      editFieldName = t('projectCompanies.moneySupplier');
      break;
    case 'investor':
      companiesDictionary = investorsDictionary;
      modalTitle = t('projectCompanies.addInvestorModal.title');
      editFieldName = t('projectCompanies.investor');
      break;
    default:
  }
  return (
    <ModalDialog
      open={isOpen}
      onCloseRequest={onClose}
    >
      <Typography variant='h1'>{modalTitle}</Typography>
      <EditField
        className={css.wide}
        id="projectCompany"
        name={editFieldName}
        value={companyIri}
        type="autocomplete"
        options={companiesDictionary}
        editing={true}
        onChange={editFieldChanged}
      />
      {selectedRelationType==='money_supplier' &&
        <EditField
          className={css.wide}
          id="amount"
          name={t('field.amount.description')}
          value={companyAmount}
          type="money"
          editing={true}
          onChange={amountFieldChanged}
        />
      }
      <DialogActions
        className={css.actions}
      >
        {selectedCompany &&
          <Button variant={'contained'} color={'error'} onClick={deleteClicked}>
            {t('action.remove')}
          </Button>
        }
        <div className={css.spacer} />
        <Button variant={'outlined'} onClick={()=>onClose()}>
          {t('action.cancel')}
        </Button>
        <Button variant={'contained'} onClick={saveClicked} disabled={!companyIri}>
          {t('action.save')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}

EditProjectCompanyModal.propTypes = {
  selectedCompany: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectCompany']).isRequired,
  }),
  selectedRelationType: PropTypes.oneOf(['intermediary', 'money_supplier', 'investor']),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

EditProjectCompanyModal.defaultProps = {
  selectedCompany: undefined,
};

export default EditProjectCompanyModal;
