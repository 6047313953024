import LoginLayout from '../../layouts/LoginLayout';
import PasswordChanged from './PasswordChanged';

export function PasswordChangedPage() {
  return (
    <LoginLayout>
      <PasswordChanged />
    </LoginLayout>
  );
}
