import {Input} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldUserNameSurname(props) {
  function callbackFirstname(event) {
    const source = Object.assign({}, props.value);
    source.firstname = event.target.value;
    props.onChange(source);
  }

  function callbackLastname(event) {
    const source = Object.assign({}, props.value);
    source.lastname = event.target.value;
    props.onChange(source);
  }

  return (
    <>
      <Input
        className="EditFieldUserNameSurname-firstname"
        size="small"
        value={props.value.firstname ?? ''}
        onChange={callbackFirstname}
        fullWidth={true}
        sx={{
          fontSize: props.isVeryLarge ? '32px' : '1em',
          fontWeight: props.isVeryLarge ? '500' : 'normal',
        }}
      />
      <Input
        className="EditFieldUserNameSurname-lastname"
        size="small"
        value={props.value.lastname ?? ''}
        onChange={callbackLastname}
        fullWidth={true}
        sx={{
          fontSize: props.isVeryLarge ? '32px' : '1em',
          fontWeight: props.isVeryLarge ? '500' : 'normal',
        }}
      />
    </>
  );
}

EditFieldUserNameSurname.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isVeryLarge: PropTypes.bool,
};
