export default function prepareCompaniesListUrlParameters(
    base, search, sorting, group, isPersonal, page, itemsPerPage, securityContextId,
) {
  let sortingUrlPart;
  switch (sorting.header) {
    case 'type':
      sortingUrlPart = `order[type]=${sorting.direction}`;
      break;
    case 'legalForm':
      sortingUrlPart = `order[legalForm]=${sorting.direction}`;
      break;
    case 'email':
      sortingUrlPart = `order[email]=${sorting.direction}`;
      break;
    case 'primaryPhone':
      sortingUrlPart = `order[primaryPhone]=${sorting.direction}`;
      break;
    case 'createdAt':
      sortingUrlPart = `order[createdAt]=${sorting.direction}`;
      break;
    default:
      sortingUrlPart = `order[name]=${sorting.direction}`;
  }

  let groupUrlPart;
  switch (group) {
    case 'investor':
      groupUrlPart = 'type[]=investor';
      break;
    case 'money_supplier':
      groupUrlPart = 'type[]=money_supplier';
      break;
    case 'intermediary':
      groupUrlPart = `type[]=intermediary`;
      break;
    case 'client_company':
      groupUrlPart = `type[]=client&isPersonal=false`;
      break;
    case 'client_personal':
      groupUrlPart = `type[]=client&isPersonal=true`;
      break;
    default:
      groupUrlPart = null;
  }

  const urlParts = [
    sortingUrlPart,
    groupUrlPart,
    isPersonal !== null ? `isPersonal=${isPersonal ? 'true' : 'false'}` : null,
    (typeof search == 'string' && search.length > 0) ? `search=${search}` : null,
    `page=${page}`,
    `itemsPerPage=${itemsPerPage}`,
    securityContextId ? `securityContext=${securityContextId}` : null,
  ].filter((p)=>!!p);

  return `${base}?${urlParts.join('&')}`;
}
