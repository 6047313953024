import './AddContactModal.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../components/EditField/EditField';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import Switch from '../../components/Switch/Switch';
import patchCompanyEmployees from './api/patchCompanyEmployees';

export default function AddContactModal(props) {
  const {t, i18n} = useTranslation('page_companies');

  const [userEmail, setUserEmail] = useState(null);
  const [userEmailNotify, setUserEmailNotify] = useState(false);
  const [userFirstname, setUserFirstname] = useState(null);
  const [userLastname, setUserLastname] = useState(null);
  const [userPhone, setUserPhone] = useState({country: '', phone: ''});
  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);

  function handleClose() {
    props.onClose();
  }

  function sendInvitation() {
    const patchData = {
      employees: [
        ...props.employees,
        {
          user: {
            firstname: userFirstname,
            lastname: userLastname,
            email: userEmail,
            userPhones: [
              {
                country: userPhone.country,
                phone: userPhone.phone,
              },
            ],
          },
        },
      ],
      sendInvitationAfterCreate: !props.forbidInvitationEmail && userEmailNotify,
    };
    setIsSending(true);
    setErrorText(null);

    patchCompanyEmployees(props.companyId, patchData)
        .then(()=>{
          clearForm();
          setIsSending(false);
          handleClose();
          props.refreshCompanyData();
        })
        .catch((error)=>{
          if (error.request.status === 409) {
            setErrorText(t('add_modal.feedback.duplicate'));
          } else {
            setErrorText(error && error.data && error.data.message ? error.data.message : null);
          }
          setIsSending(false);
        });
  }

  function fieldChanged(id, value) {
    switch (id) {
      case 'email':
        setUserEmail(value);
        break;
      case 'firstname':
        setUserFirstname(value);
        break;
      case 'lastname':
        setUserLastname(value);
        break;
      case 'phone':
        setUserPhone({
          country: value.country,
          phone: value.phone,
        });
        break;
      default:
    }
  }

  function clearForm() {
    setUserEmail(null);
    setUserFirstname(null);
    setUserLastname(null);
    setUserPhone({
      country: '',
      phone: '',
    });
  }
  return (
    <ModalDialog
      className="AddContactModal"
      open={props.open}
      onCloseRequest={handleClose}
    >
      <div className="InviteUserModal-contents-wrapper">
        <div className="InviteUserModal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('add_modal.title')}</Typography>
        </div>
        {isSending &&
          <ProgressIndicator stretch={true} />
        }
        {!isSending &&
          <>
            <EditField
              id="firstname"
              name={t('add_modal.field.firstname.description')}
              value={userFirstname}
              placeholder={t('add_modal.field.firstname.placeholder')}
              type="string"
              editing={true}
              onChange={fieldChanged}
            />
            <EditField
              id="lastname"
              name={t('add_modal.field.lastname.description')}
              value={userLastname}
              placeholder={t('add_modal.field.lastname.placeholder')}
              type="string"
              editing={true}
              onChange={fieldChanged}
            />
            <EditField
              id="email"
              name={t('add_modal.field.email.description')}
              value={userEmail}
              placeholder={t('add_modal.field.email.placeholder')}
              type="string"
              editing={true}
              onChange={fieldChanged}
            />
            {!props.forbidInvitationEmail &&
              <Switch label={t('add_modal.field.send_invitation.description')} checked={userEmailNotify} onChange={(e)=>setUserEmailNotify(e.target.checked)}/>
            }
            <EditField
              id="phone"
              name={t('add_modal.field.phone.description')}
              value={userPhone}
              placeholder={[t('add_modal.field.phone.placeholder1'), t('add_modal.field.phone.placeholder2')]}
              type="userPhone"
              editing={true}
              onChange={fieldChanged}
            />
            <Button
              className="button-send-invite"
              variant="contained"
              onClick={sendInvitation}
            >
              {t('add_modal.field.button_add.description')}
            </Button>
            {typeof errorText === 'string' &&
              <Typography className="errorMessage" color={'error'}>{errorText}</Typography>
            }
          </>
        }
      </div>
    </ModalDialog>
  );
}

AddContactModal.propTypes = {
  open: PropTypes.bool,
  companyId: PropTypes.number,
  refreshCompanyData: PropTypes.func,
  employees: PropTypes.array,
  onClose: PropTypes.func,
  forbidInvitationEmail: PropTypes.bool,
};
