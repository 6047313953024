import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import ActionsBlock from './ActionsBlock';
import FiltersBlock from './FiltersBlock';
import css from './index.module.scss';
import TableBlock from './TableBlock';

function TasksPage() {
  const {t} = useTranslation('page_tasks');

  return (
    <DefaultLayout>
      <div className={css.TasksPage}>
        <section className={css.pageTitle}>
          <Typography variant="h2">{t('page.title')}</Typography>
        </section>
        <div className={css.controlBar}>
          <FiltersBlock />
          <ActionsBlock />
        </div>
        <TableBlock />
      </div>
    </DefaultLayout>
  );
}

export default TasksPage;
