import './RecentDocumentsModal.scss';

import {Box, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import DocumentItemOnDashboard from '../../components/DocumentItemOnDashboard/DocumentItemOnDashboard';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import Switch from '../../components/Switch/Switch';
import {attachmentDateComparer, attachmentNameComparer} from '../../helpers/compare';

export default function RecentDocumentsModal(props) {
  const [sortByDate, setSortByDate] = useState(false);
  const {t, i18n} = useTranslation('component_documents');

  function onClick(activityId) {
    props.onClick(activityId);
  }

  const comparer = sortByDate ? attachmentDateComparer : attachmentNameComparer;
  const sortedAttachments = [...props.documents].sort(comparer);

  return (
    <ModalDialog
      className="RecentDocumentsModal"
      open={props.isOpen}
      onCloseRequest={props.onClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('overview_modal_title')}</Typography>
        </div>
        <Box sx={{display: 'flex', width: '70%', justifyContent: 'space-between', alignItems: 'baseline', margin: '0 0 10px 10px'}}>
          <Box sx={{marginRight: '10px'}}>
            <Button size="small" sx={sortByDate ? {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'marginRight': '10px', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}} : {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'marginRight': '10px', 'backgroundColor': '#D9DAE1', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}} } variant="outlined" onClick={()=>setSortByDate(false)}>{t('sort_by_name')}</Button>
            <Button size="small" sx={!sortByDate ? {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#ffffff', borderColor: '#D9DAE1'}} : {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'backgroundColor': '#D9DAE1', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}}} variant="outlined" onClick={()=>setSortByDate(true)}>{t('sort_by_date')}</Button>
          </Box>
        </Box>
        <div className="modal-content">
          {sortedAttachments.map((document)=>{
            return (
              <DocumentItemOnDashboard
                key={document['@id']}
                attachment={document}
                onItemClicked={onClick}
              />
            );
          })}
          {props.isLoading &&
            <ProgressIndicator />
          }
        </div>
        <div className="modal-footer">
          {props.showSeeMore &&
            <Button size={'small'} onClick={props.onSeeMoreClicked}>{t('load_more')}</Button>
          }
        </div>
      </>
    </ModalDialog>
  );
}

RecentDocumentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  onSeeMoreClicked: PropTypes.func.isRequired,
  disableGoTo: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
