import './RecentActivityItem.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';
import getRecentActivityTemplateVariables from './getRecentActivityTemplateVariables';

export default function RecentActivityItem(props) {
  const {t, i18n} = useTranslation('component_recent_activity');

  const hasItemClick = props.goTo && typeof props.onClick === 'function' && typeof props.iri === 'string';
  function itemClicked() {
    if (hasItemClick) {
      props.onClick(props.iri);
    }
  }

  const shouldDisplayCreator = typeof props.author === 'string' && props.author.length > 0;
  const shouldDisplayContent = typeof props.content === 'string' && props.content.length > 0;
  const shouldDisplayTime = typeof props.time === 'string' && props.time.length > 0;

  const hasContentTranslation = i18n.exists(`template.${props.activity.name}`, {ns: 'component_recent_activity'});
  const content = hasContentTranslation ?
    t(`template.${props.activity.name}`, getRecentActivityTemplateVariables(props.activity)) :
    (null ?? `template.${props.activity.name}`);

  const cardStyles = {
    border: 'none',
  };
  if (props.transparent) {
    cardStyles.backgroundColor = 'transparent';
  }

  return (
    <Card className="RecentActivityItem" variant="outlined" sx={cardStyles}>
      {shouldDisplayCreator &&
        <Box className="RecentActivityItem-creator">
          <Typography variant="textInBackground">
            {props.author}
          </Typography>
          {hasItemClick &&
            <Button
              className="RecentActivityItem-goto"
              size="small"
              variant="text"
              onClick={itemClicked}
            >
              <ChevronRightIcon color="disabled" />
            </Button>
          }
        </Box>
      }
      <Box className="RecentActivityItem-text">
        <Typography fontSize={'14px'}>{props.activity.relatedProject?.company?.name}</Typography>
      </Box>
      {shouldDisplayContent &&
        <Box className="RecentActivityItem-text">
          <Typography fontSize={'medium'} dangerouslySetInnerHTML={{__html: content}} />
        </Box>
      }
      {shouldDisplayTime &&
        <Box className="RecentActivityItem-timestamp">
          <Typography variant="textInBackground">
            {props.time ?? 'time not saved'}
          </Typography>
        </Box>
      }
      {props.withUnderline && <hr className="RecentActivityItem-divider" />}
    </Card>
  );
}

RecentActivityItem.propTypes = {
  iri: PropTypes.string,
  author: PropTypes.string,
  goTo: PropTypes.bool,
  content: PropTypes.string,
  time: PropTypes.string,
  timeWithIcon: PropTypes.bool,
  onClick: PropTypes.func,
  withUnderline: PropTypes.bool,
  activity: PropTypes.object,
};
