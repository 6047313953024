import './ShowAllNotesModal.scss';

import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Item from '../Item/Item';
import ModalDialog from '../ModalDialog/ModalDialog';

export default function ShowAllNotesModal(props) {
  const {t, i18n} = useTranslation('component_notes');
  return (
    <ModalDialog
      className="AllNotesModal"
      open={props.isPopupAllNotesOpen}
      onCloseRequest={props.handleAllNotesPopupClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('modal_title')}</Typography>
        </div>
        <div className="modal-content">
          {props.notes?.sort((a, b) => a.id < b.id ? 1 : -1).map((note, noteIdx) =>
            <Item
              iri={note['@id']}
              id={note.id}
              key={noteIdx}
              author={note.author.display}
              content={note.text}
              withUnderline={!!props.notes[props.notes.length - 1]}
              transparent={true}
              onClick={() => props.onNoteClick(note.id)}
            />,
          )}
        </div>
      </>
    </ModalDialog>
  );
}

ShowAllNotesModal.propTypes = {
  isPopupAllNotesOpen: PropTypes.bool,
  handleAllNotesPopupClose: PropTypes.func,
  notes: PropTypes.array,
};
