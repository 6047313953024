import * as React from 'react';
import {useTranslation} from 'react-i18next';

import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import ActionsBlock from './ActionsBlock';
import FiltersBlock from './FiltersBlock';
import GroupingBlock from './GroupingBlock';
import useUsersListFilters, {PARAM_GROUP} from './hooks/useUsersListFilters';
import css from './index.module.scss';
import TableBlock from './TableBlock';

export default function UsersPage() {
  const {t} = useTranslation('page_users');

  const {current} = useUsersListFilters();

  function refreshUsersList() {
    console.log('requested refreshUsersList from filters');
  }

  function group2text(group) {
    switch (group) {
      case 'admin': return t('list.tab.admins.text');
      case 'sales': return t('list.tab.sales.text');
      case 'viewonly': return t('list.tab.viewonly.text');
      case 'users': return t('list.tab.users.text');
      case 'all': return t('list.tab.all.text');
      default: return '';
    }
  }

  return (
    <DefaultLayout>
      <div className={css.UsersPage}>
        <GroupingBlock />
        <IndexPageHeader className={css.pageHeader} text={group2text(current[PARAM_GROUP])}/>
        <div className={css.controlBar}>
          <FiltersBlock className={css.left}/>
          <ActionsBlock requestRefresh={refreshUsersList} />
        </div>
        <TableBlock />
      </div>
    </DefaultLayout>
  );
}
