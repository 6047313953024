import Api from '../../../helpers/api/Api';

export default function getUserStakeholderRecentActivity(stakeholderId, page, itemsPerPage, userId, showOnlyMine) {
  return new Promise(function(resolve, reject) {
    const stakeholderPart = (stakeholderId !== null && !showOnlyMine) ? `&stakeholder=${stakeholderId}` : '';
    const showOnlyMinePart = showOnlyMine ? `user=${userId}&` : '';
    const url = `/audit_logs?${showOnlyMinePart}level=business&page=${page}&itemsPerPage=${itemsPerPage}&order[createdAt]=desc${stakeholderPart}`;

    Api.get(url).then((data)=>{
      if (Array.isArray(data['hydra:member'])) {
        resolve({
          activities: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
        });
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
