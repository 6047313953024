import Api from '../../../helpers/api/Api';

export default function postSearch(data) {
  return new Promise(function(resolve, reject) {
    const searchObject = {
      searchText: data.searchText ?? null,
      scope: data.scope ?? null,
      projectType: data.projectType ?? null,
      page: data.page ?? 1,
      pageLimit: data.pageLimit ?? 100,
    };

    Api.post(`/search`, searchObject).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
