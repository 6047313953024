import './AddingLeadsAutomatically.scss';

import EuroIcon from '@mui/icons-material/Euro';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import EditField from '../../components/EditField/EditField';
import SelfRegisteredCorrectlyPopUp from '../../components/Popups/SelfRegisteredCorrectlyPopUp';
import ServerErrorPopUp from '../../components/Popups/ServerErrorPopUp';
import Api from '../../helpers/api/Api';
import isValidEmail from '../../helpers/validators/isValidEmail';
import isValidName from '../../helpers/validators/isValidName';

const emptyForm = {
  companyName: '',
  legalForm: '',
  phoneCountry: '',
  phoneNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  amount: '',
  actions: '',
};

export default function AddingLeadsAutomaticallyForm() {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation('self_registration');
  const legalFormI18n = useTranslation('dictionary_legal_form');
  const [values, setValues] = useState({...emptyForm});
  const [error, setError] = useState({});
  const [isErrorForTermsAndConditions, setIsErrorForTermsAndConditions] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [companyLegalForms, setCompanyLegalForms] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleChangeForCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setIsSuccessPopupOpen(false);
  };

  const handleChange = (prop) => (id, value) => {
    setValues({...values, [prop]: value});
  };

  const basicValidate = () => {
    const currentError ={};
    const phoneCountryValidatorRegEx= /^(\+?\d{1,3}|\d{1,4})$/;
    const phoneValidatorRegEx= /^([\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

    if (values.legalForm.length === 0) {
      currentError['legalForm'] = t('feedback.legalForm');
    }
    if (values.phoneCountry.length === 0 || !phoneCountryValidatorRegEx.test(values.phoneCountry)) {
      currentError['phoneCountry'] = t('feedback.phoneCountry');
    }
    if (values.phoneNumber.length === 0 || !phoneValidatorRegEx.test(values.phoneNumber)) {
      currentError['phoneNumber'] = t('feedback.phoneNumber');
    }
    if (!isValidEmail(values.email)) {
      currentError['email'] = t('feedback.email');
    }
    if (!isValidName(values.firstName)) {
      currentError['firstName'] = t('feedback.firstName');
    }
    if (!isValidName(values.lastName)) {
      currentError['lastName'] = t('feedback.lastName');
    }
    if (isNaN(values.amount) === true) {
      currentError['amount'] = t('feedback.amount');
    }
    if (values.actions.length === 0) {
      currentError['actions'] = t('feedback.actions');
    }
    if (!checked) {
      setIsErrorForTermsAndConditions(true);
    } else {
      setIsErrorForTermsAndConditions(false);
    }

    setError(currentError);

    return !Object.entries(currentError).length;
  };

  useEffect(() => {
    Api.getCompanyLegalForms().then((data) => setCompanyLegalForms(data['hydra:member']));
  }, []);

  const legalFormsDropdownOptions = companyLegalForms?.map((form) => ({name: form['@id'], display: legalFormI18n.t(form.name)}));

  function createNewLeadAutomatically() {
    const isValid = basicValidate();
    if (isValid) {
      setIsSending(true);
      setErrorText(null);
      Api.createNewLeadAutomatically({
        companyName: values.companyName,
        legalForm: values.legalForm,
        phoneCountry: values.phoneCountry,
        phoneNumber: values.phoneNumber,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        amount: Number(values.amount),
        activities: values.actions,
        createdAt: new Date().toISOString(),
      }).then(()=>{
        setIsSending(false);
        setValues({...emptyForm});
        setIsSuccessPopupOpen(true);
      }).catch((error)=>{
        if (error.request.status === 409) {
          setErrorText(`Unable to register ${values.email}, this email already exists.`);
        } else if (error.status === 500) {
          setIsPopupOpen(true);
        } else {
          setErrorText(error && error.data && error.data.message ? error.data.message : null);
        }
        setIsSending(false);
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          'display': 'flex',
          'flexWrap': 'wrap',
          'flexDirection': 'column',

          '@media screen and (max-width: 460px)': {
            width: '90%',
          },
        }}
        className="AddingLeadAutomaticallyForm"
      >
        <div className="Title">{t('title')}</div>
        <div className="FormField">
          <EditField
            id="company-name"
            name={t('field.company.name')}
            value={values.companyName}
            type="string"
            placeholder={t('field.company.placeholder')}
            editing={true}
            onChange={handleChange('companyName')}
            error={error['companyName']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['companyName']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="legal-form"
            name={t('field.legal_form.name')}
            value={values.legalForm}
            type="dropdown"
            placeholder={t('field.legal_form.placeholder')}
            options={legalFormsDropdownOptions}
            editing={true}
            onChange={handleChange('legalForm')}
            error={error['legalForm']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['legalForm']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <div className="UserPhone">
            <EditField
              id="phoneCountry"
              name={t('field.phone.name')}
              value={values.phoneCountry}
              type="string"
              placeholder={t('field.phone.placeholder1')}
              editing={true}
              onChange={handleChange('phoneCountry')}
              error={error['phoneCountry']}
            />
            <EditField
              id="phoneNumber"
              name=" "
              value={values.phoneNumber}
              type="string"
              placeholder={t('field.phone.placeholder2')}
              editing={true}
              onChange={handleChange('phoneNumber')}
              error={error['phoneNumber']}
            />
          </div>
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['phoneNumber']}{error['phoneCountry']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="email"
            name={t('field.email.name')}
            value={values.email}
            type="string"
            placeholder={t('field.email.placeholder')}
            editing={true}
            onChange={handleChange('email')}
            error={error['email']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['email']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="firstName"
            name={t('field.firstname.name')}
            value={values.firstName}
            type="string"
            placeholder={t('field.firstname.placeholder')}
            editing={true}
            onChange={handleChange('firstName')}
            error={error['firstName']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['firstName']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="lastName"
            name={t('field.lastname.name')}
            value={values.lastName}
            type="string"
            placeholder={t('field.lastname.placeholder')}
            editing={true}
            onChange={handleChange('lastName')}
            error={error['lastName']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['lastName']}
          </FormHelperText>
        </div>
        <div className="Subtitle">{t('subtitle1')}</div>
        <div className="FormField">
          <EditField
            id="amount"
            name={t('field.amount.name')}
            value={values.amount}
            type="string"
            placeholder={t('field.amount.placeholder')}
            editing={true}
            onChange={handleChange('amount')}
            error={error['amount']}
            icon={<InputAdornment position="end">
              <EuroIcon sx={{fontSize: '20px'}} />
            </InputAdornment>
            }
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['amount']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="actions"
            name={t('field.actions.name')}
            value={values.actions}
            type="string"
            placeholder={t('field.actions.placeholder')}
            editing={true}
            onChange={handleChange('actions')}
            error={error['actions']}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['actions']}
          </FormHelperText>
        </div>
        <div className="TermsAndConditions">
          <Checkbox
            checked={checked}
            onChange={handleChangeForCheckbox}
            inputProps={{'aria-label': 'controlled'}}
            sx={{padding: '0', marginRight: '9px'}}
          />
          <p>{t('field.agreement.text')}</p>
        </div>
        {isErrorForTermsAndConditions && (<FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
          {t('feedback.no_agreement')}
        </FormHelperText>)}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '46px',
            height: '44px',
          }}
        >
          <Button
            variant="contained"
            sx={{width: '164px', height: '100%', textTransform: 'capitalize', marginBotton: '92px'}}
            onClick={createNewLeadAutomatically}
            className="CreateButton"
          >
          Create
          </Button>
        </Box>
        { typeof errorText === 'string' &&
        <FormHelperText id="submit-error-text" sx={{color: '#ED143D'}}>
          {errorText}
        </FormHelperText>
        }
      </Box>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen}/>
      <SelfRegisteredCorrectlyPopUp handlePopupClose={handlePopupClose} isPopupOpen={isSuccessPopupOpen}/>
    </>
  );
}
