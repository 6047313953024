import {MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import role2display from '../../../helpers/formatters/role2display';
import listAllRoles from '../../../helpers/security/listAllRoles';

export default function EditFieldPrimaryRole(props) {
  function callback(event) {
    props.onChange(event.target.value);
  }
  const showClientRole = !props.typeOptions || props.typeOptions.client !== false;
  const roles = listAllRoles().filter((role)=>{
    return showClientRole || role !== 'ROLE_USER';
  });
  return (
    <Select
      className="EditFieldPrimaryRole"
      size="small"
      value={props.value}
      label="Role"
      onChange={callback}
      fullWidth={true}
    >
      {roles.map((item) => (
        <MenuItem key={item} value={item}>{role2display(item)}</MenuItem>
      ))}
    </Select>
  );
}

EditFieldPrimaryRole.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  typeOptions: PropTypes.shape({
    client: PropTypes.bool,
  }),
};
