import Typography from '@mui/material/Typography';

import Tag from '../../../components/Tag/Tag';
import formatDateShort from '../../../helpers/formatters/formatDateShort';
import user2securityContextsString from '../../../helpers/formatters/user2securityContextsString';

export default function companyToTableFields(company, translator, showSecurityContext) {
  const legalFormName = translator(company.legalForm.name, {ns: 'dictionary_legal_form'});

  const companyTypeName = company.legalForm.isPersonal ?
    translator('client_personal', {ns: 'dictionary_company_type'}) :
    translator(company.type, {ns: 'dictionary_company_type'});

  let typeClass = '';
  switch (company.type) {
    case 'client':
      if (company.legalForm.isPersonal) {
        typeClass = 'company-type-client company-type-client-personal';
      } else {
        typeClass = 'company-type-client company-type-client-company';
      }
      break;
    case 'intermediary':
      typeClass = 'company-type-intermediary';
      break;
    case 'money_supplier':
      typeClass = 'company-type-money-supplier';
      break;
    default:
      typeClass = '';
  }
  const companyType = `<div style="display: flex; flex-direction: row; align-items: center;"><div class="CompanyTypeDot ${typeClass}">&#11044;</div>${companyTypeName}</div>`;


  const tags = company.tags ? <span style={{lineHeight: 1}}>{company.tags.map((item, idx)=><Tag key={idx} tag={item} small={true}/> )}</span> : '';
  const companyName = company.name && company.name.length >= 20 ? company.name.substring(0, 19) + '...' : company.name;

  const companyFields = {
    id: `company${company.id}`,
    fields: [
      {label: <div>{companyName} {tags}</div>},
      {label: companyType},
      {label: legalFormName.length >= 20 ? legalFormName.substring(0, 19) + '...' : legalFormName},
      {label: company?.primaryEmployee?.email},
      {label: company?.primaryEmployee?.primaryPhone},
      {label: formatDateShort(company.createdAt)},
    ],
  };

  if (showSecurityContext) {
    companyFields.fields.push(
        {label: <Typography variant="smallerText">{company?.securityContexts.map((ctx)=>ctx.name).join(', ')}</Typography>},
    );
  }

  return companyFields;
}
