import './MergeEmployeesModal.scss';

import {Autocomplete, Button, Dialog, DialogActions, DialogTitle, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import postMergeUsers from '../UserDetails/api/postMergeUsers';
import getCompanyUsersDictionary from './api/getCompanyUsersDictionary';

export default function MergeEmployeesModal(props) {
  const {t, i18n} = useTranslation('page_companies');

  const [isDictionaryLoading, setDictionaryLoading] = useState(true);
  const [isMergingNow, setMergingNow] = useState(false);

  const [employeesDictionary, setEmployeesDictionary] = useState([]);

  const [primaryCompanyUser, setPrimaryCompanyUser] = useState(null);
  const [secondaryCompanyUser, setSecondaryCompanyUser] = useState(null);

  const [isFinalWarningOpen, setFinalWarningOpen] = useState(false);

  useEffect(()=>{
    if (props.isOpen) {
      setDictionaryLoading(true);
      getCompanyUsersDictionary(props.company.id).then((data) => {
        setEmployeesDictionary(
            data.filter((user)=>(
              user['@id']!==props.subject['@id']
            )).map((user) => (
              {label: user.dropdownIdentifier, id: user['@id'], name: `${user.firstname} ${user.lastname}`}
            )),
        );
        setDictionaryLoading(false);
      });
    }
  }, [props.company, props.isOpen, props.subject]);

  function handlePickPrimary(event, option) {
    setPrimaryCompanyUser(option);
    setSecondaryCompanyUser(null);
  }

  function handlePickSecondary(event, option) {
    setPrimaryCompanyUser(null);
    setSecondaryCompanyUser(option);
  }

  function onClickMergePrimary() {
    setFinalWarningOpen(true);
  }

  function onClickMergeSecondary() {
    setFinalWarningOpen(true);
  }

  function onClickFinalMerge() {
    setMergingNow(true);
    const primary = primaryCompanyUser !== null ? primaryCompanyUser.id : props.subject['@id'];
    const secondary = secondaryCompanyUser !== null ? secondaryCompanyUser.id : props.subject['@id'];
    postMergeUsers(primary, secondary).then(()=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      props.onClose(true);
    }).catch((error)=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      console.error(error);
    });
  }
  const selectedPrimaryName = primaryCompanyUser !== null ?
    employeesDictionary.find((c)=>c.id===primaryCompanyUser.id)?.name :
    props.subject.display
  ;
  const selectedSecondaryName = secondaryCompanyUser !== null ?
    employeesDictionary.find((c)=>c.id===secondaryCompanyUser.id)?.name :
    props.subject.display
  ;

  return (
    <ModalDialog
      className="MergeEmployeesModal"
      open={props.isOpen}
      onCloseRequest={props.onClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('merge_employees_modal.title')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="warning">{t('merge_employees_modal.warning')}</Typography>
          <Typography variant="smallHint">{t('merge_employees_modal.hint')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="decision-title" variant="h3">{t('merge_employees_modal.subtitle')}</Typography>
          <Typography fontWeight="600">{t('merge_employees_modal.option1.header', {name: props.subject.display})}</Typography>
          <Typography>{t('merge_employees_modal.option1.body', {name: props.subject.display})}</Typography>
          {isDictionaryLoading && <ProgressIndicator />}
          {!isDictionaryLoading &&
            <div className="employee-selection selection-secondary">
              <Autocomplete
                className="employees-dropdown-autocomplete"
                disablePortal={true}
                size="small"
                id="merge-employees-pick-secondary"
                options={employeesDictionary}
                getOptionLabel={(option)=>option.label}
                renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
                renderInput={(params) => <TextField {...params} label={t('merge_employees_modal.option1.placeholder')} />}
                noOptionsText={t('merge_employees_modal.option1.no_results')}
                value={secondaryCompanyUser}
                onChange={handlePickSecondary}
              />
              <Button className="merge-action" disabled={secondaryCompanyUser === null} variant="outlined" onClick={onClickMergeSecondary}>Merge</Button>
            </div>
          }
          <Typography fontWeight="600">{t('merge_employees_modal.option2.header', {name: props.subject.display})}</Typography>
          <Typography>{t('merge_employees_modal.option2.body', {name: props.subject.display})}</Typography>
          {isDictionaryLoading && <ProgressIndicator />}
          {!isDictionaryLoading &&
            <div className="employee-selection selection-primary">
              <Autocomplete
                className="employees-dropdown-autocomplete"
                disablePortal={true}
                size="small"
                id="merge-employees-pick-primary"
                options={employeesDictionary}
                getOptionLabel={(option)=>option.label}
                renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
                renderInput={(params) => <TextField {...params} label={t('merge_employees_modal.option2.placeholder')}/>}
                noOptionsText={t('merge_employees_modal.option2.no_results')}
                value={primaryCompanyUser}
                onChange={handlePickPrimary}
              />
              <Button className="merge-action" disabled={primaryCompanyUser === null} variant="outlined"
                onClick={onClickMergePrimary}>Merge</Button>
            </div>
          }
        </div>
        <Dialog
          open={isFinalWarningOpen}
          onClose={() => setFinalWarningOpen(false)}
        >
          {isMergingNow && <ProgressIndicator />}
          {!isMergingNow &&
            <>
              <DialogTitle>
                <Typography variant="span" fontWeight={400}>{t('merge_employees_modal.final.secondary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedSecondaryName}</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_employees_modal.final.primary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedPrimaryName}?</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_employees_modal.final.primary_suffix')}</Typography>
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setFinalWarningOpen(false)} variant="outlined" color="error">{t('merge_employees_modal.final.button_cancel')}</Button>
                <Button onClick={onClickFinalMerge} variant="outlined">{t('merge_employees_modal.final.button_confirm')}</Button>
              </DialogActions>
            </>
          }
        </Dialog>
      </>
    </ModalDialog>
  );
}

MergeEmployeesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  subject: PropTypes.object.isRequired,
};
