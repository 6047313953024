import './GlobalSearch.scss';

import {Close} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from 'use-debounce';

export default function GlobalSearch(props) {
  const [text, setText] = useState('');
  const [value] = useDebounce(text, 700);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');

  function textChange(event) {
    setText(event.target.value);
  }

  function clearSearch(event) {
    setText('');
    props.callback(value);
  }

  function changeSearchQuery() {
    setSearchString(searchString);
    navigate(`/search?query=${text}&page=${props.page}`);
  };

  const callback = props.callback;
  useEffect(()=>{
    if (callback) {
      callback(value);
    }
  }, [callback]);

  return (
    <div className="Search" onKeyUp={(e) => e.key === 'Enter' && changeSearchQuery()}>
      <OutlinedInput
        className="Search-input"
        size="big"
        type="text"
        placeholder={props.placeholder ?? ''}
        onChange={textChange}
        value={text}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={text!=='' &&
          <InputAdornment position="end" onClick={clearSearch}>
            <Close />
          </InputAdornment>
        }
      />
    </div>
  );
}

GlobalSearch.propTypes = {
  placeholder: PropTypes.string,
  callback: PropTypes.func,
};
