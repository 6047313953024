import Api from '../../../helpers/api/Api';

export default function postChangeProjectStatus(projectId, data) {
  return new Promise(function(resolve, reject) {
    const url = `/projects/${projectId}/change_status`;
    Api.post(url, data).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
