import './AddDocumentsModal.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import DragAndDrop, {ALLOWED_FILE_EXTENSIONS} from '../../components/DragAndDrop/DragAndDrop';
import EditField from '../../components/EditField/EditField';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import DocumentItem from '../DocumentItem/DocumentItem';
import postCompanyDocuments from './api/postCompanyDocuments';
import postProjectDocuments from './api/postProjectDocuments';

export default function AddDocumentsModal(props) {
  const {t} = useTranslation('component_documents');
  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState('');
  const location = useLocation();
  const resourceId = location.pathname.split('/').filter((i) => i.length > 0)[1];

  function handleClose() {
    props.onClose();
  }

  const statusOptions = [
    {name: 'signed', display: t('status.signed')},
    {name: 'unsigned', display: t('status.unsigned')},
    {name: 'to_sign', display: t('status.to_sign')},
    {name: 'other', display: t('status.other')},
  ];

  const updateProgress = (event) => {
    const sizeTotal = event.total;
    const sizeLoaded = event.loaded;
    if (sizeTotal && sizeLoaded) {
      setProgress(Math.round(1000*sizeLoaded/sizeTotal)/1000);
    }
  };

  const handleAddDocuments = () => {
    if (typeof status !== 'string' || status.length === 0) {
      setErrorText(t('add_modal.feedback.no_status'));
    } else if (!files || files.length === 0) {
      setErrorText(t('add_modal.feedback.no_documents'));
    } else if (resourceId && files?.length > 0) {
      setIsSending(true);
      setErrorText(null);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('attachment[]', file.file);
        formData.append('status[]', status);
      });
      switch (props.type) {
        case 'companyAttachment':
          postCompanyDocuments(resourceId, formData, updateProgress).then(() => {
            setIsSending(false);
            setStatus(null);
            setFiles([]);
            props.refreshList();
            handleClose();
          });
          break;
        default:
          postProjectDocuments(resourceId, formData, updateProgress).then(() => {
            setIsSending(false);
            setStatus(null);
            setFiles([]);
            props.refreshList();
            handleClose();
          });
      }
    } else {
      setErrorText(t('add_modal.feedback.unknown'));
    }
  };

  const displayedAllowedFormats = ALLOWED_FILE_EXTENSIONS.map((item) => item.replace(/^\./, '').toUpperCase()).join(', ');

  return (
    <ModalDialog
      className="AddDocumentsModal"
      open={props.open}
      onCloseRequest={handleClose}
    >
      <div className="modal-contents-wrapper">
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('add_modal.title')}</Typography>
        </div>
        {isSending &&
          <ProgressIndicator stretch={true} type="linear" currentProgress={progress*100}/>
        }
        {!isSending &&
          <>
            <div className="modal-subtitle">{t('add_modal.subtitle', {formats: displayedAllowedFormats})}</div>
            <EditField
              id="files-status"
              name={t('add_modal.field.status.description')}
              value={status}
              type="dropdown"
              placeholder={t('add_modal.field.status.placeholder')}
              options={statusOptions}
              editing={true}
              onChange={(fieldId, newValue) => setStatus(newValue)}
            />
            <div className="upload-label"></div>
            <DragAndDrop
              componentId="document"
              uploadedFiles={files}
              error={null}
              setUploadedFiles={(files) => setFiles(files)}
              status={status}
            />

            <div className="actions">
              <Button
                className="button-send-invite"
                variant="contained"
                onClick={handleAddDocuments}
              >
                {t('add_modal.action.add')}
              </Button>
            </div>
            {typeof errorText === 'string' &&
              <Typography className="errorMessage" color={'error'}>{errorText}</Typography>
            }
          </>
        }
      </div>
    </ModalDialog>
  );
}

AddDocumentsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['attachment', 'companyAttachment']),
};

DocumentItem.defaultProps = {
  open: false,
  type: 'attachment',
};
