import Api from '../../../helpers/api/Api';
import prepareProjectsListUrlParameters from '../functions/prepareProjectsListUrlParameters';

export default function getProjectsList(
    pageScope,
    search,
    status,
    handler,
    showOnlyMine,
    securityContextId,
    order,
    page,
    itemsPerPage = 10,
) {
  return new Promise(function(resolve, reject) {
    const url = prepareProjectsListUrlParameters(
        '/projects',
        pageScope,
        search,
        status,
        handler,
        showOnlyMine,
        securityContextId,
        order,
        page,
        itemsPerPage,
    );

    Api.get(url).then((data)=>{
      resolve({
        projects: data['hydra:member'],
        items: parseInt(data['hydra:totalItems']),
        pages: Math.ceil(parseInt(data['hydra:totalItems']) / itemsPerPage),
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
