import {FileDownload} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import ChangeProjectStatusPopUp from '../../../components/ChangeStatusPopUps/ChangeProjectStatusPopUp';
import IndexTableManaged from '../../../components/IndexTable/IndexTableManaged';
import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../helpers/security/hasCapability';
import getPossibleStatusActions from '../../ProjectDetailsPage/ProjectEdit/functions/getPossibleStatusActions';
import getProjectsList from '../api/getProjectsList';
import getProjectsListExport from '../api/getProjectsListExport';
import useProjectsListFilters, {
  PARAM_HANDLER_NAME,
  PARAM_ORDER_BY_NAME,
  PARAM_ORDER_DIR_NAME,
  PARAM_PAGE_NAME,
} from '../hooks/useProjectsListFilters';
import projectToTableRow from './functions/projectToTableRow';
import useInitialTableHeaders from './hooks/useInitialTableHeaders';
import css from './index.module.scss';

const ITEMS_PER_PAGE = 10;

function TableBlock({pageScope}) {
  const {t} = useTranslation('page_cases');
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsTotal, setProjectsTotal] = useState(0);
  const {current, setParams} = useProjectsListFilters();

  const canEditProject = useHasCapabilityHere(CAPABILITY_EDIT);

  const [changeStatusProject, setChangeStatusProject] = useState(null);
  const [changeStatusProjectTo, setChangeStatusProjectTo] = useState(null);

  const tableHeadersCreator = useInitialTableHeaders(pageScope);
  const tableHeaders = tableHeadersCreator(current.orderBy, current.orderDir);
  const tableRows = projects.map((project)=>projectToTableRow(pageScope, project, canEditProject, t));

  function onHeaderClick(fieldId) {
    const headerDef = tableHeaders.find((header)=>header.id===fieldId);
    if (headerDef?.sortable) {
      if (current.orderBy === fieldId) {
        setParams({
          [PARAM_ORDER_DIR_NAME]: current.orderDir === 'asc' ? 'desc' : 'asc',
          [PARAM_PAGE_NAME]: 1,
        });
      } else {
        setParams({
          [PARAM_ORDER_BY_NAME]: fieldId,
          [PARAM_ORDER_DIR_NAME]: 'asc',
          [PARAM_PAGE_NAME]: 1,
        });
      }
    }
  }

  function onRowClick(rowIndex) {
    if (location?.pathname && projects[rowIndex] && projects[rowIndex].id) {
      navigate(`${location.pathname}/${projects[rowIndex].id}`);
    }
  }

  function onPageChangeClick(newPageZeroIndexed) {
    let newPage = newPageZeroIndexed + 1;
    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > Math.ceil(projectsTotal/ITEMS_PER_PAGE) ) {
      newPage = Math.ceil(projectsTotal/ITEMS_PER_PAGE);
    }
    setParams({
      [PARAM_PAGE_NAME]: newPage,
    });
  }

  function onMoreActionClick(actionId, projectIndex) {
    const project = projects[projectIndex];
    setChangeStatusProject(project);
    const possibleActions = getPossibleStatusActions(project, t);
    const actionTarget = possibleActions.find((a)=>a.id===actionId)?.newStatus;
    setChangeStatusProjectTo(actionTarget);
  }

  function onExcelDownloadClick() {
    setIsExporting(true);
    getProjectsListExport(
        pageScope,
        current.search,
        current.status,
        current[PARAM_HANDLER_NAME],
        current.showOnlyMine,
        current.securityContext,
        {
          header: current.orderBy,
          direction: current.orderDir,
        },
        current.page,
        ITEMS_PER_PAGE,
    ).then((file) => {
      setIsExporting(false);
      fileDownload(file, 'projects.xlsx');
    }).catch(()=>{
      setIsExporting(false);
    });
  }

  function onSavedStatusChange() {
    setChangeStatusProject(null);
    setChangeStatusProjectTo(null);
    refreshProjectsList();
  }

  function onCloseStatusChangePopup() {
    setChangeStatusProject(null);
    setChangeStatusProjectTo(null);
  }

  const refreshProjectsList = useCallback(()=>{
    setIsLoading(true);
    getProjectsList(
        pageScope,
        current.search,
        current.status,
        current[PARAM_HANDLER_NAME],
        current.showOnlyMine,
        current.securityContext,
        {
          header: current.orderBy,
          direction: current.orderDir,
        },
        current.page,
        ITEMS_PER_PAGE,
    ).then((response) => {
      setProjects(response.projects);
      setProjectsTotal(response.items);
      setIsLoading(false);
    });
  }, [current.handler, current.orderBy, current.orderDir, current.page, current.search, current.securityContext, current.showOnlyMine, current.status, current.refreshAt, pageScope]);

  useEffect(()=>{
    refreshProjectsList();
  }, [current.handler, current.orderBy, current.orderDir, current.page, current.search, current.securityContext, current.showOnlyMine, current.status, pageScope, refreshProjectsList]);

  return (
    <section className={css.TableBlock}>
      <IndexTableManaged
        headers={tableHeaders}
        rows={tableRows}
        pagination={{
          page: parseInt(current.page) - 1,
          rowsPerPage: ITEMS_PER_PAGE,
          items: projectsTotal,
        }}
        appendToFooter={!isExporting ?
          <IconButton onClick={onExcelDownloadClick}>
            <FileDownload />
          </IconButton> :
          <ProgressIndicator />
        }
        isLoading={isLoading}
        onHeaderClick={onHeaderClick}
        onMoreActionClick={onMoreActionClick}
        onPageChangeClick={onPageChangeClick}
        onRowClick={onRowClick}
      />
      {changeStatusProject && onCloseStatusChangePopup &&
        <ChangeProjectStatusPopUp
          isOpen={!!changeStatusProject && !!onCloseStatusChangePopup}
          project={changeStatusProject}
          newStatus={changeStatusProjectTo}
          shouldForceChange={true}
          showNotifyClient={true}
          showInternalName={changeStatusProjectTo !== 'lead_rejected' && changeStatusProjectTo !== 'lead_pending'}
          newStatusAsDropdown={changeStatusProjectTo !== 'lead_rejected' && changeStatusProjectTo !== 'lead_pending'}
          onSave={onSavedStatusChange}
          onClose={onCloseStatusChangePopup}
        />
      }
    </section>
  );
}

TableBlock.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
};

export default TableBlock;
