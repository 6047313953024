import PropTypes from 'prop-types';
import React from 'react';

import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import Tasks from '../../../components/TasksViewer/Tasks';
import css from './index.module.scss';

function ProjectTasksPanel({project}) {
  if (!project) return <ProgressIndicator />;

  return (
    <div className={css.ProjectTasksPanel}>
      <Tasks entity={project} />
    </div>
  );
}

ProjectTasksPanel.propTypes = {
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']),
  }),
};
export default ProjectTasksPanel;
