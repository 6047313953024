import {Box, Button} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BasicCard from '../../components/Card/Card';
import DocumentItemOnDashboard from '../../components/DocumentItemOnDashboard/DocumentItemOnDashboard';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';
import {
  CAPABILITY_VIEW_ALL_ATTACHMENTS,
} from '../../helpers/security/hasCapability';
import getUserRecentAttachments from './api/getUserRecentAttachments';
import RecentDocumentsModal from './RecentDocumentsModal';

const PAGE_STEP_SIZE = 4;

export default function CardUserRecentAttachments(props) {
  const {t} = useTranslation('component_documents');

  const navigate = useNavigate();
  const [userAttachments, setUserAttachments] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_STEP_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortByDate, setSortByDate] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const userId = props.user && props.user.id ? parseInt(props.user.id) : null;

  const shouldSeeAllAttachments = useHasCapabilityHere(CAPABILITY_VIEW_ALL_ATTACHMENTS);

  const getUserAttachments = useCallback(()=>{
    if (userId !== null) {
      setIsLoading(true);
      getUserRecentAttachments(
          page,
          itemsPerPage,
          shouldSeeAllAttachments,
          props.showOnlyMine,
          sortByDate,
      ).then((response) => {
        setUserAttachments(response.attachments);
        setTotalItems(response.totalItems);
        setIsLoading(false);
      });
    }
  }, [itemsPerPage, page, props.showOnlyMine, shouldSeeAllAttachments, sortByDate, userId]);

  function onSeeMoreClicked() {
    setItemsPerPage(2*PAGE_STEP_SIZE);
    setModalOpen(true);
  }

  function onItemClicked(attachment) {
    const link = getFrontendPathToObject(attachment.project);
    if (link) {
      navigate(link);
    }
  }

  function onLoadMoreClicked() {
    setItemsPerPage(itemsPerPage+PAGE_STEP_SIZE);
  }

  useEffect(function() {
    getUserAttachments();
  }, [getUserAttachments]);

  if (userId) {
    return (
      <>
        <BasicCard
          className="CardUserRecentAttachments"
          title={t('overview_panel_title')}
          appendToHeaderSecondLine={<Box sx={{marginBottom: '10px'}}>
            <Button
              size="small"
              sx={sortByDate ? {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'marginRight': '10px', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}} : {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'marginRight': '10px', 'backgroundColor': '#D9DAE1', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}} }
              variant="outlined"
              onClick={()=>setSortByDate(false)}
            >
              {t('sort_by_name')}
            </Button>
            <Button
              size="small"
              sx={!sortByDate ? {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#ffffff', borderColor: '#D9DAE1'}} : {'fontSize': 14, 'fontWeight': 500, 'padding': '0 15px', 'backgroundColor': '#D9DAE1', 'borderColor': '#D9DAE1', '&:hover': {backgroundColor: '#D9DAE1', borderColor: '#D9DAE1'}}}
              variant="outlined"
              onClick={()=>setSortByDate(true)}
            >
              {t('sort_by_date')}
            </Button>
          </Box>
          }
          appendToFooter={<>
            <CardActions
              className="BasicCard-footer"
              sx={{marginTop: 'auto', textAlign: 'right'}}
            >
              <Button size={'small'} sx={{paddingLeft: '15px'}} onClick={onSeeMoreClicked}>{totalItems > PAGE_STEP_SIZE ? t('see_more') : null}</Button>
            </CardActions>
          </>}
        >
          {isLoading &&
            <ProgressIndicator />
          }
          {!isLoading && userAttachments?.filter((file, fileIdx) => fileIdx < PAGE_STEP_SIZE).map((file, fileIdx) => (
            <DocumentItemOnDashboard
              key={file['@id'] ?? file.id ?? fileIdx}
              attachment={file}
              onItemClicked={onItemClicked}
            />
          ))
          }
        </BasicCard>
        <RecentDocumentsModal
          isOpen={isModalOpen}
          isLoading={isLoading}
          onClose={()=>setModalOpen(false)}
          documents={userAttachments.filter((file, fileIdx) => fileIdx >= PAGE_STEP_SIZE)}
          showSeeMore={totalItems > userAttachments.length}
          onSeeMoreClicked={onLoadMoreClicked}
          onClick={onItemClicked}
        />
      </>
    );
  } else {
    return null;
  }
}

CardUserRecentAttachments.propTypes = {
  user: PropTypes.object,
};
