import {OutlinedInput, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function EditFieldAddress(props) {
  const {t, i18n} = useTranslation('component_editfield');

  function callbackStreet(event) {
    const source = Object.assign({}, props.value);
    source.street = event.target.value;
    props.onChange(source);
  }
  function callbackPostal(event) {
    const source = Object.assign({}, props.value);
    source.postal = event.target.value;
    props.onChange(source);
  }
  function callbackCity(event) {
    const source = Object.assign({}, props.value);
    source.city = event.target.value;
    props.onChange(source);
  }
  function callbackCountry(event) {
    const source = Object.assign({}, props.value);
    source.country = event.target.value;
    props.onChange(source);
  }

  return (
    <>
      <div className='EditFieldAddress-wrapper'>
        <Typography fontSize="small" className="EditField-name" >{t('field.address.street.description')}</Typography>
        <OutlinedInput
          className="EditFieldAddress EditFieldAddress-street"
          size="small"
          value={props.value.street}
          onChange={callbackStreet}
          fullWidth={true}
        />
      </div>
      <div className='EditFieldAddress-wrapper'>
        <Typography fontSize="small" className="EditField-name" >{t('field.address.postal.description')}</Typography>
        <OutlinedInput
          className="EditFieldAddress EditFieldAddress-postal"
          size="small"
          value={props.value.postal}
          onChange={callbackPostal}
          fullWidth={true}
        />
      </div>
      <div className='EditFieldAddress-wrapper'>
        <Typography fontSize="small" className="EditField-name" >{t('field.address.city.description')}</Typography>
        <OutlinedInput
          className="EditFieldAddress EditFieldAddress-city"
          size="small"
          value={props.value.city}
          onChange={callbackCity}
          fullWidth={true}
        />
      </div>
      <div className='EditFieldAddress-wrapper'>
        <Typography fontSize="small" className="EditField-name" >{t('field.address.country.description')}</Typography>
        <OutlinedInput
          className="EditFieldAddress EditFieldAddress-country"
          size="small"
          value={props.value.country}
          onChange={callbackCountry}
          fullWidth={true}
        />
      </div>
    </>
  );
}

EditFieldAddress.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isVeryLarge: PropTypes.bool,
};
