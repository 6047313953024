import './UserEdit.scss';

import {Close, DriveFileRenameOutline, Save} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import EditField from '../../components/EditField/EditField';
import HorizontalDivider from '../../components/HorizontalDivider/HorizontalDivider';
import MoreButton from '../../components/MoreButton/MoreButton';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {CAPABILITY_ASSIGN_SECURITY_CONTEXTS, CAPABILITY_EDIT, CAPABILITY_MERGE, CAPABILITY_PURGE} from '../../helpers/security/hasCapability';
import patchUser from './api/patchUser';
import postDisableTotp from './api/postDisableTotp';
import MergeUsersModal from './MergeUsersModal';

export default function UserEdit(props) {
  const {t, i18n} = useTranslation('page_users');

  const [patchData, setPatchData] = useState(initializePatchData());
  const [isEditing, setIsEditing] = useState(false);
  const [isMergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);
  const hasMergeCapability = useHasCapabilityHere(CAPABILITY_MERGE);
  const hasPurgeCapability = useHasCapabilityHere(CAPABILITY_PURGE);
  const hasAssignSecurityContextCapability = useHasCapabilityHere(CAPABILITY_ASSIGN_SECURITY_CONTEXTS);

  function initializePatchData() {
    return {
      id: props.userData.id,
      firstname: props.userData.firstname,
      lastname: props.userData.lastname,
      email: props.userData.email,
      userPhones: [...props.userData.userPhones],
      primaryRole: props.userData.primaryRole,
      isTotpEnabled: props.userData.isTotpEnabled,
      roles: [...props.userData.roles],
      isEnabled: props.userData.isEnabled,
      securityContextsIds: props.userData.securityContexts.map((ctx)=>ctx.id),
    };
  }

  function toggleEdit() {
    setPatchData(initializePatchData());
    if (!isEditing && hasEditCapability) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }

  function toggleSave() {
    setIsEditing(false);
    setIsSaving(true);
    patchUser(patchData).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function userStatusChange(action) {
    const data = Object.assign({}, patchData);
    switch (action) {
      case 'activate':
        data.isEnabled=true;
        break;
      case 'deactivate':
        data.isEnabled=false;
        break;
      default:
    }
    setIsEditing(false);
    setIsSaving(true);
    patchUser(data).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function userDisableTotp(action) {
    setIsEditing(false);
    setIsSaving(true);
    postDisableTotp(patchData.id).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function editFieldChanged(id, value) {
    const data = Object.assign({}, patchData);
    switch (id) {
      case 'username':
        data.firstname = value.firstname;
        data.lastname = value.lastname;
        break;
      case 'email':
        data.email = value;
        break;
      case 'phone':
        if (data.userPhones && data.userPhones[0]) {
          data.userPhones[0].country = value.country;
          data.userPhones[0].phone = value.phone;
        } else {
          data.userPhones = [{
            country: value.country,
            phone: value.phone,
          }];
        }
        break;
      case 'primaryRole':
        data.primaryRole = value;
        data.roles = [value];
        break;
      case 'userSecurityContexts':
        data.securityContextsIds = value;
        break;
      default:
    }
    setPatchData(data);
  }

  function onMergeDialogClosed(result = false) {
    setMergeDialogOpen(false);
    if (typeof result === 'number' && result > 0) {
      navigate(`/users/${result}`);
    } else if (result === true) {
      props.onRefreshRequest();
    }
  }

  function breadcrumbBack() {
    navigate('/users');
  }

  const possibleStatusActions = [];
  if (props.userData.isEnabled) {
    possibleStatusActions.push(
        {id: 'deactivate', text: t('action.deactivate', {ns: 'dictionary_user_status'})},
    );
  }
  if (!props.userData.isEnabled) {
    possibleStatusActions.push(
        {id: 'activate', text: t('action.activate', {ns: 'dictionary_user_status'})},
    );
  }

  return (
    <div className="UserEdit">
      <div className="breadcrumb-navigation" onClick={breadcrumbBack}>
        <div className="arrow-back" />
        <Typography fontSize={14}>{t('page.back')}</Typography>
      </div>
      {!isSaving &&
          <>
            <EditField
              id="username"
              name={null}
              value={patchData}
              format="header"
              type="userNameSurname"
              editing={isEditing}
              onChange={editFieldChanged}
            />
            { hasMergeCapability &&
              <Button className="merge-button" variant="smallerText" onClick={()=>setMergeDialogOpen(!isMergeDialogOpen)}>{t('page.merge_button')}</Button>
            }
            <EditField
              id="email"
              name={t('field.email.description')}
              value={patchData.email}
              type="string"
              fontSize={16}
              placeholder={t('field.email.placeholder')}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="phone"
              name={t('field.phone.description')}
              value={
                Array.isArray(patchData.userPhones) && patchData.userPhones.length > 0 ?
                  patchData.userPhones[0] :
                  null
              }
              placeholder={[t('field.phone.placeholder1'), t('field.phone.placeholder2')]}
              type="userPhone"
              fontSize={16}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="createdAt"
              name={t('field.date_added.description')}
              value={props.userData.createdAt}
              type="dateString"
              fontSize={16}
              editing={false}
            />
            <EditField
              id="primaryRole"
              name={t('field.role.description')}
              value={patchData.primaryRole}
              type="primaryRole"
              typeOptions={{client: false}}
              fontSize={16}
              editing={patchData.primaryRole !== 'ROLE_USER' && isEditing}
              onChange={editFieldChanged}
            />
            <div className="with-button">
              <EditField
                id="isTotpEnabled"
                name={t('field.totp.description')}
                type="string"
                value={props.userData.isTotpAuthenticationEnabled ?
                  t('active', {ns: 'dictionary_user_status'}) :
                  t('inactive', {ns: 'dictionary_user_status'})
                }
                fontSize={16}
              />
              {hasEditCapability && props.userData.isTotpAuthenticationEnabled &&
                <MoreButton
                  actions={[
                    {id: 'deactivate', text: t('action.deactivate', {ns: 'dictionary_user_status'})},
                  ]}
                  onAction={userDisableTotp}
                />
              }
            </div>
            {hasAssignSecurityContextCapability &&
              <EditField
                id="userSecurityContexts"
                name={t('field.security_context.description')}
                value={patchData.securityContextsIds}
                type="securityContextMultiselect"
                fontSize={16}
                editing={isEditing}
                onChange={editFieldChanged}
              />
            }
            <div className="with-button">
              <EditField
                id="isEnabled"
                name={t('field.status.description')}
                value={patchData.isEnabled ?
                  (
                    props.userData.confirmedAt===null ?
                    t('pending', {ns: 'dictionary_user_status'}) :
                    t('active', {ns: 'dictionary_user_status'})
                  ) :
                  t('inactive', {ns: 'dictionary_user_status'})
                }
                type="string"
                fontSize={16}
              />
              {hasEditCapability &&
                <MoreButton
                  actions={possibleStatusActions}
                  onAction={userStatusChange}
                />
              }
            </div>
            <HorizontalDivider />
          </>
      }
      {!isEditing && !isSaving && hasEditCapability &&
          <Button
            startIcon={<DriveFileRenameOutline />}
            color="text"
            onClick={toggleEdit}
            sx={{padding: 0}}
          >
            <Typography fontSize={16}>{t('action.edit')}</Typography>
          </Button>
      }
      {isEditing && !isSaving && hasEditCapability &&
          <>
            <Button
              startIcon={<Save />}
              color="text"
              onClick={toggleSave}
              sx={{padding: 0}}
            >
              <Typography fontSize={16}>{t('action.save')}</Typography>
            </Button>
            <Button
              startIcon={<Close />}
              color="text"
              onClick={toggleEdit}
              sx={{padding: 0, marginLeft: '36px'}}
            >
              <Typography fontSize={16}>{t('action.cancel')}</Typography>
            </Button>
          </>
      }
      {hasPurgeCapability && props.view !== 'purge' &&
          <Button
            startIcon={<Close />}
            color="text"
            onClick={()=>navigate('purge')}
            sx={{padding: 0, marginLeft: '36px'}}
          >
            <Typography fontSize={16}>{t('action.purge')}</Typography>
          </Button>
      }
      {isSaving &&
          <ProgressIndicator />
      }
      <MergeUsersModal
        isOpen={isMergeDialogOpen}
        onClose={onMergeDialogClosed}
        subject={props.userData}
      />
    </div>
  );
}

UserEdit.propTypes = {
  userData: PropTypes.object.isRequired,
  onRefreshRequest: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['default', 'purge']),
};
