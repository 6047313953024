import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import IndexTableManaged from '../../../components/IndexTable/IndexTableManaged';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../helpers/security/hasCapability';
import postSendInvitationRequest from '../../CompanyDetails/api/postSendInvitationRequest';
import useUsersListFilters, {PARAM_ITEMS_PER_PAGE, PARAM_ORDER_BY, PARAM_ORDER_DIR, PARAM_PAGE} from '../hooks/useUsersListFilters';
import getUsersList from './api/getUsersList';
import patchUserStatus from './api/patchUserStatus';
import userToTableFields from './convert/userToTableFields';
import DisableUserModal from './DisableUserModal';
import css from './index.module.scss';

export default function TableBlock() {
  const {t} = useTranslation('page_users');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersListSize, setUsersListSize] = useState(0);
  const [reSendActivationEmail, setReSendActivationEmail] = useState('');
  const [isReSendActivationModalOpen, setReSendActivationModalOpen] = useState(false);
  const [isDisableUserModalOpen, setDisableUserModalOpen] = useState(false);
  const [userToDisable, setUserToDisable] = useState(null);

  const {current, setParams, currentFetchUrl} = useUsersListFilters();

  const canEditUsers = useHasCapabilityHere(CAPABILITY_EDIT);

  const headers = [
    {id: 'display', label: t('list.table.header.name'), sortable: true},
    {id: 'primaryRole', label: t('list.table.header.role'), sortable: false},
    {id: 'isEnabled', label: t('list.table.header.status'), sortable: false},
    {id: 'email', label: t('list.table.header.email'), sortable: true},
    {id: 'primaryPhone', label: t('list.table.header.phone'), sortable: false},
    {id: 'createdAt', label: t('list.table.header.date'), sortable: true},
    {id: 'context', label: t('list.table.header.context'), sortable: false},
  ];

  const refreshUsersList = useCallback(()=>{
    if (current && currentFetchUrl) {
      setIsLoading(true);
      getUsersList(currentFetchUrl).then((data) => {
        setUsersList(data.users);
        setUsersListSize(data.items);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [current, currentFetchUrl]);

  useEffect(()=> {
    refreshUsersList();
  }, [refreshUsersList]);

  function onUserDisableSuccess() {
    setDisableUserModalOpen(false);
    refreshUsersList();
  }

  function onTableHeaderClick(headerId) {
    const headerDef = headers.find((h)=>h.id===headerId);
    if (headerDef && headerDef.sortable===true) {
      if (current[PARAM_ORDER_BY] === headerId) {
        setParams({
          [PARAM_ORDER_DIR]: current[PARAM_ORDER_DIR] === 'asc' ? 'desc' : 'asc',
          [PARAM_PAGE]: 1,
        });
      } else {
        setParams({
          [PARAM_ORDER_BY]: headerId,
          [PARAM_ORDER_DIR]: 'asc',
          [PARAM_PAGE]: 1,
        });
      }
    }
  }

  function onMoreActionClick(actionId, userListIndex) {
    const foundUser = usersList[userListIndex];
    if (foundUser) {
      switch (actionId) {
        case 'details':
          navigate(`/users/${foundUser.id}`);
          break;
        case 'activate':
          patchUserStatus(foundUser.id, true).then(() => {
            refreshUsersList();
          });
          break;
        case 'deactivate':
          // patchUserStatus(foundUser.id, false).then(() => {
          //   refreshUsersList();
          // })
          setUserToDisable(foundUser);
          setDisableUserModalOpen(true);
          break;
        case 'reinvite':
          setReSendActivationEmail(foundUser.email);
          setReSendActivationModalOpen(true);
          break;
        default:
      }
    }
  }

  function onPageChangeClick(newPageIndex) {
    setParams({
      [PARAM_PAGE]: newPageIndex+1,
    });
  }

  function onRowClick(rowId) {
    const foundUser = usersList[rowId];
    if (foundUser) {
      navigate(`/users/${foundUser.id}`);
    }
  }

  function executeInvitationRequest() {
    if (reSendActivationEmail) {
      const email = reSendActivationEmail;
      setReSendActivationEmail(null);
      setReSendActivationModalOpen(false);
      postSendInvitationRequest(email).then(()=>{
        refreshUsersList();
      });
    }
  }

  const rows = usersList.map((userRow) => userToTableFields(userRow, canEditUsers, t, true));

  return (
    <div className={css.TableBlock}>
      <IndexTableManaged
        headers={headers.map((def)=>{
          const header = {...def};
          if (header.id===current[PARAM_ORDER_BY]) {
            header.sorted = current[PARAM_ORDER_DIR];
          }
          return header;
        })}
        rows={rows}
        pagination={{
          page: current[PARAM_PAGE]-1,
          rowsPerPage: current[PARAM_ITEMS_PER_PAGE],
          items: usersListSize,
        }}
        onHeaderClick={onTableHeaderClick}
        onMoreActionClick={onMoreActionClick}
        onPageChangeClick={onPageChangeClick}
        onRowClick={onRowClick}
        isLoading={isLoading}
      />
      <AlertDialog
        open={isReSendActivationModalOpen}
        title=""
        text={t('list.confirmation_modal.re_send_activation')}
        onClose={()=>setReSendActivationModalOpen(false)}
        onConfirm={executeInvitationRequest}
      />
      <DisableUserModal
        isOpen={isDisableUserModalOpen && !!userToDisable}
        onAbort={()=>setDisableUserModalOpen(false)}
        onSuccess={onUserDisableSuccess}
        subject={userToDisable}
      />
    </div>
  );
}

