import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useUserSecurityContext} from '../../../../customHooks/useUserSecurityContext';
import useProjectsListFilters, {PARAM_SECURITY_CONTEXT_NAME} from '../../hooks/useProjectsListFilters';
import css from './index.module.scss';

function SecurityContextFilter({onChange}) {
  const {t} = useTranslation('login');
  const {current} = useProjectsListFilters();

  const userSecurityContexts = useUserSecurityContext();

  const securityContextOptions = useMemo(()=>{
    const list = userSecurityContexts.map((ctx)=>(
      {name: ctx.id, display: t(`security_context.name.${ctx.name}`)}
    ));
    if (list.length>1) {
      list.unshift(
          {name: '', display: t(`security_context.unset`)},
      );
    }
    return list;
  }, [t, userSecurityContexts]);

  const selectedSecurityContext = userSecurityContexts && !isNaN(parseInt(current[PARAM_SECURITY_CONTEXT_NAME])) ?
    userSecurityContexts.find((ctx)=>(ctx.id===parseInt(current[PARAM_SECURITY_CONTEXT_NAME]))) :
    null;

  function onSecurityContextChange(id, val) {
    onChange(val);
  }

  return (
    <div className={css.SecurityContextFilter}>
      <EditField
        id="securityContextFilter"
        value={selectedSecurityContext?.id}
        type="dropdown"
        placeholder={t('security_context.placeholder')}
        options={securityContextOptions}
        editing={true}
        onChange={onSecurityContextChange}
      />
    </div>
  );
}

SecurityContextFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SecurityContextFilter;
