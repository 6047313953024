import './CompanyTypeDot.scss';

import PropTypes from 'prop-types';
import React from 'react';

export default function CompanyTypeDot(props) {
  let typeClass;
  switch (props.type) {
    case 'client':
      if (props.isPersonal) {
        typeClass = 'company-type-client company-type-client-personal';
      } else {
        typeClass = 'company-type-client company-type-client-company';
      }
      break;
    case 'investor':
      typeClass = 'company-type-investor';
      break;
    case 'intermediary':
      typeClass = 'company-type-intermediary';
      break;
    case 'money_supplier':
      typeClass = 'company-type-money-supplier';
      break;
    default:
      return '';
  }

  return (
    <span className={`CompanyTypeDot ${typeClass}`}>&#11044;</span>
  );
}

CompanyTypeDot.propTypes = {
  type: PropTypes.oneOf([
    'client',
    'investor',
    'intermediary',
    'money_supplier',
  ]).isRequired,
  isPersonal: PropTypes.bool.isRequired,
};
