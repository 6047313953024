import Api from '../../../helpers/api/Api';
import prepareCompaniesListUrlParameters from '../functions/prepareCompaniesListUrlParameters';

export default function getCompaniesList(search, sorting, group, isPersonal, page, itemsPerPage, securityContextId) {
  return new Promise(function(resolve, reject) {
    const url = prepareCompaniesListUrlParameters('/companies', search, sorting, group, isPersonal, page, itemsPerPage, securityContextId);

    Api.get(url).then((data)=>{
      resolve({
        companies: data['hydra:member'],
        items: parseInt(data['hydra:totalItems']),
        pages: Math.ceil(parseInt(data['hydra:totalItems']) / itemsPerPage),
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
