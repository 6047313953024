import Api from '../../../helpers/api/Api';

export default function getHandlers() {
  return new Promise(function(resolve, reject) {
    Api.get(`/users?onlyActive=true&itemsPerPage=1000000&roles[]=ROLE_SALES&roles[]=ROLE_ADMIN&roles[]=ROLE_SUPERADMIN`).then((data)=> {
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
