import i18n from '../../i18n';

export default function status2string(status) {
  switch (status) {
    case 'lead_pending': return i18n.t('lead_pending', {ns: 'dictionary_project_status'});
    case 'lead_rejected': return i18n.t('lead_rejected', {ns: 'dictionary_project_status'});
    case 'opportunity_trajectory': return i18n.t('opportunity_trajectory', {ns: 'dictionary_project_status'});
    case 'opportunity_payment': return i18n.t('opportunity_payment', {ns: 'dictionary_project_status'});
    case 'opportunity_memorandum_creating': return i18n.t('opportunity_memorandum_creating', {ns: 'dictionary_project_status'});
    case 'opportunity_memorandum_sent': return i18n.t('opportunity_memorandum_sent', {ns: 'dictionary_project_status'});
    case 'opportunity_resigned': return i18n.t('opportunity_resigned', {ns: 'dictionary_project_status'});
    case 'account_in_progress': return i18n.t('account_in_progress', {ns: 'dictionary_project_status'});
    case 'account_closed': return i18n.t('account_closed', {ns: 'dictionary_project_status'});
    default: return null;
  }
}
