import {useStateValue} from '../state/state';

const ADMINISTRATIVE_ROLES = [
  'ROLE_SUPERADMIN',
  'ROLE_ADMIN',
];

export const useIsAdministrator = () => {
  const [{user}, dispatch] = useStateValue();

  return user !== null && ADMINISTRATIVE_ROLES.some((role) => (user.roles && user.roles.indexOf(role) > -1));
};
