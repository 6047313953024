import PropTypes from 'prop-types';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import TaskItem from './TaskItem';

const TasksList = (props) => {
  const {tasks, maxTasks, onClick} = props;

  return (
    <>
      {tasks.slice(0, maxTasks).map((task, taskIdx) => {
        const isLast = props.tasks.length - taskIdx === 1;
        return (
          <TaskItem
            key={task['@id']}
            iri={task['@id']}
            author={task.creator && task.creator.display}
            executors={task.executors}
            content={task.text ?? `[[${task.name}]`}
            title={task.title ?? `[[${task.name}]`}
            createdAt={formatDateTimestamp(task.createdAt)}
            completedAt={task.completedAt}
            deadlineAt={task.deadlineAt}
            withUnderline={!isLast}
            onClick={() => onClick(task)}
          />
        );
      })}
    </>
  );
};

TasksList.propTypes = {
  maxTasks: PropTypes.number,
  onClick: PropTypes.func,
  tasks: PropTypes.arrayOf(PropTypes.object),
};

export default TasksList;
