import Api from '../../../helpers/api/Api';

export default function postMergeProjects(primaryProject, secondaryProject) {
  return new Promise(function(resolve, reject) {
    if (primaryProject === null || secondaryProject === null || primaryProject===secondaryProject) {
      reject();
    }

    Api.post('/merge/projects', {
      primary: primaryProject,
      secondary: secondaryProject,
    }).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
