import './Card.scss';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function BasicCard(props) {
  return (
    <Card className={`BasicCard ${props.className ?? ''}`} sx={{textAlign: 'left'}}>
      <CardContent
        className="BasicCard-header"
        sx={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '18px 18px 0', flexDirection: 'column'}}
      >
        <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
          {props.title &&
            <Typography variant="h5" sx={{fontSize: 20, padding: '8px 0', fontWeight: '500'}} component="div">
              {props.title}
            </Typography>
          }
          {props.appendToHeader}
        </Box>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'left'}}>
          {props.appendToHeaderSecondLine}
        </Box>
      </CardContent>
      <CardContent
        className="BasicCard-content"
        sx={{padding: '0 18px 18px'}}
      >
        {props.children}
      </CardContent>
      {props.appendToFooter}
    </Card>
  );
}

BasicCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  appendToHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  appendToFooter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
