import {useLocation} from 'react-router-dom';

import hasCapability from '../helpers/security/hasCapability';
import {useStateValue} from '../state/state';

export const useHasCapabilityHere = (capability) => {
  const [{user}] = useStateValue();
  const location = useLocation();

  let slug = location.pathname.split('/').filter((i) => i.length > 0)[0];
  if (typeof slug === 'undefined') slug = 'overview';

  return hasCapability(user, capability, slug);
};
