import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './index.module.scss';

function ShortenedText({text}) {
  return (
    <span className={classNames(css.ShortenedText, css.clearFix)}>
      <span className={css.noBreak}>{text}</span>
    </span>
  );
}

ShortenedText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default ShortenedText;
