import Api from '../../../../../helpers/api/Api';

const USER_IRI_PREFIX = '/api/users/';

export default function postDisableUserWithAssetsReassign(userId, reassignmentTarget) {
  return new Promise(function(resolve, reject) {
    const subjectId = normalizeUserId(userId);
    const inheritorIri = normalizeUserIri(reassignmentTarget);

    Api.post(`/users/${subjectId}/deactivate`, {
      inheritor: inheritorIri,
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}

function normalizeUserId(input) {
  if (
    typeof input === 'string' &&
    input.length > USER_IRI_PREFIX.length &&
    input.startsWith(USER_IRI_PREFIX) &&
    !isNaN(parseInt(input.substring(USER_IRI_PREFIX.length))) &&
    parseInt(input.substring(USER_IRI_PREFIX.length)) > 0
  ) {
    return parseInt(input.substring(USER_IRI_PREFIX.length));
  } else if (!isNaN(parseInt(input)) && parseInt(input) > 0) {
    return parseInt(input);
  } else {
    return null;
  }
}

function normalizeUserIri(input) {
  if (
    typeof input === 'object' &&
    typeof input['@id'] === 'string' &&
    input['@id'].startsWith(USER_IRI_PREFIX)
  ) {
    return input['@id'];
  } else if (
    typeof input === 'string' &&
    input.length > USER_IRI_PREFIX.length &&
    input.startsWith(USER_IRI_PREFIX) &&
    !isNaN(parseInt(input.substring(USER_IRI_PREFIX.length))) &&
    parseInt(input.substring(USER_IRI_PREFIX.length)) > 0
  ) {
    return input;
  } else if (!isNaN(parseInt(input)) && parseInt(input) > 0) {
    return `${USER_IRI_PREFIX}${input}`;
  } else {
    return null;
  }
}

