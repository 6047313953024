import Api from '../../../helpers/api/Api';

export default function postPasswordChange(data) {
  return new Promise(function(resolve, reject) {
    Api.post(`/users/password_change`, data).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
