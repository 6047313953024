import {OpenInNew, Star} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import css from './CompanyEmployee.module.scss';
import useCanShowOutgoingLink from './hooks/useCanShowOutgoingLink';

function CompanyEmployee({projectUser, isPrimary, company}) {
  const outgoingLink = `/companies/${company?.id}/users/${projectUser?.userId}`;
  const canShowLink = useCanShowOutgoingLink(outgoingLink);
  return (
    <div className={classNames(css.CompanyEmployee, {[css.bold]: isPrimary})}>
      <Typography variant="smallText">
        {isPrimary &&
          <>
            <Star fontSize="inherit" />
            &nbsp;
          </>
        }
        {projectUser?.display}
      </Typography>
      <Typography variant="smallText">{projectUser?.primaryPhoneDisplay}</Typography>
      <Typography variant="smallText">{projectUser?.email}</Typography>
      <Typography variant="smallText">
        {canShowLink &&
          <Link to={outgoingLink}>
            <OpenInNew className={css.spaced} fontSize="inherit" color="primary"/>
          </Link>
        }
      </Typography>
    </div>
  );
}

CompanyEmployee.propTypes = {
  projectUser: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectUser']).isRequired,
  }),
  isPrimary: PropTypes.bool,
  company: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Company']).isRequired,
  }),
};

CompanyEmployee.defaultProps = {
  isPrimary: false,
  companyId: 0,
};

export default CompanyEmployee;
