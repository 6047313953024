import menuConfig from '../../menu_config.json';
import {CAPABILITY_EDIT, CAPABILITY_VIEW} from './hasCapability';

export default function hasSlugAccess(user, slug) {
  if (!user) return false;

  const slugDefinition = menuConfig.find((item) => item.slug===slug);
  if (!slugDefinition) return false;

  const allRoles = [
    ...slugDefinition.capabilities[CAPABILITY_EDIT],
    ...slugDefinition.capabilities[CAPABILITY_VIEW],
  ];

  return allRoles.includes(user.primaryRole);
}
