import i18n from '../../i18n';

export default function statusTask2String(completedDate, deadlinedDate) {
  const taskCompletedDate = completedDate ? new Date(completedDate) : null;
  const taskDeadlinedDate = deadlinedDate ? new Date(deadlinedDate) : null;
  const today = new Date();

  if ((taskDeadlinedDate >= today && taskCompletedDate === null) || (taskDeadlinedDate === null)) {
    return i18n.t('table.statuses.open', {ns: 'page_tasks'});
  } else if ((taskCompletedDate === null) && (taskDeadlinedDate < today)) {
    return i18n.t('table.statuses.openDelayed', {ns: 'page_tasks'});
  } else if (taskCompletedDate !== null) {
    return i18n.t('table.statuses.finished', {ns: 'page_tasks'});
  }
}
