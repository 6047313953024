import './TotpConfigurationModal.scss';

import {Input} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalDialog from '../../components/ModalDialog/ModalDialog';
import getTotpQrCode from './api/getTotpQrCode';
import postToggleTotp from './api/postToggleTotp';

export default function TotpConfigurationModal(props) {
  const {t} = useTranslation('page_settings');
  const [qrImage, setQrImage] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [confirmationCodeHasError, setConfirmationCodeHasError] = useState(false);

  function enableSecondFactor() {
    postToggleTotp(true, confirmationCode).then((response) => {
      props.onCloseRequest(true);
    }).catch((error)=>{
      if (error.data.code === 409) {
        setConfirmationCodeHasError(true);
      }
    });
  }

  function disableSecondFactor() {
    postToggleTotp(false).then((response) => {
      props.onCloseRequest(false);
    });
  }

  useEffect(()=>{
    if (props.isOpen) {
      getTotpQrCode().then((image) => {
        setQrImage(image);
      });
      setConfirmationCode('');
      setConfirmationCodeHasError(false);
    }
  }, [props.isOpen]);

  const isTotpEnabled = props && props.user && !!props.user.isTotpEnabled;

  return (
    <ModalDialog
      className="TotpConfigurationModal"
      open={props.isOpen}
      onCloseRequest={()=>props.onCloseRequest(isTotpEnabled)}
    >
      <div className="section-title">
        <Typography variant="h2">{t('totp_configuration.modal.title')}</Typography>
      </div>
      <div className="section-contents">
        <Typography variant="h3">{t('totp_configuration.modal.qr_name')}</Typography>
        <Typography variant="body2">{t('totp_configuration.modal.qr_description')}</Typography>
        <div className="qr-code-container">
          {qrImage && <img src={qrImage} alt="" height={200} width={200}/>}
        </div>
        <Typography variant="h3" dangerouslySetInnerHTML={{
          __html: props?.user?.isTotpEnabled ?
            t('totp_configuration.modal.status_is_enabled') :
            t('totp_configuration.modal.status_is_disabled'),
        }}
        />
      </div>
      <div className="section-actions">

        <div className="action">
          <Button
            variant="outlined"
            onClick={()=>props.onCloseRequest(isTotpEnabled)}
          >
            {t('totp_configuration.modal.action.cancel')}
          </Button>
        </div>

        {!isTotpEnabled &&
          <div className="action">
            <Input
              value={confirmationCode}
              placeholder={t('totp_configuration.modal.enable_code_placeholder')}
              sx={{marginRight: '0.5em', width: '8em'}}
              error={confirmationCodeHasError}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={enableSecondFactor}
            >
              {t('totp_configuration.modal.action.enable')}
            </Button>
            {confirmationCodeHasError &&
              <Typography variant="smallText" component="div" color={'error'}>{t('totp_configuration.modal.feedback.incorrect_code')}</Typography>
            }
          </div>
        }

        {isTotpEnabled &&
          <div className="action">
            <Button
              variant="contained"
              onClick={disableSecondFactor}
            >
              {t('totp_configuration.modal.action.disable')}
            </Button>
          </div>
        }
      </div>
    </ModalDialog>
  );
}

TotpConfigurationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
