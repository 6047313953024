import Api from '../../../helpers/api/Api';

export default function getNotes(parentIri) {
  return new Promise(function(resolve, reject) {
    if (typeof parentIri !== 'string' || parentIri.length === 0) {
      reject(new Error('Unrecognized parent IRI'));
    }

    const deconstructedIri = parentIri.match(/\/api\/([^\/]*)\/([\d]*)/);
    const url = `/${deconstructedIri[1]}/${deconstructedIri[2]}/notes`;

    Api.get(url).then((data)=> {
      if (Array.isArray(data.notes)) {
        resolve(data.notes);
      } else {
        reject(new Error('Malformed API response, expected notes array'));
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
