import './RecentActivityViewer.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import BasicCard from '../Card/Card';
import AllActivitiesModal from './AllActivitiesModal';
import RecentActivityItem from './RecentActivityItem';

const MAX_RESULTS_ON_SHORT_LIST = 3;

export default function RecentActivityViewer(props) {
  const {t, i18n} = useTranslation('component_recent_activity');
  const [isAllActivitiesOpened, setAllActivitiesOpened] = useState(false);

  function onItemClicked(activityId) {
    if (typeof props.onItemClicked === 'function') {
      const activity = props.activities.find((a) => a['@id'] === activityId);
      setAllActivitiesOpened(false);
      props.onItemClicked(activityId, activity);
    }
  }

  function seeMoreClicked() {
    setAllActivitiesOpened(true);
  }

  return (
    <BasicCard
      className={`RecentActivityViewer ${props.className ?? '' }`}
      title={t('panel_title')}
      appendToFooter={<>
        <CardActions
          className="BasicCard-footer"
          sx={{marginTop: 'auto', textAlign: 'right'}}
        >
          <Button size={'small'} sx={{paddingLeft: '15px'}} onClick={seeMoreClicked}>{props.showSeeMore || props.activities.length > MAX_RESULTS_ON_SHORT_LIST ? t('see_more') : null}</Button>
        </CardActions>
      </>}
    >
      {props.activities.filter((activity, activityIdx) => activityIdx < MAX_RESULTS_ON_SHORT_LIST).map((activity, activityIdx) => {
        const isLast = props.activities.length - activityIdx === 1;
        return (
          <RecentActivityItem
            activity={activity}
            key={activity['@id']}
            iri={activity['@id']}
            author={activity.createdBy && activity.createdBy.display}
            content={activity.text ?? `[[${activity.name}]`}
            time={formatDateTimestamp(activity.createdAt)}
            withUnderline={!isLast}
            goTo={props.disableGoTo !== true && activity.relatedProject !== null && typeof activity.relatedProject === 'object'}
            onClick={onItemClicked}
          />
        );
      })
      }
      {!props.isLoading && props.activities.length === 0 &&
        <Typography fontStyle="italic" fontSize="small" align="center" color="lightgray" sx={{marginTop: '32px'}}>{t('no_result')}</Typography>
      }
      {props.isLoading &&
        <ProgressIndicator />
      }
      <AllActivitiesModal
        isOpen={isAllActivitiesOpened}
        onClose={()=>setAllActivitiesOpened(false)}
        activities={props.activities}
        showSeeMore={props.showSeeMore}
        onSeeMoreClicked={props.onSeeMoreClicked}
        disableGoTo={props.disableGoTo}
        onClick={onItemClicked}
      />
    </BasicCard>
  );
}

RecentActivityViewer.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  onSeeMoreClicked: PropTypes.func.isRequired,
  onItemClicked: PropTypes.func,
  className: PropTypes.string,
  disableGoTo: PropTypes.bool,
};
