import LoginLayout from '../../layouts/LoginLayout';
import AddingLeadsAutomaticallyForm from './AddingLeadsAutomaticallyForm';

export function AddingLeadsAutomaticallyPage() {
  return (
    <LoginLayout>
      <AddingLeadsAutomaticallyForm />
    </LoginLayout>
  );
}
