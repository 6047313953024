import menuConfig from '../../menu_config.json';

export const CAPABILITY_VIEW = 'CAPABILITY_VIEW';
export const CAPABILITY_RECENT_ACTIVITY = 'CAPABILITY_RECENT_ACTIVITY';
export const CAPABILITY_TODO_LIST = 'CAPABILITY_TODO_LIST';
export const CAPABILITY_TODO_LIST_EDIT = 'CAPABILITY_TODO_LIST_EDIT';
export const CAPABILITY_EDIT = 'CAPABILITY_EDIT';
export const CAPABILITY_DOCUMENTS_WRITE = 'CAPABILITY_DOCUMENTS_WRITE';
export const CAPABILITY_DOCUMENTS_READ = 'CAPABILITY_DOCUMENTS_READ';
export const CAPABILITY_MERGE = 'CAPABILITY_MERGE';
export const CAPABILITY_PURGE = 'CAPABILITY_PURGE';
export const CAPABILITY_EDIT_ONLY_NOTES = 'CAPABILITY_EDIT_ONLY_NOTES';
export const CAPABILITY_EDIT_ALL_NOTES = 'CAPABILITY_EDIT_ALL_NOTES';
export const CAPABILITY_EDIT_EXCLUDE_HANDLER = 'CAPABILITY_EDIT_EXCLUDE_HANDLER';
export const CAPABILITY_ASSIGN_PROJECT_USER = 'CAPABILITY_ASSIGN_PROJECT_USER';
export const CAPABILITY_VIEW_GLOBAL_STATISTICS = 'CAPABILITY_VIEW_GLOBAL_STATISTICS';
export const CAPABILITY_VIEW_ALL_RECENT_ACTIVITY = 'CAPABILITY_VIEW_ALL_RECENT_ACTIVITY';
export const CAPABILITY_VIEW_ALL_ATTACHMENTS = 'CAPABILITY_VIEW_ALL_ATTACHMENTS';
export const CAPABILITY_VIEW_PERSONALIZED_STATISTICS = 'CAPABILITY_VIEW_PERSONALIZED_STATISTICS';
export const CAPABILITY_VIEW_PERSONAL_PROJECTS = 'CAPABILITY_VIEW_PERSONAL_PROJECTS';
export const CAPABILITY_VIEW_PERSONAL_ACTIVITIES = 'CAPABILITY_VIEW_PERSONAL_ACTIVITIES';
export const CAPABILITY_VIEW_PERSONAL_ATTACHMENTS = 'CAPABILITY_VIEW_PERSONAL_ATTACHMENTS';
export const CAPABILITY_VIEW_ALL_TASKS = 'CAPABILITY_VIEW_ALL_TASKS';
export const CAPABILITY_ASSIGN_SECURITY_CONTEXTS = 'CAPABILITY_ASSIGN_SECURITY_CONTEXTS';

const POSSIBLE_CAPABILITIES = [
  CAPABILITY_VIEW,
  CAPABILITY_RECENT_ACTIVITY,
  CAPABILITY_TODO_LIST,
  CAPABILITY_TODO_LIST_EDIT,
  CAPABILITY_EDIT,
  CAPABILITY_DOCUMENTS_WRITE,
  CAPABILITY_DOCUMENTS_READ,
  CAPABILITY_MERGE,
  CAPABILITY_PURGE,
  CAPABILITY_EDIT_ONLY_NOTES,
  CAPABILITY_EDIT_ALL_NOTES,
  CAPABILITY_EDIT_EXCLUDE_HANDLER,
  CAPABILITY_ASSIGN_PROJECT_USER,
  CAPABILITY_VIEW_GLOBAL_STATISTICS,
  CAPABILITY_VIEW_ALL_RECENT_ACTIVITY,
  CAPABILITY_VIEW_ALL_ATTACHMENTS,
  CAPABILITY_VIEW_PERSONALIZED_STATISTICS,
  CAPABILITY_VIEW_PERSONAL_PROJECTS,
  CAPABILITY_VIEW_PERSONAL_ACTIVITIES,
  CAPABILITY_VIEW_PERSONAL_ATTACHMENTS,
  CAPABILITY_VIEW_ALL_TASKS,
  CAPABILITY_ASSIGN_SECURITY_CONTEXTS,
];

export default function hasCapability(user, cap, slug) {
  if (!user) return false;
  if (typeof cap !== 'string' || POSSIBLE_CAPABILITIES.indexOf(cap) < 0) return false;

  const slugDefinition = menuConfig.find((item) => item.slug===slug);
  if (!slugDefinition) return false;

  const capsDefinition = slugDefinition.capabilities[cap];
  if (!capsDefinition) return false;

  return capsDefinition.includes(user.primaryRole);
}
