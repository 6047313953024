import Api from '../../../helpers/api/Api';

export default function getUserRecentAttachments(page, itemsPerPage, showAll, showOnlyMine, sortByDate) {
  return new Promise(function(resolve, reject) {
    const urlParts = [
      `page=${page}`,
      `itemsPerPage=${itemsPerPage}`,
      showAll ? '' : 'onlyStakeholder=true',
      showOnlyMine ? `showOnlyMine=true` : '',
      sortByDate ? 'order[updatedAt]=desc' : 'order[name]=asc',
    ].filter((i)=>(!!i));

    const url = `/attachments/dictionary?${urlParts.join('&')}`;

    Api.get(url).then((data)=>{
      if (Array.isArray(data['hydra:member'])) {
        resolve({
          attachments: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
        });
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
