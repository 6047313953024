import './AllNotesModal.scss';

import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Item from '../../components/Item/Item';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';

export default function AllNotesModal(props) {
  const {t} = useTranslation('component_notes');
  return (
    <ModalDialog
      className="AllNotesModal"
      open={props.isModalOpened}
      onCloseRequest={props.closeModal}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('modal_title')}</Typography>
        </div>
        <div className="modal-content">
          {props.notes.map((note, noteIdx) =>
            <Item
              key={note['@id']}
              className="NoteItem"
              iri={note['@id']}
              author={note.author?.display}
              content={note.text}
              time={formatDateTimestamp(note.createdAt)}
              withUnderline={!!props.notes[noteIdx+1]}
              onClick={() => props.openSingleNoteRequest(note)}
              transparent={true}
            />,
          )}
          {props.isLoading &&
                <ProgressIndicator />
          }
        </div>
      </>
    </ModalDialog>
  );
}

AllNotesModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(
      PropTypes.shape({
        'id': PropTypes.number.isRequired,
        '@id': PropTypes.string.isRequired,
        '@type': PropTypes.oneOf(['Note']).isRequired,
      }),
  ),
  openSingleNoteRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
