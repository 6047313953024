// @ts-ignore
import Api from '../../api/Api';
import AbstractCachedDictionary from './AbstractCachedDictionary';

const ITEMS_PER_PAGE = 1000000;

class MoneySuppliersCachedDictionary extends AbstractCachedDictionary {
  static uniqueKey(): string {
    return 'MoneySuppliersCachedDictionary';
  }

  protected getUniqueKey(): string {
    return MoneySuppliersCachedDictionary.uniqueKey();
  }

  getTimeToLiveMiliseconds(): number {
    return 60000;
  }

  fetchNewDataset(params: {[key:string]:any}|null = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = AbstractCachedDictionary.joinUrl(
          '/companies/dictionary',
          [
            `itemsPerPage=${ITEMS_PER_PAGE}`,
            'type=money_supplier',
          ],
      );
      // @ts-ignore
      Api.get(url).then((data)=>{
        resolve(data['hydra:member']);
        // @ts-ignore
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static getInstance(): AbstractCachedDictionary {
    return AbstractCachedDictionary.getOrCreateInstance(
        MoneySuppliersCachedDictionary.uniqueKey(),
        () => ( new MoneySuppliersCachedDictionary() ),
    );
  }
}

export default MoneySuppliersCachedDictionary;
