import Api from '../../../../helpers/api/Api';

export default function getProjectNotes(id) {
  return new Promise(function(resolve, reject) {
    Api.get(`/projects/${id}/notes`).then((data)=> {
      resolve(data.notes);
    }).catch((error) => {
      reject(error);
    });
  });
}
