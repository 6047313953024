import Api from '../../../helpers/api/Api';

export default function patchUser(patchData) {
  return new Promise(function(resolve, reject) {
    const updatedPatchData = {
      ...patchData,
      securityContexts: patchData.securityContextsIds.map((id)=>`/api/security_contexts/${id}`),
    };

    Api.patch(`/users/${patchData.id}`, updatedPatchData).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
