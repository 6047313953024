import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

export const PARAM_SEARCH_NAME = 'search';
export const PARAM_STATUS_NAME = 'status';
export const PARAM_HANDLER_NAME = 'handler';
export const PARAM_SECURITY_CONTEXT_NAME = 'securityContext';
export const PARAM_ONLY_MINE_NAME = 'showOnlyMine';
export const PARAM_ORDER_BY_NAME = 'orderBy';
export const PARAM_ORDER_DIR_NAME = 'orderDir';
export const PARAM_PAGE_NAME = 'page';
export const PARAM_REFRESH_AT_NAME = 'refreshAt';

const PARAMS_DEFAULTS = {
  [PARAM_SEARCH_NAME]: '',
  [PARAM_STATUS_NAME]: '',
  [PARAM_HANDLER_NAME]: null,
  [PARAM_SECURITY_CONTEXT_NAME]: null,
  [PARAM_ONLY_MINE_NAME]: false,
  [PARAM_ORDER_BY_NAME]: 'createdAt',
  [PARAM_ORDER_DIR_NAME]: 'desc',
  [PARAM_PAGE_NAME]: 1,
  [PARAM_REFRESH_AT_NAME]: null,
};
export default function useProjectsListFilters() {
  const [urlParams, setUrlParams] = useSearchParams();

  const paramSearch = urlParams.has(PARAM_SEARCH_NAME) ? urlParams.get(PARAM_SEARCH_NAME) : PARAMS_DEFAULTS[PARAM_SEARCH_NAME];
  const paramStatus = urlParams.has(PARAM_STATUS_NAME) ? urlParams.get(PARAM_STATUS_NAME) : PARAMS_DEFAULTS[PARAM_STATUS_NAME];
  const paramHandler = urlParams.has(PARAM_HANDLER_NAME) ? urlParams.get(PARAM_HANDLER_NAME) : PARAMS_DEFAULTS[PARAM_HANDLER_NAME];
  const paramSecurityCtx = urlParams.has(PARAM_SECURITY_CONTEXT_NAME) ? urlParams.get(PARAM_SECURITY_CONTEXT_NAME) : PARAMS_DEFAULTS[PARAM_SECURITY_CONTEXT_NAME];
  const paramOnlyMine = urlParams.has(PARAM_ONLY_MINE_NAME) ? urlParams.get(PARAM_ONLY_MINE_NAME) : PARAMS_DEFAULTS[PARAM_ONLY_MINE_NAME];
  const paramOrderBy = urlParams.has(PARAM_ORDER_BY_NAME) ? urlParams.get(PARAM_ORDER_BY_NAME) : PARAMS_DEFAULTS[PARAM_ORDER_BY_NAME];
  const paramOrderDir = urlParams.has(PARAM_ORDER_DIR_NAME) ? urlParams.get(PARAM_ORDER_DIR_NAME) : PARAMS_DEFAULTS[PARAM_ORDER_DIR_NAME];
  const paramPage = urlParams.has(PARAM_PAGE_NAME) ? urlParams.get(PARAM_PAGE_NAME) : PARAMS_DEFAULTS[PARAM_PAGE_NAME];
  const paramRefreshAt = urlParams.has(PARAM_REFRESH_AT_NAME) ? urlParams.get(PARAM_REFRESH_AT_NAME) : PARAMS_DEFAULTS[PARAM_REFRESH_AT_NAME];

  const current = useMemo(()=>{
    return {
      [PARAM_SEARCH_NAME]: paramSearch,
      [PARAM_STATUS_NAME]: paramStatus,
      [PARAM_HANDLER_NAME]: paramHandler,
      [PARAM_SECURITY_CONTEXT_NAME]: paramSecurityCtx,
      [PARAM_ONLY_MINE_NAME]: paramOnlyMine,
      [PARAM_ORDER_BY_NAME]: paramOrderBy,
      [PARAM_ORDER_DIR_NAME]: paramOrderDir,
      [PARAM_PAGE_NAME]: parseInt(paramPage),
      [PARAM_REFRESH_AT_NAME]: paramRefreshAt,
    };
  }, [paramHandler, paramOnlyMine, paramOrderBy, paramOrderDir, paramPage, paramRefreshAt, paramSearch, paramSecurityCtx, paramStatus]);

  const setParams = useCallback((params)=>{
    const newUrlParams = new URLSearchParams(urlParams);
    let changed = false;
    for (const paramName in params) {
      if (current[paramName] !== params[paramName]) {
        if (params[paramName] === PARAMS_DEFAULTS[paramName]) {
          newUrlParams.delete(paramName);
        } else {
          newUrlParams.set(paramName, params[paramName]);
        }
        changed=true;
      }
    }
    if (changed) {
      setUrlParams(newUrlParams);
    }
  }, [current, setUrlParams, urlParams]);

  return {
    current,
    setParams,
  };
}
