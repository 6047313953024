import i18n from '../../i18n';

export default function formatMoney(floatVal, currencyCode = 'EUR') {
  if (isNaN(floatVal)) return floatVal;


  const locale = i18n.t('locale', {ns: 'formatting'}) === 'formatting:locale' ?
    'en-US' :
    i18n.t('locale', {ns: 'formatting'});

  const formatter = new Intl.NumberFormat(
      locale,
      {
        style: 'currency',
        currency: currencyCode,
      },
  );
  return formatter.format(floatVal);
}
