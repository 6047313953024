import Api from '../../../helpers/api/Api';
import prepareCompaniesListUrlParameters from '../functions/prepareCompaniesListUrlParameters';

export default function getCompaniesListExport(
    search, sorting, group, isPersonal, page, itemsPerPage, securityContextId,
) {
  return new Promise(function(resolve, reject) {
    const url = prepareCompaniesListUrlParameters('/companies/export', search, sorting, group, isPersonal, page, itemsPerPage, securityContextId);

    Api.getBinary(url).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
