import './Overview.scss';

import {Box, Button, Typography} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import Switch from '../../components/Switch/Switch';
import Tasks from '../../components/TasksViewer/Tasks';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {useIsAdministrator} from '../../customHooks/useIsAdministrator';
import Api from '../../helpers/api/Api';
import formatDateHuman from '../../helpers/formatters/formatDateHuman';
import {CAPABILITY_RECENT_ACTIVITY, CAPABILITY_TODO_LIST, CAPABILITY_VIEW_PERSONAL_ACTIVITIES, CAPABILITY_VIEW_PERSONAL_ATTACHMENTS} from '../../helpers/security/hasCapability';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {useStateValue} from '../../state/state';
import getUserOverviewData from './api/getUserOverviewData';
import CardUserRecentAttachments from './CardUserRecentAttachments';
import CardUserStakeholderRecentActivity from './CardUserStakeholderRecentActivity';
import CardUserStatistics from './CardUserStatistics';

export default function OverviewPage() {
  const {t, i18n} = useTranslation('page_overview');
  const [{user, navTarget}, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [searchString, setSearchString] = useState('');

  const [todayString, setTodayString] = useState('');

  const canViewRecentActivity = useHasCapabilityHere(CAPABILITY_RECENT_ACTIVITY);
  const canViewTodoList = useHasCapabilityHere(CAPABILITY_TODO_LIST);

  const hasViewPersonalActivitiesCapability = useHasCapabilityHere(CAPABILITY_VIEW_PERSONAL_ACTIVITIES);
  const hasViewPersonalAttachmentsCapability = useHasCapabilityHere(CAPABILITY_VIEW_PERSONAL_ATTACHMENTS);

  const [searchParams, setSearchParams] = useSearchParams();
  const isAdministrator = useIsAdministrator();
  const showOnlyMine = searchParams.get('showOnlyMine') !== null ?
    searchParams.get('showOnlyMine')==='true' || searchParams.get('showOnlyMine')==='1' :
    !isAdministrator && hasViewPersonalActivitiesCapability && hasViewPersonalAttachmentsCapability;

  const userId = user && user.id;
  useEffect(function() {
    if (userId) {
      setIsLoading(true);
      Api.get(`/users/${userId}`).then((data) => {
        setUserData(data);
        setIsLoading(false);
      });
    }
  }, [userId]);

  useEffect(function() {
    if (userId) {
      getUserOverviewData().then((data)=>{
        setTodayString(data.date);
      });
    }
  }, [userId]);

  function setShowOnlyMine(newValue) {
    searchParams.set('showOnlyMine', newValue ? 'true' : 'false');
    setSearchParams(searchParams);
  }

  function onSearch(term) {
    setSearchString(term);
  }

  if (isLoading) {
    return (
      <DefaultLayout>
        <ProgressIndicator stretch={true} />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <div className="Overview-wrapper">
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Typography variant="h1" sx={{fontSize: 32, padding: '8px 0', fontWeight: '500'}} component="div">
              {getWelcome(user, t)}
            </Typography>

            <div className="date">{formatDateHuman(new Date())}</div>
          </Box>
          <GlobalSearch placeholder={t('search.placeholder')} callback={onSearch} page={1} />
        </Box>

        <CardUserStatistics user={user} />

        <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'baseline', margin: '0 0 0 10px'}}>
          <Box sx={{marginTop: '24px'}}><Switch checked={showOnlyMine} onChange={()=>setShowOnlyMine(!showOnlyMine)} label={t('filter.only_mine')} /></Box>
        </Box>

        <div className="lists-grid">
          {canViewRecentActivity &&
              <div className="grid-member">
                <CardUserStakeholderRecentActivity user={userData} showOnlyMine={showOnlyMine} />
              </div>
          }
          {canViewTodoList &&
              <div className="grid-member">
                <Tasks
                  entity={userData}
                />
              </div>
          }
          <div className="grid-member">
            <CardUserRecentAttachments user={user} showOnlyMine={showOnlyMine} />
          </div>
        </div>

      </div>
    </DefaultLayout>
  );
}

function getWelcome(user, translator) {
  const hour = (new Date()).getHours();
  if (hour >=5 && hour <=11) {
    return translator('welcome.from5to11', {username: user.display.toUpperCase()});
  } else if (hour >=12 && hour <=17) {
    return translator('welcome.from12to17', {username: user.display.toUpperCase()});
  } else if (hour >=12 && hour <=17) {
    return translator('welcome.from18to23', {username: user.display.toUpperCase()});
  } else {
    return translator('welcome.other', {username: user.display.toUpperCase()});
  }
}
