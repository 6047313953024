import PropTypes from 'prop-types';
import React from 'react';

import CompanyAddress from './components/CompanyAddress';
import CompanyEmployee from './components/CompanyEmployee';
import CompanyName from './components/CompanyName';
import css from './index.module.scss';

function TypeClient({projectCompany, projectUsers, primaryProjectUser}) {
  return (
    <div className={css.CompanyType}>
      <div>
        <CompanyName projectCompany={projectCompany} />
        <CompanyAddress projectCompany={projectCompany} multiline={true} />
      </div>
      <div className={css.spacer} />
      <div className={css.details}>
        {projectUsers && projectUsers.map((pu)=>(
          <CompanyEmployee
            key={pu['@id']}
            projectUser={pu}
            isPrimary={primaryProjectUser['@id']===pu['@id']}
            company={projectCompany?.company}
          />
        ))}
      </div>
    </div>
  );
}

TypeClient.propTypes = {
  projectCompany: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectCompany']).isRequired,
    'company': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['Company']),
    }).isRequired,
  }),
  projectUsers: PropTypes.arrayOf(PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectUser']).isRequired,
  })),
  primaryProjectUser: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectUser']).isRequired,
  }),
};

export default TypeClient;
