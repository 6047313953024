import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ShowSingleTaskModal from '../../../components/ShowSingleTaskModal/ShowSingleTaskModal';
import deleteTask from './api/deleteTask';
import getTask from './api/getTask';
import patchTask from './api/patchTask';

function SingleTaskModal({taskIri, onAborted, onRemoved, onUpdated}) {
  const {t} = useTranslation('page_tasks');

  const [task, setTask] = useState(null);

  useEffect(()=>{
    if (typeof taskIri === 'string' && taskIri.includes('/api/tasks/')) {
      getTask(taskIri).then((fullTaskData) => {
        setTask(fullTaskData);
      });
    } else {
      setTask(null);
    }
  }, [taskIri]);

  const removeTask = (taskId) => {
    if (window.confirm(t('confirmation.deleteTask'))) {
      deleteTask(taskId).then(() => {
        onRemoved();
      });
    }
  };

  const changeTask = (taskId, newTitle, newText, newDeadline, newUsers, newDocuments, newProjects, newCompanies, newNotes, newTask, newExecutors, completedAt) => {
    patchTask(taskId, newTitle, newText, newDeadline, newUsers, newDocuments, newProjects, newCompanies, newNotes, newTask, newExecutors, completedAt).then(() => {
      onUpdated();
    });
  };

  return (
    <ShowSingleTaskModal
      handleShowSingleTaskPopupClose={onAborted}
      isShowSingleTaskModalOpen={!!task}
      taskInfo={task ?? {}}
      removeTask={removeTask}
      changeTask={changeTask}
    />
  );
}

SingleTaskModal.propTypes = {
  taskIri: PropTypes.string,
  onAborted: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default SingleTaskModal;
