import Api from '../../../helpers/api/Api';

export default function deleteAttachment(id) {
  return new Promise(function(resolve, reject) {
    Api.delete(`/attachments/${id}`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
