import React from 'react';
import {useTranslation} from 'react-i18next';

import Switch from '../../../../components/Switch/Switch';
import useUsersListFilters, {PARAM_ACTIVE, PARAM_PAGE} from '../../hooks/useUsersListFilters';
import css from './ActiveFilter.module.scss';

function ActiveFilter() {
  const {t} = useTranslation('page_users');
  const {current, setParams} = useUsersListFilters();

  const handleOnChange = () => {
    setParams({
      [PARAM_ACTIVE]: !current[PARAM_ACTIVE],
      [PARAM_PAGE]: 1,
    });
  };

  return (
    <div className={css.ActiveFilter}>
      <Switch
        checked={current[PARAM_ACTIVE]}
        onChange={handleOnChange}
        label={t('list.filters.active.label')}
      />
    </div>
  );
}

export default ActiveFilter;
