export default function status2type(status) {
  switch (status) {
    case 'lead_pending':
    case 'lead_rejected':
      return 'lead';
    case 'opportunity_trajectory':
    case 'opportunity_payment':
    case 'opportunity_memorandum_creating':
    case 'opportunity_memorandum_sent':
    case 'opportunity_resigned':
      return 'opportunity';
    case 'account_in_progress':
    case 'account_closed':
      return 'account';
    default:
      return null;
  }
}
