import {Input, OutlinedInput} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldUserPhone(props) {
  const value = props.value ?? {country: '', phone: ''};

  function callbackCountry(event) {
    const source = Object.assign({}, value);
    source.country = event.target.value;
    props.onChange(source);
  }
  function callbackPhone(event) {
    const source = Object.assign({}, value);
    source.phone = event.target.value;
    props.onChange(source);
  }
  const placeholder1 = Array.isArray(props.placeholder) ? props.placeholder[0] : 'Country code';
  const placeholder2 = Array.isArray(props.placeholder) ? props.placeholder[1] : 'Phone number';
  return (
    <>
      <OutlinedInput
        className="EditFieldUserPhone-country"
        size="small"
        value={value.country}
        onChange={callbackCountry}
        placeholder={placeholder1}
        sx={{
          width: props.typeOptions && props.typeOptions.width && props.typeOptions.width[0] ? props.typeOptions.width[0] : '25%',
        }}
        style={{
          fontSize: props.fontSize,
        }}
      />
      <OutlinedInput
        className="EditFieldUserPhone-phone"
        size="small"
        value={value.phone}
        onChange={callbackPhone}
        placeholder={placeholder2}
        sx={{
          width: props.typeOptions && props.typeOptions.width && props.typeOptions.width[1] ? props.typeOptions.width[1] : '75%',
          float: 'right',
        }}
        style={{
          fontSize: props.fontSize,
        }}
      />
    </>
  );
}

EditFieldUserPhone.propTypes = {
  value: PropTypes.any,
  typeOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
