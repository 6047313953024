import {
  PARAM_COMPANIES_WITH_TYPE, PARAM_PROJECTS_WITH_STATUS,
  PARAM_WITH_ATTACHMENTS,
  PARAM_WITH_NOTES,
  PARAM_WITH_TASKS,
  PARAM_WITH_USERS, PARAMS_DEFAULTS,
} from '../../hooks/useTasksListFilters';

const PARAMETERS = [
  PARAM_WITH_USERS,
  PARAM_WITH_ATTACHMENTS,
  PARAM_WITH_NOTES,
  PARAM_WITH_TASKS,
  PARAM_COMPANIES_WITH_TYPE, // money_supplier,intermediary,client,investor
  PARAM_PROJECTS_WITH_STATUS, // lead,opportunity,account
];

export function getCurrentSelection(paramsObj) {
  // only zero or one parameter can be non-default
  const nonDefaultParams = PARAMETERS.filter((name) => {
    return isNotDefault(name, paramsObj[name]);
  });

  if (nonDefaultParams.length === 1) {
    const name = nonDefaultParams.pop();
    switch (name) {
      case PARAM_WITH_USERS:
        return 'user';
      case PARAM_WITH_ATTACHMENTS:
        return 'document';
      case PARAM_WITH_NOTES:
        return 'note';
      case PARAM_WITH_TASKS:
        return 'task';
      default:
        return paramsObj[name];
    }
  } else {
    return '';
  }
}

export function createSelectionParams(currentSelection) {
  const response = {};
  PARAMETERS.forEach((name) => {
    response[name] = PARAMS_DEFAULTS[name].default;
  });
  switch (currentSelection) {
    case 'money_supplier':
      response[PARAM_COMPANIES_WITH_TYPE] = 'money_supplier';
      break;
    case 'intermediary':
      response[PARAM_COMPANIES_WITH_TYPE] = 'intermediary';
      break;
    case 'client':
      response[PARAM_COMPANIES_WITH_TYPE] = 'client';
      break;
    case 'investor':
      response[PARAM_COMPANIES_WITH_TYPE] = 'investor';
      break;
    case 'lead':
      response[PARAM_PROJECTS_WITH_STATUS] = 'lead';
      break;
    case 'opportunity':
      response[PARAM_PROJECTS_WITH_STATUS] = 'opportunity';
      break;
    case 'account':
      response[PARAM_PROJECTS_WITH_STATUS] = 'account';
      break;
    case 'user':
      response[PARAM_WITH_USERS] = true;
      break;
    case 'document':
      response[PARAM_WITH_ATTACHMENTS] = true;
      break;
    case 'note':
      response[PARAM_WITH_NOTES] = true;
      break;
    case 'task':
      response[PARAM_WITH_TASKS] = true;
      break;
    default:
  }
  return response;
}

function isNotDefault(name, value) {
  return typeof PARAMS_DEFAULTS[name] === 'undefined' || PARAMS_DEFAULTS[name].default !== value;
}
