export const ACTION_SET_USER = 'ACTION_SET_USER';
export const ACTION_SET_USER_FIELD = 'ACTION_SET_USER_FIELD';
export const ACTION_SET_UNREAD_NOTIFICATIONS_COUNT = 'ACTION_SET_UNREAD_NOTIFICATIONS_COUNT';

export function setUser(user) {
  return {
    type: ACTION_SET_USER,
    data: {
      ...user,
      '@id': `/api/users/${user.id}`,
      '@type': 'User',
    },
  };
}

export function setUserField(field, value) {
  return {
    type: ACTION_SET_USER_FIELD,
    data: {field, value},
  };
}

export function setUnreadNotificationsCount(count) {
  return {type: ACTION_SET_UNREAD_NOTIFICATIONS_COUNT, data: count};
}
