import Api from '../../../../helpers/api/Api';

export default function patchProjectCompanies(projectId, sourceProjectCompanies, modifications = []) {
  return new Promise(function(resolve, reject) {
    const numericProjectId = typeof projectId === 'string' ?
      parseInt(projectId.replace('/api/projects/', '')) :
      parseInt(projectId);

    if (isNaN(numericProjectId) || numericProjectId<=0) {
      reject(new Error('Unrecognized projectId'));
    }


    const projectCompaniesNewFormat = [];
    sourceProjectCompanies.forEach((pc)=>{
      pc.roles.forEach((projectCompanyRole) => {
        const transformed = {
          company: typeof pc?.company === 'string' ? pc.company : pc?.company['@id'],
          role: projectCompanyRole,
        };
        if (projectCompanyRole === 'money_supplier') {
          transformed.options = {amount: pc?.amount};
        }
        projectCompaniesNewFormat.push(transformed);
      });
    });

    modifications.forEach((mod)=>{
      const existing = projectCompaniesNewFormat.find((item)=>(
        item.company===mod.company && item.role===mod.type
      ));

      if (existing && mod.delete) {
        existing.remove = true;
      } else if (existing && mod.amount) {
        existing.options = {amount: parseInt(mod.amount)};
      } else {
        projectCompaniesNewFormat.push({
          company: mod.company,
          role: mod.type,
          options: {amount: parseInt(mod.amount)},
        });
      }
    });

    const data = {companies: projectCompaniesNewFormat.filter((i)=>!!i.company)};

    Api.patch(`/projects/${numericProjectId}/companies`, data).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
