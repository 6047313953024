import './Tag.scss';

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

export default function Tag(props) {
  const {t} = useTranslation('dictionary_tags');

  const sx = {
    color: props.tag.colorText ? props.tag.colorText : 'inherit',
    backgroundColor: props.tag.colorBackground ? props.tag.colorBackground : 'inherit',
  };

  const component = props.component ?? 'span';
  return (
    <Typography
      variant={props.small ? 'tinyTag' : 'smallText'}
      component={component}
      className="Tag" sx={sx}>
      #{t(props.tag.name)}
    </Typography>
  );
}

Tag.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    colorText: PropTypes.string,
    colorBackground: PropTypes.string,
  }),
  component: PropTypes.string,
  small: PropTypes.bool,
};
