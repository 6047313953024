import './DefaultLayout.scss';

import React from 'react';

import LeftMenu from '../../components/LeftMenu/LeftMenu';
import NavBar from '../../components/NavBar/NavBar';

export default function DefaultLayout(props) {
  return (
    <div className="DefaultLayout">
      <div className="DefaultLayout-left">
        <LeftMenu />
      </div>
      <div className="DefaultLayout-right">
        <div className="DefaultLayout-navbar">
          <NavBar />
        </div>
        <div className="DefaultLayout-contents">
          {props.children}
        </div>
      </div>
    </div>
  );
}
