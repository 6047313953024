import {useEffect, useState} from 'react';

import getResourceResponseStatus from './api/getResourceResponseStatus';

export default function useCanShowOutgoingLink(link) {
  const [isAccessible, setIsAccessible] = useState(false);

  useEffect(()=>{
    const apiLink = getResourceApiLink(link);
    if (apiLink) {
      getResourceResponseStatus(apiLink).then(()=>{
        setIsAccessible(true);
      }).catch(()=>{
        setIsAccessible(false);
      });
    }
  }, [link]);

  return isAccessible;
}

function getResourceApiLink(link) {
  const linkParts = link.split('/').filter((p)=>(!!p));
  if (isCompanyResource(linkParts)) {
    return `/companies/${linkParts[1]}`;
  } else {
    return null;
  }
}

function isCompanyResource(parts) {
  return (parts.length===2 || parts.length===4) && parts[0]==='companies' && !isNaN(parseInt(parts[1]));
}
