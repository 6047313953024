export default function filterActiveUserSecurityContexts(values, userSecurityContexts) {
  return userSecurityContexts.filter((ctx) => {
    return Array.isArray(values) && values.filter((val) => {
      if (typeof val === 'string') {
        return val === ctx['@id'];
      } else if (typeof val === 'number') {
        return val === ctx.id;
      } else {
        return false;
      }
    }).length > 0;
  });
}
