import Api from '../../../helpers/api/Api';

export default function postDisableTotp(userId) {
  return new Promise(function(resolve, reject) {
    Api.post(`/users/${userId}/disable_totp`, {}).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
