import Api from '../../../helpers/api/Api';

export default function postNote(parentIri, note) {
  return new Promise(function(resolve, reject) {
    if (typeof parentIri !== 'string' || parentIri.length === 0 || !note) {
      reject(new Error('Unrecognized parent IRI'));
    }

    const deconstructedIri = parentIri.match(/\/api\/([^\/]*)\/([\d]*)/);
    const url = `/${deconstructedIri[1]}/${deconstructedIri[2]}/note`;


    Api.post(url, note).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
