import './MergeProjectsModal.scss';

import {Autocomplete, Button, Dialog, DialogActions, DialogTitle, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import getProjectNormalizedInternalName from '../../helpers/formatters/getProjectNormalizedInternalName';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';
import getProjectsDictionary from './api/getProjectsDictionary';
import postMergeProjects from './api/postMergeProjects';

export default function MergeProjectsModal(props) {
  const {t, i18n} = useTranslation('page_cases');
  const [isDictionaryLoading, setDictionaryLoading] = useState(true);
  const [isMergingNow, setMergingNow] = useState(false);
  const [isFinalWarningOpen, setFinalWarningOpen] = useState(false);

  const [projectsDictionary, setProjectsDictionary] = useState([]);

  const [primaryProject, setPrimaryProject] = useState(null);
  const [secondaryProject, setSecondaryProject] = useState(null);

  const subjectInternalName = getProjectNormalizedInternalName(props.subject);

  useEffect(()=>{
    if (props.isOpen) {
      setDictionaryLoading(true);
      getProjectsDictionary().then((data) => {
        setProjectsDictionary(
            data.filter((project)=>(
              project['@id']!==props.subject['@id']
            )).map((project) => (
              {label: `${project.dropdownIdentifier} #${project.id}`, id: project['@id'], name: getProjectNormalizedInternalName(project)}
            )),
        );
        setDictionaryLoading(false);
      });
    }
  }, [props.isOpen]);

  function handlePickPrimary(event, option) {
    setPrimaryProject(option);
    setSecondaryProject(null);
  }

  function handlePickSecondary(event, option) {
    setPrimaryProject(null);
    setSecondaryProject(option);
  }

  function onClickMergePrimary() {
    setFinalWarningOpen(true);
  }

  function onClickMergeSecondary() {
    setFinalWarningOpen(true);
  }

  function onClickFinalMerge() {
    setMergingNow(true);
    const primary = primaryProject !== null ? primaryProject.id : props.subject['@id'];
    const secondary = secondaryProject !== null ? secondaryProject.id : props.subject['@id'];
    postMergeProjects(primary, secondary).then(()=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      if (primaryProject === null) {
        props.onClose(true);
      } else {
        const target = projectsDictionary.filter((project)=>project['@id']===primaryProject.id)[0];
        if (target) {
          props.onClose(getFrontendPathToObject(target));
        } else {
          props.onClose(true);
        }
      }
    }).catch((error)=>{
      setMergingNow(false);
      setFinalWarningOpen(false);
      console.error(error);
    });
  }

  const selectedPrimaryName = primaryProject !== null ?
    projectsDictionary.find((p)=>p.id===primaryProject.id)?.name :
    getProjectNormalizedInternalName(props.subject)
  ;
  const selectedSecondaryName = secondaryProject !== null ?
    projectsDictionary.find((p)=>p.id===secondaryProject.id)?.name :
    getProjectNormalizedInternalName(props.subject)
  ;

  return (
    <ModalDialog
      className="MergeProjectsModal"
      open={props.isOpen}
      onCloseRequest={props.onClose}
    >
      <>
        <div className="modal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('merge_modal.title')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="warning">{t('merge_modal.warning')}</Typography>
          <Typography variant="smallHint">{t('merge_modal.hint')}</Typography>
        </div>
        <div className="modal-content">
          <Typography className="decision-title" variant="h3">{t('merge_modal.subtitle')}</Typography>
          <Typography fontWeight="600">{t('merge_modal.option1.header', {name: subjectInternalName})}</Typography>
          <Typography>{t('merge_modal.option1.body', {name: subjectInternalName})}</Typography>
          {isDictionaryLoading && <ProgressIndicator />}
          {!isDictionaryLoading &&
            <div className="project-selection selection-secondary">
              <Autocomplete
                className="projects-dropdown-autocomplete"
                disablePortal={true}
                size="small"
                id="merge-projects-pick-secondary"
                options={projectsDictionary}
                getOptionLabel={(option)=>option.label}
                renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
                renderInput={(params) => <TextField {...params} label={t('merge_modal.option1.placeholder')} />}
                noOptionsText={t('merge_modal.option1.no_results')}
                value={secondaryProject}
                onChange={handlePickSecondary}
              />
              <Button
                className="merge-action"
                disabled={secondaryProject === null}
                variant="outlined"
                onClick={onClickMergeSecondary}
              >
                {t('merge_modal.option1.button')}
              </Button>
            </div>
          }
          <Typography fontWeight="600">{t('merge_modal.option2.header', {name: subjectInternalName})}</Typography>
          <Typography>{t('merge_modal.option2.body', {name: subjectInternalName})}</Typography>
          {isDictionaryLoading && <ProgressIndicator />}
          {!isDictionaryLoading &&
            <div className="project-selection selection-primary">
              <Autocomplete
                className="projects-dropdown-autocomplete"
                disablePortal={true}
                size="small"
                id="merge-projects-pick-primary"
                options={projectsDictionary}
                getOptionLabel={(option)=>option.label}
                renderOption={(props, option)=><Typography {...props}>{option.label}</Typography>}
                renderInput={(params) => <TextField {...params} label={t('merge_modal.option2.placeholder')}/>}
                noOptionsText={t('merge_modal.option2.no_results')}
                value={primaryProject}
                onChange={handlePickPrimary}
              />
              <Button
                className="merge-action"
                disabled={primaryProject === null}
                variant="outlined"
                onClick={onClickMergePrimary}
              >
                {t('merge_modal.option2.button')}
              </Button>
            </div>
          }
        </div>
        <Dialog
          open={isFinalWarningOpen}
          onClose={() => setFinalWarningOpen(false)}
        >
          {isMergingNow && <ProgressIndicator />}
          {!isMergingNow &&
            <>
              <DialogTitle>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.secondary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedSecondaryName}</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.primary_prefix')}</Typography>
                <Typography variant="span" fontWeight={600}>{selectedPrimaryName}?</Typography>
                <Typography variant="span" fontWeight={400}>{t('merge_modal.final.primary_suffix')}</Typography>
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setFinalWarningOpen(false)} variant="outlined" color="error">{t('merge_modal.final.button_cancel')}</Button>
                <Button onClick={onClickFinalMerge} variant="outlined">{t('merge_modal.final.button_confirm')}</Button>
              </DialogActions>
            </>
          }
        </Dialog>
      </>
    </ModalDialog>
  );
}

MergeProjectsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subject: PropTypes.object.isRequired,
};
