import PropTypes from 'prop-types';

import AddingTaskModal from '../AddingTaskModal/AddingTaskModal';
import ShowSingleTaskModal from '../ShowSingleTaskModal/ShowSingleTaskModal';
import AllTasksModal from './AllTasksModal';
import deleteTask from './api/deleteTask';
import patchTask from './api/patchTask';

const TasksModals = (props) => {
  const handleAddingTaskPopupClose = () => {
    props.closeModal('isAddingTaskModalOpen');
  };

  const handleShowSingleTaskPopupClose = () => {
    props.closeModal('isShowSingleTaskModalOpen');
  };

  const removeTask = (taskId) => {
    deleteTask(taskId).then(() => {
      props.refreshTasksList();
      handleShowSingleTaskPopupClose();
    });
  };

  const changeTask = (
      taskId,
      newTitle,
      newText,
      newDeadline,
      newUsers,
      newDocuments,
      newProjects,
      newCompanies,
      newNotes,
      newTask,
      newExecutors,
      completedAt,
  ) => {
    patchTask(
        taskId,
        newTitle,
        newText,
        newDeadline,
        newUsers,
        newDocuments,
        newProjects,
        newCompanies,
        newNotes,
        newTask,
        newExecutors,
        completedAt,
    ).then(() => {
      props.refreshTasksList();
      handleShowSingleTaskPopupClose();
    });
  };

  return (
    <>
      <AddingTaskModal
        isAddingTaskModalOpen={props.modalState.isAddingTaskModalOpen}
        handleAddingTaskPopupClose={handleAddingTaskPopupClose}
        refreshTaskList={props.refreshTasksList}
        type={props.type}
        projectData={props.projectData}
      />
      {props.modalState.isShowSingleTaskModalOpen && (
        <ShowSingleTaskModal
          handleShowSingleTaskPopupClose={handleShowSingleTaskPopupClose}
          isShowSingleTaskModalOpen={props.modalState.isShowSingleTaskModalOpen}
          taskInfo={props.taskInfo}
          removeTask={removeTask}
          changeTask={changeTask}
        />
      )}
      <AllTasksModal
        isOpen={props.modalState.isAllTasksModalOpened}
        onClose={() => props.closeModal('isAllTasksModalOpened')}
        tasks={props.tasks}
        showSeeMore={props.showSeeMore}
        onSeeMoreClicked={props.onSeeMoreClicked}
        disableGoTo={props.disableGoTo}
        onClick={props.onClick}
        refreshTasksList={props.refreshTasksList}
        setParams={props.setParams}
        params={props.params}
        selectedFilters={props.selectedFilters}
        setSelectedFilters={props.setSelectedFilters}
      />
    </>
  );
};

TasksModals.propTypes = {
  closeModal: PropTypes.func,
  disableGoTo: PropTypes.func,
  modalState: PropTypes.shape({
    isAddingTaskModalOpen: PropTypes.bool,
    isAllTasksModalOpened: PropTypes.bool,
    isShowSingleTaskModalOpen: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  onSeeMoreClicked: PropTypes.func,
  params: PropTypes.string,
  projectData: PropTypes.object,
  refreshTasksList: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(
      PropTypes.oneOf([
        'uncompleted',
        'myTasks',
        'overdue',
        'nearExpiration',
        'newestFirst',
        'completed',
        'nearDeadline',
      ]),
  ),
  setParams: PropTypes.func,
  setSelectedFilters: PropTypes.func,
  showSeeMore: PropTypes.bool,
  taskInfo: PropTypes.object,
  tasks: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
};

export default TasksModals;
