import PropTypes from 'prop-types';
import React from 'react';

import useProjectsListFilters, {
  PARAM_HANDLER_NAME,
  PARAM_ONLY_MINE_NAME, PARAM_PAGE_NAME,
  PARAM_SEARCH_NAME,
  PARAM_SECURITY_CONTEXT_NAME,
  PARAM_STATUS_NAME,
} from '../hooks/useProjectsListFilters';
import HandlerFilter from './HandlerFilter';
import css from './index.module.scss';
import PersonalFilter from './PersonalFilter';
import SearchFilter from './SearchFilter';
import SecurityContextFilter from './SecurityContextFilter';
import StatusFilter from './StatusFilter';

function FiltersBlock({pageScope}) {
  const {current, setParams} = useProjectsListFilters();

  function onSearchChanged(text) {
    if (current[PARAM_SEARCH_NAME] !== text) {
      setParams({
        [PARAM_SEARCH_NAME]: text,
        [PARAM_PAGE_NAME]: 1,
      });
    }
  }

  function onStatusChanged(val) {
    if (current[PARAM_STATUS_NAME] !== val) {
      setParams({
        [PARAM_STATUS_NAME]: val,
        [PARAM_PAGE_NAME]: 1,
      });
    }
  }

  function onExecutorChanged(val) {
    if (current[PARAM_HANDLER_NAME] !== val) {
      setParams({
        [PARAM_HANDLER_NAME]: val,
        [PARAM_PAGE_NAME]: 1,
      });
    }
  }

  function onSecurityContextChanged(val) {
    if (current[PARAM_SECURITY_CONTEXT_NAME] !== val) {
      setParams({
        [PARAM_SECURITY_CONTEXT_NAME]: val,
        [PARAM_PAGE_NAME]: 1,
      });
    }
  }

  function onPersonalChanged(val) {
    if (current[PARAM_ONLY_MINE_NAME] !== val) {
      setParams({
        [PARAM_ONLY_MINE_NAME]: !!val,
        [PARAM_PAGE_NAME]: 1,
      });
    }
  }

  return (
    <section className={css.FiltersBlock}>
      <SearchFilter onSearch={onSearchChanged} />
      <StatusFilter onChange={onStatusChanged} pageScope={pageScope} />
      {pageScope !== 'cases' &&
        <HandlerFilter onChange={onExecutorChanged} pageScope={pageScope} />
      }
      <SecurityContextFilter onChange={onSecurityContextChanged} />
      <PersonalFilter onChange={onPersonalChanged} />
    </section>
  );
}

FiltersBlock.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
};

export default FiltersBlock;
