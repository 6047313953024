import {useCallback, useEffect, useState} from 'react';

import getSecurityContextsDictionary from '../helpers/dictionaries/getSecurityContextsDictionary';
import {ROLE_SUPERADMIN} from '../helpers/security/listAllRoles';
import {useStateValue} from '../state/state';


export const useUserSecurityContext = () => {
  const [{user}] = useStateValue();
  const [securityContextsDictionary, setSecurityContextsDictionary] = useState([]);
  const [userSecurityContexts, setUserSecurityContexts] = useState([]);

  const updateUserSecurityContexts = useCallback(()=>{
    const userSecurityContextsIds = Array.isArray(user?.securityContexts) && user?.securityContexts.map((item)=>item.id);
    if (userSecurityContextsIds && Array.isArray(securityContextsDictionary) && securityContextsDictionary) {
      setUserSecurityContexts(
          securityContextsDictionary.filter((securityCtx) => {
            return user?.primaryRole === ROLE_SUPERADMIN || userSecurityContextsIds.includes(securityCtx.id);
          }),
      );
    } else {
      setUserSecurityContexts([]);
    }
  }, [user.primaryRole, user.securityContexts, securityContextsDictionary]);

  useEffect(()=>{
    getSecurityContextsDictionary().then(setSecurityContextsDictionary).then(updateUserSecurityContexts);
  }, [updateUserSecurityContexts]);


  return userSecurityContexts;
};
