import getLosingProjectStatuses from '../../../helpers/dictionaries/getLosingProjectStatuses';
import getPossibleProjectStatuses from '../../../helpers/dictionaries/getPossibleProjectStatuses';

export default function prepareProjectsListUrlParameters(
    base,
    pageScope,
    search,
    status,
    handler,
    showOnlyMine,
    securityContextId,
    order,
    page,
    itemsPerPage = 10,
) {
  const orderingUrlPart = getOrderingUrlPart(order.header, order.direction);

  const statusesUrlPart = getStatusesUrlPart(pageScope, status);

  const handlerUrlPart = !isNaN(parseInt(handler)) && handler > 0 ? `onlyForHandlerId=${handler}` : null;

  const searchUrlPart = search ? `search=${encodeURIComponent(search)}` : null;

  const onlyMineUrlPart = showOnlyMine ? 'showOnlyMine=true' : null;

  const urlParts = [
    orderingUrlPart,
    statusesUrlPart,
    handlerUrlPart,
    searchUrlPart,
    onlyMineUrlPart,
    `page=${page}`,
    `itemsPerPage=${itemsPerPage}`,
    securityContextId ? `securityContext=${securityContextId}` : null,
  ].filter((p)=>!!p);

  return `${base}?${urlParts.join('&')}`;
}

function getOrderingUrlPart(fieldName, direction) {
  let orderingUrlPart;
  switch (fieldName) {
    case 'name':
      orderingUrlPart = `order[name]=${direction}`;
      break;
    case 'company':
      orderingUrlPart = `order[company]=${direction}`;
      break;
    case 'handler':
      orderingUrlPart = `order[handler]=${direction}`;
      break;
    case 'user':
      orderingUrlPart = `order[user]=${direction}`;
      break;
    case 'email':
      orderingUrlPart = `order[user]=${direction}`;
      break;
    case 'phone':
      orderingUrlPart = `order[user]=${direction}`;
      break;
    case 'createdAt':
      orderingUrlPart = `order[createdAt]=${direction}`;
      break;
    case 'status':
      orderingUrlPart = `order[status]=${direction}`;
      break;
    default:
  }
  return orderingUrlPart;
}

function getStatusesUrlPart(pageScope, status) {
  let statuses = getPossibleProjectStatuses();

  switch (pageScope) {
    case 'leads':
      statuses = statuses.filter((status) => (
        [
          'lead_pending',
          'lead_rejected',
        ].indexOf(status) > -1
      ));
      break;
    case 'opportunities':
      statuses = statuses.filter((status) => (
        [
          'opportunity_trajectory',
          'opportunity_payment',
          'opportunity_memorandum_creating',
          'opportunity_memorandum_sent',
          'opportunity_resigned',
        ].indexOf(status) > -1
      ));
      break;
    case 'accounts':
      statuses = statuses.filter((status) => (
        [
          'account_in_progress',
          'account_closed',
        ].indexOf(status) > -1
      ));
      break;
    default:
  }

  if (Array.isArray(status)) {
    // user wants to view more than one status
    statuses = statuses.filter((candidate) => (
      status.indexOf(candidate) > -1
    ));
  } else if (typeof status === 'string' && status.length > 0) {
    // user wants to view one status
    statuses = statuses.filter((candidate) => (
      candidate === status
    ));
  } else {
    // status is empty, none was selected, show default view
    const losingStatuses = getLosingProjectStatuses();
    statuses = statuses.filter((candidate) => (
      losingStatuses.indexOf(candidate) === -1
    ));
  }

  return statuses.map((status) => (`status[]=${status}`)).join('&');
}
