import {useEffect, useState} from 'react';

import ProjectsCachedDictionary from '../cachedDictionary/ProjectsCachedDictionary';

export const useProjectsDictionary = () => {
  const [data, setData] = useState([]);
  const [validUntilTimestamp, setValidUntilTimestamp] = useState(0);

  const now = new Date();
  const isValidNow = typeof validUntilTimestamp === 'number' &&
    validUntilTimestamp>0 &&
    validUntilTimestamp > now.getTime();

  useEffect(()=>{
    if (!isValidNow) {
      const dictionaryInstance = ProjectsCachedDictionary.getInstance();
      dictionaryInstance.get().then((payload)=> {
        setData(payload);
        setValidUntilTimestamp(
            dictionaryInstance.getFetchedAt().getTime() + dictionaryInstance.getTTL(),
        );
      });
    }
  }, [isValidNow]);

  return data;
};
