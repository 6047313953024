import i18n from '../../i18n';

export default function status2typeString(status) {
  switch (status) {
    case 'lead_pending':
    case 'lead_rejected':
      return i18n.t('lead', {ns: 'dictionary_project_type'});
    case 'opportunity_trajectory':
    case 'opportunity_payment':
    case 'opportunity_memorandum_creating':
    case 'opportunity_memorandum_sent':
    case 'opportunity_resigned':
      return i18n.t('opportunity', {ns: 'dictionary_project_type'});
    case 'account_in_progress':
    case 'account_closed':
      return i18n.t('account', {ns: 'dictionary_project_type'});
    default:
      return null;
  }
}
