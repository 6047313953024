import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';

import {useIntervalLoop} from '../../customHooks/useIntervalLoop';
import getProjectTasks from './api/getProjectTasks';
import getRelationTasks from './api/getRelationTasks';
import getUserTasks from './api/getUserTasks';
import TasksViewer from './TasksViewer';

const PAGE_STEP_SIZE = 5;

const Tasks = (props) => {
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_STEP_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState();

  const entityId = props.entity && props.entity.id ? props.entity.id : null;
  const entityType = props.entity && props.entity['@type'];

  function getTasksList(params) {
    if (entityId !== null) {
      setIsLoading(true);
      const apiCall =
        entityType === 'User' ?
          getUserTasks :
          entityType === 'Company' ?
          getRelationTasks :
          getProjectTasks;
      apiCall(entityId, page, itemsPerPage, params).then((response) => {
        setTasks(response.tasks);
        setTotalItems(response.totalItems);
        setIsLoading(false);
      });
    }
  }

  const refreshTasksList = useCallback(() => {
    getTasksList(params);
  }, [params, itemsPerPage, entityId]);

  function loadMoreClicked() {
    setItemsPerPage(itemsPerPage + PAGE_STEP_SIZE);
  }

  useIntervalLoop(refreshTasksList);

  useEffect(() => {
    refreshTasksList(params);
  }, [props.entity, entityId, page, itemsPerPage]);

  if (entityId) {
    return (
      <TasksViewer
        tasks={tasks}
        isLoading={isLoading}
        showAdd={true}
        showSeeMore={totalItems > tasks.length}
        onSeeMoreClicked={loadMoreClicked}
        refreshTasksList={refreshTasksList}
        type={entityType}
        projectData={props}
        setParams={setParams}
        params={params}
      />
    );
  } else {
    return null;
  }
};

Tasks.propTypes = {
  entity: PropTypes.shape({
    '@context': PropTypes.string.isRequired,
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.string.isRequired,
    'id': PropTypes.number.isRequired,
    'email': PropTypes.string,
    'roles': PropTypes.arrayOf(PropTypes.string),
    'firstname': PropTypes.string,
    'lastname': PropTypes.string,
    'confirmedAt': PropTypes.string,
    'isEnabled': PropTypes.bool,
    'userPhones': PropTypes.array,
    'createdAt': PropTypes.string,
    'updatedAt': PropTypes.string,
    'isTotpAuthenticationEnabled': PropTypes.bool,
    'securityContexts': PropTypes.array,
    'display': PropTypes.string,
    'primaryRole': PropTypes.string,
    'primaryPhone': PropTypes.string,
  }),
};

export default Tasks;
