import './SingleNoteModal.scss';

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AlertDialog from '../../components/AlertDialog/AlertDialog';
import EditField from '../../components/EditField/EditField';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import {CAPABILITY_EDIT_ALL_NOTES} from '../../helpers/security/hasCapability';
import deleteNote from './api/deleteNote';
import patchNote from './api/patchNote';

export default function SingleNoteModal(props) {
  const {t} = useTranslation('component_notes');
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  function onSaveClicked() {
    setIsEditing(false);
    if (props.note) {
      const newNote = cloneDeep(props.note);
      newNote.text = noteText;
      patchNote(newNote).then((responseNote)=>{
        if (typeof props.onNoteChanged === 'function') {
          props.onNoteChanged(responseNote);
        }
        props.closeModal();
      });
    }
  }

  function onDeleteConfirmed() {
    setDeleteConfirmationOpen(false);
    if (props.note) {
      deleteNote(props.note).then(()=>{
        if (typeof props.onNoteChanged === 'function') {
          props.onNoteChanged(null);
        }
        props.closeModal();
      });
    }
  }

  function onCancelClicked() {
    setIsEditing(false);
    setNoteText(props.note.text);
  }

  function closeModal() {
    setIsEditing(false);
    props.closeModal();
  }

  useEffect(()=>{
    if (props.note) {
      setNoteText(props.note.text);
    }
  }, [props.note]);

  const isUserAuthor = props?.user['@id'] && props?.note?.author['@id'] && props.user['@id'] === props.note.author['@id'];
  const canEditNote = useHasCapabilityHere(CAPABILITY_EDIT_ALL_NOTES) || isUserAuthor;

  return (
    <ModalDialog
      className="NoteModal"
      open={props.isModalOpened}
      onCloseRequest={closeModal}
    >
      <div className="modal-contents-wrapper">
        <div className="modal-content">
          <EditField
            id="noteContent"
            name=""
            value={noteText}
            type="stringTextArea"
            placeholder={t('modal_view.field.text.placeholder')}
            editing={isEditing}
            onChange={(id, newTextValue) => setNoteText(newTextValue)}
            className="note-text"
          />

          {props?.note?.author &&
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="mediumHint">
                    {t('modal_view.field.creator.description')} {props.note?.author?.display} <br />
                    {t('modal_view.field.date.description')} {formatDateTimestamp(props.note?.createdAt)}
                  </Typography>
                  {canEditNote &&
                    <DialogActions>
                      {!isEditing &&
                        <>
                          <Button onClick={() => setIsEditing(true)}>{t('modal_view.action.edit')}</Button>
                          <Button onClick={() => setDeleteConfirmationOpen(true)} sx={{color: '#ED143D'}}>{t('modal_view.action.delete')}</Button>
                        </>
                      }
                      {isEditing &&
                        <>
                          <Button onClick={() => onSaveClicked()}>{t('modal_view.action.save')}</Button>
                          <Button onClick={onCancelClicked}>{t('modal_view.action.cancel')}</Button>
                        </>
                      }
                    </DialogActions>
                  }
                </Box>
          }
        </div>
      </div>
      <AlertDialog
        open={isDeleteConfirmationOpen}
        onClose={()=>setDeleteConfirmationOpen(false)}
        onConfirm={onDeleteConfirmed}
        title=""
        text={t('delete_confirmation.text')}
      />
    </ModalDialog>
  );
}

SingleNoteModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  note: PropTypes.shape({
    'id': PropTypes.number.isRequired,
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Note']).isRequired,
    'text': PropTypes.string,
    'author': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      'display': PropTypes.string,
    }),
    'createdAt': PropTypes.string,
  }),
  user: PropTypes.shape({
    'id': PropTypes.number.isRequired,
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['User']).isRequired,
  }),
  onNoteChanged: PropTypes.func,
};
