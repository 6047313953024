import './InviteUserModal.scss';

import * as React from 'react';

import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';

export default function InvitationResultModal(props) {
  function handleClose() {
    props.onClose();
  }

  return (
    <ConfirmationDialog
      className="InvitationResultModal"
      open={props.open}
      onCloseRequest={handleClose}
      variant="success"
      text="The invitation was sent successfully"
      buttonText="Ok"
    />
  );
}
