import './EditField.scss';

import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import formatDateShort from '../../helpers/formatters/formatDateShort';
import formatMoney from '../../helpers/formatters/formatMoney';
import role2display from '../../helpers/formatters/role2display';
import Tag from '../Tag/Tag';
import EditFieldAddress from './editors/EditFieldAddress';
import EditFieldDatepicker from './editors/EditFieldDatepicker';
import EditFieldDateString from './editors/EditFieldDateString';
import EditFieldDropdown from './editors/EditFieldDropdown';
import EditFieldDropdownAutocomplete from './editors/EditFieldDropdownAutocomplete';
import EditFieldDropdownFilters from './editors/EditFieldDropdownFilters';
import EditFieldMoney from './editors/EditFieldMoney';
import EditFieldPrimaryRole from './editors/EditFieldPrimaryRole';
import EditFieldSecurityContextMultiselect from './editors/EditFieldSecurityContextMultiselect';
import EditFieldSecurityContextMultiselectPreviewString
  from './editors/EditFieldSecurityContextMultiselectPreviewString';
import EditFieldString from './editors/EditFieldString';
import EditFieldStringTextArea from './editors/EditFieldStringTextArea';
import EditFieldTags from './editors/EditFieldTags';
import EditFieldUserNameSurname from './editors/EditFieldUserNameSurname';
import EditFieldUserPhone from './editors/EditFieldUserPhone';
export default function EditField(props) {
  const {t} = useTranslation('component_editfield');

  function onFieldChanged(newValue) {
    if (props.onChange) {
      props.onChange(props.id, newValue);
    }
  }

  const isComponentVeryLarge = isVeryLarge(props);

  const readOnly = (
    <Typography
      className={props.icon ? 'read-only-with-icon' : ''}
      fontSize={isComponentVeryLarge ? '32px' : (props.fontSize ?? 'normal')}
      fontWeight={isComponentVeryLarge ? '500' : 'normal'}
      lineHeight={1}
      sx={{wordBreak: 'break-word'}}
    >
      {props.icon}
      { typeof value2string(props) === 'string' && props.type !== 'tags' &&
        <span dangerouslySetInnerHTML={{__html: value2string(props)}} />
      }
      { props.type !== 'tags' && value2string(props) === '' && props.type === 'address' &&
        <span className="text-grey" dangerouslySetInnerHTML={{__html: t('field.address.on_empty')}} />
      }
      { (props.type === 'tags' || typeof value2string(props) === 'object')&&
        value2string(props)
      }
    </Typography>
  );
  return (
    <div className={`EditField ${isComponentVeryLarge ? 'bottom-spacing' : ''} ${props.editing ? 'is-editing' : ''} ${props.className ? props.className : ''}`}>
      {props.name &&
        <Typography fontSize="small" className="EditField-name" >{props.name}</Typography>
      }
      {props.editing &&
          getCorrectEditorElement(props, onFieldChanged, isComponentVeryLarge, props.placeholder, props.fontSize)
      }
      {!props.editing &&
          (props.href ? <Link to={props.href}>{readOnly}</Link> : readOnly)
      }
    </div>
  );
}

function isVeryLarge(props) {
  return props.format === 'header';
}


function value2string(props) {
  switch (props.type) {
    case 'string':
      return props.value;
    case 'stringTextArea':
      return typeof props.value === 'string' ? props.value.replaceAll('\n', '<br />') : props.value;
    case 'userPhone':
      return props.value ? `${props.value.country} ${props.value.phone}` : '&nbsp;';
    case 'datepicker':
    case 'dateString':
      const date = new Date(props.value);
      return formatDateShort(date);
    case 'primaryRole':
      return role2display(props.value);
    case 'userStatus':
      return props.value ? 'Active' : 'Deactivated';
    case 'userNameSurname':
      return `${props.value.firstname ?? ''} ${props.value.lastname ?? ''}`;
    case 'dropdownFilter':
      const selectedOptions = props.options.map((option) => option.display).join(', ');
      return <Typography>{selectedOptions}</Typography>;
    case 'securityContextMultiselect':
      return <EditFieldSecurityContextMultiselectPreviewString value={props.value} />;
    case 'dropdown':
    case 'autocomplete':
      const option = props.options.find((dropOpt)=>(dropOpt.name===props.value));
      return option ? option.display : '';
    case 'address':
      return `${props.value.street ? props.value.street + ', ' : ''}${props.value.postal ? props.value.postal + ' ' : ''}${props.value.city ? props.value.city + ', ' : ''}${props.value.country || ''}`;
    case 'money':
      return formatMoney(props.value);
    case 'tags':
      return <span>{props.value.map((item, idx)=><Tag key={idx} tag={item} />)}</span>;
    default:
      return '';
  }
}

function getCorrectEditorElement(props, onChange, isVeryLarge= false, placeholder=null, fontSize='normal') {
  switch (props.type) {
    case 'string':
      return <EditFieldString value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} icon={props.icon} error={props.error} typeOptions={props.typeOptions}/>;
    case 'stringTextArea':
      return <EditFieldStringTextArea value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions} minRows={props.minRows} maxRows={props.maxRows}/>;
    case 'userPhone':
      return <EditFieldUserPhone value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'datepicker':
      return <EditFieldDatepicker value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'dateString':
      return <EditFieldDateString value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'primaryRole':
      return <EditFieldPrimaryRole value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions} />;
    case 'userStatus':
      return <EditFieldString value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions} />;
    case 'userNameSurname':
      return <EditFieldUserNameSurname value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'dropdown':
      return <EditFieldDropdown value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} options={props.options} label={props.name} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'dropdownFilter':
      return <EditFieldDropdownFilters value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} options={props.options} label={props.name} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions} refresh={props.refreshTasksList}/>;
    case 'securityContextMultiselect':
      return <EditFieldSecurityContextMultiselect value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} options={props.options} label={props.name} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'autocomplete':
      return <EditFieldDropdownAutocomplete value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} options={props.options} label={props.name} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'address':
      return <EditFieldAddress value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'money':
      return <EditFieldMoney value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    case 'tags':
      return <EditFieldTags value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
    default:
      return <EditFieldString value={props.value} onChange={onChange} isVeryLarge={isVeryLarge} placeholder={placeholder} fontSize={fontSize} error={props.error} typeOptions={props.typeOptions}/>;
  }
}

EditField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.any,
  format: PropTypes.string,
  fontSize: PropTypes.number,
  type: PropTypes.oneOf([
    'string',
    'dateString',
    'datepicker',
    'userPhone',
    'userStatus',
    'userNameSurname',
    'primaryRole',
    'dropdown',
    'dropdownFilter',
    'securityContextMultiselect',
    'autocomplete',
    'stringTextArea',
    'address',
    'money',
    'tags',
  ]),
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.element,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  typeOptions: PropTypes.object,
  error: PropTypes.any,
};
