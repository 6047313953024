import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Switch from '../../../../components/Switch/Switch';
import {useHasCapabilityHere} from '../../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_VIEW_PERSONAL_PROJECTS} from '../../../../helpers/security/hasCapability';
import useProjectsListFilters, {PARAM_ONLY_MINE_NAME} from '../../hooks/useProjectsListFilters';
import css from './index.module.scss';

function PersonalFilter({onChange}) {
  const {t} = useTranslation('page_cases');
  const {current} = useProjectsListFilters();

  const hasViewPersonalProjectsCapability = useHasCapabilityHere(CAPABILITY_VIEW_PERSONAL_PROJECTS);

  const isChecked = current[PARAM_ONLY_MINE_NAME];

  if (!hasViewPersonalProjectsCapability) {
    return null;
  } else {
    return (
      <div className={css.PersonalFilter}>
        <Switch
          checked={!!isChecked}
          onChange={()=>onChange(!isChecked)}
          label={t('filter.only_mine')}
        />
      </div>
    );
  }
}

PersonalFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PersonalFilter;
