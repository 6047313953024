import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {useUserSecurityContext} from '../../../customHooks/useUserSecurityContext';
import filterActiveUserSecurityContexts from './functions/filterActiveUserSecurityContexs';

export default function EditFieldSecurityContextMultiselectPreviewString(props) {
  const {t} = useTranslation('login');
  const userSecurityContexts = useUserSecurityContext();
  const selectedSecurityContexts = filterActiveUserSecurityContexts(props.value, userSecurityContexts);


  if (!!userSecurityContexts) {
    return (
      <>
        {selectedSecurityContexts.map((item)=>t(`security_context.name.${item.name}`, {ns: 'login'})).join(', ')}
      </>
    );
  } else {
    return null;
  }
}

EditFieldSecurityContextMultiselectPreviewString.propTypes = {
  value: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
};
