import Api from '../../../helpers/api/Api';

export default function getNotificationsList(page, itemsPerPage) {
  return new Promise(function(resolve, reject) {
    const url = `/notifications?page=${page}&itemsPerPage=${itemsPerPage}`;

    Api.get(url).then((data)=>{
      resolve({
        notifications: data['hydra:member'],
        items: parseInt(data['hydra:totalItems']),
        pages: Math.ceil(parseInt(data['hydra:totalItems']) / itemsPerPage),
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
