import './IndexTable.scss';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TableHead, TablePagination,
  TableRow, TableSortLabel,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import MoreButton from '../MoreButton/MoreButton';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import css from './IndexTableManaged.module.scss';

export default function IndexTableManaged(props) {
  const {t} = useTranslation('component_table');
  const tableSxOptions = props.sx ? props.sx : {};
  const cellSxOptions = {
    padding: '12px 8px',
    cursor: 'pointer',
  };

  const isLastPage = ((props.pagination.page+1) * props.pagination.rowsPerPage >= props.pagination.items);
  const missingRowsCount = props.isLoading ?
    props.pagination.rowsPerPage - 1 :
    (props.pagination.rowsPerPage - (props.pagination.items % props.pagination.rowsPerPage));
  const rowsToAppend = Array.apply(null, {length: ((isLastPage && missingRowsCount!==props.pagination.rowsPerPage) || props.isLoading) ? missingRowsCount : 0});

  function handleHeaderClick(item) {
    if (props.onHeaderClick) {
      props.onHeaderClick(item);
    }
  }

  function handleMoreActionClick(actionId, rowId) {
    if (props.onMoreActionClick) {
      props.onMoreActionClick(actionId, rowId);
    }
  }

  function handleRowClick(rowId) {
    if (props.onRowClick) {
      props.onRowClick(rowId);
    }
  }

  function handlePageChange(event, newPageIndex) {
    if (props.onPageChangeClick) {
      props.onPageChangeClick(newPageIndex);
    }
  }

  return (
    <div className="IndexTable">
      <TableContainer component={Paper}>
        <Table sx={tableSxOptions} size="small" aria-label="Table">
          <TableHead>
            <TableRow>
              {props.headers.map((header, headerIndex) => (
                <TableCell
                  key={headerIndex}
                  onClick={()=>handleHeaderClick(header.id)}
                  width={header?.width ?? undefined}
                >
                  {header?.sortable!==false &&
                    <TableSortLabel
                      active={Boolean(header.sorted)}
                      direction={header.sorted}
                    >
                      {header.label}
                    </TableSortLabel>
                  }
                  {header?.sortable===false &&
                    <div className={css.unsortableHeader}>
                      {header.label}
                    </div>
                  }
                </TableCell>
              ))}
              <TableCell><span>&nbsp;</span></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isLoading && props.rows.map((row, rowIdx) => {
              return (
                <TableRow key={rowIdx}>
                  {row.fields.map((field, fieldIdx) => {
                    if (typeof field.label === 'string') {
                      return <TableCell
                        className="IndexTable-cell"
                        key={fieldIdx}
                        sx={cellSxOptions}
                        onClick={() => handleRowClick(rowIdx)}
                        dangerouslySetInnerHTML={{__html: field.label}}
                      />;
                    } else {
                      return <TableCell
                        className="IndexTable-cell"
                        key={fieldIdx}
                        sx={cellSxOptions}
                        onClick={() => handleRowClick(rowIdx)}
                      >{field.label}</TableCell>;
                    }
                  })}
                  <TableCell>
                    {row.moreMenu &&
                        <MoreButton
                          actions={row.moreMenu}
                          rowKey={rowIdx.toString()}
                          onAction={handleMoreActionClick}
                        />
                    }
                  </TableCell>
                </TableRow>
              );
            })}
            {props.isLoading &&
              <TableRow key="loader">
                <TableCell
                  sx={cellSxOptions}
                  colSpan={props.headers.length+1}
                >
                  <ProgressIndicator sx={{padding: 0, height: '1.43em'}} />
                </TableCell>
              </TableRow>
            }
            {rowsToAppend.map((auxRow, auxRowIdx)=>(
              <TableRow key={auxRowIdx}>
                <TableCell
                  sx={cellSxOptions}
                  colSpan={props.headers.length+1}
                >&nbsp;</TableCell>
              </TableRow>
            ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              {props.appendToFooter &&
                <TableCell>
                  {props.appendToFooter}
                </TableCell>
              }
              <TablePagination
                rowsPerPageOptions={[props.pagination.rowsPerPage]}
                colSpan={props.headers.length}
                count={props.pagination.items}
                rowsPerPage={props.pagination.rowsPerPage}
                page={props.pagination.page}
                labelDisplayedRows={({from, to, count, page}) => ( t('pagination', {from, to, count} ) ) }
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

IndexTableManaged.propTypes = {
  headers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]).isRequired,
        sorted: PropTypes.oneOf([null, 'asc', 'desc']),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
  ).isRequired,
  rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fields: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
        })),
        moreMenu: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          color: PropTypes.string,
        })),
      }),
  ),
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    items: PropTypes.number.isRequired,
  }).isRequired,
  appendToFooter: PropTypes.element,
  isLoading: PropTypes.bool.isRequired,
  onHeaderClick: PropTypes.func,
  onMoreActionClick: PropTypes.func,
  onPageChangeClick: PropTypes.func,
  onRowClick: PropTypes.func,
  sx: PropTypes.any,
};
