import LoginLayout from '../../layouts/LoginLayout';
import ForgottenPassword from './ForgottenPassword';

export function ForgottenPasswordPage() {
  return (
    <LoginLayout>
      <ForgottenPassword />
    </LoginLayout>
  );
}
