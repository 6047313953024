import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import AccessDeniedPopUp from '../../components/Popups/AccessDeniedPopUp';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {CAPABILITY_RECENT_ACTIVITY, CAPABILITY_TODO_LIST} from '../../helpers/security/hasCapability';
import SingleEntityLayout from '../../layouts/SingleEntityLayout/SingleEntityLayout';
import getProject from './api/getProject';
import CardProjectPurge from './CardProjectPurge';
import css from './index.module.scss';
import ProjectDocumentsPanel from './ProjectDocumentsPanel';
import ProjectEdit from './ProjectEdit';
import ProjectLinkedCompaniesPanel from './ProjectLinkedCompaniesPanel';
import ProjectNotesPanel from './ProjectNotesPanel';
import ProjectRecentActivityPanel from './ProjectRecentActivityPanel';
import ProjectTasksPanel from './ProjectTasksPanel';

export const REFRESH_REASON_PROJECT_EDIT = 'REFRESH_REASON_PROJECT_EDIT';

function ProjectDetailsPage({pageScope, view}) {
  const [accessForbidden, setAccessForbidden] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const routeParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const projectId = !isNaN(parseInt(routeParams?.id)) ? parseInt(routeParams?.id) : null;

  const canViewTodoList = useHasCapabilityHere(CAPABILITY_TODO_LIST);
  const canViewRecentActivity = useHasCapabilityHere(CAPABILITY_RECENT_ACTIVITY);


  const loadProjectData = useCallback((refreshCause=null)=>{
    setProjectData(null);
    if (projectId) {
      getProject(projectId).then((data) => {
        setProjectData(data);
      }).catch((error) => {
        if (error.status === 403 || error.status === 404) {
          switch (refreshCause) {
            case REFRESH_REASON_PROJECT_EDIT:
              const pathElements = location.pathname.split('/').filter((i)=>(!!i));
              navigate(`/${pathElements[0]}`);
              break;
            default:
              setAccessForbidden(true);
          }
        }
      });
    }
  }, [location.pathname, navigate, projectId]);

  function refreshAllData(refreshCause=null) {
    loadProjectData(refreshCause);
  }

  useEffect(()=>{
    loadProjectData();
  }, [loadProjectData]);

  return (
    <>
      <SingleEntityLayout
        leftPanel={
          <ProjectEdit
            project={projectData}
            onRefreshRequest={refreshAllData}
          />
        }
      >
        {view === 'default' &&
          <div className={css.inner}>
            <ProjectLinkedCompaniesPanel
              pageScope={pageScope}
              project={projectData}
              onRefreshRequest={refreshAllData}
            />
            <div className={css.gridView}>
              <ProjectNotesPanel project={projectData} />
              {canViewTodoList &&
                <ProjectTasksPanel project={projectData} />
              }
              {canViewRecentActivity &&
                <ProjectRecentActivityPanel project={projectData} />
              }
            </div>
            <ProjectDocumentsPanel project={projectData} />
          </div>
        }
        {view === 'purge' &&
          <div className={css.inner}>
            <CardProjectPurge
              project={projectData}
              pageScope={pageScope}
            />
          </div>
        }
      </SingleEntityLayout>
      <AccessDeniedPopUp isOpen={accessForbidden} onClose={()=>setAccessForbidden(false)} />
    </>
  );
}

ProjectDetailsPage.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
  view: PropTypes.oneOf([
    'default', 'purge',
  ]),
};

ProjectDetailsPage.defaultProps = {
  view: 'default',
};

export default ProjectDetailsPage;
