import Api from '../../../../../../../helpers/api/Api';

export default function getResourceResponseStatus(url) {
  return new Promise(function(resolve, reject) {
    Api.get(url).then(()=>{
      resolve();
    }).catch(() => {
      reject();
    });
  });
}
