import './ForgottenPassword.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import Api from '../../helpers/api/Api';

export default function ForgottenPassword() {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation('login');
  const [value, setValue] = useState({
    email: '',
  });
  const handleChange = (prop) => (event) => {
    setValue({...value, [prop]: event.target.value});
  };
  const [isError, setIsError] = useState(false);

  const sendLink = async (email) => {
    setIsError(false);
    Api.sendForgottenPasswordLink(email)
        .then(() => {
          navigate('/restore-password');
        })
        .catch((errorResponse) => {
          console.error(errorResponse);
          setIsError(true);
        });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
      className="forgotten-password-form"
    >
      <div className="title">{t('forgotten_password.title')}</div>
      <div className="content">{t('forgotten_password.description')}</div>
      <div className="field-title">
        <p>{t('forgotten_password.email_field.description')}</p>
        <FormControl variant="outlined" sx={{width: '100%'}}>
          <OutlinedInput
            id="outlined-adornment-email"
            type="text"
            placeholder={t('forgotten_password.email_field.placeholder')}
            onChange={handleChange('email')}
            value={value.email}
            className={'on-dark-background'}
          />
        </FormControl>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '46px',
        }}
      >
        <Button
          variant="contained"
          sx={{'width': '68%',
            'height': '44px',

            '@media screen and (max-width: 460px)': {
              width: '200px',
              fontSize: '14px',
            },
          }}
          onClick={() => sendLink(value.email)}
        >
          {t('forgotten_password.button.recover')}
        </Button>
        <div className="cancel">
          <Link to="/login">{t('forgotten_password.button.cancel')}</Link>
        </div>
      </Box>
    </Box>
  );
}
