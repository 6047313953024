import Api from '../../../helpers/api/Api';

export default function postToggleTotp(enabled, code = null) {
  return new Promise(function(resolve, reject) {
    Api.post('/users/toggle_totp', {
      enabled,
      code,
    }).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
