import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import RecentActivityViewer from '../../../components/RecentActivityViewer/RecentActivityViewer';
import getFrontendPathToObject from '../../../helpers/navigation/getFrontendPathToObject';
import getProjectRecentActivity from './api/getProjectRecentActivity';

const PAGE_STEP_SIZE = 7;

export default function CardProjectRecentActivity(props) {
  const navigate = useNavigate();
  const [projectActivities, setProjectActivities] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_STEP_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const projectId = props.project && props.project.id ? parseInt(props.project.id) : null;

  function getProjectActivities() {
    if (projectId !== null) {
      setIsLoading(true);
      getProjectRecentActivity(projectId, page, itemsPerPage).then((response) => {
        setProjectActivities(response.activities);
        setTotalItems(response.totalItems);
        setIsLoading(false);
      });
    }
  }

  function onItemClicked(id, item) {
    const link = getFrontendPathToObject(item);
    if (link) {
      navigate(link);
    }
  }

  function loadMoreClicked() {
    setItemsPerPage(itemsPerPage+PAGE_STEP_SIZE);
  }

  useEffect(function() {
    getProjectActivities();
  }, [props.project, projectId, page, itemsPerPage]);

  return (
    <RecentActivityViewer
      className={props.className}
      activities={projectActivities}
      isLoading={isLoading}
      showSeeMore={totalItems > projectActivities.length}
      onSeeMoreClicked={loadMoreClicked}
      onItemClicked={onItemClicked}
      disableGoTo={true}
    />
  );
}

CardProjectRecentActivity.propTypes = {
  project: PropTypes.object,
  className: PropTypes.string,
};
