import Api from '../../../helpers/api/Api';

export default function deleteNote(noteIdentifier) {
  return new Promise(function(resolve, reject) {
    let noteId = null;
    if (typeof noteIdentifier === 'string' && noteIdentifier.length > 0) {
      noteId = parseInt(noteIdentifier.replace('/api/notes/', ''));
    } else if (!isNaN(noteIdentifier)) {
      noteId = parseInt(noteIdentifier);
    } else if (typeof noteIdentifier === 'object' && typeof noteIdentifier['@id'] === 'string' && noteIdentifier['@id'].length > 0) {
      noteId = parseInt(noteIdentifier['@id'].replace('/api/notes/', ''));
    }
    if (!noteId) {
      reject(new Error('Unrecognized note identifier'));
    }

    Api.delete(`/notes/${noteId}`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
