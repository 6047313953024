import cloneDeep from 'lodash/cloneDeep';

import {ACTION_SET_UNREAD_NOTIFICATIONS_COUNT, ACTION_SET_USER, ACTION_SET_USER_FIELD} from './actions';

export const reducer = (state, action) => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case ACTION_SET_USER:
      newState.user = action.data;
      break;

    case ACTION_SET_USER_FIELD:
      newState.user[action.data.field] = action.data.value;
      break;

    case ACTION_SET_UNREAD_NOTIFICATIONS_COUNT:
      newState.unseenNotificationsCount = parseInt(action.data);
      break;

    default:
  }

  return newState;
};
