export default function getFrontendPathToObject(object) {
  if (!object) return null;

  switch (object['@type']) {
    case 'Project':

      const projectId = typeof object.id === 'number' ?
        parseInt(object.id) :
        (typeof object['@id'] === 'string' ? parseInt(object['@id'].replace('/api/projects/', '')) : null)
      ;
      switch (object['status']) {
        case 'lead_pending':
        case 'lead_rejected':
          return `/leads/${projectId}`;
        case 'opportunity_trajectory':
        case 'opportunity_payment':
        case 'opportunity_memorandum_creating':
        case 'opportunity_memorandum_sent':
        case 'opportunity_resigned':
          return `/opportunities/${projectId}`;
        case 'account_in_progress':
        case 'account_closed':
          return `/accounts/${projectId}`;
        default:
          return null;
      }

    case 'User':
      const userId = typeof object.id === 'number' ?
      parseInt(object.id) :
      (typeof object['@id'] === 'string' ? parseInt(object['@id'].replace('/api/projects/', '')) : null)
    ;
      return `/users/${userId}`;

    case 'Company':
      const companyId = typeof object.id === 'number' ?
      parseInt(object.id) :
      (typeof object['@id'] === 'string' ? parseInt(object['@id'].replace('/api/projects/', '')) : null)
    ;
      return `/companies/${companyId}`;

    default:
      return null;
  }
}
