import PropTypes from 'prop-types';
import React from 'react';

import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../helpers/security/hasCapability';
import ActionAddUser from './ActionAddUser';
import css from './index.module.scss';

function ActionsBlock({requestRefresh}) {
  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);

  return (
    <section className={css.ActionsBlock}>
      {hasEditCapability &&
        <ActionAddUser
          requestRefresh={requestRefresh}
        />
      }
    </section>
  );
}

ActionsBlock.propTypes = {
  requestRefresh: PropTypes.func.isRequired,
};

export default ActionsBlock;
