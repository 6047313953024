import './CompanyEdit.scss';

import {Close, DriveFileRenameOutline, Save} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import CompanyTypeDot from '../../components/CompanyTypeDot/CompanyTypeDot';
import EditField from '../../components/EditField/EditField';
import HorizontalDivider from '../../components/HorizontalDivider/HorizontalDivider';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import getPossibleCompanyTypes from '../../helpers/dictionaries/getPossibleCompanyTypes';
import formatDateShort from '../../helpers/formatters/formatDateShort';
import {
  CAPABILITY_ASSIGN_SECURITY_CONTEXTS,
  CAPABILITY_EDIT,
  CAPABILITY_MERGE,
  CAPABILITY_PURGE,
} from '../../helpers/security/hasCapability';
import patchCompany from './api/patchCompany';
import MergeCompaniesModal from './MergeCompaniesModal';

export default function CompanyEdit(props) {
  const {t, i18n} = useTranslation('page_companies');
  const [patchData, setPatchData] = useState(initializePatchData());
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isMergeDialogOpen, setMergeDialogOpen] = useState(false);

  const navigate = useNavigate();

  const legalFormsDropdownOptions = props.companyLegalForms?.map((form) => (
    {name: form.id, display: t(form.name, {ns: 'dictionary_legal_form'})}
  ));
  const handlerDropdownOptions = props.handlers?.map((handler) => ({name: handler.id, display: `${handler.lastname}, ${handler.firstname}`}));

  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);
  const hasMergeCapability = useHasCapabilityHere(CAPABILITY_MERGE);
  const hasPurgeCapability = useHasCapabilityHere(CAPABILITY_PURGE);
  const hasAssignSecurityContextCapability = useHasCapabilityHere(CAPABILITY_ASSIGN_SECURITY_CONTEXTS);

  function initializePatchData() {
    return {
      id: props.companyData.id,
      type: props.companyData.type,
      name: props.companyData.name,
      handler: props.companyData.handler?.id ? props.companyData.handler.id : '',
      legalForm: props.companyData.legalForm.id,
      createdAt: props.companyData.createdAt,
      street: props.companyData.primaryAddress?.line1,
      postal: props.companyData.primaryAddress?.postal,
      city: props.companyData.primaryAddress?.city,
      country: props.companyData.primaryAddress?.country,
      tags: props.companyData.tags,
      securityContextsIds: props.companyData.securityContexts.map((ctx)=>ctx.id),
    };
  }

  function toggleEdit() {
    setPatchData(initializePatchData());
    if (!isEditing && hasEditCapability) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }

  function toggleSave() {
    setIsEditing(false);
    setIsSaving(true);
    patchCompany(
        patchData,
        initializePatchData(),
    ).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function editFieldChanged(id, value) {
    const data = Object.assign({}, patchData);
    switch (id) {
      case 'name':
        data.name = value;
        break;
      case 'legalForm':
        data.legalForm = value;
        break;
      case 'type':
        data.type = value;
        break;
      case 'handler':
        data.handler = value;
        break;
      case 'address':
        data.street = value.street;
        data.postal = value.postal;
        data.city = value.city;
        data.country = value.country;
        break;
      case 'tags':
        data.tags = value;
        break;
      case 'companySecurityContexts':
        data.securityContextsIds = value;
        break;
      default:
    }
    setPatchData(data);
  }

  function onMergeDialogClosed(result = false) {
    setMergeDialogOpen(false);
    if (typeof result === 'number' && result > 0) {
      navigate(`/companies/${result}`);
    } else if (result === true) {
      props.onRefreshRequest();
    }
  }

  function breadcrumbBack() {
    navigate('/companies');
  }

  const relationTypes = getPossibleCompanyTypes().map((item) => (
    {name: item, display: t(item, {ns: 'dictionary_company_type'})}
  ));

  return (
    <div className="CompanyEdit">
      <div className="breadcrumb-navigation" onClick={breadcrumbBack}>
        <div className="arrow-back" />
        <Typography fontSize={14}>{t('page.back')}</Typography>
      </div>
      {!isSaving &&
          <>
            <EditField
              id="name"
              name={isEditing && t('field.name.description')}
              value={patchData.name}
              format="header"
              type="string"
              editing={isEditing}
              onChange={editFieldChanged}
            />
            { hasMergeCapability &&
              <Button className="merge-button" variant="smallerText" onClick={()=>setMergeDialogOpen(!isMergeDialogOpen)}>{t('page.merge_button')}</Button>
            }
            <EditField
              id="handler"
              name={t('field.handler.description')}
              value={patchData.handler}
              type="autocomplete"
              placeholder={t('field.handler.placeholder')}
              options={handlerDropdownOptions}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="legalForm"
              name={t('field.legal_form.description')}
              value={patchData.legalForm}
              type="dropdown"
              placeholder={t('field.legal_form.placeholder')}
              options={legalFormsDropdownOptions}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="type"
              name={t('field.type.description')}
              value={patchData.type}
              icon={
                <InputAdornment position="end" component='span'>
                  <CompanyTypeDot type={patchData.type} isPersonal={!!props.companyData?.isPersonal} />
                </InputAdornment>
              }
              type="dropdown"
              placeholder={t('field.type.placeholder')}
              options={relationTypes}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            { !isEditing && <EditField
              id="created"
              name={t('field.created_at.description')}
              value={formatDateShort(patchData.createdAt)}
              type="string"
              editing={false}
              onChange={editFieldChanged}
            /> }
            <EditField
              id="address"
              name={!isEditing && t('field.address.description')}
              value={patchData}
              type="address"
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="tags"
              name={t('field.tags.description')}
              value={patchData.tags}
              type="tags"
              editing={isEditing}
              onChange={editFieldChanged}
            />
            {hasAssignSecurityContextCapability &&
              <EditField
                id="companySecurityContexts"
                name={t('field.security_context.description')}
                value={patchData.securityContextsIds}
                type="securityContextMultiselect"
                fontSize={16}
                editing={isEditing}
                onChange={editFieldChanged}
              />
            }
            <HorizontalDivider />
          </>
      }
      {!isEditing && !isSaving && hasEditCapability &&
          <Button
            startIcon={<DriveFileRenameOutline />}
            color="text"
            onClick={toggleEdit}
            sx={{padding: 0}}
          >
            <Typography fontSize={16}>{t('action.edit')}</Typography>
          </Button>
      }
      {isEditing && !isSaving && hasEditCapability &&
          <>
            <Button
              startIcon={<Save />}
              color="text"
              onClick={toggleSave}
              sx={{padding: 0}}
            >
              <Typography fontSize={16}>{t('action.save')}</Typography>
            </Button>
            <Button
              startIcon={<Close />}
              color="text"
              onClick={toggleEdit}
              sx={{padding: 0, marginLeft: '36px'}}
            >
              <Typography fontSize={16}>{t('action.cancel')}</Typography>
            </Button>
          </>
      }
      {hasPurgeCapability && props.view !== 'purge' &&
          <Button
            startIcon={<Close />}
            color="text"
            onClick={()=>navigate('purge')}
            sx={{padding: 0, marginLeft: '36px'}}
          >
            <Typography fontSize={16}>{t('action.purge')}</Typography>
          </Button>
      }
      {isSaving &&
          <ProgressIndicator />
      }
      <MergeCompaniesModal
        isOpen={isMergeDialogOpen}
        onClose={onMergeDialogClosed}
        subject={props.companyData}
      />
    </div>
  );
}

CompanyEdit.propTypes = {
  companyData: PropTypes.object.isRequired,
  handlers: PropTypes.array.isRequired,
  companyLegalForms: PropTypes.array.isRequired,
  onRefreshRequest: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['default', 'purge']),
};
