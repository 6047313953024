import './ServerErrorPopUp.scss';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import infoIcon from '../../assets/informers.svg';

export default function ServerErrorPopUp(props) {
  return (
    <Dialog
      open={props.isPopupOpen}
      onClose={props.handlePopupClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <img className="infoIcon" src={infoIcon} alt="info" />
        <DialogTitle
          sx={{fontSize: '28px', fontWeight: '500', textAlign: 'center'}}
        >
            Server is temporarily unavailable
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '500',
            color: '#252733',
          }}
        >
            Something went wrong, try again. If the problem persists, please
            contact support.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{margin: '0 auto 48px'}}>
        <Button
          onClick={props.handlePopupClose}
          variant="contained"
          sx={{width: '135px'}}
        >
            Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
