import './NotificationItem.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import getNotificationText from './getNotificationText';

export default function NotificationItem(props) {
  function unseenClicked() {
    if (!isSeen && props.onUnseenClicked && typeof props.onUnseenClicked === 'function') {
      props.onUnseenClicked(props.notification);
    }
  }

  function navigateClicked() {
    if (props.onNavigateClicked && typeof props.onNavigateClicked === 'function') {
      props.onNavigateClicked(props.notification);
    }
  }

  const isSeen = props.notification && typeof props.notification.seenAt === 'string' && props.notification.seenAt.length > 5;

  const creatorName = props.notification && props.notification.related && props.notification.related.creator && props.notification.related.creator['@type'] === 'User' ?
      props.notification.related.creator.display :
      'unknown';

  const notificationText = props.notification ?
    getNotificationText(props.notification) :
    '';

  const timestampString = props.notification && props.notification.createdAt ?
    formatDateTimestamp(props.notification.createdAt) :
    '';

  return (
    <div className="NotificationItem">
      <div className="NotificationItem-group">
        <div className="NotificationItem-section NotificationItem-creator">
          <Typography variant="textInBackground">{creatorName}</Typography>
        </div>
        <div className={`NotificationItem-section NotificationItem-text ${isSeen ? 'seen' : 'unseen'}`} onClick={unseenClicked}>
          { isSeen &&
              <Typography variant="notification" dangerouslySetInnerHTML={{__html: notificationText}} />
          }
          { !isSeen &&
              <>
                <span className="NotificationItem-red-dot">&#9679;</span>
                <Typography variant="notificationUnseen" dangerouslySetInnerHTML={{__html: notificationText}} />
              </>
          }
        </div>
        <div className="NotificationItem-section NotificationItem-timestamp">
          <Typography variant="textInBackground">{timestampString}</Typography>
        </div>
      </div>
      <div className="NotificationItem-group">
        <Button className="NotificationItem-navigate" variant="navigateOut" onClick={navigateClicked}><ChevronRightIcon /></Button>
      </div>
    </div>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onNavigateClicked: PropTypes.func,
  onUnseenClicked: PropTypes.func,
};
