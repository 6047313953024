import './AddingNoteModal.scss';

import TextareaAutosize from '@mui/base/TextareaAutosize';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../EditField/EditField';
import ModalDialog from '../ModalDialog/ModalDialog';
import ServerErrorPopUp from '../Popups/ServerErrorPopUp';
import postNote from './api/postNote';

export default function AddingNoteModal(props) {
  const {t, i18n} = useTranslation('component_notes');
  const [noteText, setNoteText] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  function handleAddingNewNoteModalClose() {
    setNoteText('');
    props.handleAddingPopupClose();
  }

  function addNote() {
    setErrorText(null);
    postNote({
      text: noteText,
    }, props.projectId).then(()=>{
      props.onRefresh();
      handleAddingNewNoteModalClose();
      setNoteText('');
    });
  }

  return (
    <>
      <ModalDialog
        className="AddingNote"
        open={props.isAddingNoteOpen}
        onCloseRequest={handleAddingNewNoteModalClose}
      >
        <div className="Title">{t('modal_add.title')}</div>
        <div className="FormField">
          <TextareaAutosize
            aria-label="minimum height"
            minRows={20}
            maxRows={20}
            placeholder={t('modal_add.placeholder')}
            style={{width: 400, fontFamily: '"Barlow", "Roboto", "Helvetica", "Arial", sans-serif', fontSize: '16px'}}
            value={noteText}
            onChange={((e) => setNoteText(e.target.value))}
          />
        </div>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '14px',
            height: '44px',
          }}
        >
          <Button
            variant="contained"
            sx={{width: '164px', height: '100%', textTransform: 'capitalize', marginBotton: '92px'}}
            onClick={addNote}
            className="CreateButton"
          >
            {t('modal_add.add_button')}
          </Button>
        </Box>
      </ModalDialog>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen}/>
    </>
  );
}
