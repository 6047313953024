import './LoginForm.scss';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import classNames from 'classnames';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

import ServerErrorPopUp from '../../components/Popups/ServerErrorPopUp';
import {useRedirectTo} from '../../customHooks/useRedirectTo';
import Api from '../../helpers/api/Api';
import hasSlugAccess from '../../helpers/security/hasSlugAccess';
import {setUser} from '../../state/actions';
import {useStateValue} from '../../state/state';

export default function LoginForm() {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation('login');
  const [{user}, dispatch] = useStateValue();
  const [values, setValues] = useState({
    password: '',
    email: '',
    code: '',
    showPassword: false,
    showCode: false,
  });
  const [isTotpRequired, setTotpRequired] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isTotpError, setTotpIsError] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isUsernamePasswordDisabled, setUsernamePasswordDisabled] = useState(false);
  const redirectTo = useRedirectTo();

  const passwordElementRef = useRef();
  const totpCodeElementRef = useRef();
  const submitElementRef = useRef();

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowCode = () => {
    setValues({
      ...values,
      showCode: !values.showCode,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownCode = (event) => {
    event.preventDefault();
  };

  const handleUsernameEnterPress = (event) => {
    if (event.key === 'Enter' && passwordElementRef.current) {
      passwordElementRef.current.focus();
    }
  };

  const handlePasswordEnterPress = (event) => {
    if (event.key === 'Enter' && isTotpRequired && totpCodeElementRef.current) {
      totpCodeElementRef.current.focus();
    } else if (event.key === 'Enter' && submitElementRef.current) {
      submitElementRef.current.click();
    }
  };

  const handleTotpCodeEnterPress = (event) => {
    if (event.key === 'Enter' && submitElementRef.current) {
      submitElementRef.current.click();
    }
  };

  const login = async (email, password, code) => {
    setIsError(false);
    setTotpIsError(false);
    Api.login(email, password, code)
        .then((data) => {
          dispatch(setUser(data.user));
        })
        .catch((errorResponse) => {
          if (errorResponse.data.code === 409) {
            setUsernamePasswordDisabled(true);
            setTotpRequired(true);
          } else if (errorResponse.data.code === 500) {
            setIsPopupOpen(true);
          } else if (errorResponse.data.code === 401 && isUsernamePasswordDisabled) {
            setTotpIsError(true);
          } else {
            setIsError(true);
          }
        });
  };

  // Focus on TOTP code query, after it shows
  useEffect(()=>{
    if (totpCodeElementRef && isTotpRequired) {
      totpCodeElementRef.current.focus();
    }
  }, [isTotpRequired, totpCodeElementRef]);

  // Redirect after successful login
  useEffect(()=>{
    if (user?.id && navigate) {
      if (redirectTo) {
        const canOpenRedirectLink = hasSlugAccess(user, redirectTo.split('/').pop());
        if (canOpenRedirectLink) {
          navigate(redirectTo);
        } else {
          navigate('/');
        }
      } else if (user?.primaryRole === 'ROLE_USER') {
        navigate('/cases');
      } else {
        navigate('/overview');
      }
    }
  }, [navigate, redirectTo, user, user?.id, user?.primaryRole]);

  return (
    <div className="LoginForm">
      <Typography className="LoginForm-title" variant="h1">{t('login.title')}</Typography>
      <div className="LoginForm-field">
        <Typography className="LoginForm-field-title" variant="body2">{t('login.email_field.description')}</Typography>
        <OutlinedInput
          id="outlined-adornment-email"
          type="text"
          value={values.email}
          onChange={handleChange('email')}
          onKeyPress={handleUsernameEnterPress}
          fullWidth={true}
          disabled={isUsernamePasswordDisabled}
          placeholder={t('login.email_field.placeholder')}
          error={isError}
          sx={isError ? {color: '#FF80A0'} : {color: '#000000'}}
          className={classNames('on-dark-background', {'input-error': isError})}
        />
      </div>
      <div className="LoginForm-field">
        <Typography className="LoginForm-field-title" variant="body2">{t('login.password_field.description')}</Typography>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          onKeyPress={handlePasswordEnterPress}
          fullWidth={true}
          disabled={isUsernamePasswordDisabled}
          inputRef={passwordElementRef}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          placeholder={t('login.password_field.placeholder')}
          error={isError}
          sx={isError ? {color: '#FF80A0'} : {color: '#000000'}}
          className={classNames('on-dark-background')}
        />
      </div>
      { isTotpRequired &&
        <div className="LoginForm-field">
          <Typography className="LoginForm-field-title" variant="body2">{t('login.totp_code_field.description')}</Typography>
          <OutlinedInput
            id="outlined-adornment-code"
            type={values.showCode ? 'text' : 'password'}
            value={values.code}
            onChange={handleChange('code')}
            onKeyPress={handleTotpCodeEnterPress}
            fullWidth={true}
            inputRef={totpCodeElementRef}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle code visibility"
                  onClick={handleClickShowCode}
                  onMouseDown={handleMouseDownCode}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={t('login.totp_code_field.placeholder')}
            error={isTotpError}
            sx={isTotpError ? {color: '#FF80A0'} : {color: '#000000'}}
            className={classNames('on-dark-background')}
          />
        </div>
      }
      {isError && (
        <FormHelperText id="component-error-text" sx={{color: '#FF80A0', fontSize: '16px'}}>
          {t('login.message.login_incorrect')}
        </FormHelperText>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '46px',
          height: '44px',
        }}
      >
        <Button
          variant="contained"
          sx={{width: '164px', height: '100%', textTransform: 'capitalize'}}
          onClick={() => login(values.email, values.password, values.code)}
          ref={submitElementRef}
          className="login-button"
        >
          {t('login.button.login')}
        </Button>
        <div className="forgotten-password">
          <Link to="/forgotten-password"><span>{t('login.button.forgotten_password')}</span></Link>
        </div>
      </Box>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen}/>
    </div>
  );
}
