import './RestorePassword.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

import Api from '../../helpers/api/Api';

export default function RestorePassword() {
  const {t, i18n} = useTranslation('login');

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
      className="restore-password"
    >
      <div className="title">{t('restore_password.title')}</div>
      <div className="content">
        {t('restore_password.description')}
      </div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '46px',
        }}
      >
        <Button
          variant="contained"
          sx={{width: '58%', height: '44px'}}
        >
          <Link to="/login" className="link">
            {t('restore_password.button')}
          </Link>
        </Button>
      </Box>
    </Box>
  );
}
