import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import IndexTableManaged from '../../../components/IndexTable/IndexTableManaged';
import {useExecutorsDictionary} from '../../../helpers/dictionaries/hooks/useExecutorsDictionary';
import useTasksListFilters, {
  PARAM_ITEMS_PER_PAGE,
  PARAM_ORDER_BY,
  PARAM_ORDER_DIR,
  PARAM_PAGE,
} from '../hooks/useTasksListFilters';
import getTasksList from './api/getTasksList';
import createTableHeaders from './functions/createTableHeaders';
import taskToTableRow from './functions/taskToTableRow';
import css from './index.module.scss';
import SingleTaskModal from './SingleTaskModal';

function TableBlock() {
  const {t} = useTranslation('page_tasks');

  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [tasksTotal, setTasksTotal] = useState(0);
  const {current, setParams, currentFetchUrl} = useTasksListFilters();

  const [selectedTask, setSelectedTask] = useState(null);

  const executorsDictionary = useExecutorsDictionary();

  const tableHeaders = createTableHeaders(current, t);

  const tableRows = tasks.map((task)=>(
    {fields: taskToTableRow(task, executorsDictionary)}
  ));

  function onHeaderClick(fieldId) {
    const headerDef = tableHeaders.find((header)=>header.id===fieldId);
    if (headerDef?.sortable) {
      if (current.orderBy === fieldId) {
        setParams({
          [PARAM_ORDER_DIR]: current.orderDir === 'asc' ? 'desc' : 'asc',
          [PARAM_PAGE]: 1,
        });
      } else {
        setParams({
          [PARAM_ORDER_BY]: fieldId,
          [PARAM_ORDER_DIR]: 'desc',
          [PARAM_PAGE]: 1,
        });
      }
    }
  }

  function onRowClick(rowIndex) {
    const clickedTask = tasks[rowIndex];
    setSelectedTask(clickedTask);
  }

  function onPageChangeClick(newPageZeroIndexed) {
    let newPage = newPageZeroIndexed + 1;
    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > Math.ceil(tasksTotal/current[PARAM_ITEMS_PER_PAGE]) ) {
      newPage = Math.ceil(tasksTotal/current[PARAM_ITEMS_PER_PAGE]);
    }
    setParams({
      [PARAM_PAGE]: newPage,
    });
  }

  function closeTaskModal(refresh = false) {
    setSelectedTask(null);
    if (!!refresh) {
      refreshTasksList();
    }
  }

  const refreshTasksList = useCallback(()=>{
    setIsLoading(true);
    getTasksList(currentFetchUrl).then((data)=>{
      setTasks(data.tasks);
      setTasksTotal(data.totalItems);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
    });
  }, [currentFetchUrl]);

  useEffect(()=>{
    refreshTasksList();
  }, [refreshTasksList]);

  return (
    <section className={css.TableBlock}>
      <IndexTableManaged
        headers={tableHeaders}
        rows={tableRows}
        pagination={{
          page: parseInt(current[PARAM_PAGE]) - 1,
          rowsPerPage: current[PARAM_ITEMS_PER_PAGE],
          items: tasksTotal,
        }}
        isLoading={isLoading}
        onHeaderClick={onHeaderClick}
        onPageChangeClick={onPageChangeClick}
        onRowClick={onRowClick}
      />
      <SingleTaskModal
        taskIri={!!selectedTask ? selectedTask['@id'] : null}
        onAborted={()=>closeTaskModal(false)}
        onRemoved={()=>closeTaskModal(true)}
        onUpdated={()=>closeTaskModal(true)}
      />
    </section>
  );
}

export default TableBlock;
