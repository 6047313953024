import './CardCompanyPurge.scss';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BasicCard from '../../components/Card/Card';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import Api from '../../helpers/api/Api';
import {CAPABILITY_PURGE} from '../../helpers/security/hasCapability';
import {useStateValue} from '../../state/state';

export default function CardCompanyPurge(props) {
  const {t, i18n} = useTranslation('page_companies');
  const [{user}, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isWorkingNow, setWorkingNow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const canPurge = useHasCapabilityHere(CAPABILITY_PURGE);

  function onConfirmationDialogCanceled() {
    if (!isWorkingNow) {
      setConfirmationOpen(false);
    }
  }

  function onConfirmationDialogConfirmed() {
    if (canPurge) {
      setWorkingNow(true);
      Api.delete(`/companies/${props.company.id}/purge`).then((data) => {
        if (props.goBackTo) {
          navigate(props.goBackTo);
        } else {
          navigate(-1);
        }
      }).catch((error) => {
        if (error?.data?.message) {
          setErrorMessage(`${t('card_purge.feedback.server_error', {message: error?.data?.message})}`);
        } else if (error?.data?.detail) {
          setErrorMessage(`${t('card_purge.feedback.server_error', {message: error?.data?.detail})}`);
        }
      }).finally(() => {
        setWorkingNow(false);
        if (props.onPurgeCompletion) {
          props.onPurgeCompletion();
        }
      });
    }
  }

  return (
    <>
      {(!user || !props.company) &&
        <ProgressIndicator />
      }
      {user && props.company &&
        <>
          <BasicCard
            className="CardCompanyPurge"
            title={t('card_purge.title')}
          >
            <Typography dangerouslySetInnerHTML={{__html: t('card_purge.description1')}}/>
            <Typography dangerouslySetInnerHTML={{__html: t('card_purge.description2')}}/>
            <Typography dangerouslySetInnerHTML={{__html: t('card_purge.description3')}}/>
            {canPurge &&
              <Button variant="outlined" onClick={() => setConfirmationOpen(true)}>
                {t('card_purge.purge_button', {company: props.company?.name})}
              </Button>
            }
          </BasicCard>
          <Dialog
            className="CardCompanyPurge-confirmation-modal"
            open={isConfirmationOpen}
            onClose={onConfirmationDialogCanceled}
          >
            <DialogTitle>{t('card_purge.confirmation_modal.question')}</DialogTitle>
            <DialogContent>
              {!errorMessage &&
                <Typography>{t('card_purge.confirmation_modal.hint')}</Typography>
              }
              {errorMessage &&
                <Typography className="error-feedback" dangerouslySetInnerHTML={{__html: errorMessage}}/>
              }
            </DialogContent>
            <DialogActions>
              {isWorkingNow &&
                <ProgressIndicator/>
              }
              {!isWorkingNow &&
                <>
                  <Button onClick={onConfirmationDialogCanceled} variant="outlined"
                    color="error">{t('card_purge.confirmation_modal.cancel')}</Button>
                  <Button onClick={onConfirmationDialogConfirmed}
                    variant="outlined">{t('card_purge.confirmation_modal.confirm')}</Button>
                </>
              }
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
}

CardCompanyPurge.propTypes = {
  company: PropTypes.object.isRequired,
  onPurgeCompletion: PropTypes.func,
  goBackTo: PropTypes.string,
};
