import {useEffect, useState} from 'react';

import AttachmentsCachedDictionary from '../cachedDictionary/AttachmentsCachedDictionary';

export const useProjectAttachmentsDictionary = (projectId) => {
  const [dataSet, setDataSet] = useState({});
  const [validUntilTimestampSet, setValidUntilTimestampSet] = useState({});

  const key = `attachment-${projectId}`;

  const now = new Date();
  const isValidNow = typeof validUntilTimestampSet[key] !== 'undefined' &&
    validUntilTimestampSet[key] > 0 &&
    validUntilTimestampSet[key] > now.getTime();

  useEffect(()=>{
    if (!!projectId && !isValidNow) {
      const dictionaryInstance = AttachmentsCachedDictionary.getInstance();
      dictionaryInstance.get({projectId: projectId}).then((payload)=> {
        setDataSet({...dataSet, [key]: payload});
        setValidUntilTimestampSet({
          ...validUntilTimestampSet,
          [key]: dictionaryInstance.getFetchedAt().getTime() + dictionaryInstance.getTTL(),
        });
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [projectId, isValidNow, key]);

  return dataSet[key] ?? [];
};
