import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import RelatedProjectsViewer from '../../components/RelatedProjectsViewer/RelatedProjectsViewer';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';
import getUserRelatedProjects from './api/getUserRelatedProjects';

const PAGE_STEP_SIZE = 5;

export default function CardUserRelatedProjects(props) {
  const navigate = useNavigate();
  const [userProjects, setUserProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_STEP_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const userId = props.user && props.user.id ? parseInt(props.user.id) : null;

  function onItemClicked(id, item) {
    const link = getFrontendPathToObject(item);
    if (link) {
      navigate(link);
    }
  }

  function loadMoreClicked() {
    setItemsPerPage(itemsPerPage+PAGE_STEP_SIZE);
  }

  useEffect(function() {
    if (userId !== null) {
      setIsLoading(true);
      getUserRelatedProjects(userId, page, itemsPerPage).then((response) => {
        setUserProjects(response.projects);
        setTotalItems(response.totalItems);
        setIsLoading(false);
      });
    }
  }, [props.user, userId, page, itemsPerPage]);

  if (userId) {
    return (
      <RelatedProjectsViewer
        projects={userProjects}
        isLoading={isLoading}
        showSeeMore={totalItems > userProjects.length}
        onSeeMoreClicked={loadMoreClicked}
        onItemClicked={onItemClicked}
      />
    );
  } else {
    return null;
  }
}

CardUserRelatedProjects.propTypes = {
  user: PropTypes.object,
};
