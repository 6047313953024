import i18n from '../../i18n';
import status2typeString from './status2typeString';

export default function getProjectNormalizedInternalName(project, company) {
  if (!project) {
    return null;
  } else if (!project.status) {
    return project.internalName;
  } else {
    return project.internalName ?
      `${status2typeString(project.status)} ${project.internalName}` :
      `${company ? company.name : project.id} ${status2typeString(project.status).toLowerCase()}`
    ;
  }
}
