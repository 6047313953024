export default function sortCompanyAttachments(attachments, newestFirst) {
  return [...attachments].sort(newestFirst ? attachmentDateComparer : attachmentNameComparer);
}

export const attachmentNameComparer = (attachment1, attachment2) => {
  const attachment1Name = attachment1.originalName.toUpperCase();
  const attachment2Name = attachment2.originalName.toUpperCase();

  if (attachment1Name < attachment2Name) {
    return -1;
  }
  if (attachment1Name > attachment2Name) {
    return 1;
  }

  return 0;
};

export const attachmentDateComparer = (attachment1, attachment2) => {
  const attachment1Date = new Date(attachment1.createdAt);
  const attachment2Date = new Date(attachment2.createdAt);

  if (attachment1Date < attachment2Date) {
    return 1;
  }
  if (attachment1Date > attachment2Date) {
    return -1;
  }

  return 0;
};
