import Api from '../../../helpers/api/Api';

export default function getTotpQrCode() {
  return new Promise(function(resolve, reject) {
    Api.get(`/users/totp_qr?base64=true`).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
