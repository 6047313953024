import Api from '../../../helpers/api/Api';

export default function getCompaniesDictionary() {
  return new Promise(function(resolve, reject) {
    Api.get(`/companies/dictionary?order['lastname']=asc&pagination=false`).then((data)=>{
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
