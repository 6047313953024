import {PARAM_ORDER_BY, PARAM_ORDER_DIR} from '../../hooks/useTasksListFilters';

export default function createTableHeaders(paramObj, t) {
  return [
    {id: 'id', label: t('table.header.id'), width: '75px', sortable: false},
    {id: 'title', label: t('table.header.title'), width: '25%', sortable: true},
    {id: 'relatedProjects', label: t('table.header.relatedProjects'), width: '25%', sortable: false},
    {id: 'executors', label: t('table.header.executors'), sortable: false},
    {id: 'deadlineAt', label: t('table.header.deadlineAt'), sortable: true},
    {id: 'completedAt', label: t('table.header.completedAt'), sortable: true},
    {id: 'status', label: t('table.header.status'), sortable: false},
  ].map((row) => {
    if (paramObj[PARAM_ORDER_BY] === row.id) {
      row.sorted = paramObj[PARAM_ORDER_DIR];
    }
    return row;
  });
}
