import './UserProjects.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import arrowRight from '../../assets/arrow_right.svg';
import caseIcon from '../../assets/case_icon.svg';
import leadIcon from '../../assets/lead_icon.svg';
import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import formatDateShort from '../../helpers/formatters/formatDateShort';
import status2string from '../../helpers/formatters/status2string';
import {CAPABILITY_ASSIGN_PROJECT_USER, CAPABILITY_PURGE} from '../../helpers/security/hasCapability';
import SingleEntityLayout from '../../layouts/SingleEntityLayout/SingleEntityLayout';
import getCompanyClientProjects from '../CompanyDetails/api/getCompanyClientProjects';
import CardUserPurge from '../UserDetails/CardUserPurge';
import getCompany from './api/getCompany';
import getUsersMap from './api/getUsersMap';
import patchUsersMap from './api/patchUsersMap';
import UserData from './UserData';

export default function UserDetailsPage(props) {
  const {t, i18n} = useTranslation('page_companies');

  const [companyData, setCompanyData] = useState(null);
  const [leadFilter, setLeadFilter] = useState('all');
  const [projects, setProjects] = useState([]);
  const [usersMap, setUsersMap] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const companyId = location.pathname.split('/').filter((i) => i.length > 0)[1];
  const userId = location.pathname.split('/').filter((i) => i.length > 0)[3];
  const currentEmployee = companyData?.employees.find((empl) => (empl.user.id === parseInt(userId)));

  useEffect(() => {
    refreshCompanyData();
  }, [companyId, userId]);

  function refreshCompanyData() {
    if (companyId) {
      getCompany(companyId).then((data) => setCompanyData(data));
      getCompanyClientProjects(companyId).then((data) => setProjects(data));
      userId && refreshUsersMap(companyId, userId);
    }
  }
  const refreshUsersMap = (companyId, userId) => {
    getUsersMap(companyId).then((data) => setUsersMap(data.users[`/api/users/${userId}`]?.projects));
  };

  const handleVisibilityChange = (projectId, value) => {
    patchUsersMap(companyId, userId, projectId, value).then(() => refreshUsersMap(companyId, userId));
  };

  const filterLeads = (leads) => {
    if (leadFilter === 'lead') return leads.filter((lead) => lead.status === 'lead_pending' || lead.status === 'lead_rejected');
    if (leadFilter === 'opportunity') return leads.filter((lead) => lead.status === 'opportunity_trajectory' || lead.status === 'opportunity_payment' || lead.status === 'opportunity_memorandum_creating' || lead.status === 'opportunity_memorandum_sent' || lead.status === 'opportunity_resigned');
    if (leadFilter === 'account') return leads.filter((lead) => lead.status === 'account_in_progress' || lead.status === 'account_closed');
    return leads;
  };

  const mapProjects = (projectStatus) => {
    if (projectStatus === 'lead_pending' || projectStatus === 'lead_rejected') {
      return 'leads';
    } else if (projectStatus === 'opportunity_trajectory' || projectStatus === 'opportunity_payment' || projectStatus === 'opportunity_memorandum_creating' || projectStatus === 'opportunity_memorandum_sent' || projectStatus === 'opportunity_resigned') {
      return 'opportunities';
    } else {
      return 'accounts';
    }
  };

  const canChangeVisibility = useHasCapabilityHere(CAPABILITY_ASSIGN_PROJECT_USER);
  const canPurge = useHasCapabilityHere(CAPABILITY_PURGE);
  const view = props.view ?? 'default';

  return (
    <SingleEntityLayout
      leftPanel={ companyData && <UserData companyData={companyData} onRefreshRequest={refreshCompanyData} /> }
      rightClassName={`type-${companyData?.type.replace('_', '-')} ${companyData?.legalForm?.isPersonal ? 'type-is-personal' : ''}`}
    >
      {view === 'default' && companyData?.type === 'client' &&
        <div className="UserProjects">
          <IndexPageHeader text={t('user.title')}/>
          <div className="filters">
            <div className={`filter ${leadFilter === 'all' && 'filter-active'}`}
              onClick={() => setLeadFilter('all')}>{t('all', {ns: 'dictionary_project_type'})}</div>
            <div className={`filter ${leadFilter === 'lead' && 'filter-active'}`}
              onClick={() => setLeadFilter('lead')}>{t('lead', {ns: 'dictionary_project_type'})}</div>
            <div className={`filter ${leadFilter === 'opportunity' && 'filter-active'}`}
              onClick={() => setLeadFilter('opportunity')}>{t('opportunity', {ns: 'dictionary_project_type'})}</div>
            <div className={`filter ${leadFilter === 'account' && 'filter-active'}`}
              onClick={() => setLeadFilter('account')}>{t('account', {ns: 'dictionary_project_type'})}</div>
          </div>
          <div className="leads-grid">
            {projects && filterLeads(projects).map((lead) => <div className="lead-box" key={lead.id}>
              <div className="lead-header" onClick={() => navigate(`/${mapProjects(lead.status)}/${lead.id}`)}>
                <div className="lead-header-left">
                  <img src={leadIcon} alt="lead"/>
                  <span>{lead?.handler?.display || '-'}</span>
                </div>
                <img src={arrowRight} alt="arrow right"/>
              </div>
              <div className="lead-name">{lead?.internalName || '-'}</div>
              <div className="lead-status">{status2string(lead?.status)}</div>
              <div className="lead-primary-user">
                <img src={caseIcon} alt="handler"/>
                <span>{lead?.primaryProjectUser?.display}</span>
              </div>
              <div className="lead-date">{formatDateShort(lead?.lastUpdatedAt)}</div>
              {canChangeVisibility &&
                <div className="lead-visibility">
                  <FormControlLabel control={<Switch checked={usersMap[`/api/projects/${lead.id}`]?.isConnected}
                    onChange={() => handleVisibilityChange(lead.id, !usersMap[`/api/projects/${lead.id}`]?.isConnected)}/>}
                  label={t('user.visibility_switch')}/>
                </div>
              }
            </div>)}
          </div>
        </div>
      }
      {view === 'default' && companyData?.type !== 'client' &&
        <div className="UserProjects">
          <div className="filters">{t('user.no_content', {relation: t(companyData?.type, {ns: 'dictionary_company_type'})})}</div>
        </div>
      }
      {canPurge && view === 'purge' && currentEmployee &&
        <CardUserPurge user={currentEmployee?.user} goBackTo={`/companies/${companyId}`}/>
      }
    </SingleEntityLayout>
  );
}

UserDetailsPage.propTypes = {
  view: PropTypes.oneOf(['default', 'purge']),
};
