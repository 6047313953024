import './AddingTaskModal.scss';

import ClearIcon from '@mui/icons-material/Clear';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import {useStateValue} from '../../state/state';
import EditField from '../EditField/EditField';
import ModalDialog from '../ModalDialog/ModalDialog';
import ServerErrorPopUp from '../Popups/ServerErrorPopUp';
import getCompanies from './api/getCompanies';
import getDocuments from './api/getDocuments';
import getExecutors from './api/getExecutors';
import getNotes from './api/getNotes';
import getProjects from './api/getProjects';
import getTasks from './api/getTasks';
import getUsers from './api/getUsers';
import postTask from './api/postTask';


export const ReferType = {
  user: 'user',
  document: 'document',
  note: 'note',
  task: 'task',
  company: 'company',
  project: 'project',
  executor: 'executor',
  moneySuppliers: 'moneySuppliers',
  intermediares: 'intermediares',
  client: 'client',
  lead: 'lead',
  opportunity: 'opportunity',
  account: 'account',
};

export default function AddingTaskModal(props) {
  const {t} = useTranslation('component_tasks');

  const [{user}] = useStateValue();
  const [taskTitle, setTaskTitle] = useState('');
  const [taskText, setTaskText] = useState('');
  const [taskDeadline, setTaskDeadline] = useState(new Date(new Date().setHours(23, 59, 59, 999)).toISOString());
  const [usersList, setUsersList] = useState([]);
  const [executorsList, setExecutorsList] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [moneySuppliersList, setMoneySuppliersList] = useState([]);
  const [intermediaresList, setIntermediaresList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [leadsList, setLeadsList] = useState([]);
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [refersArray, setRefersArray] = useState([{referType: '', referValue: ''}]);

  const [isNoDeadline, setIsNoDeadline] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const relatedUsers = props.type === 'User' ? [props.projectData.entity.user ? `/api/users/${props.projectData.entity.user.id}` : `/api/users/${props.projectData.entity.id}`] : [];
  const relatedExecutors = [];
  const relatedProjects = props.type === 'Project' ? [props.projectData.entity['@id']] : [];
  const relatedCompanies = props.type === 'Company' ? [props.projectData.entity['@id']] : [];
  const relatedTasks = [];
  const relatedNotes = [];
  const relatedDocuments = [];

  const clearForm = () => {
    setTaskTitle('');
    setTaskText('');
    setRefersArray([{referType: '', referValue: ''}]);
    setTaskDeadline(new Date(new Date().setHours(23, 59, 59, 999)).toISOString());
    setIsNoDeadline(false);
    setActiveStep(0);
    setProjectsList([]);
  };

  const location = useLocation();
  const pathname = location.pathname;

  // below is used also for users and it is depends on pathname, for overview, we don't use id for get data
  const projectId = pathname !== '/overview' ?
  props.type === 'Project' ?
    (props.projectData && props.projectData.entity && props.projectData.entity.id) || '' :
    (props.projectData && props.projectData.entity && props.projectData.entity.user) ?
      (props.projectData.entity.user.id || '') :
      (props.projectData && props.projectData.entity && props.projectData.entity.id) || '' :
  '';

  const referTypeDropdownOptions = props.type === 'Project' ?
    [
      {name: ReferType.user, display: t('reference.type.user')},
      {name: ReferType.document, display: t('reference.type.document')},
      {name: ReferType.note, display: t('reference.type.note')},
      {name: ReferType.task, display: t('reference.type.task')},
      {name: ReferType.executor, display: t('reference.type.executor')},
      {name: ReferType.moneySuppliers, display: t('reference.type.moneySuppliers')},
      {name: ReferType.intermediares, display: t('reference.type.intermediares')},
      {name: ReferType.client, display: t('reference.type.client')},
    ] :
    [
      {name: ReferType.user, display: t('reference.type.user')},
      {name: ReferType.document, display: t('reference.type.document')},
      {name: ReferType.note, display: t('reference.type.note')},
      {name: ReferType.task, display: t('reference.type.task')},
      {name: ReferType.executor, display: t('reference.type.executor')},
      {name: ReferType.moneySuppliers, display: t('reference.type.moneySuppliers')},
      {name: ReferType.intermediares, display: t('reference.type.intermediares')},
      {name: ReferType.client, display: t('reference.type.client')},
      {name: ReferType.lead, display: t('reference.type.lead')},
      {name: ReferType.opportunity, display: t('reference.type.opportunity')},
      {name: ReferType.account, display: t('reference.type.account')},
    ];

  const handleNext = () => {
    setErrorText(null);
    if (taskTitle.length > 0 && taskText.length > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrorText(t('has_missing_fields'));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  function handleAddingTaskModalClose() {
    clearForm();
    setErrorText(null);
    props.handleAddingTaskPopupClose();
  }

  function mapRefersArray() {
    refersArray.map((item) => {
      if (item.referType === ReferType.user) {
        relatedUsers.push(item.referValue);
      } else if (item.referType === ReferType.executor) {
        relatedExecutors.push(item.referValue);
      } else if (item.referType === ReferType.document) {
        relatedDocuments.push(item.referValue);
      } else if (item.referType === ReferType.note) {
        relatedNotes.push(item.referValue);
      } else if (item.referType === ReferType.task) {
        relatedTasks.push(item.referValue);
      } else if (item.referType === ReferType.moneySuppliers) {
        relatedCompanies.push(item.referValue);
      } else if (item.referType === ReferType.intermediares) {
        relatedCompanies.push(item.referValue);
      } else if (item.referType === ReferType.client) {
        relatedCompanies.push(item.referValue);
      } else if (item.referType === ReferType.lead) {
        relatedProjects.push(item.referValue);
      } else if (item.referType === ReferType.opportunity) {
        relatedProjects.push(item.referValue);
      } else if (item.referType === ReferType.account) {
        relatedProjects.push(item.referValue);
      }
    });
  }

  function addTask() {
    mapRefersArray();
    setErrorText(null);
    postTask({
      title: taskTitle,
      text: taskText,
      createdAt: new Date(),
      deadlineAt: !isNoDeadline ? taskDeadline : null,
      completedAt: null,
      creator: `/api/users/${user.id}`,
      executors: (relatedExecutors.length > 0) ? relatedExecutors : [`/api/users/${user.id}`],
      relatedUsers: relatedUsers[0] === '' ? [] : relatedUsers,
      relatedProjects: relatedProjects[0] === '' ? [] : relatedProjects,
      relatedCompanies: relatedCompanies[0] === '' ? [] : relatedCompanies,
      relatedTasks: relatedTasks[0] === '' ? [] : relatedTasks,
      relatedNotes: relatedNotes[0] === '' ? [] : relatedNotes,
      relatedAttachments: relatedDocuments[0] === '' ? [] : relatedDocuments,
    }).then(() => {
      props.refreshTaskList();
      clearForm();
      handleAddingTaskModalClose();
    }).catch((error) => {
      console.error(error);
      setErrorText(t('add_modal.feedback.unknown'));
    });
  }

  useEffect(() => {
    if (props.isAddingTaskModalOpen) {
      getUsers().then((data) => {
        setUsersList(
            data.map((user) => ({name: user['@id'], display: user['dropdownIdentifier']})),
        );
      });
      getExecutors().then((data) => {
        setExecutorsList(
            data.map((executor) => ({name: executor['@id'], display: executor['dropdownIdentifier']})),
        );
      });
      getTasks(projectId).then((data) => {
        setTasksList(
            data.map((task) => ({name: task['@id'], display: task['dropdownIdentifier']})),
        );
      });
      getNotes(projectId).then((data) => {
        setNotesList(
            data.map((note) => ({name: note['@id'], display: note['dropdownIdentifier']})),
        );
      });
      getDocuments(projectId).then((data) => {
        setDocumentsList(
            data.map((document) => ({name: document['@id'], display: document['dropdownIdentifier']})),
        );
      });
      getProjects().then((data) => {
        setProjectsList(
            data.map((project) => ({name: project['@id'], display: project['dropdownIdentifier']})),
        );
        setLeadsList(data.filter((project) => (project['status'] === 'lead_pending' || project['status'] === 'lead_rejected')).map((project) => ({name: project['@id'], display: project['dropdownIdentifier']})));
        setOpportunitiesList(data.filter((project) => (project['status'] === 'opportunity_trajectory' || project['status'] === 'opportunity_payment' || project['status'] === 'opportunity_memorandum_creating' || project['status'] === 'opportunity_memorandum_sent' || project['status'] === 'opportunity_resigned')).map((project) => ({name: project['@id'], display: project['dropdownIdentifier']})));
        setAccountsList(data.filter((project) => (project['status'] === 'account_in_progress' || project['status'] === 'account_in_progress')).map((project) => ({name: project['@id'], display: project['dropdownIdentifier']})));
      });
      getCompanies().then((data) => {
        setCompaniesList(
            data.map((company) => ({name: company['@id'], display: company['name']})),
        );
        setMoneySuppliersList(data.filter((company) => company['type'] === 'money_supplier').map((company) => ({name: company['@id'], display: company['name']})));
        setIntermediaresList(data.filter((company) => company['type'] === 'intermediary').map((company) => ({name: company['@id'], display: company['name']})));
        setClientsList(data.filter((company) => company['type'] === 'client').map((company) => ({name: company['@id'], display: company['name']})));
      });
    }
  }, [projectId, props.isAddingTaskModalOpen, user.id, user.primaryRole]);

  return (
    <>
      <ModalDialog
        className="AddingTask"
        open={props.isAddingTaskModalOpen}
        onCloseRequest={handleAddingTaskModalClose}
      >
        <div className="Title">{t('add_modal.title')}</div>
        {activeStep === 0 && <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '28px',
            height: '660px',
          }}
        >
          <div className="FormField">
            <EditField
              id="taskTitle"
              name={t('add_modal.field.title.description')}
              value={taskTitle}
              type="string"
              placeholder={t('add_modal.field.title.placeholder')}
              editing={true}
              onChange={(_, newTitleValue) => setTaskTitle(newTitleValue)}
            />
          </div>
          <div className="FormField">
            <EditField
              id="taskDescription"
              name={t('add_modal.field.description.description')}
              value={taskText}
              minRows={13}
              maxRows={13}
              type="stringTextArea"
              placeholder={t('add_modal.field.description.placeholder')}
              editing={true}
              onChange={(_, newTextValue) => setTaskText(newTextValue)}
            />
          </div>
          {typeof errorText === 'string' &&
            <Box sx={{margin: '5px auto'}}>
              <Typography className="ErrorMessage" color={'error'}>{errorText}</Typography>
            </Box>
          }
          <div className="DeadlineWrapper">
            <div className="FormField">
              <EditField
                id="taskDeadline"
                name={t('add_modal.field.deadline.description')}
                value={taskDeadline}
                type="datepicker"
                editing={true}
                onChange={(_, newDeadlineValue) => setTaskDeadline(newDeadlineValue)}
              />
            </div>
            <FormControlLabel control={<Checkbox checked={isNoDeadline} onChange={() => setIsNoDeadline(!isNoDeadline)} />} label={t('add_modal.field.deadline.no_deadline')} />
          </div>
        </Box>}
        {activeStep === 1 && <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'start',
            marginTop: '14px',
            minHeight: '500px',
            width: '500px',

          }}
        >
          {refersArray.map((refer, index) =>
            <Box key={index} sx={{display: 'flex', flexDirection: 'row', height: '100px'}}>
              <EditField
                id="referType"
                name={t('add_modal.field.reference.description_type')}
                value={refer.referType}
                type="dropdown"
                placeholder={t('add_modal.field.reference.placeholder_type')}
                options={referTypeDropdownOptions}
                editing={true}
                onChange={(fieldId, newReferTypeValue) => {
                  const newRefers = [...refersArray];
                  newRefers[index] = {
                    ...newRefers[index], referType: newReferTypeValue,
                  };
                  setRefersArray(newRefers);
                }}
                className="ReferType"
              />
              <EditField
                id="refer"
                name={t('add_modal.field.reference.description_object')}
                value={refer.referValue}
                type="autocomplete"
                placeholder={t('add_modal.field.reference.placeholder_object')}
                options={refer.referType === ReferType.user ? usersList : (refer.referType === ReferType.executor) ?
                                                            executorsList : (refer.referType === ReferType.document) ?
                                                            documentsList : (refer.referType === ReferType.note) ?
                                                            notesList : (refer.referType === ReferType.task) ?
                                                            tasksList : (refer.referType === ReferType.moneySuppliers) ?
                                                            moneySuppliersList : (refer.referType === ReferType.intermediares) ?
                                                            intermediaresList : (refer.referType === ReferType.client) ?
                                                            clientsList : (refer.referType === ReferType.lead) ?
                                                            leadsList : (refer.referType === ReferType.opportunity) ?
                                                            opportunitiesList : (refer.referType === ReferType.account) ?
                                                            accountsList : []}
                editing={true}
                onChange={(fieldId, newReferValue) => {
                  const newRefers = [...refersArray];
                  newRefers[index] = {
                    ...newRefers[index], referValue: newReferValue,
                  };
                  setRefersArray(newRefers);
                }}
                className="Refer"
              />
              <IconButton aria-label="delete" color="red" sx={{margin: '50px 0'}} onClick={
                () => {
                  const newRefersArray = [...refersArray];
                  newRefersArray.splice(index, 1);
                  setRefersArray(newRefersArray);
                }
              }>
                <ClearIcon />
              </IconButton>
            </Box>)}
          <Button
            size="small"
            onClick={() => setRefersArray([...refersArray, {referType: '', referValue: ''}])}
            sx={{fontSize: '16px'}}
          >
            {t('add_modal.action.add_reference')}
          </Button>
        </Box>}
        <MobileStepper
          variant="text"
          steps={2}
          position="static"
          activeStep={activeStep}
          nextButton={
            <>
              {activeStep === 0 && <Button
                size="small"
                variant="contained"
                onClick={handleNext}
                sx={{fontSize: '16px', marginRight: '48px'}}
              >
                {t('add_modal.action.next')}
              </Button>}
              {activeStep === 1 &&
                <div className="RightButton">
                  <Button
                    size="small"
                    onClick={handleBack}
                    sx={{color: '#000000', fontSize: '16px'}}
                  >
                    {t('add_modal.action.back')}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={addTask}
                    sx={{fontSize: '16px'}}
                  >
                    {t('add_modal.action.create')}
                  </Button>
                </div>}
            </>
          }
          backButton={
            <Button size="small" sx={{color: '#000000', fontSize: '16px'}} onClick={handleAddingTaskModalClose}>
              {t('add_modal.action.cancel')}
            </Button>
          }
          sx={{width: '103%', margin: '0 0 -33px 0'}}
        />
      </ModalDialog>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen} />
    </>
  );
}
