import Api from '../../../helpers/api/Api';

export default function patchUsersMap(companyId, userId, projectId, value) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/companies/${companyId}/project_users_map`, {
      users: {
        [`/api/users/${userId}`]: {
          projects: {
            [`/api/projects/${projectId}`]: {
              isConnected: value,
            },
          },
        },
      },
    }).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
