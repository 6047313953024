import './Search.scss';

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDebounce} from 'use-debounce';

import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTable from '../../components/IndexTable/IndexTable';
import ShowSingleNoteModal from '../../components/ShowSingleNoteModal/ShowSingleNoteModal';
import ShowSingleTaskModal from '../../components/ShowSingleTaskModal/ShowSingleTaskModal';
import Api from '../../helpers/api/Api';
import formatDateShort from '../../helpers/formatters/formatDateShort';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {useStateValue} from '../../state/state';
import deleteNote from './api/deleteNote';
import deleteTask from './api/deleteTask';
import patchNote from './api/patchNote';
import patchTask from './api/patchTask';
import postSearch from './api/postSearch';
import searchResultsToTableData from './convert/searchResultsToTableData';
import css from './Search.scss';

export default function SearchPage() {
  const {t, i18n} = useTranslation('page_search');
  const [{user}, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page') ? searchParams.get('page') : 1;
  const currentQuery = searchParams.get('query') ? searchParams.get('query') : '';

  const [text, setText] = useState(currentQuery);
  const [value] = useDebounce(text, 700);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [searchedData, setSearchedData] = useState([]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskInfo, setTaskInfo] = useState({
    projectId: user.id,
    creator: {},
    taskTitle: '',
    taskText: '',
    deadline: '',
    refers: [],
    completedAt: '',
  });
  const [isPopupNote, setIsPopupNote] = useState(false);
  const [noteInfo, setNoteInfo] = useState({});
  const [textNote, setTextNote] = useState('');

  function onSearch(term) {
    setSearchString(term);
  }

  const handleNotePopupClose = () => {
    setIsPopupNote(false);
  };

  const changeSearchQuery = useCallback(async () => {
    setSearchString(searchString);
    const searchText = searchString || text;

    postSearch({
      searchText: searchText,
      scope: ['project', 'company', 'user', 'note', 'task', 'attachment'],
      page: page,
    }).then((response) => {
      setSearchedData(response.data);
    });
  }, [searchString, text]);


  useEffect(() => {
    changeSearchQuery();
  }, [changeSearchQuery]);


  const entityTypeDictionary = {lead: t('entityType.lead'), opportunity: t('entityType.opportunity'), account: t('entityType.account'), money_supplier: t('entityType.moneySupplier'), client: t('entityType.client'), intermediary: t('entityType.intermediary')};
  const searchedAllArrayForTable = searchedData.map((item) => ({
    id: item.id,
    entity: item.entity,
    relatedProject: item.project ?? null,
    type: item.entity === 'Project' ? entityTypeDictionary[item.type] : item.entity === 'Company' ? entityTypeDictionary[item.type] : item.entity,
    display: item['name'] || item['internalName'] || item['originalName'] || item['title'] || item['shortenedText'] || item['dropdownIdentifier'] || item['display'] || item['text'],
  }));

  const tableRows = searchedAllArrayForTable.map((tableRow) => searchResultsToTableData(tableRow));

  function onPageChanged(newPage) {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
    setPage(newPage);
  }

  const onCloseTaskModal = () => {
    setIsTaskModalOpen(false);
  };

  const removeTask = (taskId) => {
    deleteTask(taskId).then(() => {
      onCloseTaskModal();
    });
  };

  const changeTask = (taskId, newTitle, newText, newDeadline, newUsers, newDocuments, newProjects, newCompanies, newNotes, newTask, newExecutors, completedAt) => {
    patchTask(taskId, newTitle, newText, newDeadline, newUsers, newDocuments, newProjects, newCompanies, newNotes, newTask, newExecutors, completedAt).then(() => {
      onCloseTaskModal();
    });
  };

  const getTaskInfo = (id) => {
    async function fetchData() {
      const taskData = await Api.get(`tasks/${id}`);
      setTaskInfo({
        taskId: taskData.id,
        creator: taskData.creator,
        taskTitle: taskData.title,
        taskText: taskData?.text,
        deadline: taskData.deadlineAt,
        refers: [taskData.relatedUsers, taskData.relatedAttachments, taskData.relatedTasks, taskData.relatedCompanies, taskData.relatedNotes, taskData.relatedProjects],
        executors: taskData.executors,
        completedAt: taskData.completedAt,
      });
    }
    fetchData();
  };

  const removeNote = (noteId) => {
    deleteNote(noteId).then(() => {
      handleNotePopupClose();
    });
  };

  const changeNoteText = (noteId, newText) => {
    patchNote(noteId, newText).then(() => {});
    handleNotePopupClose();
  };

  const getNoteInfo = (id) => {
    async function fetchData() {
      const noteData = await Api.get(`notes/${id}`);
      setNoteInfo({
        id: noteData.id,
        author: noteData.author,
        content: noteData.text,
        createdAt: formatDateShort(noteData.createdAt),
      });
      setIsPopupNote(true);
      setTextNote(noteData.text);
    }
    fetchData();
  };

  function openSearchedResultDetailsByRowId(rowId) {
    const searchedItem = searchedAllArrayForTable.find((searchedResult) => searchedResult.id === rowId);
    if (searchedItem.entity === 'Company') {
      navigate(`/companies/${searchedItem.id}`);
    } else if (searchedItem.entity === 'Project') {
      if (searchedItem.type === 'Lead') {
        navigate(`/leads/${searchedItem.id}`);
      } else if (searchedItem.type === 'Opportunity') {
        navigate(`/opportunities/${searchedItem.id}`);
      } else if (searchedItem.type === 'Account') {
        navigate(`/accounts/${searchedItem.id}`);
      }
    } else if (searchedItem.type === 'Task') {
      getTaskInfo(searchedItem.id);
      setIsTaskModalOpen(true);
    } else if (searchedItem.type === 'Attachment') {
      if (searchedItem.relatedProject.type === 'lead') {
        navigate(`/leads/${searchedItem.relatedProject.id}`);
      } else if (searchedItem.relatedProject.type === 'opportunity') {
        navigate(`/opportunities/${searchedItem.relatedProject.id}`);
      } else if (searchedItem.relatedProject.type === 'account') {
        navigate(`/accounts/${searchedItem.relatedProject.id}`);
      }
    } else if (searchedItem.type === 'Note') {
      getNoteInfo(searchedItem.id);
      setIsPopupNote(true);
    } else if (searchedItem.type === 'User') {
      navigate(`/users/${searchedItem.id}`);
    }
  }
  return (
    <DefaultLayout>
      <IndexPageHeader
        text={t('page.title')}
      />
      <div className="section">
        <div className="section-breakable">
          <GlobalSearch
            callback={onSearch}
            placeholder={t('search.placeholder')}
            page={1}
          />
        </div>
        <div className="section-filler" />
      </div>

      <div className="Search-wrapper">
        <IndexTable
          className={css.SearchResultsTable}
          headers={[
            {sortField: 'id', label: t('table.header.id')},
            {sortField: 'type', label: t('table.header.type'), width: '20%'},
            {sortField: 'name', label: t('table.header.name')},
          ]}
          rows={tableRows}
          rowsKeyIndex={0}
          rowsPerPage={10}
          page={currentPage}
          onPageChanged={onPageChanged}
          onRowClicked={openSearchedResultDetailsByRowId}
        />
      </div>
      {isTaskModalOpen &&<ShowSingleTaskModal handleShowSingleTaskPopupClose={onCloseTaskModal} isShowSingleTaskModalOpen={isTaskModalOpen} taskInfo={taskInfo} removeTask={removeTask} changeTask={changeTask} />}
      {isPopupNote &&<ShowSingleNoteModal handleNotePopupClose={handleNotePopupClose} onChange={setTextNote} isPopupNote={isPopupNote} noteInfo={noteInfo} textNote={textNote} removeNote={removeNote} changeNoteText={changeNoteText} />}
    </DefaultLayout>
  );
}
