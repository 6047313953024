import './DocumentItemOnDashboard.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';

export default function DocumentItemOnDashboard(props) {
  function navigateToProject() {
    props.onItemClicked(props.attachment);
  }

  return (
    <div className={`DocumentItemOnDashboard ${props.className}`}>
      <div className="DocumentItemOnDashboard-inner">
        <div className="DocumentItemOnDashboard-section DocumentItemOnDashboard-section-main">
          <Typography className="document-filename" variant="body2" sx={{wordBreak: 'break-all'}}>
            {!isNaN(props.index) &&
              <span>{props.index}) </span>
            }
            {props.attachment.originalName}
          </Typography>
          <div className="document-metadata">
            <div className="DocumentItemOnDashboard-status-narrow">
              {createStatusField(props.attachment.status)}
            </div>
            {props.attachment.project.internalName &&
            <Typography variant="smallHint" sx={{whiteSpace: 'nowrap'}}>
              {props.attachment.project.internalName}
              <span className="bullet-point"> &bull;</span>
            </Typography>
            }
            <Typography variant="smallHint" sx={{whiteSpace: 'nowrap'}}>
              {formatDateTimestamp(props.attachment.updatedAt)}
            </Typography>
          </div>
        </div>
        <div className="DocumentItemOnDashboard-section DocumentItemOnDashboard-status-wide">
          {createStatusField(props.attachment.status)}
        </div>
        <div className="DocumentItemOnDashboard-section">
          <Button
            className="RecentActivityItem-goto"
            size="small"
            variant="text"
            onClick={navigateToProject}
          >
            <ChevronRightIcon color="disabled" />
          </Button>
        </div>
      </div>
    </div>
  );
}

DocumentItemOnDashboard.propTypes = {
  attachment: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    'status': PropTypes.oneOf([
      'signed',
      'to_sign',
      'unsigned',
      'other',
    ]).isRequired,
    'originalName': PropTypes.string.isRequired,
    'updatedAt': PropTypes.string.isRequired,
    'project': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.string.isRequired,
      'internalName': PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number,
  onItemClicked: PropTypes.func.isRequired,
};

function createStatusField(status) {
  const style = {};
  switch (status) {
    case 'signed': break;
    case 'to_sign': break;
    case 'unsigned': style.color = '#FF7A00'; break;
    default:
  }
  return <Typography variant="body2" sx={style}>{status2string(status)}</Typography>;
}

function status2string(status) {
  switch (status) {
    case 'signed': return 'Signed';
    case 'to_sign': return 'To sign';
    case 'unsigned': return 'Unsigned';
    default: return 'Other';
  }
}
