import Api from '../../../helpers/api/Api';

export default function postMergeCompanies(primaryCompany, secondaryCompany) {
  return new Promise(function(resolve, reject) {
    if (primaryCompany === null || secondaryCompany === null || primaryCompany===secondaryCompany) {
      reject();
    }

    Api.post('/merge/companies', {
      primary: primaryCompany,
      secondary: secondaryCompany,
    }).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
