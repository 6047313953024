import i18n from '../../i18n';

export default function formatDateHuman(isoString) {
  const date = new Date(isoString);
  if (!date instanceof Date || isNaN(date)) {
    return null;
  }

  const locale = i18n.t('locale', {ns: 'formatting'}) === 'formatting:locale' ?
    'en-US' :
    i18n.t('locale', {ns: 'formatting'});

  return new Intl.DateTimeFormat(
      locale,
      i18n.t('date.human', {ns: 'formatting', returnObjects: true}),
  ).format(date);
}
