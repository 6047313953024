import './UnseenNotificationsList.scss';

import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import NotificationItem from '../../components/NotificationItem/NotificationItem';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';


export default function UnseenNotificationsList(props) {
  const navigate = useNavigate();

  function onUnseenClicked(item) {
    props.markAsSeen(item);
  }

  function navigateOut(item) {
    const target = getFrontendPathToObject(item.related && item.related.project);
    if (item.seenAt === null) {
      props.markAsSeen(item, ()=> {
        navigate(target);
      });
    } else {
      navigate(target);
    }
  }

  return (
    <div className="UnseenNotificationsList">
      {props.notifications.length > 0 && props.notifications.map((notification) => {
        return (
          <div className="UnseenNotificationsList-item" key={notification['@id']}>
            <NotificationItem
              notification={notification}
              onUnseenClicked={onUnseenClicked}
              onNavigateClicked={navigateOut}
            />
          </div>
        );
      })}
      {props.notifications.length === 0 &&
          <div className="UnseenNotificationsList-empty">
            <Typography>No unread notifications</Typography>
          </div>
      }
    </div>
  );
}

UnseenNotificationsList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  notificationsCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  markAsSeen: PropTypes.func.isRequired,
};
