import i18n from '../../i18n';
import {ROLE_SUPERADMIN} from '../security/listAllRoles';

export default function user2securityContextsString(user) {
  if (user?.primaryRole === ROLE_SUPERADMIN) {
    return i18n.t('security_context.superadmin', {ns: 'login'});
  } else if (user?.securityContexts) {
    return user.securityContexts.map((sc) => i18n.t(`security_context.name.${sc.name}`, {ns: 'login'})).join(', ');
  } else {
    return '';
  }
}
