import Api from '../../../helpers/api/Api';

export default function postSetEmployeeAsPrimaryCompanyUser(employeeId) {
  return new Promise(function(resolve, reject) {
    Api.post(`/employees/${employeeId}/set_as_primary`, {}).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
