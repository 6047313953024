import Api from '../../../helpers/api/Api';

export default function deleteCompanyAttachment(id) {
  return new Promise(function(resolve, reject) {
    Api.delete(`/company_attachments/${id}`).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
