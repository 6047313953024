import PropTypes from 'prop-types';
import React from 'react';

import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import CardProjectRecentActivity from './CardProjectRecentActivity';
import css from './index.module.scss';

function ProjectRecentActivityPanel({project}) {
  if (!project) return <ProgressIndicator />;

  return (
    <div className={css.ProjectRecentActivityPanel}>
      <CardProjectRecentActivity project={project} />
    </div>
  );
}

ProjectRecentActivityPanel.propTypes = {
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']),
  }),
};
export default ProjectRecentActivityPanel;
