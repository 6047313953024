import LoginLayout from '../../layouts/LoginLayout';
import RestorePassword from './RestorePassword';

export function RestorePasswordPage() {
  return (
    <LoginLayout>
      <RestorePassword />
    </LoginLayout>
  );
}
