import './LeftMenu.scss';

import {Menu} from '@mui/icons-material';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';

import hasSlugAccess from '../../helpers/security/hasSlugAccess';
import menu_config from '../../menu_config.json';
import {useStateValue} from '../../state/state';
import LeftMenuItem from './LeftMenuItem';

export default function LeftMenu() {
  const [{user, unseenNotificationsCount}, dispatch] = useStateValue();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const location = useLocation();
  const currentSlug = location.pathname.split('/').filter((i) => i.length > 0)[0];

  function menuToggleOpen(setTo = null) {
    if (typeof setTo === 'boolean') {
      setIsMenuOpened(setTo);
    } else {
      setIsMenuOpened(!isMenuOpened);
    }
  }

  return (
    <div className="LeftMenu">
      <div className="LeftMenu-platform">
        <div className="LeftMenu-toggle" onClick={()=>menuToggleOpen()}>
          <Menu />
        </div>
        <div className="LeftMenu-company">
          <div className="LeftMenu-logo" />
        </div>
      </div>
      <div className={`LeftMenu-items ${isMenuOpened ? 'is-opened' : 'is-closed'}`}>
        {menu_config.map((item)=>{
          if (hasSlugAccess(user, item.slug)) {
            const unseenCount = item.slug === 'notifications' && unseenNotificationsCount > 0 ? (unseenNotificationsCount < 10 ? unseenNotificationsCount : '9+') : null;
            return (
              <React.Fragment key={item.slug}>
                {item.slug !== 'search' &&
                              <LeftMenuItem
                                text={item.text}
                                textI18nKey={item.text_i18n_key}
                                slug={item.slug}
                                icon={item.icon}
                                isMenuOpened={isMenuOpened}
                                isActive={currentSlug === item.slug}
                                unseenCount={unseenCount}
                              />
                }
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}
