import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import {useCompaniesDictionary} from '../../../../helpers/dictionaries/hooks/useCompaniesDictionary';
import useTasksListFilters, {
  PARAM_ONLY_FOR_COMPANY_ID, PARAM_PAGE,
} from '../../hooks/useTasksListFilters';
import css from './index.module.scss';

function CompanyFilter() {
  const {t} = useTranslation('page_tasks');
  const {current, setParams} = useTasksListFilters();

  const companiesDictionary = useCompaniesDictionary();

  const currentDropdownValue = !isNaN(parseInt(current[PARAM_ONLY_FOR_COMPANY_ID])) ?
    `/api/companies/${current[PARAM_ONLY_FOR_COMPANY_ID]}` :
    '';

  const companiesDropdownList = useMemo(()=>{
    return [
      {name: '', display: t('filter.company.options.all')},
      ...companiesDictionary.map((comp) => (
        {name: comp['@id'], display: comp.dropdownIdentifier}
      )),
    ];
  }, [companiesDictionary, t]);


  function setCompanyRelation(id, val) {
    const selectedId = typeof val === 'string' && val.length > 0 ?
      parseInt(val.replace('/api/companies/', '')) :
      null;

    setParams({
      [PARAM_ONLY_FOR_COMPANY_ID]: selectedId,
      [PARAM_PAGE]: 1,
    });
  }

  return (
    <div className={css.filterWrapper}>
      <EditField
        className={classNames(css.filter, css.noPadding)}
        typeOptions={{innerClassName: css.inputHeight}}
        id="executorsList"
        value={currentDropdownValue}
        type="autocomplete"
        placeholder={t('filter.company.placeholder')}
        options={companiesDropdownList ?? []}
        editing={true}
        onChange={setCompanyRelation}
      />
    </div>
  );
}

export default CompanyFilter;
