import Api from '../../../helpers/api/Api';

export default function getCompany(id) {
  return new Promise(function(resolve, reject) {
    let url;
    if (isNaN(id) && typeof id === 'string') {
      const stripped = parseInt(id.replace('/api/companies/', ''));
      url = `/companies/${stripped}`;
    } else {
      url = `/companies/${id}`;
    }

    Api.get(url).then((data)=> {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
