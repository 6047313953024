import './RelatedProjectsViewer.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import status2string from '../../helpers/formatters/status2string';
import status2typeString from '../../helpers/formatters/status2typeString';
import BasicCard from '../Card/Card';
import RelatedProjectItem from './RelatedProjectItem';

export default function RelatedProjectsViewer(props) {
  const {t, i18n} = useTranslation('component_related_projects');

  function onItemClicked(projectId) {
    if (typeof props.onItemClicked === 'function') {
      const project = props.projects.find((a) => a['@id'] === projectId);
      props.onItemClicked(projectId, project);
    }
  }

  return (
    <BasicCard
      className="RelatedProjectsViewer"
      title={t('panel_title')}
      appendToFooter={<>
        <CardActions
          className="BasicCard-footer"
          sx={{marginTop: 'auto', textAlign: 'right'}}
        >
          <Button size={'small'} sx={{paddingLeft: '15px'}} onClick={props.onSeeMoreClicked}>{props.showSeeMore ? t('see_more') : null}</Button>
        </CardActions>
      </>}
    >
      {props.projects.map((project, projectIdx) => {
        const isLast = props.projects.length - projectIdx === 1;
        const projectTypeString = status2string(project.status);
        const projectStatusString = status2typeString(project.status);
        return (
          <RelatedProjectItem
            key={project['@id']}
            iri={project['@id']}
            handler={project.handlerDisplay}
            name={project.internalName ?? `${projectTypeString} ${project.id}`}
            type={projectTypeString}
            status={projectStatusString}
            primaryProjectUser={project.primaryProjectUser ? project.primaryProjectUser.display : t('no_contact')}
            timestamp={formatDateTimestamp(project.lastUpdatedAt)}
            withUnderline={!isLast}
            onClick={onItemClicked}
          />
        );
      })
      }
      {!props.isLoading && props.projects.length === 0 &&
        <Typography fontStyle="italic" fontSize="small" align="center" color="lightgray" sx={{marginTop: '32px'}}>{t('no_result')}</Typography>
      }
      {props.isLoading &&
        <ProgressIndicator />
      }
    </BasicCard>
  );
}

RelatedProjectsViewer.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  onSeeMoreClicked: PropTypes.func.isRequired,
  onItemClicked: PropTypes.func,
};
