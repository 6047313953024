import Api from '../../../helpers/api/Api';

export default function postNote(data, projectId) {
  return new Promise(function(resolve, reject) {
    Api.post(`/projects/${projectId}/note`, data).then((data)=>{
      resolve(data);
    }, projectId).catch((error) => {
      reject(error);
    });
  });
}
