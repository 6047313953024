import formatDateShort from '../../../helpers/formatters/formatDateShort';
import getUserActivationStatus from '../../../helpers/formatters/getUserActivationStatus';

export default function employeeToTableData(employee) {
  const tag = employee.isPrimary ? 'strong' : 'span';
  return [
    `<${tag}>${employee.display.length >= 20 ? employee.display.substring(0, 19) + '...' : employee.display}</${tag}>`,
    `<${tag}>${getUserActivationStatus(employee)}</${tag}>`,
    `<${tag}>${employee.user.email ?? ''}</${tag}>`,
    `<${tag}>${employee.primaryPhone ?? ''}</${tag}>`,
    `<${tag}>${formatDateShort(employee.createdAt)}</${tag}>`,
  ];
}
