import Api from '../../../helpers/api/Api';

export default function getCompanyNamesList() {
  return new Promise(function(resolve, reject) {
    Api.get(`/companies/names?type=client`).then((data)=> {
      resolve(data.companies);
    }).catch((error) => {
      reject(error);
    });
  });
}
