import './HorizontalDivider.scss';

import PropTypes from 'prop-types';
import React from 'react';

export default function HorizontalDivider(props) {
  return (
    <hr className="HorizontalDivider" />
  );
}

HorizontalDivider.propTypes = {

};
