import Api from '../../../../helpers/api/Api';

export default function getUsersList(urlParams) {
  return new Promise(function(resolve, reject) {
    Api.get(`/users?${urlParams}`).then((data)=>{
      resolve({
        users: data['hydra:member'],
        items: parseInt(data['hydra:totalItems']),
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
