import './SingleAddressPanel.scss';

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function SingleAddressPanel(props) {
  return (
    <div className="SingleAddressPanel">
      <Typography variant="h2" className="SingleAddressPanel-title">{props.title}</Typography>
      {props.items.map((item, itemIdx) => {
        let element='';
        if (typeof (item) === 'object' && typeof (item.text) === 'string' && typeof (item.href) === 'string') {
          element = (
            <a href={item.href} className="link">
              <Typography variant="notification" className="link-text">{item.text}</Typography>
            </a>
          );
        } else if (typeof (item) === 'object' && typeof (item.text) === 'string') {
          element = (
            <Typography variant="notification">{item.text}</Typography>
          );
        } else {
          element = (
            <Typography variant="notification">{item}</Typography>
          );
        }
        return <div key={itemIdx}>{element}</div>;
      },
      )}
    </div>
  );
}

SingleAddressPanel.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ])).isRequired,
};
