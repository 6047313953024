import Api from '../../../helpers/api/Api';

const itemsPerPage = 10000;

export default function getUsers() {
  return new Promise(function(resolve, reject) {
    Api.get(`/users/dictionary?itemsPerPage=${itemsPerPage}&order['lastname']=asc`).then((data)=>{
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
