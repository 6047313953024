import './ChangeProjectStatusPopUp.scss';

import {MenuItem, Select, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import infoIcon from '../../assets/informer_warning.svg';
import getPossibleProjectStatuses from '../../helpers/dictionaries/getPossibleProjectStatuses';
import status2string from '../../helpers/formatters/status2string';
import status2typeString from '../../helpers/formatters/status2typeString';
import EditField from '../EditField/EditField';
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';
import Switch from '../Switch/Switch';
import postChangeProjectStatus from './api/postChangeProjectStatus';

export default function ChangeProjectStatusPopUp(props) {
  const {t} = useTranslation('page_cases');
  const [shouldNotifyClient, setNotifyClient] = useState(true);
  const [internalName, setInternalName] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [isMissingInternalName, setMissingInternalName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function changeStatus() {
    if (props.showInternalName && props.requireInternalName && (typeof internalName !== 'string' || internalName.length === 0)) {
      setMissingInternalName(true);
    } else {
      const data = {
        newStatus: newStatus,
        notifyClient: props.showNotifyClient && shouldNotifyClient,
      };
      if (props.showInternalName) {
        data.projectName = internalName;
      }
      if (props.shouldForceChange === true) {
        data.forceChange = true;
      }
      setIsLoading(true);
      postChangeProjectStatus(props.project.id, data).then(()=>{
        if (props.onSave) {
          props.onSave();
        }
        setIsLoading(false);
        props.onClose(true);
      }).catch((error)=>{
        console.error(error);
        setIsLoading(false);
      });
    }
  }

  function abortDialog() {
    props.onClose(false);
  }

  useEffect(()=>{
    if (props.isOpen) {
      setNewStatus(props.newStatus);
      setInternalName(props.project.internalName ?? '');
      setNotifyClient(props.notifyClientDefault!==false);
      setMissingInternalName(false);
      setIsLoading(false);
    }
  }, [props.isOpen, props.newStatus, props.notifyClientDefault, props.project.internalName]);

  const statusChangeFrom = status2typeString(props.project.status) === status2typeString(props.newStatus) ?
    status2string(props.project.status) :
    `${status2typeString(props.project.status)}: ${status2string(props.project.status)}`;

  const statusChangeTo = status2typeString(props.project.status) === status2typeString(props.newStatus) ?
    status2string(props.newStatus) :
    `${status2typeString(props.newStatus)}: ${status2string(props.newStatus)}`;

  const projectName = props.project.internalName ??
    [props?.project?.primaryProjectUser?.display, props?.project?.company.name].filter((i)=>!!i).join(', ');

  const availableStatuses = (props.availableStatuses ?? getPossibleProjectStatuses()).filter((status)=>status !== props?.project?.status);

  return (
    <Dialog
      className="ChangeProjectStatus"
      open={props.isOpen}
      onClose={abortDialog}M
    >
      <DialogContent className="ChangeProjectStatus-content">
        <div className="ChangeProjectStatus-content-left">
          <img className="ChangeProjectStatus-icon" src={infoIcon} alt="Info" />
        </div>
        <div className="ChangeProjectStatus-content-right">
          <Typography className="ChangeProjectStatus-title" variant="h2" >{projectName}</Typography>
          <Typography className="ChangeProjectStatus-text" variant="body2" component="span">
            {t('status_modal.please_confirm')}
          </Typography>
          <div className="status-row">
            <div className="status-cell">
              <Typography variant="body2" fontWeight={500} component="span">&rarr;</Typography>
            </div>
            <div className="status-cell">
              <Typography variant="body2" fontWeight={500} component="span">{t('status_modal.from_prefix')}</Typography>
            </div>
            <div className="status-cell">
              <Typography variant="body2" fontWeight={500} component="span">{statusChangeFrom}</Typography>
            </div>
          </div>
          <div className="status-row">
            <div className="status-cell">
              <Typography variant="body2" fontWeight={500} component="span">&rarr;</Typography>
            </div>
            <div className="status-cell">
              <Typography variant="body2" fontWeight={500} component="span">{t('status_modal.to_prefix')}</Typography>
            </div>
            <div className="status-cell">
              {!props.newStatusAsDropdown &&
                <Typography variant="body2" fontWeight={500} component="span"> {statusChangeTo} </Typography>
              }
              {props.newStatusAsDropdown &&
                <Select
                  size="small"
                  value={newStatus}
                  onChange={(event)=>setNewStatus(event.target.value)}
                  disabled={isLoading}
                >
                  {availableStatuses.map((status) => (
                    <MenuItem key={status} value={status}>{status2string(status)}</MenuItem>
                  ))}
                </Select>
              }
            </div>
          </div>
          {props.showInternalName &&
            <div className="ChangeProjectStatus-internal-name">
              <EditField
                id="internalName"
                name={t('field.internal_name.description')}
                value={internalName}
                error={isMissingInternalName}
                type="string"
                placeholder={t('field.internal_name.placeholder')}
                editing={!isLoading}
                onChange={(fieldId, newValue) => {
                  setInternalName(newValue);
                }}
              />
            </div>
          }
          {props.showNotifyClient &&
            <div className="ChangeProjectStatus-notify-client">
              <Switch
                label={<Typography variant="body2">{t('status_modal.email_client_switch')}</Typography>}
                checked={shouldNotifyClient}
                onChange={() => setNotifyClient(!shouldNotifyClient)}
                disabled={isLoading}
              />
            </div>
          }
        </div>
      </DialogContent>
      <HorizontalDivider />
      <DialogActions>
        <Button onClick={abortDialog} variant="text">{t('status_modal.button_cancel')}</Button>
        <Button onClick={changeStatus} variant="contained">{t('status_modal.button_confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}

ChangeProjectStatusPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.string.isRequired,
    'id': PropTypes.number.isRequired,
    'status': PropTypes.oneOf(getPossibleProjectStatuses()),
    'internalName': PropTypes.string,
    'primaryProjectUser': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.string.isRequired,
      'display': PropTypes.string,
    }),
    'company': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.string.isRequired,
      'name': PropTypes.string,
    }),
  }).isRequired,
  newStatus: PropTypes.oneOf(getPossibleProjectStatuses()).isRequired,
  showNotifyClient: PropTypes.bool.isRequired,
  notifyClientDefault: PropTypes.bool,
  showInternalName: PropTypes.bool.isRequired,
  requireInternalName: PropTypes.bool,
  newStatusAsDropdown: PropTypes.bool,
  availableStatuses: PropTypes.arrayOf(PropTypes.oneOf(getPossibleProjectStatuses())),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  shouldForceChange: PropTypes.bool,
};

