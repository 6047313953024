import {DriveFileRenameOutline} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

import {useHasCapabilityHere} from '../../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../../helpers/security/hasCapability';
import CompanyAddress from './components/CompanyAddress';
import CompanyName from './components/CompanyName';
import css from './index.module.scss';

function TypeInvestor({projectCompany, onEditClick}) {
  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);

  function onEditRequested() {
    onEditClick(projectCompany['@id'], 'investor');
  }

  return (
    <div className={css.CompanyType}>
      <div>
        <CompanyName projectCompany={projectCompany} />
        <CompanyAddress projectCompany={projectCompany} />
      </div>
      <div className={css.details}>

      </div>
      <div className={css.spacer} />
      <div className={css.actions}>
        {hasEditCapability &&
          <IconButton
            color="text"
            onClick={onEditRequested}
          >
            <DriveFileRenameOutline />
          </IconButton>
        }
      </div>
    </div>
  );
}

TypeInvestor.propTypes = {
  projectCompany: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectCompany']).isRequired,
    'company': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['Company']),
    }).isRequired,
  }),
  onEditClick: PropTypes.func.isRequired,
};

export default TypeInvestor;
