
export const LEAD_PENDING = 'lead_pending';
export const LEAD_REJECTED = 'lead_rejected';
export const OPPORTUNITY_TRAJECTORY = 'opportunity_trajectory';
export const OPPORTUNITY_PAYMENT = 'opportunity_payment';
export const OPPORTUNITY_MEMORANDUM_CREATING = 'opportunity_memorandum_creating';
export const OPPORTUNITY_MEMORANDUM_SENT = 'opportunity_memorandum_sent';
export const OPPORTUNITY_RESIGNED = 'opportunity_resigned';
export const ACCOUNT_IN_PROGRESS = 'account_in_progress';
export const ACCOUNT_CLOSED = 'account_closed';

export default function getPossibleProjectStatuses() {
  return [
    LEAD_PENDING,
    LEAD_REJECTED,
    OPPORTUNITY_TRAJECTORY,
    OPPORTUNITY_PAYMENT,
    OPPORTUNITY_MEMORANDUM_CREATING,
    OPPORTUNITY_MEMORANDUM_SENT,
    OPPORTUNITY_RESIGNED,
    ACCOUNT_IN_PROGRESS,
    ACCOUNT_CLOSED,
  ];
}
