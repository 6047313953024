import './ShowSingleNoteModal.scss';

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useStateValue} from '../../state/state';
import EditField from '../EditField/EditField';
import Item from '../Item/Item';
import ModalDialog from '../ModalDialog/ModalDialog';

export default function ShowSingleNoteModal(props) {
  const {t, i18n} = useTranslation('component_notes');
  const [{user}, dispatch] = useStateValue();
  const [isEditing, setIsEditing] = useState(false);

  const changeNote = () => {
    props.changeNoteText(props.noteInfo.id, props.textNote);
    setIsEditing(false);
  };

  const closeModal = () => {
    setIsEditing(false);
    props.handleNotePopupClose();
  };

  return (
    <ModalDialog
      className="NoteModal"
      open={props.isPopupNote}
      onCloseRequest={closeModal}
    >
      <div className="modal-contents-wrapper">
        <div className="modal-content">
          <EditField
            id="noteContent"
            name=""
            value={props.textNote}
            type="stringTextArea"
            placeholder={t('modal_view.field.text.placeholder')}
            editing={isEditing}
            onChange={(_, newTextValue) => props.onChange(newTextValue)}
            className="note-text"
          />

          {props.noteInfo.author &&
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={{fontSize: 14, margin: 'auto 0'}} color="#9C9FAF" >
                  {t('modal_view.field.creator.description')} {props.noteInfo.author?.display} <br />
                  {t('modal_view.field.date.description')} {props.noteInfo.createdAt}
                </Typography>
                {(user.primaryRole !== 'ROLE_SALES' || user.id === props.noteInfo.author.id) &&
                <DialogActions>
                  {!isEditing && <Button onClick={() => setIsEditing(true)}>{t('modal_view.action.edit')}</Button>}
                  {isEditing &&<Button onClick={() => changeNote()}>{t('modal_view.action.save')}</Button>}
                  <Button onClick={() => props.removeNote(props.noteInfo.id)} sx={{color: '#ED143D'}}>{t('modal_view.action.delete')}</Button>
                </DialogActions>}
              </Box>}
        </div>
      </div>
    </ModalDialog>
  );
}
