import {MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useTagsDictionary} from '../../../helpers/dictionaries/hooks/useTagsDictionary';

export default function EditFieldTags(props) {
  const {t} = useTranslation('dictionary_tags');

  function callback(event) {
    const selected = tags.filter((tag) => event.target.value.indexOf(tag.name) > -1);
    props.onChange(selected);
  }

  const tags = useTagsDictionary();

  return (
    <Select
      className="EditFieldTags"
      size="small"
      value={props?.value.map((item)=>item.name)}
      multiple={true}
      label="Tags"
      onChange={callback}
      fullWidth={true}
    >
      {tags.map((item) => (
        <MenuItem key={item['@id']} value={item.name}>{t(item.name)}</MenuItem>
      ))}
    </Select>
  );
}

EditFieldTags.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  typeOptions: PropTypes.shape({
    client: PropTypes.bool,
  }),
};
