import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import BasicCard from '../../../components/Card/Card';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../helpers/security/hasCapability';
import patchProjectCompanies from './api/patchProjectCompanies';
import EditProjectCompanyModal from './EditProjectCompanyModal';
import css from './index.module.scss';
import TypeClient from './linkedCompanyTypes/TypeClient';
import TypeIntermediary from './linkedCompanyTypes/TypeIntermediary';
import TypeInvestor from './linkedCompanyTypes/TypeInvestor';
import TypeMoneySupplier from './linkedCompanyTypes/TypeMoneySupplier';


function ProjectLinkedCompaniesPanel({pageScope, project, onRefreshRequest}) {
  const {t} = useTranslation('page_cases');

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedProjectCompany, setSelectedProjectCompany] = useState(null);
  const [selectedProjectCompanyType, setSelectedProjectCompanyType] = useState(null);

  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);

  const shouldShowDetails = ['leads', 'opportunities', 'accounts'].indexOf(pageScope) > -1;

  const clients = project?.projectCompanies ?
    project.projectCompanies.filter((pc)=>(pc?.isClient)) :
    [];

  const clientEmployees = project?.projectUsers ?
    project.projectUsers.filter((pu)=>(pu?.type === 'user')) :
    [];

  const intermediaries = project?.projectCompanies ?
    project.projectCompanies.filter((pc)=>(pc?.isIntermediary)) :
    [];

  const investors = project?.projectCompanies ?
    project.projectCompanies.filter((pc)=>(pc?.isInvestor)) :
    [];

  const moneySuppliers = project?.projectCompanies ?
    project.projectCompanies.filter((pc)=>(pc?.isMoneySupplier)) :
    [];

  function requestNewCompanyRelation(type) {
    setSelectedProjectCompany(null);
    setSelectedProjectCompanyType(type);
    setEditModalOpen(true);
  }

  function requestEditCompanyRelation(iri, type) {
    const selectedCompany = project?.projectCompanies.find((pc) => pc['@id']===iri);
    if (selectedCompany) {
      setSelectedProjectCompany(selectedCompany);
      setSelectedProjectCompanyType(type);
      setEditModalOpen(true);
    }
  }

  function onSaveCompanyRelation(companyIri, type, amountVal) {
    const modification = {company: companyIri, type: type};
    if (amountVal) modification.amount = amountVal;
    patchProjectCompanies(
        project['@id'],
        project?.projectCompanies,
        [modification],
    ).then((r)=>{
      setEditModalOpen(false);
      onRefreshRequest();
    });
  }

  function onRemoveProjectCompany(companyIri, type) {
    if (window.confirm(t('projectCompanies.confirmDeletion'))) {
      const modifications = [{company: companyIri, type: type, delete: true}];
      patchProjectCompanies(project['@id'], project?.projectCompanies, modifications).then(() => {
        setEditModalOpen(false);
        onRefreshRequest();
      });
    }
  }


  const actions = hasEditCapability && shouldShowDetails ?
    <CardActions className={css.cardActions}>
      <Button variant="smallText" onClick={()=>requestNewCompanyRelation('intermediary')}>
        {t('projectCompanies.addIntermediary')}
      </Button>
      <Button variant="smallText" onClick={()=>requestNewCompanyRelation('investor')}>
        {t('projectCompanies.addInvestor')}
      </Button>
      <Button variant="smallText" onClick={()=>requestNewCompanyRelation('money_supplier')}>
        {t('projectCompanies.addMoneySupplier')}
      </Button>
    </CardActions> :
    undefined;

  return (
    <div className={css.ProjectLinkedCompanies}>
      <BasicCard
        className={classNames(css.card, {[css.isEditing]: isEditModalOpen})}
        appendToFooter={actions}
      >
        {Array.isArray(clients) && clients.length > 0 &&
          <div className={css.relationType}>
            <Typography variant="h2">{t('projectCompanies.clients')}</Typography>
            {clients.map((projectCompany)=>(
              <TypeClient
                key={projectCompany['@id']}
                projectCompany={projectCompany}
                projectUsers={clientEmployees}
                primaryProjectUser={project?.primaryProjectUser}
              />
            ))}
          </div>
        }
        {shouldShowDetails && Array.isArray(intermediaries) && intermediaries.length > 0 &&
          <div className={css.relationType}>
            <Typography variant="h2">{t('projectCompanies.intermediaries')}</Typography>
            {intermediaries.map((projectCompany)=>(
              <TypeIntermediary
                key={projectCompany['@id']}
                projectCompany={projectCompany}
                onEditClick={requestEditCompanyRelation}
              />
            ))}
          </div>
        }
        {shouldShowDetails && Array.isArray(investors) && investors.length > 0 &&
          <div className={css.relationType}>
            <Typography variant="h2">{t('projectCompanies.investors')}</Typography>
            {investors.map((projectCompany)=>(
              <TypeInvestor
                key={projectCompany['@id']}
                projectCompany={projectCompany}
                onEditClick={requestEditCompanyRelation}
              />
            ))}
          </div>
        }
        {shouldShowDetails && Array.isArray(moneySuppliers) && moneySuppliers.length > 0 &&
          <div className={css.relationType}>
            <Typography variant="h2">{t('projectCompanies.moneySuppliers')}</Typography>
            {moneySuppliers.map((projectCompany)=>(
              <TypeMoneySupplier
                key={projectCompany['@id']}
                projectCompany={projectCompany}
                onEditClick={requestEditCompanyRelation}
              />
            ))}
          </div>
        }
      </BasicCard>
      <EditProjectCompanyModal
        onDelete={onRemoveProjectCompany}
        onClose={()=>setEditModalOpen(false)}
        isOpen={isEditModalOpen}
        onSave={onSaveCompanyRelation}
        selectedCompany={selectedProjectCompany}
        selectedRelationType={selectedProjectCompanyType}
      />
    </div>
  );
}

ProjectLinkedCompaniesPanel.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
  project: (PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']),
    'projectCompanies': PropTypes.arrayOf(PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['ProjectCompany']),
    })),
    'projectUsers': PropTypes.arrayOf(PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['ProjectUser']),
    })),
    'primaryProjectUser': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['ProjectUser']),
    }),
  })),
  onRefreshRequest: PropTypes.func.isRequired,
};

export default ProjectLinkedCompaniesPanel;
