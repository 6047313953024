import './CreateNewPasswordForm.scss';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

import ServerErrorPopUp from '../../components/Popups/ServerErrorPopUp';
import {usePasswordValidation} from '../../customHooks/usePasswordValidation';
import Api from '../../helpers/api/Api';

export default function CreateNewPasswordForm() {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation('login');
  const [values, setValues] = useState({
    password: '',
    newPassword: '',
    showPassword: false,
    showConfirmationPassword: false,
  });

  const [validLength, hasNumber, upperCase, lowerCase, specialChar, match] =
    usePasswordValidation({
      firstPassword: values.password,
      secondPassword: values.newPassword,
    });

  const setPassword = (event) => {
    setIsError(false);
    setValues({...values, password: event.target.value});
  };
  const confirmPassword = (event) => {
    setIsError(false);
    setValues({...values, newPassword: event.target.value});
  };

  const [isError, setIsError] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmationPassword = () => {
    setValues({
      ...values,
      showConfirmationPassword: !values.showConfirmationPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async (newPassword) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    const type = query.get('type');
    setIsError(false);
    if (validLength && hasNumber && upperCase && lowerCase && specialChar && match) {
      Api.createNewPassword(newPassword, token)
          .then(() => {
            if (type === 'password_reset_execute') {
              navigate('/password-changed');
            } else {
              navigate('/login');
            }
          })
          .catch((errorResponse) => {
            console.error(errorResponse);
            if (errorResponse.status === 500) {
              setIsPopupOpen(true);
            } else {
              setIsError(true);
            }
          });
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          'display': 'flex',
          'flexWrap': 'wrap',
          'flexDirection': 'column',
          'minWidth': '500px',

          '@media screen and (max-width: 460px)': {
            minWidth: '90%',
          },
        }}
        className="create-new-password-form"
      >
        <div className="title">{t('new_password.title')}</div>
        <div className="field-title">
          <p>{t('new_password.field_password1.description')}</p>
          <FormControl
            sx={
              isError ?
                {
                  color: '#FF80A0',
                  border: '1px solid #FF80A0',
                  borderRadius: '5px',
                  width: '100%',
                } :
                {color: '#000000', width: '100%'}
            }
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={setPassword}
              placeholder={t('new_password.field_password1.placeholder')}
              error={isError}
              sx={isError ? {color: '#FF80A0'} : {color: '#9C9FAF'}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              className={'on-dark-background'}
            />
          </FormControl>
        </div>
        <Box sx={{height: '30px'}}>
          {(!validLength || !hasNumber || !upperCase || !lowerCase || !specialChar) && (
            <FormHelperText id="component-error-text" sx={isError ? {color: '#FF80A0'} : {color: 'inherit'}}>
              {!validLength && t('new_password.feedback.too_short')}
              {!hasNumber && t('new_password.feedback.no_numbers')}
              {(!upperCase || !lowerCase) && t('new_password.feedback.no_case')}
              {!specialChar && t('new_password.feedback.no_special')}
            </FormHelperText>
          )}
        </Box>
        <div className="field-title">
          <p>{t('new_password.field_password2.description')}</p>
          <FormControl
            sx={
              isError ?
                {
                  color: '#FF80A0',
                  border: '1px solid #FF80A0',
                  borderRadius: '5px',
                  width: '100%',
                } :
                {color: '#000000', width: '100%'}
            }
            variant="outlined"
          >
            <OutlinedInput
              id="outlined-adornment-password-confirmation"
              type={values.showConfirmationPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={confirmPassword}
              placeholder={t('new_password.field_password2.placeholder')}
              error={isError}
              sx={isError ? {color: '#FF80A0'} : {color: '#9C9FAF'}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmationPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showConfirmationPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              className={'on-dark-background'}
            />
          </FormControl>
        </div>

        <Box sx={{height: '40px'}}>
          {(!match && isError === false) &&(
            <FormHelperText id="component-error-text" sx={{color: 'inherit'}}>
              {t('new_password.feedback.not_match')}
            </FormHelperText>
          )}
          {isError && (
            <FormHelperText id="component-error-text" sx={{color: '#FF80A0'}}>
              {t('new_password.feedback.all')}
            </FormHelperText>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <Button
            variant="contained"
            sx={{'width': '68%',
              'height': '44px',

              '@media screen and (max-width: 460px)': {
                width: '80%',
                fontSize: '14px',
              },
            }}
            onClick={() => changePassword(values.password, values.newPassword)}
          >
            {t('new_password.button')}
          </Button>
          {typeof errorText === 'string' &&
              <Typography sx={{fontSize: '0.85rem', marginTop: '12px'}} className="errorMessage" color={'error'}>{errorText}</Typography>
          }
        </Box>
      </Box>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen}/>
    </>
  );
}
