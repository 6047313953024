import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useHasCapabilityHere} from '../../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../../helpers/security/hasCapability';
import css from './index.module.scss';
import InvitationResultModal from './InvitationResultModal';
import InviteUserModal from './InviteUserModal';

export default function ActionAddUser({requestRefresh}) {
  const {t} = useTranslation('page_users');

  const [isInvitingNewUser, setIsInvitingNewUser] = useState(false);
  const [isDisplayingInvitationResult, setIsDisplayingInvitationResult] = useState(false);

  const canAddUsers = useHasCapabilityHere(CAPABILITY_EDIT);

  function inviteNewUser() {
    setIsInvitingNewUser(true);
  }

  function inviteNewUserAbort() {
    setIsInvitingNewUser(false);
  }

  function inviteNewUserSuccess() {
    setIsInvitingNewUser(false);
    setIsDisplayingInvitationResult(true);
    requestRefresh();
  }

  if (!canAddUsers) return null;

  return (
    <div className={css.ActionAddUser}>
      <Button
        variant="contained"
        onClick={inviteNewUser}
      >
        {t('list.filters.new.label')}
      </Button>
      <InviteUserModal
        open={isInvitingNewUser}
        onClose={inviteNewUserAbort}
        onSave={inviteNewUserSuccess}
      />
      <InvitationResultModal
        open={isDisplayingInvitationResult}
        onClose={()=>{
          setIsDisplayingInvitationResult(false);
        }}
      />
    </div>
  );
}

ActionAddUser.propTypes = {
  requestRefresh: PropTypes.func.isRequired,
};
