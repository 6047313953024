import './InviteUserModal.scss';

import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../../../components/EditField/EditField';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import ProgressIndicator from '../../../../components/ProgressIndicator/ProgressIndicator';
import role2display from '../../../../helpers/formatters/role2display';
import listAllRoles from '../../../../helpers/security/listAllRoles';
import isValidEmail from '../../../../helpers/validators/isValidEmail';
import isValidName from '../../../../helpers/validators/isValidName';
import isValidUserRole from '../../../../helpers/validators/isValidUserRole';
import postInviteUser from './api/postInviteUser';

export default function InviteUserModal(props) {
  const {t} = useTranslation('page_users');

  const [userRole, setUserRole] = useState(null);
  const [isUserRoleInvalid, setUserRoleInvalid] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [isUserEmailInvalid, setUserEmailInvalid] = useState(false);
  const [userFirstname, setUserFirstname] = useState(null);
  const [isUserFirstnameInvalid, setUserFirstnameInvalid] = useState(false);
  const [userLastname, setUserLastname] = useState(null);
  const [isUserLastnameInvalid, setUserLastnameInvalid] = useState(false);
  const [userPhone, setUserPhone] = useState({country: '', phone: ''});

  const [errorText, setErrorText] = useState(null);
  const [isSending, setIsSending] = useState(false);

  function handleClose() {
    props.onClose();
  }

  function validateForm() {
    let hasErrors = false;
    if (!isValidEmail(userEmail)) {
      setUserEmailInvalid(true);
      hasErrors = true;
    } else {
      setUserEmailInvalid(false);
    }
    if (!isValidUserRole(userRole)) {
      setUserRoleInvalid(true);
      hasErrors = true;
    } else {
      setUserRoleInvalid(false);
    }
    if (userFirstname!==null && userFirstname !== '' && !isValidName(userFirstname)) {
      setUserFirstnameInvalid(true);
      hasErrors = true;
    } else {
      setUserFirstnameInvalid(false);
    }
    if (userLastname!==null && userLastname !== '' && !isValidName(userLastname)) {
      setUserLastnameInvalid(true);
      hasErrors = true;
    } else {
      setUserLastnameInvalid(false);
    }
    return hasErrors;
  }

  function sendInvitation() {
    const hasErrors = validateForm();
    if (!hasErrors) {
      setIsSending(true);
      setErrorText(null);
      postInviteUser({
        firstname: userFirstname,
        lastname: userLastname,
        email: userEmail,
        role: userRole,
        userPhones: userPhone.country || userPhone.phone ? [userPhone] : [],
        shouldSendMessage: true,
      }).then(() => {
        setIsSending(false);
        setUserRole(null);
        setUserEmail(null);
        setUserFirstname(null);
        setUserLastname(null);
        setUserPhone(null);
        if (props.onSave) {
          props.onSave();
        }
      }).catch((error) => {
        if (error.request.status === 409) {
          setErrorText(t('invite_modal.feedback.duplicate'));
        } else {
          setErrorText(error && error.data && error.data.message ? error.data.message : null);
        }
        setIsSending(false);
      });
    }
  }

  function fieldChanged(id, value) {
    switch (id) {
      case 'role':
        setUserRole(value);
        break;
      case 'email':
        setUserEmail(value);
        break;
      case 'firstname':
        setUserFirstname(value);
        break;
      case 'lastname':
        setUserLastname(value);
        break;
      case 'phone':
        setUserPhone({
          country: value.country,
          phone: value.phone,
        });
        break;
      default:
    }
  }
  const rolesDropdownOptions = listAllRoles()
      .filter((role) => role !== 'ROLE_USER')
      .map((role)=>({name: role, display: role2display(role)}));

  return (
    <ModalDialog
      className="InviteUserModal"
      open={props.open}
      onCloseRequest={handleClose}
    >
      <div className="InviteUserModal-contents-wrapper">
        <div className="InviteUserModal-title">
          <Typography variant="h2" fontSize={28} fontWeight={500}>{t('invite_modal.title')}</Typography>
          <Typography fontSize={16} fontWeight={400}>{t('invite_modal.hint')}</Typography>
        </div>
        {isSending &&
          <ProgressIndicator stretch={true} />
        }
        {!isSending &&
          <>
            <EditField
              id="role"
              name={t('field.role.description')}
              value={userRole}
              type="dropdown"
              placeholder={t('field.role.placeholder')}
              options={rolesDropdownOptions}
              editing={true}
              error={isUserRoleInvalid}
              onChange={fieldChanged}
            />
            <EditField
              id="firstname"
              name={t('field.firstname.description')}
              value={userFirstname}
              placeholder={t('field.firstname.placeholder')}
              type="string"
              error={isUserFirstnameInvalid}
              editing={true}
              onChange={fieldChanged}
            />
            <EditField
              id="lastname"
              name={t('field.lastname.description')}
              value={userLastname}
              placeholder={t('field.lastname.placeholder')}
              type="string"
              error={isUserLastnameInvalid}
              editing={true}
              onChange={fieldChanged}
            />
            <EditField
              id="phone"
              name={t('field.phone.description_optional')}
              value={userPhone}
              placeholder={[t('field.phone.placeholder1'), t('field.phone.placeholder2')]}
              type="userPhone"
              editing={true}
              onChange={fieldChanged}
            />
            <EditField
              id="email"
              name={t('field.email.description')}
              value={userEmail}
              placeholder={t('field.email.placeholder')}
              type="string"
              error={isUserEmailInvalid}
              typeOptions={{}}
              editing={true}
              onChange={fieldChanged}
            />
            <Button
              className="button-send-invite"
              variant="contained"
              onClick={sendInvitation}
            >
              {t('action.send_invitation')}
            </Button>
            {typeof errorText === 'string' &&
              <Typography className="errorMessage" color={'error'}>{errorText}</Typography>
            }
          </>
        }
      </div>
    </ModalDialog>
  );
}
