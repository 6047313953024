import Api from '../../../helpers/api/Api';

export default function getCompanyMoneySupplierProjects(id) {
  return new Promise(function(resolve, reject) {
    Api.get(`/companies/${id}/related_projects?type=money_supplier`).then((data)=> {
      if (Array.isArray(data['hydra:member'])) {
        resolve(data['hydra:member']);
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
