import './AddProjectModal.scss';

import EuroIcon from '@mui/icons-material/Euro';
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../components/EditField/EditField';
import ModalDialog from '../../components/ModalDialog/ModalDialog';
import ServerErrorPopUp from '../../components/Popups/ServerErrorPopUp';
import Switch from '../../components/Switch/Switch';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import {useIsSalesman} from '../../customHooks/useIsSalesman';
import status2string from '../../helpers/formatters/status2string';
import {CAPABILITY_EDIT_EXCLUDE_HANDLER} from '../../helpers/security/hasCapability';
import isValidEmail from '../../helpers/validators/isValidEmail';
import isValidName from '../../helpers/validators/isValidName';
import {useStateValue} from '../../state/state';
import getCompany from './api/getCompany';
import getCompanyLegalForms from './api/getCompanyLegalForms';
import getCompanyNamesList from './api/getCompanyNamesList';
import getHandlers from './api/getHandlers';
import postNewProject from './api/postNewProject';

export default function AddProjectModal(props) {
  const {t} = useTranslation('component_add_project');
  const [{user}] = useStateValue();

  const canAppointHandler = useHasCapabilityHere(CAPABILITY_EDIT_EXCLUDE_HANDLER);
  const shouldBecomeDefaultHandler = useIsSalesman();

  const [isFormModeNewCompany, setFormModeNewCompany] = useState(false);
  const [isFormModeExistingCompany, setFormModeExistingCompany] = useState(false);
  const [isFormModeNewUser, setFormModeNewUser] = useState(false);
  const [isFormModeExistingUser, setFormModeExistingUser] = useState(false);

  const [companyIri, setCompanyIri] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLegalFormIri, setCompanyLegalFormIri] = useState('');
  const [userIri, setUserIri] = useState('');
  const [userPhoneCountry, setUserPhoneCountry] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userSendEmail, setUserSendEmail] = useState(true);
  const [projectName, setProjectName] = useState('');
  const [projectStatus, setProjectStatus] = useState(props.defaultStatus);
  const [projectAmount, setProjectAmount] = useState('');
  const [projectActions, setProjectActions] = useState('');
  const [projectCreatedAt, setProjectCreatedAt] = useState(new Date());
  const [projectHandler, setProjectHandler] = useState(shouldBecomeDefaultHandler ? user['@id'] : '');

  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [companyLegalForms, setCompanyLegalForms] = useState([]);
  const [companyNamesList, setCompanyNamesList] = useState([]);
  const [companyEmployeesList, setCompanyEmployeesList] = useState([]);
  const [handlers, setHandlers] = useState([]);


  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  function handleAddingNewLeadModalClose() {
    emptyForm();
    props.onClose();
  }

  function emptyForm() {
    setFormModeNewCompany(false);
    setFormModeExistingCompany(false);
    setFormModeNewUser(false);
    setFormModeExistingUser(false);
    setCompanyIri('');
    setCompanyName('');
    setCompanyLegalFormIri('');
    setUserIri('');
    setUserPhoneCountry('');
    setUserPhoneNumber('');
    setUserEmail('');
    setUserFirstName('');
    setUserLastName('');
    setUserSendEmail(true);
    setProjectAmount('');
    setProjectName('');
    setProjectStatus(props.defaultStatus);
    setProjectActions('');
    setProjectCreatedAt(new Date());
    setProjectHandler(shouldBecomeDefaultHandler ? user['@id'] : '');
    setErrorText(null);
    setError({});
  }

  function validateForm() {
    const currentErrors = {};
    const phoneCountryValidatorRegEx= /^(\+?\d{1,3}|\d{1,4})$/;
    const phoneValidatorRegEx= /^([\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    if (isFormModeExistingCompany && (typeof companyIri !== 'string' || companyIri.trim().length === 0)) {
      currentErrors['companyIri'] = t('feedback.no_company');
    }
    if (isFormModeExistingUser && (typeof userIri !== 'string' || userIri.trim().length === 0)) {
      currentErrors['userIri'] = t('feedback.no_employee');
    }
    if (isFormModeNewCompany && (typeof companyName !== 'string')) {
      currentErrors['companyName'] = t('feedback.wrong_company_name');
    }
    if (isFormModeNewCompany && (typeof companyLegalFormIri !== 'string' || companyLegalFormIri.trim().length === 0)) {
      currentErrors['legalForm'] = t('feedback.wrong_legal_form');
    }
    if (isFormModeNewUser && (typeof userPhoneCountry !== 'string' || userPhoneCountry.trim().length === 0 || !phoneCountryValidatorRegEx.test(userPhoneCountry))) {
      currentErrors['userPhonesCountry'] = t('feedback.wrong_phone_country');
    }
    if (isFormModeNewUser && (typeof userPhoneNumber !== 'string' || userPhoneNumber.trim().length === 0 || !phoneValidatorRegEx.test(userPhoneNumber))) {
      currentErrors['userPhonesPhone'] = t('feedback.wrong_phone_number');
    }
    if (isFormModeNewUser && (typeof userEmail !== 'string' || userEmail.trim().length === 0 || !isValidEmail(userEmail))) {
      currentErrors['email'] = t('feedback.wrong_email');
    }
    if (isFormModeNewUser && (typeof userFirstName !== 'string' || userFirstName.trim().length === 0 || !isValidName(userFirstName))) {
      currentErrors['firstName'] = t('feedback.wrong_firstname');
    }
    if (isFormModeNewUser && (typeof userLastName !== 'string' || userLastName.trim().length === 0 || !isValidName(userLastName))) {
      currentErrors['lastName'] = t('feedback.wrong_lastname');
    }
    if (props.isProjectNameAvailable && props.isProjectNameRequired && (typeof projectName !== 'string' || projectName.trim().length === 0)) {
      currentErrors['projectName'] = t('feedback.no_project_name');
    }
    if (typeof projectActions !== 'string' || projectActions.trim().length === 0) {
      currentErrors['actions'] = t('feedback.no_actions');
    }
    if (typeof projectAmount === 'string' && projectAmount.trim().length > 0 && isNaN(parseInt(projectAmount))) {
      currentErrors['amount'] = t('feedback.no_amount');
    }
    setError(currentErrors);
    return !Object.entries(currentErrors).length;
  }

  useEffect(() => {
    if (companyIri && companyIri.length > 0) {
      getCompany(companyIri).then((data) => {
        setCompanyEmployeesList(
            data.employees.map((employee) => ({name: employee.user['@id'], display: employee.display})),
        );
        if (data.employees.length === 1) {
          setUserIri(data.employees[0].user['@id']);
        } else {
          setUserIri('');
        }
      });
    } else {
      setUserIri('');
    }
  }, [companyIri]);

  useEffect(() => {
    if (props.isOpen) {
      getCompanyLegalForms().then((data) => {
        const legalFormObjects = data['hydra:member'];
        setCompanyLegalForms(
            legalFormObjects.map((form) => ({name: form['@id'], display: t(form.name, {ns: 'dictionary_legal_form'})})),
        );
      });
      getCompanyNamesList().then((data) => {
        setCompanyNamesList(
            data.map((form) => ({name: form['@id'], display: form.name})),
        );
      });
      getHandlers().then((data) => {
        setHandlers(
            data?.map((handler) => ({name: handler['@id'], display: handler.display})),
        );
      });
    }
  }, [props.isOpen, t]);


  function onFormModeCompanySelectionChanged(event) {
    switch (event.target.value) {
      case 'new':
        setFormModeExistingCompany(false);
        setFormModeNewCompany(true);
        setFormModeExistingUser(false);
        setFormModeNewUser(true);
        break;
      case 'existing':
        setFormModeExistingCompany(true);
        setFormModeNewCompany(false);
        setFormModeExistingUser(false);
        setFormModeNewUser(false);
        break;
      default:
        setFormModeExistingCompany(false);
        setFormModeNewCompany(false);
        setFormModeExistingUser(false);
        setFormModeNewUser(false);
        break;
    }
    setError({});
  }

  function onFormModeUserSelectionChanged(event) {
    switch (event.target.value) {
      case 'new':
        setFormModeExistingUser(false);
        setFormModeNewUser(true);
        break;
      case 'existing':
        setFormModeExistingUser(true);
        setFormModeNewUser(false);
        break;
      default:
        setFormModeExistingUser(false);
        setFormModeNewUser(false);
        break;
    }
    setError({});
  }

  function createNewLeadManually() {
    const isValid = validateForm();
    if (isValid) {
      setIsSending(true);
      setErrorText(null);
      postNewProject({
        companyIri,
        companyName,
        companyLegalFormIri,
        userIri,
        userPhoneCountry,
        userPhoneNumber,
        userEmail,
        userFirstName,
        userLastName,
        userSendEmail,
        projectName,
        projectStatus,
        projectAmount,
        projectActions,
        projectCreatedAt,
        projectHandler,
      }, {
        isFormModeNewCompany,
        isFormModeExistingCompany,
        isFormModeNewUser,
        isFormModeExistingUser,
        isProjectNameRequired: props.isProjectNameRequired,
        isProjectNameAvailable: props.isProjectNameAvailable,
        isHandlerAvailable: props.isHandlerAvailable,
        defaultStatus: props.defaultStatus,
      }).then(()=>{
        setIsSending(false);
        if (props.onSave) {
          props.onSave();
        }
        handleAddingNewLeadModalClose();
      }).catch((error)=>{
        console.error(error);
        if (error.data.code === 500) {
          setIsPopupOpen(true);
        } else {
          setErrorText(error && error.data && error.data.message ? error.data.message : null);
        }
        setIsSending(false);
      });
    }
  }

  return (
    <>
      <ModalDialog
        className="AddProjectModal"
        open={props.isOpen}
        onCloseRequest={handleAddingNewLeadModalClose}
        sx={{outline: 'none'}}
      >
        <div className="Title">{t('title', {type: props.entityName})}</div>
        <div className="AddProjectModal-content">

          {renderNewOrExistingCompanySelector()}

          {isFormModeNewCompany &&
            renderNewCompanyFormFields()
          }

          {isFormModeExistingCompany &&
            renderExistingCompanyPickerFormFields()
          }

          { isFormModeExistingCompany && companyIri!=='' &&
            renderNewOrExistingEmployeeSelector()
          }

          { isFormModeExistingCompany && companyIri!=='' && isFormModeExistingUser &&
            renderExistingEmployeePickerFormFields()
          }

          {(isFormModeNewCompany || isFormModeNewUser) &&
            renderNewUserFormFields()
          }

          {(isFormModeNewCompany || (isFormModeExistingCompany && (isFormModeNewUser || isFormModeExistingUser))) &&
            renderNewProjectDetailsFormFields()
          }

          {props.isHandlerAvailable && canAppointHandler && (isFormModeNewCompany || (isFormModeExistingCompany && (isFormModeNewUser || isFormModeExistingUser))) &&
            renderHandlerSelectionFormFields()
          }

          {(isFormModeNewCompany || (isFormModeExistingCompany && (isFormModeNewUser || isFormModeExistingUser))) &&
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '14px',
              height: '44px',
            }}
          >
            <Button
              variant="contained"
              sx={{width: '164px', height: '100%', textTransform: 'capitalize', marginBotton: '92px'}}
              onClick={createNewLeadManually}
              className="CreateButton"
            >
              {t('create_button', {type: props.entityName})}
            </Button>
          </Box>
          }
          {typeof errorText === 'string' &&
            <Typography className="ErrorMessage" color={'error'}>{errorText}</Typography>
          }
        </div>
      </ModalDialog>
      <ServerErrorPopUp handlePopupClose={handlePopupClose} isPopupOpen={isPopupOpen}/>
    </>
  );

  function renderNewOrExistingCompanySelector() {
    return (
      <FormControl
        className="form-mode-radio-group"
      >
        <RadioGroup
          aria-labelledby="form-mode-company-radio-group-label"
          name="form-mode-company-radio-group"
          onChange={onFormModeCompanySelectionChanged}
        >
          <FormControlLabel value="new" control={<Radio />} label={t('decision1.new_record')} />
          <FormControlLabel value="existing" control={<Radio />} label={t('decision1.existing_record')} />
        </RadioGroup>
      </FormControl>
    );
  }

  function renderNewCompanyFormFields() {
    return (
      <>
        <div className="FormField">
          <EditField
            id="company-name"
            name={t('new_company.name.description')}
            value={companyName}
            type="string"
            placeholder={t('new_company.name.placeholder')}
            editing={true}
            onChange={(id, val) => {
              setCompanyName(val);
            }}
            error={typeof error['companyName'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['companyName']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="legal-form"
            name={t('new_company.legal_form.description')}
            value={companyLegalFormIri}
            type="dropdown"
            placeholder={t('new_company.legal_form.placeholder')}
            options={companyLegalForms}
            editing={true}
            onChange={(id, val) => {
              setCompanyLegalFormIri(val);
            }}
            error={typeof error['legalForm'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['legalForm']}
          </FormHelperText>
        </div>
      </>
    );
  }

  function renderExistingCompanyPickerFormFields() {
    return (
      <div className="FormField">
        <EditField
          id="existing-company"
          name={t('existing_company.name.description')}
          value={companyIri}
          type="autocomplete"
          placeholder={t('existing_company.name.placeholder')}
          options={companyNamesList}
          editing={true}
          onChange={(id, val) => {
            setCompanyIri(val);
          }}
          error={typeof error['companyIri'] !== 'undefined'}
        />
        <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
          {error['companyIri']}
        </FormHelperText>
      </div>
    );
  }

  function renderNewOrExistingEmployeeSelector() {
    return (
      <FormControl
        className="form-mode-radio-group"
      >
        <RadioGroup
          aria-labelledby="form-mode-user-radio-group-label"
          name="form-mode-user-radio-group"
          onChange={onFormModeUserSelectionChanged}
        >
          <FormControlLabel value="new" control={<Radio />} label={t('decision2.new_person')} />
          <FormControlLabel value="existing" control={<Radio />} label={t('decision2.existing_person')} />
        </RadioGroup>
      </FormControl>
    );
  }

  function renderExistingEmployeePickerFormFields() {
    return (
      <div className="FormField">
        <EditField
          id="existing-employee"
          name={t('existing_person.name.description')}
          value={userIri}
          type="autocomplete"
          placeholder={t('existing_person.name.placeholder')}
          options={companyEmployeesList}
          editing={true}
          onChange={(id, val) => {
            setUserIri(val);
          }}
          error={typeof error['userIri'] !== 'undefined'}
        />
        <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
          {error['userIri']}
        </FormHelperText>
      </div>
    );
  }

  function renderNewUserFormFields() {
    return (
      <>
        <div className="FormField">
          <div className="UserPhone">
            <EditField
              id="userPhone"
              name={t('new_person.phone.description')}
              value={{country: userPhoneCountry, phone: userPhoneNumber}}
              type="userPhone"
              editing={true}
              placeholder={[t('new_person.phone.placeholder1'), t('new_person.phone.placeholder2')]}
              onChange={(id, phone)=>{
                setUserPhoneCountry(phone.country);
                setUserPhoneNumber(phone.phone);
              }}
              typeOptions={{width: ['24%', '74%']}}
              error={typeof error['userPhonesCountry'] !== 'undefined' || typeof error['userPhonesPhone'] !== 'undefined'}
            />
          </div>
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['userPhonesPhone']}{error['userPhonesCountry']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="email"
            name={t('new_person.email.description')}
            value={userEmail}
            type="string"
            placeholder={t('new_person.email.placeholder')}
            editing={true}
            onChange={(id, text)=>setUserEmail(text)}
            error={typeof error['email'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['email']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <Switch label={t('new_person.send_registration.description')} checked={userSendEmail} onChange={(e)=>setUserSendEmail(e.target.checked)}/>
        </div>
        <div className="FormField">
          <EditField
            id="firstName"
            name={t('new_person.firstname.description')}
            value={userFirstName}
            type="string"
            placeholder={t('new_person.firstname.placeholder')}
            editing={true}
            onChange={(id, text)=>setUserFirstName(text)}
            error={typeof error['firstName'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['firstName']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="lastName"
            name={t('new_person.lastname.description')}
            value={userLastName}
            type="string"
            placeholder={t('new_person.lastname.placeholder')}
            editing={true}
            onChange={(id, text)=>setUserLastName(text)}
            error={typeof error['lastName'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['lastName']}
          </FormHelperText>
        </div>
      </>
    );
  }

  function renderNewProjectDetailsFormFields() {
    return (
      <>
        {(props.isProjectNameAvailable || props.isStatusDropdownAvailable) &&
          <div className="Subtitle">{t('subtitle1', {type: props.entityName.toLowerCase()})}</div>
        }
        {props.isProjectNameAvailable &&
          <>
            <div className="FormField">
              <EditField
                id="projectName"
                name={t('new_project.name.description')}
                value={projectName}
                type="string"
                placeholder={t('new_project.name.placeholder', {type: props.entityName.toLowerCase()})}
                editing={true}
                onChange={(id, name)=>setProjectName(name)}
                error={typeof error['projectName'] !== 'undefined'}
              />
              <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
                {error['projectName']}
              </FormHelperText>
            </div>
          </>
        }
        {props.isStatusDropdownAvailable &&
          <>
            <div className="FormField">
              <EditField
                id="projectStatus"
                name={t('new_project.status.description')}
                value={projectStatus}
                type="dropdown"
                options={props.availableStatuses ? props.availableStatuses.map((status) => {
                  return {name: status, display: status2string(status)};
                }) : []}
                placeholder={t('new_project.status.placeholder')}
                editing={true}
                onChange={(id, status)=>setProjectStatus(status)}
                error={typeof error['projectName'] !== 'undefined'}
              />
              <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
                {error['projectName']}
              </FormHelperText>
            </div>
          </>
        }
        <div className="Subtitle">{t('subtitle2')}</div>
        <div className="FormField">
          <EditField
            id="amount"
            name={t('new_project.amount.description')}
            value={projectAmount}
            type="string"
            placeholder={t('new_project.amount.placeholder')}
            editing={true}
            onChange={(id, amount)=>setProjectAmount(amount)}
            error={typeof error['amount'] !== 'undefined'}
            icon={
              <InputAdornment position="end">
                <EuroIcon sx={{fontSize: '20px'}} />
              </InputAdornment>
            }
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['amount']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="actions"
            name={t('new_project.actions.description')}
            value={projectActions}
            type="string"
            placeholder={t('new_project.actions.placeholder')}
            editing={true}
            onChange={(id, text)=>setProjectActions(text)}
            error={typeof error['actions'] !== 'undefined'}
          />
          <FormHelperText id="component-error-text" sx={{color: '#ED143D', fontSize: '12px'}}>
            {error['actions']}
          </FormHelperText>
        </div>
        <div className="FormField">
          <EditField
            id="actions"
            name={t('new_project.creation_date.description')}
            value={projectCreatedAt}
            type="datepicker"
            editing={true}
            onChange={(id, date)=>setProjectCreatedAt(date)}
          />
        </div>
      </>
    );
  }

  function renderHandlerSelectionFormFields() {
    return (
      <>
        <div className="Subtitle">{t('subtitle3', {type: props.entityName.toLowerCase()})}</div>
        <EditField
          id="handler"
          name={t('handler.description')}
          value={projectHandler}
          type="autocomplete"
          placeholder={t('handler.placeholder')}
          options={handlers}
          editing={true}
          onChange={(id, text)=>setProjectHandler(text)}
        />
      </>
    );
  }
}

AddProjectModal.propTypes = {
  defaultStatus: PropTypes.string.isRequired,
  isProjectNameRequired: PropTypes.bool.isRequired,
  isProjectNameAvailable: PropTypes.bool.isRequired,
  isStatusDropdownAvailable: PropTypes.bool.isRequired,
  isHandlerAvailable: PropTypes.bool.isRequired,
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  entityName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
