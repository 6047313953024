import './IndexTable.scss';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TableHead, TablePagination,
  TableRow, TableSortLabel,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import hasCapability, {CAPABILITY_EDIT} from '../../helpers/security/hasCapability';
import {useStateValue} from '../../state/state';
import MoreButton from '../MoreButton/MoreButton';
export default function IndexTable(props) {
  const {t, i18n} = useTranslation('component_table');
  const [{user}, dispatch] = useStateValue();
  const tableSxOptions = props.sx ? props.sx : {};
  const cellSxOptions = {
    padding: '12px 8px',
  };
  const hasActions = (props.actions && props.actions.length > 0) || (props.rowActions && props.rowActions.length > 0);
  const currentPage = props.page-1;

  function stripTags(str) {
    if (typeof str === 'string' && str.length > 0) {
      return str.replace(/(<([^>]+)>)/gi, '');
    } else {
      return str ? str : '';
    }
  }

  function onMoreAction(actionId, rowId) {
    if (props.onAction) {
      props.onAction(actionId, stripTags(rowId));
    }
  }

  function handleChangePage(event, newPage) {
    if (props.onPageChanged) {
      props.onPageChanged(newPage+1);
    }
  }

  function onSortingChanged(item) {
    props.onSortingChanged(item);
  }

  function onRowClicked(rowId) {
    if (props.onRowClicked) {
      props.onRowClicked(stripTags(rowId));
    }
  }

  const paginatedRows = props.rowsPerPage > 0 ?
    props.rows.slice(currentPage * props.rowsPerPage, currentPage * props.rowsPerPage + props.rowsPerPage) :
    props.rows;

  return (
    <div className={classNames('IndexTable', props.className)}>
      <TableContainer component={Paper}>
        <Table sx={tableSxOptions} size="small" aria-label="Table">
          <TableHead>
            <TableRow>
              {props.headers.map((item) => (
                <TableCell
                  key={item.label}
                  width={item.width ?? undefined}
                >
                  <TableSortLabel
                    active={props.sortedBy && props.sortedBy.header === item.sortField}
                    direction={props.sortedBy && props.sortedBy.direction==='asc' ? 'asc' : 'desc'}
                    onClick={() => onSortingChanged(item)}
                  >
                    <Typography fontSize={14} color={'gray'}>
                      <>{item.label}</>
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
              {hasActions &&
                <TableCell><span>&nbsp;</span></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, rowIdx) => {
              return (
                <TableRow key={rowIdx} className={row[row.length - 1] === ('Open (delayed)' || 'Open (vertraagd)') ? 'delayed' : ''}>
                  {row.map((item, index) => {
                    if (typeof item === 'string') {
                      return (
                        <TableCell
                          key={index}
                          sx={cellSxOptions}
                          onClick={() => onRowClicked(row[props.rowsKeyIndex])}
                          dangerouslySetInnerHTML={{__html: item}}
                          className={`index-${index}`}
                        />
                      );
                    } else {
                      return (
                        <TableCell
                          key={index}
                          sx={cellSxOptions}
                          onClick={() => onRowClicked(row[props.rowsKeyIndex])}
                        >{item}</TableCell>
                      );
                    }
                  })}
                  {hasActions &&
                    <TableCell>
                      {hasCapability(user, CAPABILITY_EDIT, 'leads') &&<MoreButton
                        actions={props.rowActions ? props.rowActions[rowIdx] : props.actions}
                        rowKey={row[props.rowsKeyIndex]}
                        onAction={onMoreAction}
                      />}
                    </TableCell>
                  }
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[props.rowsPerPage]}
                colSpan={props.headers.length}
                count={props.rows.length}
                rowsPerPage={props.rowsPerPage}
                page={currentPage}
                labelDisplayedRows={({from, to, count, page}) => ( t('pagination', {from, to, count} ) ) }
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

IndexTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
  sortedBy: PropTypes.shape({
    header: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
  rows: PropTypes.arrayOf(
      PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
      ).isRequired,
  ).isRequired,
  rowsKeyIndex: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
  })),
  rowActions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
  }))),
  onAction: PropTypes.func,
  onPageChanged: PropTypes.func,
  onRowClicked: PropTypes.func,
  onSortingChanged: PropTypes.func,
  sx: PropTypes.any,
};

IndexTable.defaultProps = {
  className: null,
};
