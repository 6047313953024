import guessArrayOfIris from './guessArrayOfIris';

export default function prepareInitialProjectState(project) {
  if (!project) return null;

  return {
    internalName: project.internalName,

    name: project.primaryProjectUser ? project.primaryProjectUser.display : '',
    company: project.company?.name,
    legalForm: typeof project.company?.legalForm === 'string' ? project.company.legalForm : null,
    phone: project.primaryProjectUser ? project.primaryProjectUser.primaryPhoneDisplay : '',
    email: project.primaryProjectUser ? project.primaryProjectUser.email : '',

    amount: project.amount ?? '',
    activities: project.activities ?? '',

    securityContexts: guessArrayOfIris(project?.securityContexts),

    status: project.status,
    createdAt: project.createdAt,
    becameOpportunityAt: project.becameOpportunityAt,
    becameAccountAt: project.becameAccountAt,

    handler: project?.handler ?
      (typeof project?.handler === 'string' ? project.handler : project?.handler['@id']) :
      null,
  };
}

