import PropTypes from 'prop-types';
import React from 'react';

import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {CAPABILITY_EDIT} from '../../../helpers/security/hasCapability';
import CreateProjectAction from './CreateProjectAction';
import css from './index.module.scss';

function ActionsBlock({pageScope}) {
  const hasEditCapability = useHasCapabilityHere(CAPABILITY_EDIT);

  return (
    <section className={css.ActionsBlock}>
      {hasEditCapability &&
        <CreateProjectAction
          pageScope={pageScope}
        />
      }
    </section>
  );
}

ActionsBlock.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
};

export default ActionsBlock;
