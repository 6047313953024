import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';
import ModalDialog from '../ModalDialog/ModalDialog';
import TaskItem from '../TasksViewer/TaskItem';
import css from './AllTasksModal.module.scss';
import FilterAndSort from './FilterAndSort';

export default function AllTasksModal({
  isOpen,
  onClose,
  tasks,
  showSeeMore,
  disableGoTo,
  onSeeMoreClicked,
  onClick,
  params,
  setParams,
  selectedFilters,
  setSelectedFilters,
  refreshTasksList,
}) {
  const {t} = useTranslation('component_tasks');

  return (
    <ModalDialog
      className={css.AllTasksModal}
      open={isOpen}
      onCloseRequest={onClose}
    >
      <>
        <div className={css.modalTitle}>
          <Typography variant="h2" fontSize={28} fontWeight={500}>
            {t('modal_title')}
          </Typography>
        </div>
        <FilterAndSort
          setParams={setParams}
          params={params}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          refreshTasksList={refreshTasksList}
        />
        <div className={css.modalContent}>
          {tasks.map((task, taskIdx) => {
            const isNotLast = taskIdx < tasks.length - 1;
            const databaseId = parseInt(
                task['@id'].replace('/api/audit_logs/', ''),
            );
            return (
              <TaskItem
                id={databaseId}
                key={task['@id']}
                iri={task['@id']}
                author={
                  task.creator ? task.creator.display : t('unknown_creator')
                }
                executors={task.executors}
                content={task.text}
                title={task.title}
                createdAt={task.createdAt}
                completedAt={task.completedAt}
                deadlineAt={task.deadlineAt}
                withUnderline={isNotLast}
                transparent={true}
                goTo={
                  disableGoTo !== true &&
                  task.relatedProject !== null &&
                  typeof task.relatedProject === 'object'
                }
                onClick={() => onClick(task)}
              />
            );
          })}
        </div>
        <div className={css.modalFooter}>
          {showSeeMore && (
            <Button size={'small'} onClick={onSeeMoreClicked}>
              {t('load_more')}
            </Button>
          )}
        </div>
      </>
    </ModalDialog>
  );
}

AllTasksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSeeMore: PropTypes.bool.isRequired,
  onSeeMoreClicked: PropTypes.func.isRequired,
  disableGoTo: PropTypes.bool,
  onClick: PropTypes.func,
};
