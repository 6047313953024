import i18n from '../../i18n';
import Api from '../api/Api';

export default function getLegalFormsDictionary() {
  return new Promise(function(resolve, reject) {
    Api.get(`/company_legal_forms`).then((data)=> {
      if (data['hydra:member']) {
        resolve(
            data['hydra:member'].map((item) => (
              {name: item['@id'], display: i18n.t(item.name, {ns: 'dictionary_legal_form'})}
            )),
        );
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
