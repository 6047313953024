import './UserDetails.scss';

import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import Tasks from '../../components/TasksViewer/Tasks';
import SingleEntityLayout from '../../layouts/SingleEntityLayout/SingleEntityLayout';
import getUser from './api/getUser';
import CardUserPurge from './CardUserPurge';
import CardUserRecentActivity from './CardUserRecentActivity';
import CardUserRelatedProjects from './CardUserRelatedProjects';
import UserEdit from './UserEdit';

export default function UserDetailsPage(props) {
  const [userData, setUserData] = useState(null);
  const location = useLocation();

  const currentUserId = location.pathname.split('/').filter((i) => i.length > 0)[1];

  function refreshUserData() {
    setUserData(null);
    getUser(currentUserId).then((data)=>{
      setUserData(data);
    });
  }

  useEffect(function() {
    refreshUserData();
  }, [currentUserId]);

  const view = props.view ?? 'default';


  return (
    <SingleEntityLayout
      leftPanel={userData ?
        <UserEdit userData={userData} onRefreshRequest={refreshUserData} view={view}/> :
        <ProgressIndicator />
      }
    >
      <div className="UserDetails">
        {view === 'default' &&
          <>
            <div className="UserDetails-child">
              <Tasks
                entity={userData}
              />
            </div>
            <div className="UserDetails-child">
              <CardUserRecentActivity
                user={userData}
              />
            </div>
            <div className="UserDetails-child">
              <CardUserRelatedProjects
                user={userData}
              />
            </div>
          </>
        }
        {view === 'purge' &&
          <div className="UserDetails-child">
            <CardUserPurge
              user={userData}
              goBackTo="/users"
            />
          </div>
        }
      </div>
    </SingleEntityLayout>
  );
}

UserDetailsPage.propTypes = {
  view: PropTypes.oneOf(['default', 'purge']),
};
