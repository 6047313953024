import {Close} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebounce} from 'use-debounce';

import useFirstSlug from '../../hooks/useFirstSlug';
import css from './index.module.scss';

function SearchFilter({onSearch}) {
  const {t} = useTranslation('page_cases');

  const [text, setText] = useState('');
  const [value] = useDebounce(text, 700);
  const firstSlug = useFirstSlug();

  const placeholder = useMemo(()=>{
    switch (firstSlug) {
      case 'leads':
        return t('page.leads.filter_search');
      case 'opportunities':
        return t('page.opportunities.filter_search');
      case 'accounts':
        return t('page.accounts.filter_search');
      default:
        return t('page.cases.filter_search');
    }
  }, [firstSlug, t]);

  function textChange(event) {
    setText(event.target.value);
  }

  function clearSearch() {
    setText('');
    onSearch(value);
  }

  useEffect(()=>{
    if (onSearch) {
      onSearch(value);
    }
  }, [onSearch, value]);

  return (
    <div className={css.SearchFilter}>
      <OutlinedInput
        className={css.input}
        size="small"
        type="text"
        placeholder={placeholder}
        onChange={textChange}
        value={text}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={text!=='' &&
          <InputAdornment position="end" onClick={clearSearch}>
            <Close />
          </InputAdornment>
        }
      />
    </div>
  );
}

SearchFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchFilter;
