import './NavBar.scss';

import {Logout, NotificationsNone} from '@mui/icons-material';
import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {useIntervalLoop} from '../../customHooks/useIntervalLoop';
import Api from '../../helpers/api/Api';
import user2securityContextsString from '../../helpers/formatters/user2securityContextsString';
import {ROLE_SUPERADMIN} from '../../helpers/security/listAllRoles';
import menuConfig from '../../menu_config.json';
import {setUnreadNotificationsCount} from '../../state/actions';
import {useStateValue} from '../../state/state';
import getUnseenNotificationsCount from './api/getUnseenNotificationsCount';
import UnseenNotificationsDialog from './UnseenNotificationsDialog';

export default function NavBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {t, i18n} = useTranslation('layout');

  const [isUnseenOpened, setIsUnseenOpened] = useState(false);
  const [{user, unseenNotificationsCount}, dispatch] = useStateValue();

  const currentSlug = location.pathname.split('/').filter((i) => i.length > 0)[0];
  const currentMenuItem = menuConfig.find((item) => item.slug === currentSlug);
  const headerI18nKey = currentMenuItem ? currentMenuItem.header_i18n_key : null;

  const skipAction = props.skipAction === true;

  useEffect(()=>{
    updateUnseenNotificationsCount();
  }, []);

  function logoutAction() {
    Api.logout().finally(()=>{
      navigate('/login');
    });
  }

  function updateUnseenNotificationsCount() {
    getUnseenNotificationsCount().then((data)=>{
      dispatch(setUnreadNotificationsCount(data));
    });
  }
  useIntervalLoop(updateUnseenNotificationsCount);

  function clickedNotificationIcon() {
    setIsUnseenOpened(true);
  }

  function closeUnseenNotifications() {
    setIsUnseenOpened(false);
  }

  const unseenCount = unseenNotificationsCount > 0 ? (unseenNotificationsCount < 10 ? unseenNotificationsCount : '9+') : null;

  const securityContext = user2securityContextsString(user);

  return (
    <div className="NavBar">
      {!skipAction &&
            <div className="NavBar-element NavBar-action">
              {headerI18nKey &&
                <Typography variant="h1">{t(headerI18nKey)}</Typography>
              }
            </div>
      }
      <div className="NavBar-spacer" />
      <div
        className={`NavBar-element NavBar-notifications ${unseenNotificationsCount > 0 ? 'has-unread' : ''}`}
        onClick={clickedNotificationIcon}
      >
        <NotificationsNone />
        {unseenCount &&
              <div className="NavBar-notifications-overlay">
                <div className="NavBar-notifications-red-dot">{unseenCount}</div>
              </div>
        }
      </div>
      <div className="NavBar-element NavBar-userprofile">
        {user.display}<Typography variant="smallHint"> &nbsp; ({securityContext})</Typography>
      </div>
      <div className="NavBar-element NavBar-logout" onClick={logoutAction}>
        <Logout />
      </div>
      {user &&
            <UnseenNotificationsDialog
              isOpen={isUnseenOpened}
              user={user}
              onClose={closeUnseenNotifications}
            />
      }
    </div>
  );
}
