import {Close, DriveFileRenameOutline, Save} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import ChangeProjectStatusPopUp from '../../../components/ChangeStatusPopUps/ChangeProjectStatusPopUp';
import EditField from '../../../components/EditField/EditField';
import HorizontalDivider from '../../../components/HorizontalDivider/HorizontalDivider';
import MergeProjectsModal from '../../../components/MergeProjectsModal/MergeProjectsModal';
import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import ProjectStatusBanner from '../../../components/ProjectStatusBanner/ProjectStatusBanner';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import {useHandlersDictionary} from '../../../helpers/dictionaries/hooks/useHandlersDictionary';
import {useLegalFormsDictionary} from '../../../helpers/dictionaries/hooks/useLegalFormsDictionary';
import formatDateShort from '../../../helpers/formatters/formatDateShort';
import {
  CAPABILITY_ASSIGN_SECURITY_CONTEXTS,
  CAPABILITY_EDIT,
  CAPABILITY_MERGE, CAPABILITY_PURGE,
} from '../../../helpers/security/hasCapability';
import {REFRESH_REASON_PROJECT_EDIT} from '../index';
import patchProject from './api/patchProject';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import getModifiedProjectState from './functions/getModifiedProjectState';
import getPossibleStatusActions from './functions/getPossibleStatusActions';
import prepareInitialProjectState from './functions/prepareInitialProjectState';
import css from './index.module.scss';

function ProjectEdit({project, onRefreshRequest}) {
  const {t} = useTranslation('page_cases');
  const navigate = useNavigate();
  const location = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [projectData, setProjectData] = useState(null);

  const [isStatusChangePopupOpen, setStatusChangePopupOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const [isMergeDialogOpen, setMergeDialogOpen] = useState(false);

  const legalFormsDropdownOptions = useLegalFormsDictionary()
      .map((form) => ({name: form['@id'], display: t(form.name, {ns: 'dictionary_legal_form'})}));

  const handlerDropdownOptions = useHandlersDictionary(
      {name: '@id', display: 'dropdownIdentifier'},
  );

  const hasCapabilityEdit = useHasCapabilityHere(CAPABILITY_EDIT);
  const hasCapabilityMerge = useHasCapabilityHere(CAPABILITY_MERGE);
  const hasAssignSecurityContextCapability = useHasCapabilityHere(CAPABILITY_ASSIGN_SECURITY_CONTEXTS);
  const hasCapabilityPurge = useHasCapabilityHere(CAPABILITY_PURGE);

  function fieldChanged(fieldName, value) {
    setProjectData(getModifiedProjectState(fieldName, value, projectData));
  }

  function clickedEdit() {
    if (hasCapabilityEdit) {
      setIsEditing(true);
    }
  }

  function clickedSave() {
    if (hasCapabilityEdit) {
      setIsSaving(true);
      patchProject(project['@id'], projectData).then((r)=>{
        setIsEditing(false);
        setIsSaving(false);
        onRefreshRequest(REFRESH_REASON_PROJECT_EDIT);
      });
    }
  }

  function clickedCancel() {
    if (hasCapabilityEdit) {
      setIsEditing(false);
      setProjectData(prepareInitialProjectState(project));
    }
  }

  function openStatusChangePopUp(action) {
    const possibleStatuses = getPossibleStatusActions(project, t);
    const newStatusDef = possibleStatuses.find((candidate)=>(candidate.id === action));
    if (newStatusDef) {
      setNewStatus(newStatusDef.newStatus);
      setStatusChangePopupOpen(true);
    }
  }

  function closeStatusChangePopup(wasUpdated) {
    setStatusChangePopupOpen(false);
    if (wasUpdated) {
      onRefreshRequest();
    }
  }

  function onMergeDialogClosed(result = false) {
    setMergeDialogOpen(false);
    if (typeof result === 'string' && result.length > 0 && isNaN(result)) {
      navigate(result);
    } else if (result === true) {
      onRefreshRequest();
    }
  }

  useEffect(()=>{
    setProjectData(prepareInitialProjectState(project));
  }, [project]);

  const optionalPrimaryUserName = project?.primaryProjectUser?.display;

  return (
    <div className={css.ProjectEdit}>
      <BreadcrumbNavigation />

      {(!projectData || !project)&&
        <ProgressIndicator />
      }
      {projectData && !!project &&
        <>
          <EditField
            id="internalName"
            name={null}
            value={projectData.internalName ?? (!isEditing ? optionalPrimaryUserName : '')}
            format="header"
            type="string"
            editing={isEditing}
            onChange={fieldChanged}
            className="InternalName"
            placeholder={t('field.internal_name.placeholder')}
          />
          <ProjectStatusBanner
            project={project}
            canChangeStatus={hasCapabilityEdit}
            possibleActions={getPossibleStatusActions(project, t)}
            onStatusChanged={openStatusChangePopUp}
          />
          {hasCapabilityMerge &&
          <div className={css.mergeButton}>
            <Button variant="smallerText" onClick={()=>setMergeDialogOpen(!isMergeDialogOpen)}>
              {t('page.account_details.merge_button')}
            </Button>
          </div>
          }
          <EditField
            id="name"
            name={t('field.contact.description')}
            value={projectData.name}
            type="string"
            editing={false}
            onChange={fieldChanged}
          />
          <EditField
            id="company"
            name={t('field.company.description')}
            value={projectData.company}
            type="string"
            editing={false}
            onChange={fieldChanged}
          />
          <EditField
            id="legalForm"
            name={t('field.legal_form.description')}
            value={projectData.legalForm}
            type="dropdown"
            placeholder={t('field.legal_form.placeholder')}
            options={legalFormsDropdownOptions}
            editing={false}
            onChange={fieldChanged}
          />
          <EditField
            id="phone"
            name={t('field.phone.description')}
            value={projectData.phone}
            placeholder={[t('field.phone.placeholder1'), t('field.phone.placeholder2')]}
            type="string"
            fontSize={16}
            editing={false}
            onChange={fieldChanged}
          />
          <EditField
            id="email"
            name={t('field.email.description')}
            value={projectData.email}
            type="string"
            fontSize={16}
            placeholder={t('field.email.description')}
            editing={false}
            onChange={fieldChanged}
          />
          <HorizontalDivider />
          <EditField
            id="amount"
            name={t('field.amount.description')}
            value={projectData.amount}
            type="money"
            editing={isEditing}
            onChange={fieldChanged}
            className="Amount"
          />
          <EditField
            id="activities"
            name={t('field.actions.description')}
            value={projectData.activities}
            type="string"
            editing={isEditing}
            onChange={fieldChanged}
          />

          <HorizontalDivider />

          {projectData.createdAt &&
          <EditField
            id="created-lead"
            name={t('field.lead_created_at.description')}
            value={formatDateShort(projectData.createdAt)}
            type="string"
            editing={false}
            onChange={null}
          />
          }

          {projectData.becameOpportunityAt &&
          <EditField
            id="created-opportunity"
            name={t('field.opportunity_created_at.description')}
            value={formatDateShort(projectData.becameOpportunityAt)}
            type="string"
            editing={false}
            onChange={null}
          />
          }

          {projectData.becameAccountAt &&
          <EditField
            id="created-account"
            name={t('field.account_created_at.description')}
            value={formatDateShort(projectData.becameAccountAt)}
            type="string"
            editing={false}
            onChange={null}
          />
          }

          <HorizontalDivider />

          {hasAssignSecurityContextCapability &&
          <EditField
            id="securityContexts"
            name={t('field.security_context.description')}
            value={projectData.securityContexts}
            type="securityContextMultiselect"
            typeOptions={{iriFormat: true}}
            fontSize={16}
            editing={isEditing}
            onChange={fieldChanged}
          />
          }

          <EditField
            id="handler"
            name={t('field.handler.description')}
            value={projectData.handler}
            type="autocomplete"
            placeholder={t('field.handler.placeholder')}
            options={handlerDropdownOptions}
            editing={isEditing && hasCapabilityEdit}
            onChange={fieldChanged}
          />

          <HorizontalDivider />
          {!isEditing && !isSaving && hasCapabilityEdit &&
          <Button
            startIcon={<DriveFileRenameOutline />}
            color="text"
            onClick={clickedEdit}
            sx={{padding: 0}}
          >
            <Typography fontSize={16}>{t('action.edit')}</Typography>
          </Button>
          }
          {isEditing && !isSaving && hasCapabilityEdit &&
          <>
            <Button
              startIcon={<Save />}
              color="text"
              onClick={clickedSave}
              sx={{padding: 0}}
            >
              <Typography fontSize={16}>{t('action.save')}</Typography>
            </Button>
            <Button
              startIcon={<Close />}
              color="text"
              onClick={clickedCancel}
              sx={{padding: 0, marginLeft: '36px'}}
            >
              <Typography fontSize={16}>{t('action.cancel')}</Typography>
            </Button>
          </>
          }
          {hasCapabilityPurge && !isEditing && !isSaving &&
          <Button
            startIcon={<Close />}
            color="text"
            onClick={()=>{
              navigate(`${location.pathname}/purge`);
            }}
            sx={{padding: 0, marginLeft: '36px'}}
          >
            <Typography fontSize={16}>{t('action.purge')}</Typography>
          </Button>
          }
          {isSaving &&
            <ProgressIndicator />
          }

          {project && newStatus &&
          <ChangeProjectStatusPopUp
            isOpen={isStatusChangePopupOpen}
            project={project}
            newStatus={newStatus}
            shouldForceChange={true}
            showNotifyClient={false}
            showInternalName={true}
            newStatusAsDropdown={true}
            onClose={closeStatusChangePopup}
          />
          }
          <MergeProjectsModal
            isOpen={isMergeDialogOpen}
            onClose={onMergeDialogClosed}
            subject={project}
          />
        </>
      }
    </div>
  );
}

ProjectEdit.propTypes = {
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']).isRequired,
    'id': PropTypes.number.isRequired,
  }),
  onRefreshRequest: PropTypes.func.isRequired,
};

export default ProjectEdit;
