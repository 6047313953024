import {TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldStringTextArea(props) {
  function callback(event) {
    props.onChange(event.target.value);
  }

  return (
    <TextField
      className="EditFieldStringTextArea"
      multiline={true}
      minRows={props.minRows}
      maxRows={props.maxRows}
      placeholder={props.placeholder}
      style={{width: '100%', height: '100%', overflow: 'auto'}}
      value={props.value}
      onChange={callback}
    />
  );
}

EditFieldStringTextArea.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};
