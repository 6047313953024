import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function CompanyAddress({projectCompany, multiline}) {
  function stripAddressLines(projectCompany) {
    const lastLine = [
      projectCompany?.company?.primaryAddress?.postal,
      projectCompany?.company?.primaryAddress?.country,
    ].filter((item) => !!item).join('; ');
    return [
      projectCompany?.company?.primaryAddress?.line1,
      projectCompany?.company?.primaryAddress?.city,
      lastLine,
    ].filter((item) => !!item);
  }

  if (!projectCompany?.company) return null;

  if (multiline) {
    return (
      <>
        {stripAddressLines(projectCompany).map((line)=>(
          <Typography key={line} variant="body2">{line}</Typography>
        ))}
      </>
    );
  } else {
    return (
      <Typography variant="body2">{stripAddressLines(projectCompany).join('; ')}</Typography>
    );
  }
}

CompanyAddress.propTypes = {
  projectCompany: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['ProjectCompany']).isRequired,
    'company': PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      '@type': PropTypes.oneOf(['Company']),
    }).isRequired,
  }),
  multiline: PropTypes.bool,
};

CompanyAddress.defaultProps = {
  multiline: false,
};

export default CompanyAddress;
