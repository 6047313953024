import axios from 'axios';

import {SERVER_URL} from '../../config';
import getLoginFrontendPath from '../navigation/getLoginFrontendPath';
import {
  getAccessToken,
  getRefreshToken,
  isAccessTokenSet,
  isAccessTokenValid, removeAccessToken, removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './token-persistence';

const LOGIN_ROUTE = '/users/login';
const LOGOUT_ROUTE = '/users/logout';
const REFRESH_TOKEN_ROUTE = '/users/refresh_token';
const FORGOTTEN_PASSWORD_ROUTE = '/users/password_reset/send_link';
const COMPANY_LEGAL_FORMS_ROUTE = '/company_legal_forms';
const LEAD_SELF_REGISTRATION_ROUTE = '/projects/lead_self_registration';
const CREATE_NEW_PASSWORD_ROUTE = '/users/password_reset/execute';
const EVENT_TOKEN_REFRESH_SUCCESSFUL = 'EVENT_TOKEN_REFRESH_SUCCESSFUL';
const EVENT_TOKEN_REFRESH_FAILED = 'EVENT_TOKEN_REFRESH_FAILED';

const eventTarget = new EventTarget();
let isRefreshing = false;

/**
 * Unconditionally attempts token refresh.
 * @return {Promise<any>}
 */
function refreshCredentials() {
  return new Promise(function(resolve, reject) {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      reject();
    } else {
      axios.post(REFRESH_TOKEN_ROUTE,
          {refresh_token: refreshToken},
          {baseURL: SERVER_URL})
          .then((response) => {
            setAccessToken(response.data.token);
            setRefreshToken(response.data.refresh_token);
            resolve(response.data);
          }).catch(() => {
            reject();
          });
    }
  });
}
/**
 * Checks if token is valid, refreshes it if needed, and then makes the request.
 * @param method {string}
 * @param route {string}
 * @param data {any}
 * @param headers {any}
 * @param options {any}
 * @return {Promise<any>}
 * @private
 */
function request(method, route, data = {}, headers={}, options={}) {
  function validateToken() {
    return new Promise(function(resolve, reject) {
      if (!isAccessTokenSet()) {
        reject();
      }
      if (!isAccessTokenValid()) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshCredentials().then(() => {
            isRefreshing = false;
            eventTarget.dispatchEvent(new Event(EVENT_TOKEN_REFRESH_SUCCESSFUL));
            resolve();
          }).catch(() => {
            isRefreshing = false;
            eventTarget.dispatchEvent(new Event(EVENT_TOKEN_REFRESH_FAILED));
            reject();
          });
        } else {
          eventTarget.addEventListener(EVENT_TOKEN_REFRESH_SUCCESSFUL, () => {
            resolve();
          });
          eventTarget.addEventListener(EVENT_TOKEN_REFRESH_FAILED, () => {
            reject();
          });
        }
      } else {
        resolve();
      }
    });
  }

  return new Promise(function(resolve, reject) {
    validateToken().then(()=>{
      const allHeaders = Object.assign({}, headers);
      allHeaders['Authorization']=`Bearer ${getAccessToken()}`;
      const axiosConfig = {
        method: method,
        url: route,
        data: data,
        headers: allHeaders,
        baseURL: SERVER_URL,
      };
      if (typeof (options.responseType === 'string')) {
        axiosConfig.responseType = options.responseType;
      }
      if (typeof (options.onUploadProgress === 'function')) {
        axiosConfig.onUploadProgress = options.onUploadProgress;
      }
      axios(axiosConfig).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    }).catch(()=>{
      document.location = getLoginFrontendPath();
      reject('Authentication failed.');
    });
  });
}

const Api = {
  get: function(route) {
    return request('get', route);
  },
  getBinary: function(route) {
    return request('get', route, {}, {}, {
      responseType: 'arraybuffer',
    });
  },
  post: function(route, data = {}, options = {}) {
    return request('post', route, data, {}, options);
  },
  postFiles: function(route, data = {}, options) {
    return request('post', route, data, {'Content-type': 'multipart/form-data'}, options);
  },
  patch: function(route, data = {}, options) {
    return request('patch', route, data, {'Content-type': 'application/merge-patch+json'}, options);
  },
  delete: function(route, data = {}) {
    return request('delete', route);
  },
  login: function(username, password, token = null) {
    return new Promise(function(resolve, reject) {
      axios.post(LOGIN_ROUTE, {
        username, password, token,
      }, {
        baseURL: SERVER_URL,
      }).then((response) => {
        setAccessToken(response.data.token);
        setRefreshToken(response.data.refresh_token);
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  },
  refreshCredentials: function() {
    return refreshCredentials();
  },
  logout: function() {
    return new Promise(function(resolve, reject) {
      request('post', LOGOUT_ROUTE).then(()=>{
        removeAccessToken();
        removeRefreshToken();
        resolve();
      }).catch(()=>{
        reject();
      });
    });
  },
  sendForgottenPasswordLink: function(username) {
    return new Promise(function(resolve, reject) {
      axios.post(FORGOTTEN_PASSWORD_ROUTE, {email: username}, {
        baseURL: SERVER_URL,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  },
  createNewPassword: function(password, token) {
    return new Promise(function(resolve, reject) {
      axios.post(CREATE_NEW_PASSWORD_ROUTE, {password: password, token: token}, {
        baseURL: SERVER_URL,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  },
  getCompanyLegalForms: function() {
    return new Promise(function(resolve, reject) {
      axios.get(COMPANY_LEGAL_FORMS_ROUTE, {
        baseURL: SERVER_URL,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  },
  createNewLeadAutomatically: function({companyName, legalForm, phoneCountry, phoneNumber, email, firstName, lastName, amount, activities, createdAt}) {
    return new Promise(function(resolve, reject) {
      axios.post(LEAD_SELF_REGISTRATION_ROUTE, {
        companyName: companyName,
        legalForm: legalForm,
        phoneCountry: phoneCountry,
        phoneNumber: phoneNumber,
        email: email,
        firstName: firstName,
        lastName: lastName,
        amount: amount,
        activities: activities,
        createdAt: createdAt,
      }, {
        baseURL: SERVER_URL,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error.response);
      });
    });
  },
};

export default Api;
