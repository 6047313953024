import './AddCompanyModal.scss';

import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useUserSecurityContext} from '../../customHooks/useUserSecurityContext';
import getLegalFormsDictionary from '../../helpers/dictionaries/getLegalFormsDictionary';
import getPossibleCompanyTypes from '../../helpers/dictionaries/getPossibleCompanyTypes';
import {useHandlersDictionary} from '../../helpers/dictionaries/hooks/useHandlersDictionary';
import EditField from '../EditField/EditField';
import ModalDialog from '../ModalDialog/ModalDialog';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import postCompany from './api/postCompany';

export default function AddCompanyModal(props) {
  const {t, i18n} = useTranslation('component_add_company');

  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyLegalForms, setCompanyLegalForms] = useState([]);
  const handlers = useHandlersDictionary({name: '@id', display: 'display'});

  const [companyName, setCompanyName] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [companyLegalFormIri, setCompanyLegalFormIri] = useState(null);
  const [companyHandlerIri, setCompanyHandlerIri] = useState(null);
  const [officeIri, setOfficeIri] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const userSecurityContexts = useUserSecurityContext();
  const offices = userSecurityContexts.map((office) => ({name: office['@id'], display: office['name']}));

  function emptyForm() {
    setCompanyName(null);
    setCompanyType(null);
    setCompanyLegalFormIri(null);
    setCompanyHandlerIri(null);
    setOfficeIri(null);
  }

  function handleModalClose() {
    emptyForm();
    props.onClose();
  }

  function createNewCompany() {
    setIsSending(true);
    postCompany({
      name: companyName,
      type: companyType,
      legalForm: companyLegalFormIri,
      handler: companyHandlerIri ?? null,
      securityContextsIds: [officeIri],
    }).then(()=>{
      emptyForm();
      setIsSending(false);
      props.onClose();
    });
  }


  useEffect(() => {
    if (props.isOpen) {
      getLegalFormsDictionary().then((legalFormsSet) => setCompanyLegalForms(legalFormsSet));
      setCompanyTypes(
          getPossibleCompanyTypes().map((ct) => (
            {name: ct, display: t(ct, {ns: 'dictionary_company_type'})}
          )),
      );
    }
  }, [props.isOpen, t]);

  return (
    <>
      <ModalDialog
        className="AddCompanyModal"
        open={props.isOpen}
        onCloseRequest={handleModalClose}
        sx={{outline: 'none'}}
      >
        <div className="AddCompanyModal-title">{t('title')}</div>
        <div className="AddCompanyModal-fields">
          <EditField
            id="type"
            name={t('field.type.description')}
            value={companyType}
            type="dropdown"
            placeholder={t('field.type.placeholder')}
            options={companyTypes}
            editing={true}
            onChange={(id, val) => {
              setCompanyType(val);
            }}
          />
          <EditField
            id="companyName"
            name={t('field.name.description')}
            value={companyName}
            type="string"
            placeholder={t('field.name.placeholder')}
            editing={true}
            onChange={(id, val) => {
              setCompanyName(val);
            }}
          />
          <EditField
            id="legalForm"
            name={t('field.legal_form.description')}
            value={companyLegalFormIri}
            type="dropdown"
            placeholder={t('field.legal_form.placeholder')}
            options={companyLegalForms}
            editing={true}
            onChange={(id, val) => {
              setCompanyLegalFormIri(val);
            }}
          />
          {props.showHandler &&
            <EditField
              id="handler"
              name={t('field.handler.description')}
              value={companyHandlerIri}
              type="autocomplete"
              placeholder={t('field.handler.placeholder')}
              options={handlers}
              editing={true}
              onChange={(id, text)=>setCompanyHandlerIri(text)}
            />
          }
          <EditField
            id="officeName"
            name={t('field.office_name.description')}
            value={officeIri}
            type="dropdown"
            placeholder={t('field.office_name.placeholder')}
            options={offices}
            editing={true}
            onChange={(id, val) => {
              setOfficeIri(val);
            }}
          />
        </div>
        <div className="AddCompanyModal-buttons">
          {!isSending &&
            <Button
              variant="contained"
              sx={{width: '164px', height: '100%', textTransform: 'capitalize', marginBotton: '92px'}}
              onClick={createNewCompany}
              className="CreateButton"
            >{t('create_button')}</Button>
          }
          {isSending &&
            <ProgressIndicator />
          }
        </div>
      </ModalDialog>
    </>
  );
}

AddCompanyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showHandler: PropTypes.bool,
};
