import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import BasicCard from '../../../components/Card/Card';
import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import {useHasCapabilityHere} from '../../../customHooks/useHasCapabilityHere';
import Api from '../../../helpers/api/Api';
import {CAPABILITY_PURGE} from '../../../helpers/security/hasCapability';
import {useStateValue} from '../../../state/state';
import css from './index.module.scss';

export default function CardProjectPurge({pageScope, project, onPurgeCompletion}) {
  const {t} = useTranslation('page_cases');
  const [{user}] = useStateValue();
  const navigate = useNavigate();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isWorkingNow, setWorkingNow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const canPurge = useHasCapabilityHere(CAPABILITY_PURGE);

  function onConfirmationDialogCanceled() {
    if (!isWorkingNow) {
      setConfirmationOpen(false);
    }
  }

  function onConfirmationDialogConfirmed() {
    if (canPurge) {
      setWorkingNow(true);
      Api.delete(`/projects/${project.id}`).then(() => {
        switch (pageScope) {
          case 'leads':
            navigate('/leads');
            break;
          case 'opportunities':
            navigate('/opportunities');
            break;
          case 'accounts':
            navigate('/accounts');
            break;
          case 'cases':
            navigate('/cases');
            break;
          default:
            navigate(-1);
        }
      }).catch((error) => {
        setErrorMessage(`${t('card_purge.feedback.server_error', {message: error?.data?.message})}`);
      }).finally(() => {
        setWorkingNow(false);
        if (onPurgeCompletion) {
          onPurgeCompletion();
        }
      });
    }
  }

  return (
    <>
      {(!user || !project) &&
        <ProgressIndicator />
      }
      {user && project &&
        <>
          <BasicCard
            className={css.CardProjectPurge}
            title={t('card_purge.title')}
          >
            <Typography dangerouslySetInnerHTML={{__html: t('card_purge.description1')}}/>
            <Typography dangerouslySetInnerHTML={{__html: t('card_purge.description2')}}/>
            {canPurge && user.id !== project.id &&
              <Button variant="outlined" onClick={() => setConfirmationOpen(true)}>
                {t('card_purge.purge_button', {user: project?.display})}
              </Button>
            }
            {canPurge && user.id === project.id &&
              <Button variant="outlined" disabled={true}>
                {t('card_purge.purge_disabled', {user: project?.display})}
              </Button>
            }
          </BasicCard>
          <Dialog
            className={css.CardProjectPurgeConfirmationModal}
            open={isConfirmationOpen}
            onClose={onConfirmationDialogCanceled}
          >
            <DialogTitle>{t('card_purge.confirmation_modal.question')}</DialogTitle>
            <DialogContent>
              {!errorMessage &&
                <Typography>{t('card_purge.confirmation_modal.hint')}</Typography>
              }
              {errorMessage &&
                <Typography className="error-feedback" dangerouslySetInnerHTML={{__html: errorMessage}}/>
              }
            </DialogContent>
            <DialogActions>
              {isWorkingNow &&
                <ProgressIndicator/>
              }
              {!isWorkingNow &&
                <>
                  <Button onClick={onConfirmationDialogCanceled} variant="outlined"
                    color="error">{t('card_purge.confirmation_modal.cancel')}</Button>
                  <Button onClick={onConfirmationDialogConfirmed}
                    variant="outlined">{t('card_purge.confirmation_modal.confirm')}</Button>
                </>
              }
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
}

CardProjectPurge.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
  project: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Project']).isRequired,
    'id': PropTypes.number.isRequired,
  }),
  onPurgeCompletion: PropTypes.func,
};
