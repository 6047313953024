import Api from '../../../helpers/api/Api';

const itemsPerPage = 100000;

export default function getCompanies() {
  return new Promise(function(resolve, reject) {
    Api.get(`/companies/dictionary?itemsPerPage=${itemsPerPage}`).then((data)=>{
      resolve(data['hydra:member']);
    }).catch((error) => {
      reject(error);
    });
  });
}
