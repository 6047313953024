import {OutlinedInput} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldString(props) {
  function callback(event) {
    props.onChange(event.target.value);
  }
  const placeholder = !Array.isArray(props.placeholder) ? props.placeholder :null;
  return (
    <OutlinedInput
      className="EditFieldString"
      size="small"
      placeholder={placeholder}
      value={props.value ?? ''}
      onChange={callback}
      fullWidth={true}
      style={{
        fontSize: props.fontSize,
      }}
      endAdornment={props.icon}
      error={props.error}
    />
  );
}

EditFieldString.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.element,
};
