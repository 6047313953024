import PropTypes from 'prop-types';
import React from 'react';

import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import ActionsBlock from './ActionsBlock';
import FiltersBlock from './FiltersBlock';
import css from './index.module.scss';
import ProjectsPageTitle from './ProjectsPageTitle';
import TableBlock from './TableBlock';

function ProjectsPage({pageScope}) {
  return (
    <DefaultLayout>
      <div className={css.ProjectsPage}>
        <ProjectsPageTitle />
        <div className={css.controlBar}>
          <FiltersBlock pageScope={pageScope} />
          <ActionsBlock pageScope={pageScope}/>
        </div>
        <TableBlock pageScope={pageScope}/>
      </div>
    </DefaultLayout>
  );
}

ProjectsPage.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
};

export default ProjectsPage;
