import './DocumentItem.scss';

import {Close, Save} from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import EditField from '../../components/EditField/EditField';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import bytes2FileSizeString from '../../helpers/formatters/bytes2FileSizeString';
import formatDate from '../../helpers/formatters/formatDate';
import {CAPABILITY_DOCUMENTS_WRITE} from '../../helpers/security/hasCapability';
import {useStateValue} from '../../state/state';
import AlertDialog from '../AlertDialog/AlertDialog';
import deleteAttachment from './api/deleteAttachment';
import deleteCompanyAttachment from './api/deleteCompanyAttachment';
import getAttachmentBinary from './api/getAttachmentBinary';
import getCompanyAttachmentBinary from './api/getCompanyAttachmentBinary';
import patchAttachmentsName from './api/patchAttachmentsName';
import patchCompanyAttachmentsName from './api/patchCompanyAttachmentsName';


export default function DocumentItem(props) {
  const {t, i18n} = useTranslation('component_documents');

  const canEditDocument = useHasCapabilityHere(CAPABILITY_DOCUMENTS_WRITE);
  const [{user}] = useStateValue();

  const [isDeleteConfirmationOpened, setDeleteConfirmationOpened] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [attachmentOriginalName, setAttachmentOriginalName] = useState(props.fileName);

  function onDownloadClicked() {
    switch (props.type) {
      case 'companyAttachment':
        getCompanyAttachmentBinary(props.fileId).then((data)=>{
          fileDownload(data, props.fileName);
        });
        break;
      default:
        getAttachmentBinary(props.fileId).then((data)=>{
          fileDownload(data, props.fileName);
        });
    }
  }

  function onRemoveClicked() {
    if (canEditDocument) {
      setDeleteConfirmationOpened(true);
    }
  }

  function onRemoveConfirmed() {
    if (canEditDocument) {
      switch (props.type) {
        case 'companyAttachment':
          deleteCompanyAttachment(props.fileId).then(()=>{
            props.onItemDeleted(props.fileId);
          });
          break;
        default:
          deleteAttachment(props.fileId).then(()=>{
            props.onItemDeleted(props.fileId);
          });
      }
    }
  }

  function onEditClicked() {
    setIsEditing(!isEditing);
  }

  function toggleSave() {
    if (canEditDocument) {
      switch (props.type) {
        case 'companyAttachment':
          patchCompanyAttachmentsName(props.fileId, attachmentOriginalName).then(() => {
            setIsEditing(false);
            props.onItemNameChanged(props.fileId);
          });
          break;
        default:
          patchAttachmentsName(props.fileId, attachmentOriginalName).then(() => {
            setIsEditing(false);
            props.onItemNameChanged(props.fileId);
          });
      }
    }
  }

  return (
    <Card className={`DocumentItem ${props.className ?? ''}`} sx={{boxShadow: 'none'}}>
      <div className="DocumentItem-section width-a">
        <EditField
          id="originalNameId"
          value={attachmentOriginalName}
          type="string"
          placeholder={t('filter.status_placeholder')}
          editing={isEditing}
          onChange={(fieldId, newValue) => {
            setAttachmentOriginalName(newValue);
          }}
        />
        {isEditing &&
          <>
            <Button
              startIcon={<Save />}
              color="text"
              onClick={toggleSave}
              sx={{padding: 0}}
            >
              <Typography fontSize={16}>{t('action.save')}</Typography>
            </Button>
            <Button
              startIcon={<Close />}
              color="text"
              onClick={onEditClicked}
              sx={{padding: 0, marginLeft: '36px'}}
            >
              <Typography fontSize={16}>{t('action.cancel')}</Typography>
            </Button>
          </>
        }
      </div>
      <div className="DocumentItem-section width-a">
        <Typography variant="smallHint">
          {props.fileName.split(/[.]+/).pop().toUpperCase()}
          &bull;
          {bytes2FileSizeString(props.fileSizeBytes)}
          &bull;
          {formatDate(props.fileDate)}
        </Typography>
      </div>
      <div className="DocumentItem-section width-b float-right">
        {renderStatusElement(props.status, t)}
        <IconButton className="clickable-icon color-text" onClick={onDownloadClicked}>
          <FileDownloadOutlinedIcon />
        </IconButton>
        {canEditDocument &&
          <IconButton className="clickable-icon color-text" onClick={onEditClicked}>
            <ModeEditOutlineIcon />
          </IconButton>
        }
        {canEditDocument && user.primaryRole !== 'ROLE_USER' &&
          <IconButton className="clickable-icon color-text" onClick={onRemoveClicked}>
            <DeleteOutlinedIcon/>
          </IconButton>
        }
      </div>
      <AlertDialog
        title={t('delete_confirmation_modal.title')}
        text={t('delete_confirmation_modal.text')}
        open={isDeleteConfirmationOpened}
        onClose={() => setDeleteConfirmationOpened(false)}
        onConfirm={onRemoveConfirmed}
        textConfirm={t('delete_confirmation_modal.button_confirm')}
        textClose={t('delete_confirmation_modal.button_cancel')}
      />
    </Card>
  );
}

DocumentItem.propTypes = {
  type: PropTypes.oneOf(['attachment', 'companyAttachment']),
  className: PropTypes.string,
  fileId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fileName: PropTypes.string.isRequired,
  fileSizeBytes: PropTypes.number.isRequired,
  fileDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onItemDeleted: PropTypes.func.isRequired,
  onItemNameChanged: PropTypes.func.isRequired,
};

DocumentItem.defaultProps = {
  type: 'attachment',
};

function renderStatusElement(status, translator) {
  switch (status) {
    case 'signed':
      return (
        <Typography className="DocumentItem-status status-signed" variant="body2" component="span">
          {translator('status.signed')}
        </Typography>
      );
    case 'to_sign':
      return (
        <Typography className="DocumentItem-status status-to-sign" variant="body2" component="span">
          {translator('status.to_sign')}
        </Typography>
      );
    case 'unsigned':
      return (
        <Typography className="DocumentItem-status status-unsigned" variant="body2" component="span">
          {translator('status.unsigned')}
        </Typography>
      );
    default:
      return (
        <Typography className="DocumentItem-status status-other" variant="body2" component="span">
          {translator('status.other')}
        </Typography>
      );
  }
}
