import './NotesPanel.scss';

import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import BasicCard from '../../components/Card/Card';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import {useStateValue} from '../../state/state';
import AddNoteModal from './AddNoteModal';
import AllNotesModal from './AllNotesModal';
import getNotes from './api/getNotes';
import SingleNoteModal from './SingleNoteModal';
import SingleNotePreview from './SingleNotePreview';

export default function NotesPanel(props) {
  const [{user}] = useStateValue();
  const {t} = useTranslation('component_notes');

  const [notesList, setNotesList] = useState([]);
  const [selectedSingleNote, setSelectedSingleNote] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [isPopupAllNotesOpen, setIsPopupAllNotesOpen] = useState(false);
  const [isPopupCreateNoteOpen, setIsPopupCreateNoteOpen] = useState(false);

  const presentationMode = props.presentationMode ?? 'list';

  const refreshNotesList = useCallback(()=>{
    if (props.parentIri) {
      setLoading(true);
      getNotes(props.parentIri).then((data)=>{
        setNotesList(
            data.sort((a, b)=>{
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateA.getTime - dateB.getTime;
            }),
        );
        setLoading(false);
      });
    }
  }, [props.parentIri]);

  useEffect(()=>{
    refreshNotesList();
  }, [props.parentIri, refreshNotesList]);

  const shouldDisplaySeeMore = !!props.canDisplaySeeMore && props.previewEntriesLimit && notesList.length > props.previewEntriesLimit;

  return (
    <>
      <BasicCard
        className={`NotesPanel NotesPanel-${presentationMode}`}
        title={t('panel_title')}
        appendToHeader={<>
          <CardActions>
            <Button size="small" sx={{fontSize: 15, padding: 0}} onClick={() => setIsPopupCreateNoteOpen(true)}>{t('add_button')}</Button>
          </CardActions></>}
        appendToFooter={
          <CardActions
            className="BasicCard-footer"
            sx={{marginTop: 'auto', textAlign: 'right'}}
          >
            <Button
              size={'small'}
              sx={{paddingLeft: '15px'}}
              onClick={() => setIsPopupAllNotesOpen(true)}
            >
              {shouldDisplaySeeMore && t('see_more')}
            </Button>
          </CardActions>}
      >
        <div className="NotesPanel-body">
          {notesList.filter((note, noteIdx)=>(props.previewEntriesLimit ? noteIdx < props.previewEntriesLimit : true)).map((note) =>
            <SingleNotePreview
              key={note['@id']}
              note={note}
              onClick={() => setSelectedSingleNote(note)}
              presentationMode={presentationMode}
              characterLimit={presentationMode === 'bricks' ? 50 : 0}
            />,
          )}
          {isLoading &&
            <ProgressIndicator />
          }
        </div>
      </BasicCard>
      {props.parentIri &&
        <>
          <AllNotesModal
            isModalOpened={isPopupAllNotesOpen}
            closeModal={()=>setIsPopupAllNotesOpen(false)}
            notes={notesList}
            openSingleNoteRequest={(note)=>setSelectedSingleNote(note)}
            isLoading={isLoading}
          />
          <SingleNoteModal
            isModalOpened={!!selectedSingleNote}
            closeModal={()=>setSelectedSingleNote(null)}
            note={selectedSingleNote}
            user={user}
            onNoteChanged={refreshNotesList}
          />
          <AddNoteModal
            closeModal={()=>setIsPopupCreateNoteOpen(false)}
            isModalOpened={isPopupCreateNoteOpen}
            parentIri={props.parentIri}
            onNoteChanged={refreshNotesList}
          />
        </>
      }
    </>
  );
}

NotesPanel.propTypes = {
  parentIri: PropTypes.string.isRequired,
  canDisplaySeeMore: PropTypes.bool,
  presentationMode: PropTypes.oneOf(['list', 'bricks']),
  previewEntriesLimit: PropTypes.number,
};
