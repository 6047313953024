import Api from '../../../../helpers/api/Api';

export default function patchUserStatus(userId, isEnabled) {
  return new Promise(function(resolve, reject) {
    Api.patch(`/users/${userId}`, {
      isEnabled: isEnabled,
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
