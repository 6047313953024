import './SettingsPage.scss';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import HorizontalDivider from '../../components/HorizontalDivider/HorizontalDivider';
import ChangePasswordCorrectlyPopUp from '../../components/Popups/ChangePasswordCorrectlyPopUp';
import Switch from '../../components/Switch/Switch';
import {usePasswordValidation} from '../../customHooks/usePasswordValidation';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {useStateValue} from '../../state/state';
import getUserInfo from './api/getUserInfo';
import patchNotificationsSending from './api/patchNotificationsSending';
import postPasswordChange from './api/postPasswordChange';
import TotpConfiguration from './TotpConfiguration';

const emptyForm = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
  showCurrentPassword: false,
  showPassword: false,
  showConfirmationPassword: false,
};

export default function SettingsPage() {
  const {t, i18n} = useTranslation('page_settings');
  const [{user, navTarget}, dispatch] = useStateValue();
  const [userInfo, setUserInfo] = useState({});
  const [isErrorForOldPassword, setIsErrorForOldPassword] = useState(false);
  const [isErrorForNewPassword, setIsErrorForNewPassword] = useState(false);
  const [isErrorForConfirmationPassword, setIsErrorConfirmationPassword] = useState(false);
  const [values, setValues] = useState({...emptyForm});
  const [errorTextForNewPassword, setErrorTextForNewPassword] = useState(null);
  const [errorTextForConfirmationPassword, setErrorTextForConfirmationPassword] = useState(null);
  const [errorTextForOldPassword, setErrorTextForOldPassword] = useState(null);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [validLength, hasNumber, upperCase, lowerCase, specialChar, match] =
    usePasswordValidation({
      firstPassword: values.newPassword,
      secondPassword: values.newPasswordConfirmation,
    });

  const enterOldPassword = (event) => {
    setIsErrorForOldPassword(false);
    setValues({...values, currentPassword: event.target.value});
  };
  const setPassword = (event) => {
    setIsErrorForNewPassword(false);
    setErrorTextForNewPassword(null);
    setValues({...values, newPassword: event.target.value});
    if (values.newPassword.length === 0) {
      setIsErrorForNewPassword(false);
      setErrorTextForNewPassword(false);
    }
  };
  const confirmPassword = (event) => {
    setIsErrorConfirmationPassword(false);
    setErrorTextForConfirmationPassword(null);
    setValues({...values, newPasswordConfirmation: event.target.value});
    if (values.newPasswordConfirmation.length === 0) {
      setIsErrorConfirmationPassword(false);
      setErrorTextForConfirmationPassword(false);
    }
  };

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmationPassword = () => {
    setValues({
      ...values,
      showConfirmationPassword: !values.showConfirmationPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function changePassword() {
    setIsErrorForNewPassword(false);
    setIsErrorForOldPassword(false);
    setIsErrorConfirmationPassword(false);
    setErrorTextForOldPassword(null);
    if (values.newPassword === values.newPasswordConfirmation) {
      postPasswordChange({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      }).then(()=>{
        setValues({...emptyForm});
        setIsSuccessPopupOpen(true);
      }).catch((error)=>{
        console.error(error);
        if (error.status === 500) {
          setErrorTextForNewPassword(error && error.statusText ? error.statusText : null);
          setErrorTextForConfirmationPassword(error && error.statusText ? error.statusText : null);
        } if (error.status === 401) {
          setIsErrorForOldPassword(true);
          setErrorTextForOldPassword(error && error.data && error.data.message ? error.data['hydra:description'] : null);
        } else if (error.status === 422) {
          setIsErrorForNewPassword(true);
          setIsErrorConfirmationPassword(true);
          setErrorTextForNewPassword(error && error.data && error.data['hydra:description'] ? error.data['hydra:description'] : null);
          setErrorTextForConfirmationPassword(error && error.data && error.data['hydra:description'] ? error.data['hydra:description'] : null);
        }
      });
    } else {
      setIsErrorForNewPassword(true);
      setIsErrorConfirmationPassword(true);
      setErrorTextForConfirmationPassword(t('password.feedback.not_match'));
    }
  }

  const handlePopupClose = () => {
    setIsSuccessPopupOpen(false);
  };


  useEffect(function() {
    getUserInfo(user.id).then((data)=>{
      setUserInfo(data);
    });
  }, [user.id]);

  const setNotificationsSending = (event) => {
    patchNotificationsSending(user.id, event.target.checked);
  };

  return (
    <DefaultLayout>
      <div className="Settings-wrapper">
        <Box
          sx={{
            'display': 'flex',
            'flexWrap': 'wrap',
            'flexDirection': 'column',
            'maxWidth': '450px',

            '@media screen and (max-width: 460px)': {
              width: '90%',
            },
          }}
          className="change-password-form"
        >
          <Typography variant="h1" sx={{fontSize: 20, fontWeight: '500'}} component="div">
            {t('password.title')}
          </Typography>
          <div className="field-title">
            <p>{t('password.field_current.description')}</p>
            <FormControl
              sx={
                isErrorForOldPassword ?
                    {
                      color: '#ED143D',
                      border: '1px solid #ED143D',
                      borderRadius: '5px',
                      width: '100%',
                    } :
                    {color: '#000000', width: '100%'}
              }
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password-confirmation"
                type={values.showCurrentPassword ? 'text' : 'password'}
                value={values.currentPassword}
                onChange={enterOldPassword}
                placeholder={t('password.field_current.placeholder')}
                error={isErrorForOldPassword}
                sx={isErrorForOldPassword ? {color: '#ED143D', height: '50px'} : {color: '#9C9FAF', height: '50px'}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {isErrorForOldPassword &&
                <Typography className="ErrorMessage" color={'error'}>{t('password.feedback.incorrect')}.</Typography>
            }

          </div>
          <div className="field-title">
            <p>{t('password.field_new1.description')}</p>
            <FormControl
              sx={
                isErrorForNewPassword ?
                    {
                      color: '#ED143D',
                      border: '1px solid #ED143D',
                      borderRadius: '5px',
                      width: '100%',
                    } :
                    {color: '#000000', width: '100%'}
              }
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={setPassword}
                placeholder={t('password.field_new1.placeholder')}
                error={isErrorForNewPassword}
                sx={isErrorForNewPassword ? {color: '#ED143D', height: '50px'} : {color: '#9C9FAF', height: '50px'}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {(!validLength || !hasNumber || !upperCase || !lowerCase || !specialChar) && !isErrorForNewPassword && (
            <FormHelperText id="component-error-text" sx={{color: '#A5ABB6'}}>
              {!validLength && t('password.feedback.too_short')}
              {!hasNumber && t('password.feedback.no_numbers')}
              {(!upperCase || !lowerCase) && t('password.feedback.no_case')}
              {!specialChar && t('password.feedback.no_special')}
            </FormHelperText>
          )}

          <div className="field-title">
            <p>{t('password.field_new2.description')}</p>
            <FormControl
              sx={
                isErrorForConfirmationPassword ?
                    {
                      color: '#ED143D',
                      border: '1px solid #ED143D',
                      borderRadius: '5px',
                      width: '100%',
                    } :
                    {color: '#000000', width: '100%'}
              }
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password-confirmation"
                type={values.showConfirmationPassword ? 'text' : 'password'}
                value={values.newPasswordConfirmation}
                onChange={confirmPassword}
                placeholder={t('password.field_new2.placeholder')}
                error={isErrorForConfirmationPassword}
                sx={isErrorForConfirmationPassword ? {color: '#ED143D', height: '50px'} : {color: '#9C9FAF', height: '50px'}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmationPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmationPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          {!match && !isErrorForNewPassword && (
            <FormHelperText id="component-error-text" sx={{color: '#A5ABB6'}}>
              {t('password.feedback.not_match')}
            </FormHelperText>
          )}

          {typeof errorTextForConfirmationPassword === 'string' &&
                <Typography className="ErrorMessage" color={'error'}>{t('password.feedback.all')}</Typography>
          }

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: '46px 0 24px',
            }}
          >
            <Button
              variant="contained"
              sx={{'width': '68%',
                'height': '44px',
                '@media screen and (max-width: 460px)': {
                  width: '80%',
                  fontSize: '14px',
                },
              }}
              onClick={() => changePassword(values.currentPassword, values.newPassword)}
            >
              {t('password.button')}
            </Button>
          </Box>
          <HorizontalDivider />
        </Box>
        <div>
          <TotpConfiguration />
        </div>
        <Box
          sx={{
            'display': 'flex',
            'flexWrap': 'wrap',
            'flexDirection': 'column',
            'width': '40%',

            '@media screen and (max-width: 460px)': {
              width: '90%',
            },
          }}
          className="notifications"
        >
          <Typography variant="h1" sx={{fontSize: 20, padding: '48px 0 28px', fontWeight: '500'}} component="div">
            {t('notifications.title')}
          </Typography>
          {userInfo.settings?.receive_notifications_by_email !== undefined &&
              <Switch
                label={t('notifications.switch.label')}
                defaultChecked={Boolean(userInfo.settings?.receive_notifications_by_email)}
                onChange={setNotificationsSending}
              />
          }
        </Box>
      </div>
      <ChangePasswordCorrectlyPopUp handlePopupClose={handlePopupClose} isPopupOpen={isSuccessPopupOpen}/>
    </DefaultLayout>
  );
}
