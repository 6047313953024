import Api from '../../../helpers/api/Api';

export default function postSendInvitationRequest(userEmail) {
  return new Promise(function(resolve, reject) {
    Api.post('/users/invite', {
      email: userEmail,
      shouldSendMessage: true,
    }).then(()=>{
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}
