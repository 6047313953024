import Api from '../../../helpers/api/Api';

export default function getUserOverviewData(dateFrom, dateTo) {
  return new Promise(function(resolve, reject) {
    const dayFrom = dateFrom?.toISOString().split('T')[0];
    const dayTo = dateTo?.toISOString().split('T')[0];

    const urlParts = [];
    if (dayFrom) urlParts.push(`from=${dayFrom}`);
    if (dayTo) urlParts.push(`to=${dayTo}`);

    const url = `/users/overview?${urlParts.join('&')}`;

    Api.get(url).then((data)=>{
      if (data['overviewData']) {
        resolve(data['overviewData']);
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
