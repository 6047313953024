import './SingleNotePreview.scss';

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

import formatDateTimestamp from '../../helpers/formatters/formatDateTimestamp';

export default function SingleNotePreview(props) {
  const presentationMode = props.presentationMode ?? 'list';

  function itemClicked() {
    if (typeof props.onClick === 'function' && props.note) {
      props.onClick(props.note);
    }
  }

  const textNormalized = props?.note?.text && props.characterLimit && props.characterLimit > 3 ?
    `${props.note.text.substring(0, props.characterLimit-3)}...` :
    props?.note?.text;
  return (
    <div
      className={`SingleNotePreview SingleNotePreview-${presentationMode}`}
      onClick={itemClicked}
    >
      <div className="SingleNotePreview-section author">
        <Typography variant="mediumHint">{props?.note?.author?.display}</Typography>
      </div>
      <div className="SingleNotePreview-section content">
        <Typography variant="body2">{textNormalized}</Typography>
      </div>
      <div className="SingleNotePreview-section date">
        <Typography variant="mediumHint">{formatDateTimestamp(props?.note?.createdAt)}</Typography>
      </div>
    </div>
  );
}

SingleNotePreview.propTypes = {
  note: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    '@type': PropTypes.oneOf(['Note']).isRequired,
    'id': PropTypes.number.isRequired,
    'text': PropTypes.string,
    'author': PropTypes.shape({
      display: PropTypes.string,
    }),
    'createdAt': PropTypes.string,
  }),
  onClick: PropTypes.func,
  presentationMode: PropTypes.oneOf(['list', 'bricks']),
  characterLimit: PropTypes.number,
};
