import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import AddProjectModal from '../../../../components/AddProjectModal/AddProjectModal';
import useCreateProjectModalConfiguration from './hooks/useCreateProjectModalConfiguration';
import css from './index.module.scss';

function CreateProjectAction({pageScope}) {
  const {t} = useTranslation('page_cases');
  const [searchParams, setSearchParams] = useSearchParams();

  const [isCreationModalOpen, setCreationModalOpen] = useState(false);

  const modalConfiguration = useCreateProjectModalConfiguration(pageScope);

  const buttonText = useMemo(()=>{
    switch (pageScope) {
      case 'leads':
        return t('page.leads.add_button');
      case 'opportunities':
        return t('page.opportunities.add_button');
      case 'accounts':
        return t('page.accounts.add_button');
      default:
        return t('page.cases.add_button');
    }
  }, [pageScope, t]);

  function onCreateNewEntry() {
    setCreationModalOpen(true);
  }

  function onAbortCreation() {
    setCreationModalOpen(false);
  }

  function onSuccessfulCreation() {
    setCreationModalOpen(false);
    refreshCurrentTable();
  }

  function refreshCurrentTable() {
    searchParams.set('refreshAt', Date.now());
    setSearchParams(searchParams);
  }

  return (
    <>
      <Button
        className={css.button}
        variant="contained"
        onClick={onCreateNewEntry}
      >
        {buttonText}
      </Button>
      <AddProjectModal
        isOpen={isCreationModalOpen}
        onClose={onAbortCreation}
        onSave={onSuccessfulCreation}
        {...modalConfiguration}
      />
    </>
  );
}

CreateProjectAction.propTypes = {
  pageScope: PropTypes.oneOf([
    'leads', 'opportunities', 'accounts', 'cases',
  ]).isRequired,
};

export default CreateProjectAction;
