import EuroIcon from '@mui/icons-material/Euro';
import {OutlinedInput} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';

export default function EditFieldMoney(props) {
  function callback(event) {
    const newValue = event.target.value.replaceAll(/[^0-9]/g, '');
    props.onChange(newValue);
  }

  const placeholder = !Array.isArray(props.placeholder) ? props.placeholder : null;
  return (
    <OutlinedInput
      className="EditFieldMoney"
      size="small"
      placeholder={placeholder}
      value={props.value ?? ''}
      onChange={callback}
      fullWidth={true}
      style={{
        fontSize: props.fontSize,
      }}
      endAdornment={
        <InputAdornment position="end">
          <EuroIcon sx={{fontSize: '20px'}} />
        </InputAdornment>
      }
      error={props.error}
    />
  );
}

EditFieldMoney.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
