import Api from '../../../helpers/api/Api';

export default function postNotificationMarkAllAsSeen() {
  return new Promise(function(resolve, reject) {
    const url = `/notifications/mark_all_as_seen`;

    Api.post(url, {}).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
