import './UserData.scss';

import {Close, DriveFileRenameOutline, Save} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import EditField from '../../components/EditField/EditField';
import HorizontalDivider from '../../components/HorizontalDivider/HorizontalDivider';
import MoreButton from '../../components/MoreButton/MoreButton';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import hasCapability, {CAPABILITY_EDIT, CAPABILITY_MERGE, CAPABILITY_PURGE} from '../../helpers/security/hasCapability';
import {useStateValue} from '../../state/state';
import MergeEmployeesModal from '../CompanyDetails/MergeEmployeesModal';
import postDisableTotp from '../UserDetails/api/postDisableTotp';
import patchUser from './api/patchUser';

export default function UserData(props) {
  const {t, i18n} = useTranslation('page_companies');

  const navigate = useNavigate();
  const location = useLocation();

  const companyId = location.pathname.split('/').filter((i) => i.length > 0)[1];
  const userId = location.pathname.split('/').filter((i) => i.length > 0)[3];

  const employee = props.companyData?.employees?.find((e) => e?.user?.id == userId);

  function breadcrumbBack() {
    navigate(`/companies/${companyId}`);
  }

  const [{user}, dispatch] = useStateValue();

  const [patchData, setPatchData] = useState(initializePatchData());
  const [isMergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const hasMergeCapability = hasCapability(user, CAPABILITY_MERGE, 'users');
  const hasPurgeCapability = hasCapability(user, CAPABILITY_PURGE, 'users');

  function initializePatchData() {
    return {
      id: employee.user.id,
      firstname: employee.firstname,
      lastname: employee.lastname,
      email: employee.email,
      userPhones: [...employee.user.userPhones],
      primaryRole: employee.primaryRole,
      isEnabled: employee.isEnabled,
    };
  }

  function toggleEdit() {
    setPatchData(initializePatchData());
    if (!isEditing && hasCapability(user, CAPABILITY_EDIT, 'users')) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }

  function toggleSave() {
    setIsEditing(false);
    setIsSaving(true);
    patchUser(patchData).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function userStatusChange(action) {
    const data = Object.assign({}, patchData);
    switch (action) {
      case 'activate':
        data.isEnabled=true;
        break;
      case 'deactivate':
        data.isEnabled=false;
        break;
      default:
    }
    setIsEditing(false);
    setIsSaving(true);
    patchUser(data).then(()=>{
      setIsSaving(false);
      setPatchData(data);
      props.onRefreshRequest();
    });
  }

  function userDisableTotp(action) {
    setIsEditing(false);
    setIsSaving(true);
    postDisableTotp(employee.user.id).then(()=>{
      setIsSaving(false);
      props.onRefreshRequest();
    });
  }

  function editFieldChanged(id, value) {
    const data = Object.assign({}, patchData);
    switch (id) {
      case 'username':
        data.firstname = value.firstname;
        data.lastname = value.lastname;
        break;
      case 'email':
        data.email = value;
        break;
      case 'phone':
        if (data.userPhones && data.userPhones[0]) {
          data.userPhones[0].country = value.country;
          data.userPhones[0].phone = value.phone;
        } else {
          data.userPhones = [{
            country: value.country,
            phone: value.phone,
          }];
        }
        break;
      case 'primaryRole':
        data.primaryRole = value;
        data.roles = [value];
        break;
      default:
    }
    setPatchData(data);
  }

  function onMergeDialogClosed(result = false) {
    setMergeDialogOpen(false);
    if (result === true) {
      props.onRefreshRequest();
    }
  }

  const possibleStatusActions = [];
  if (employee.isEnabled) possibleStatusActions.push({id: 'deactivate', text: t('action.deactivate', {ns: 'dictionary_user_status'})});
  if (!employee.isEnabled) possibleStatusActions.push({id: 'activate', text: t('action.activate', {ns: 'dictionary_user_status'})});

  return (
    <div className="CompanyEdit UserData">
      <div className="breadcrumb-navigation" onClick={breadcrumbBack}>
        <div className="arrow-back" />
        <Typography fontSize={14}>{t('user.action.back')}</Typography>
      </div>
      {!isSaving &&
          <>
            <EditField
              id="username"
              name={null}
              value={patchData}
              format="header"
              type="userNameSurname"
              editing={isEditing}
              onChange={editFieldChanged}
            />
            { hasMergeCapability &&
              <Button className="merge-button" variant="smallerText" onClick={()=>setMergeDialogOpen(!isMergeDialogOpen)}>{t('user.merge_button')}</Button>
            }
            <EditField
              id="email"
              name={t('user.field.email.description')}
              value={patchData.email}
              type="string"
              fontSize={16}
              placeholder={t('user.field.email.placeholder')}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="phone"
              name={t('user.field.phone.description')}
              value={
                Array.isArray(patchData.userPhones) && patchData.userPhones.length > 0 ?
                  patchData.userPhones[0] :
                  null
              }
              placeholder={[t('user.field.phone.placeholder1'), t('user.field.phone.placeholder2')]}
              type="userPhone"
              fontSize={16}
              editing={isEditing}
              onChange={editFieldChanged}
            />
            <EditField
              id="createdAt"
              name={t('user.field.date.description')}
              value={employee.createdAt}
              type="dateString"
              fontSize={16}
              editing={false}
            />
            <div className="with-button">
              <EditField
                id="isTotpEnabled"
                name={t('user.field.totp.description')}
                value={employee.user.isTotpAuthenticationEnabled ?
                  t('active', {ns: 'dictionary_user_status'}) :
                  t('inactive', {ns: 'dictionary_user_status'})
                }
                type="string"
                fontSize={16}
              />
              {hasCapability(user, CAPABILITY_EDIT, 'users') && employee.user.isTotpAuthenticationEnabled &&
                <MoreButton
                  actions={[
                    {id: 'deactivate', text: t('action.deactivate', {ns: 'dictionary_user_status'})},
                  ]}
                  onAction={userDisableTotp}
                />
              }
            </div>
            {props?.companyData?.type === 'client' &&
              <>
                <EditField
                  id="primaryRole"
                  name={t('user.field.role.description')}
                  value={patchData.primaryRole}
                  type="primaryRole"
                  typeOptions={{client: false}}
                  fontSize={16}
                  editing={patchData.primaryRole !== 'ROLE_USER' && isEditing}
                  onChange={editFieldChanged}
                />
                <div className="with-button">
                  <EditField
                    id="isEnabled"
                    name={t('user.field.status.description')}
                    value={patchData.isEnabled ?
                      (
                        employee.confirmedAt === null ?
                          t('pending', {ns: 'dictionary_user_status'}) :
                          t('active', {ns: 'dictionary_user_status'})
                      ) :
                      t('inactive', {ns: 'dictionary_user_status'})
                    }
                    type="string"
                    fontSize={16}
                  />
                  {hasCapability(user, CAPABILITY_EDIT, 'users') &&
                    <MoreButton
                      actions={possibleStatusActions}
                      onAction={userStatusChange}
                    />
                  }
                </div>
              </>
            }
            <HorizontalDivider />
          </>
      }
      {!isEditing && !isSaving && hasCapability(user, CAPABILITY_EDIT, 'users') &&
          <Button
            startIcon={<DriveFileRenameOutline />}
            color="text"
            onClick={toggleEdit}
            sx={{padding: 0}}
          >
            <Typography fontSize={16}>{t('user.action.edit')}</Typography>
          </Button>
      }
      {isEditing && !isSaving && hasCapability(user, CAPABILITY_EDIT, 'users') &&
          <>
            <Button
              startIcon={<Save />}
              color="text"
              onClick={toggleSave}
              sx={{padding: 0}}
            >
              <Typography fontSize={16}>{t('user.action.save')}</Typography>
            </Button>
            <Button
              startIcon={<Close />}
              color="text"
              onClick={toggleEdit}
              sx={{padding: 0, marginLeft: '36px'}}
            >
              <Typography fontSize={16}>{t('user.action.cancel')}</Typography>
            </Button>
          </>
      }
      {hasPurgeCapability && props.view !== 'purge' &&
          <Button
            startIcon={<Close />}
            color="text"
            onClick={()=>navigate('purge')}
            sx={{padding: 0, marginLeft: '36px'}}
          >
            <Typography fontSize={16}>{t('action.purge')}</Typography>
          </Button>
      }
      {isSaving &&
          <ProgressIndicator />
      }
      {employee && employee.user && props.companyData &&
          <MergeEmployeesModal
            isOpen={isMergeDialogOpen}
            onClose={onMergeDialogClosed}
            subject={employee?.user}
            company={props.companyData}
          />
      }
    </div>
  );
}

UserData.propTypes = {
  companyData: PropTypes.object.isRequired,
  onRefreshRequest: PropTypes.func.isRequired,
};
