import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import RecentActivityViewer from '../../components/RecentActivityViewer/RecentActivityViewer';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import getFrontendPathToObject from '../../helpers/navigation/getFrontendPathToObject';
import {CAPABILITY_VIEW_ALL_RECENT_ACTIVITY} from '../../helpers/security/hasCapability';
import getUserStakeholderRecentActivity from './api/getUserStakeholderRecentActivity';

const PAGE_STEP_SIZE = 7;

export default function CardUserStakeholderRecentActivity(props) {
  const navigate = useNavigate();
  const [userActivities, setUserActivities] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_STEP_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const userId = props.user && props.user.id ? parseInt(props.user.id) : null;

  const shouldSeeAllRecentActivity = useHasCapabilityHere(CAPABILITY_VIEW_ALL_RECENT_ACTIVITY);

  function getUserActivities() {
    if (userId !== null) {
      setIsLoading(true);
      const limitToUser = shouldSeeAllRecentActivity ? null : userId;
      getUserStakeholderRecentActivity(limitToUser, page, itemsPerPage, props.user.id, props.showOnlyMine).then((response) => {
        setUserActivities(response.activities);
        setTotalItems(response.totalItems);
        setIsLoading(false);
      });
    }
  }

  function onItemClicked(id, item) {
    const link = getFrontendPathToObject(item.relatedProject);
    if (link) {
      navigate(link);
    }
  }

  function loadMoreClicked() {
    setItemsPerPage(itemsPerPage+PAGE_STEP_SIZE);
  }

  useEffect(function() {
    getUserActivities();
  }, [props.user, userId, page, itemsPerPage, props.showOnlyMine]);

  if (userId) {
    return (
      <RecentActivityViewer
        activities={userActivities}
        isLoading={isLoading}
        showSeeMore={totalItems > userActivities.length}
        onSeeMoreClicked={loadMoreClicked}
        onItemClicked={onItemClicked}
      />
    );
  } else {
    return null;
  }
}

CardUserStakeholderRecentActivity.propTypes = {
  user: PropTypes.object,
};
