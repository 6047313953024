import {useStateValue} from '../state/state';

const SALESMAN_ROLES = [
  'ROLE_SALES',
];

export const useIsSalesman = () => {
  const [{user}] = useStateValue();
  return user !== null && SALESMAN_ROLES.some((role) => (user.roles && user.roles.indexOf(role) > -1));
};
