export default function getPossibleStatusActions(project, t) {
  switch (project?.status) {
    case 'lead_pending':
      return [
        {id: 'accept', text: t('action.lead_pending_to_trajectory_start', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'reject', text: t('action.lead_pending_to_rejected', {ns: 'dictionary_project_status'}), newStatus: 'lead_rejected'},
      ];
    case 'lead_rejected':
      return [
        {id: 'accept', text: t('action.lead_pending_to_trajectory_start', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'inReview', text: t('action.lead_reset_to_pending', {ns: 'dictionary_project_status'}), newStatus: 'lead_pending'},
      ];
    case 'opportunity_trajectory':
      return [
        {id: 'opportunity_payment', text: t('action.opportunity_to_payment', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_payment'},
        {id: 'opportunity_memorandum_creating', text: t('action.opportunity_to_memorandum_creating', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_creating'},
        {id: 'opportunity_memorandum_sent', text: t('action.opportunity_to_memorandum_sent', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_sent'},
        {id: 'opportunity_resigned', text: t('action.opportunity_to_resigned', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_resigned'},
        {id: 'account_in_progress', text: t('action.opportunity_to_account_in_progress', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    case 'opportunity_payment':
      return [
        {id: 'opportunity_trajectory', text: t('action.opportunity_to_trajectory', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'opportunity_memorandum_creating', text: t('action.opportunity_to_memorandum_creating', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_creating'},
        {id: 'opportunity_memorandum_sent', text: t('action.opportunity_to_memorandum_sent', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_sent'},
        {id: 'opportunity_resigned', text: t('action.opportunity_to_resigned', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_resigned'},
        {id: 'account_in_progress', text: t('action.opportunity_to_account_in_progress', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    case 'opportunity_memorandum_creating':
      return [
        {id: 'opportunity_trajectory', text: t('action.opportunity_to_trajectory', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'opportunity_payment', text: t('action.opportunity_to_payment', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_payment'},
        {id: 'opportunity_memorandum_sent', text: t('action.opportunity_to_memorandum_sent', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_sent'},
        {id: 'opportunity_resigned', text: t('action.opportunity_to_resigned', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_resigned'},
        {id: 'account_in_progress', text: t('action.opportunity_to_account_in_progress', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    case 'opportunity_memorandum_sent':
      return [
        {id: 'opportunity_trajectory', text: t('action.opportunity_to_trajectory', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'opportunity_payment', text: t('action.opportunity_to_payment', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_payment'},
        {id: 'opportunity_memorandum_creating', text: t('action.opportunity_to_memorandum_creating', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_creating'},
        {id: 'opportunity_resigned', text: t('action.opportunity_to_resigned', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_resigned'},
        {id: 'account_in_progress', text: t('action.opportunity_to_account_in_progress', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    case 'opportunity_resigned':
      return [
        {id: 'opportunity_trajectory', text: t('action.opportunity_to_trajectory', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_trajectory'},
        {id: 'opportunity_payment', text: t('action.opportunity_to_payment', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_payment'},
        {id: 'opportunity_memorandum_creating', text: t('action.opportunity_to_memorandum_creating', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_creating'},
        {id: 'opportunity_memorandum_sent', text: t('action.opportunity_to_memorandum_sent', {ns: 'dictionary_project_status'}), newStatus: 'opportunity_memorandum_sent'},
        {id: 'account_in_progress', text: t('action.opportunity_to_account_in_progress', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    case 'account_in_progress':
      return [
        {id: 'account_closed', text: t('action.account_in_progress_close', {ns: 'dictionary_project_status'}), newStatus: 'account_closed'},
      ];
    case 'account_closed':
      return [
        {id: 'account_in_progress', text: t('action.account_closed_reopen', {ns: 'dictionary_project_status'}), newStatus: 'account_in_progress'},
      ];
    default:
      return [];
  }
}
