import './RelatedProjectItem.scss';

import {BusinessCenter} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {SvgIcon} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

import {ReactComponent as LogoSFIcon} from '../../assets/logo_plain.svg';
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';

export default function RelatedProjectItem(props) {
  function itemClicked() {
    if (typeof props.onClick === 'function' && typeof props.iri === 'string') {
      props.onClick(props.iri);
    }
  }

  return (
    <Card
      className="RelatedProjectItem"
      variant="outlined"
      sx={{border: 'none'}}
      onClick={itemClicked}
    >
      <CardContent>
        <Box className="RelatedProjectItem-handler">
          <SvgIcon component={LogoSFIcon} fontSize="initial"/>
          <Typography className="user-display" fontSize="initial" fontWeight={400}>{props.handler ?? 'unknown'}</Typography>
          <ChevronRightIcon sx={{color: '#9C9FAF'}} />
        </Box>
        <Box className="RelatedProjectItem-project">
          <Typography fontSize={'medium'} fontWeight={400}>
            {props.name}
          </Typography>
        </Box>
        <Box className="RelatedProjectItem-status">
          <Typography fontSize="14px" fontWeight={400}>
            {props.type}
            &middot;
            {props.status}
          </Typography>
        </Box>
        <Box className="RelatedProjectItem-client">
          <BusinessCenter fontSize="initial" />
          <Typography className="user-display" fontSize="initial" fontWeight={400}>
            {props.primaryProjectUser}
          </Typography>
        </Box>
        <Box className="RelatedProjectItem-time">
          <Typography className="time-display" fontSize="initial" fontWeight={400}>
            {props.timestamp}
          </Typography>
        </Box>
      </CardContent>
      {props.withUnderline && <HorizontalDivider sx/>}
    </Card>
  );
}

RelatedProjectItem.propTypes = {
  iri: PropTypes.string,
  handler: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  primaryProjectUser: PropTypes.string,
  timestamp: PropTypes.string,
  onClick: PropTypes.func,
  withUnderline: PropTypes.bool,
};
