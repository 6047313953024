import Api from '../api/Api';

const REMEMBER = {};

export default function getSecurityContextsDictionary() {
  return new Promise(function(resolve, reject) {
    if (Array.isArray(REMEMBER?.security_context)) {
      resolve(REMEMBER.security_context);
    } else {
      Api.get('/security_contexts?itemsPerPage=999999').then((data)=> {
        if (data['hydra:member']) {
          REMEMBER.security_context = data['hydra:member'];
          resolve(data['hydra:member']);
        } else {
          reject();
        }
      }).catch((error) => {
        reject(error);
      });
    }
  });
}
