import JsCookie from 'js-cookie';

import i18n from '../../i18n';

const URL_LANG_PARAM_NAME = 'lang';

const LANG_OVERRIDE_COOKIE_NAME = 'lang';
const LANG_OVERRIDE_COOKIE_DURATION_DAYS = 1;

export default function useLanguageOverride() {
  const currentLocation = new URL(document.location.href);
  const requestedLang = currentLocation.searchParams.get(URL_LANG_PARAM_NAME);
  const storedLang = JsCookie.get(LANG_OVERRIDE_COOKIE_NAME);

  if (requestedLang && requestedLang!==i18n.language) {
    i18n.changeLanguage(requestedLang);
    JsCookie.set(LANG_OVERRIDE_COOKIE_NAME, requestedLang, {expires: LANG_OVERRIDE_COOKIE_DURATION_DAYS});
    currentLocation.searchParams.delete(URL_LANG_PARAM_NAME);
    document.location = currentLocation;
  } else if (storedLang && storedLang !== i18n.language) {
    i18n.changeLanguage(storedLang);
    return true;
  } else {
    return false;
  }
}
