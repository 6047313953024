import './CardUserStatistics.scss';

import {ChevronRight} from '@mui/icons-material';
import {Button, SvgIcon, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import CountUp from 'react-countup';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ReactComponent as icon1} from '../../assets/overview-icon-1.svg';
import {ReactComponent as icon2} from '../../assets/overview-icon-2.svg';
import {ReactComponent as icon3} from '../../assets/overview-icon-3.svg';
import {ReactComponent as icon4} from '../../assets/overview-icon-4.svg';
import {useHasCapabilityHere} from '../../customHooks/useHasCapabilityHere';
import formatDateShort from '../../helpers/formatters/formatDateShort';
import {
  CAPABILITY_VIEW_GLOBAL_STATISTICS,
  CAPABILITY_VIEW_PERSONALIZED_STATISTICS,
} from '../../helpers/security/hasCapability';
import getUserOverviewData from './api/getUserOverviewData';

export default function CardUserStatistics(props) {
  const {t, i18n} = useTranslation('page_overview');

  const [globalCounters, setGlobalCounters] = useState({leads: null, opportunities: null, accounts: null});
  const [personalCounters, setPersonalCounters] = useState({leads: null, opportunities: null, accounts: null});

  const [isMenuOpened, setMenuOpened] = useState(false);
  const [rangeFrom, setRangeFrom] = useState(getDefaultDateFrom());
  const [rangeTo, setRangeTo] = useState(new Date());

  const userId = props.user && props.user.id;

  const shouldSeeGlobalStatistics = useHasCapabilityHere(CAPABILITY_VIEW_GLOBAL_STATISTICS);
  const shouldSeePersonalStatistics = useHasCapabilityHere(CAPABILITY_VIEW_PERSONALIZED_STATISTICS);


  const rangeMenuAnchor = useRef();

  useEffect(function() {
    if (userId) {
      refreshOverviewData();
    }
  }, [userId, rangeFrom, rangeTo]);

  function refreshOverviewData() {
    getUserOverviewData(rangeFrom, rangeTo).then((data)=>{
      if (shouldSeeGlobalStatistics) {
        setGlobalCounters({leads: data?.global?.leads_in_review, opportunities: data?.global?.opportunities_not_rejected, accounts: data?.global?.accounts_closed});
      } else {
        setGlobalCounters({leads: null, opportunities: null, accounts: null});
      }
      if (shouldSeePersonalStatistics) {
        setPersonalCounters({leads: data?.personal?.leads_in_review, opportunities: data?.personal?.opportunities_not_rejected, accounts: data?.personal?.accounts_closed});
      } else {
        setPersonalCounters({leads: null, opportunities: null, accounts: null});
      }
    });
  }

  function createCounter(number, icon, text, target = '') {
    return (
      <div className="CardUserStatistics-box">
        <SvgIcon component={icon}/>
        <Link to={target}><Typography className="title" variant="h2">{text}</Typography></Link>
        <div className="value">
          <CountUp
            start={0}
            end={number}
            delay={0}
            duration={1}
          >
            {({countUpRef}) => (
              <span ref={countUpRef} />
            )}
          </CountUp>
        </div>
      </div>

    );
  }

  function setRangeFromDate(date) {
    setRangeFrom(date);
  }

  function setRangeToDate(date) {
    setRangeTo(date);
  }

  const maxSelectableDateFrom = rangeTo;
  const maxSelectableDateTo = new Date();

  return (
    <div className="CardUserStatistics">
      <div className="CardUserStatistics-boxes">
        {shouldSeeGlobalStatistics && !isNaN(globalCounters.leads) &&
          createCounter(globalCounters.leads, icon1, t('statistics.brick.leads_in_review'), '/leads?status=lead_pending')
        }
        {shouldSeePersonalStatistics && !shouldSeeGlobalStatistics && !isNaN(personalCounters.leads) &&
          createCounter(personalCounters.leads, icon1, t('statistics.brick.my_leads_in_review'), '/leads?status=lead_pending&showOnlyMine=true')
        }
        {shouldSeeGlobalStatistics && !isNaN(globalCounters.opportunities) &&
          createCounter(globalCounters.opportunities, icon2, t('statistics.brick.opportunities'), '/opportunities')
        }
        {shouldSeePersonalStatistics && !isNaN(personalCounters.opportunities) &&
          createCounter(personalCounters.opportunities, icon3, t('statistics.brick.my_opportunities'), '/opportunities?showOnlyMine=true')
        }
        {shouldSeeGlobalStatistics && !isNaN(globalCounters.accounts) &&
          createCounter(globalCounters.accounts, icon4, t('statistics.brick.closed_cases'), '/accounts')
        }
        {shouldSeePersonalStatistics && !shouldSeeGlobalStatistics && !isNaN(personalCounters.accounts) &&
          createCounter(personalCounters.accounts, icon4, t('statistics.brick.my_closed_cases'), '/accounts?showOnlyMine=true')
        }
      </div>
      <div className="CardUserStatistics-range-selector" ref={rangeMenuAnchor}>
        <Button
          className="toggle-button"
          onClick={()=>setMenuOpened(!isMenuOpened)}
          variant="text"
          color="gray"
        >
          {<span dangerouslySetInnerHTML={{__html: getRangeDescription(rangeFrom, rangeTo, t)}} />}
          <ChevronRight className={isMenuOpened ? 'rotate90deg' : ''} />
        </Button>
        { isMenuOpened &&
              <div className="hidden-section">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {t('statistics.filter.prefix_from')}
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={rangeFrom}
                    maxDate={maxSelectableDateFrom}
                    onChange={(date)=> setRangeFromDate(date)}
                    renderInput={(params) => <TextField variant={'standard'} className="CardUserStatistics-DatePicker" size={'small'} {...params} />}
                  />
                  {t('statistics.filter.prefix_to')}
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={rangeTo}
                    onChange={(date)=> setRangeToDate(date)}
                    minDate={rangeFrom}
                    maxDate={maxSelectableDateTo}
                    renderInput={(params) => <TextField variant={'standard'} className="CardUserStatistics-DatePicker" size={'small'} {...params} />}
                  />
                  {t('statistics.filter.suffix_to')}
                </LocalizationProvider>
              </div>
        }
      </div>
    </div>
  );
}

CardUserStatistics.propTypes = {
  user: PropTypes.object.isRequired,
};

function getDefaultDateFrom() {
  const d = new Date();
  d.setHours(d.getHours() - (24*28));
  return d;
}

function getRangeDescription(from, to, translator) {
  if (!from && !to) {
    return translator('statistics.filter.hint.no_selection');
  } else if (!from && to) {
    return translator('statistics.filter.hint.after_day', {day: formatDateShort(to)});
  } else if (from && !to) {
    return translator('statistics.filter.hint.before_day', {day: formatDateShort(from)});
  } else {
    const ONE_DAY = 1000*60*60*24;

    const now = new Date();
    const isTodayTo = Math.floor((now.getTime()-to.getTime())/ONE_DAY) === 0;
    const daysIntoPast = Math.floor((now.getTime()-from.getTime())/ONE_DAY);

    if (isTodayTo && daysIntoPast >= 28 && daysIntoPast <= 31) {
      return translator('statistics.filter.hint.last_4_weeks');
    } else {
      return translator('statistics.filter.hint.date_range', {from: formatDateShort(from), to: formatDateShort(to)});
    }
  }
}
