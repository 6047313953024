import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Switch from '../../../../components/Switch/Switch';
import {useStateValue} from '../../../../state/state';
import useTasksListFilters, {
  PARAM_ONLY_FOR_USER_ID,
  PARAM_ONLY_MINE,
  PARAM_PAGE,
} from '../../hooks/useTasksListFilters';
import css from './index.module.scss';

function PersonalFilter() {
  const {t} = useTranslation('page_tasks');
  const {current, setParams} = useTasksListFilters();
  const [{user}] = useStateValue();
  const [isChecked, setIsChecked] = useState(current[PARAM_ONLY_MINE]);

  const handleOnChange = () => {
    const newValue = !current[PARAM_ONLY_MINE];
    setIsChecked(newValue);

    setParams({
      [PARAM_ONLY_MINE]: newValue,
      [PARAM_ONLY_FOR_USER_ID]: newValue ? user.id : null,
      [PARAM_PAGE]: 1,
    });
  };

  useEffect(() => {
    setIsChecked(current[PARAM_ONLY_MINE]);
  }, [current]);

  useEffect(() => {
    if (current[PARAM_ONLY_FOR_USER_ID] === null && current[PARAM_ONLY_MINE]) {
      setParams({
        [PARAM_ONLY_FOR_USER_ID]: user.id,
      });
    }
  }, []); // Empty dependency array ensures this effect runs only on first render

  return (
    <div className={css.PersonalFilter}>
      <Switch
        checked={isChecked}
        onChange={handleOnChange}
        label={t('statuses.onlyMine')}
      />
    </div>
  );
}

export default PersonalFilter;
