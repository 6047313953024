import Api from '../../../../helpers/api/Api';

export default function getAttachments(companyId) {
  return new Promise(function(resolve, reject) {
    const numericId = typeof companyId === 'string' ?
      parseInt(companyId.replace('/api/companies/', '')) :
      parseInt(companyId);

    Api.get(`/companies/${numericId}/attachments`).then((data)=> {
      resolve({
        attachments: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      });
    }).catch((error) => {
      reject(error);
    });
  });
}
