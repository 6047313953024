import './TabbedNavigation.scss';

import {Typography} from '@mui/material';
import React from 'react';
import {useSearchParams} from 'react-router-dom';

export default function TabbedNavigation(props) {
  const [queryParams, setQueryParams] = useSearchParams();

  const queryTerm = queryParams.get(props.paramName);
  const activeTab = queryTerm ? props.tabs.filter((item) => item.id === queryTerm)[0] : props.tabs[0];

  function setTab(tabId) {
    queryParams.set(props.paramName, tabId);
    if (props.beforeParamsChanged) {
      props.beforeParamsChanged(queryParams);
    }
    setQueryParams(queryParams);
  }

  return (
    <div className="TabbedNavigation">
      {props.tabs.map((item)=>(
        <Typography
          key={item.id}
          variant="h3"
          onClick={()=>setTab(item.id)}
          className={`TabbedNavigation-tab ${item.id===activeTab.id ? 'is-active' : 'is-inactive'}`}
        >
          {item.text}
        </Typography>
      ))}
    </div>
  );
}
