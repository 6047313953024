import Api from '../../../../helpers/api/Api';

export default function getUserRecentActivity(projectId, page, itemsPerPage) {
  return new Promise(function(resolve, reject) {
    const url = `/audit_logs?level=business&page=${page}&itemsPerPage=${itemsPerPage}&order[createdAt]=desc&project=${projectId}`;

    Api.get(url).then((data)=>{
      if (Array.isArray(data['hydra:member'])) {
        resolve({
          activities: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
        });
      } else {
        reject();
      }
    }).catch((error) => {
      reject(error);
    });
  });
}
