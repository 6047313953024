import React from 'react';

import CompanyFilter from './components/CompanyFilter';
import ExecutorFilter from './components/ExecutorFilter';
import LinkedResourceFilter from './components/LinkedResourceFilter';
import PersonalFilter from './components/PersonalFilter';
import SearchFilter from './components/SearchFilter';
import StatusFilter from './components/StatusFilter';
import css from './index.module.scss';

function FiltersBlock() {
  return (
    <section className={css.FiltersBlock}>
      <SearchFilter />
      <StatusFilter />
      <ExecutorFilter />
      <CompanyFilter />
      <LinkedResourceFilter />
      <PersonalFilter/>
    </section>
  );
}

export default FiltersBlock;
