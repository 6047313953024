import Api from '../../../helpers/api/Api';

export default function postCompany(data) {
  return new Promise(function(resolve, reject) {
    const companyObject = {
      name: data.name ?? null,
      legalForm: data.legalForm ?? null,
      type: data.type ?? null,
      handler: data.handler ?? null,
      securityContexts: data.securityContextsIds ?? null,
    };

    Api.post(`/companies`, companyObject).then((data)=>{
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}
