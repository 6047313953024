import './MoreButton.scss';

import {MoreVert} from '@mui/icons-material';
import {ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function MoreButton(props) {
  const [isOpened, setIsOpened] = React.useState(false);
  const moreRef = React.useRef(null);

  function handleMoreToggle() {
    setIsOpened((prevOpen) => !prevOpen);
  }

  const handleMoreClose = (event, actionId) => {
    event.stopPropagation();
    if (moreRef.current && moreRef.current.contains(event.target)) {
      return;
    }
    setIsOpened(false);
    if (actionId && props.onAction) {
      props.onAction(actionId, props.rowKey);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpened(false);
    } else if (event.key === 'Escape') {
      setIsOpened(false);
    }
  }

  return (
    <div className="MoreButton">
      <MoreVert
        onClick={handleMoreToggle}
        ref={moreRef}
      />
      <Popper
        className="MoreButton-Popper"
        open={isOpened}
        anchorEl={moreRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMoreClose}>
                <MenuList
                  autoFocusItem={isOpened}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {props.actions.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={(event) => handleMoreClose(event, item.id)}
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        color={item.color ?? 'normal'}
                      >
                        <>{item.text}</>
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

MoreButton.propTypes = {
  onAction: PropTypes.func,
  actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        color: PropTypes.string,
      }).isRequired,
  ).isRequired,
  rowKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
