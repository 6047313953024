import './Companies.scss';

import {FileDownload} from '@mui/icons-material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import fileDownload from 'js-file-download';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

import AddCompanyModal from '../../components/AddCompanyModal/AddCompanyModal';
import CompanyTypeDot from '../../components/CompanyTypeDot/CompanyTypeDot';
import IndexPageHeader from '../../components/IndexPageHeader/IndexPageHeader';
import IndexTableManaged from '../../components/IndexTable/IndexTableManaged';
import ProgressIndicator from '../../components/ProgressIndicator/ProgressIndicator';
import Search from '../../components/Search/Search';
import SecurityContextDropdownSelector from '../../components/SecurityContextDropdownSelector';
import TabbedNavigation from '../../components/TabbedNavigation/TabbedNavigation';
import {useUserSecurityContext} from '../../customHooks/useUserSecurityContext';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import {useStateValue} from '../../state/state';
import getCompaniesList from './api/getCompaniesList';
import getCompaniesListExport from './api/getCompaniesListExport';
import companyToTableFields from './convert/companyToTableFields';

export default function UsersPage() {
  const {t} = useTranslation('page_companies');
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesListSize, setCompaniesListSize] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const userSecurityContexts = useUserSecurityContext();
  const [{user}] = useStateValue();

  const currentPageSize = 10;
  const currentPage = searchParams.get('page') && companiesListSize > 0 ? parseInt(searchParams.get('page')) : 1;
  const currentGroup = searchParams.get('group') ? searchParams.get('group') : 'all';
  const personalOnly = (searchParams.get('personalOnly') === 'true' || searchParams.get('personalOnly') === '1') ? true : null;
  const securityContextId = searchParams.get('securityContext') && !isNaN(parseInt(searchParams.get('securityContext'))) ?
    parseInt(searchParams.get('securityContext')) :
    null;

  const [sortingHeaderId, setSortingHeaderId] = useState('name');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [isCreatingNewCompany, setCreatingNewCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const canSeeSecurityContext = userSecurityContexts && userSecurityContexts.length > 1;
  function onSearch(term) {
    setSearchTerm(term);
  }

  function createNewAccount() {
    setCreatingNewCompany(true);
  }

  function refreshCompaniesList() {
    setIsLoading(true);
    const sorting = {header: sortingHeaderId, direction: sortingOrder};
    getCompaniesList(searchTerm, sorting, currentGroup, personalOnly, currentPage, currentPageSize, securityContextId).then((data)=>{
      setCompaniesList(data.companies);
      setCompaniesListSize(data.items);
      setIsLoading(false);
    }).catch(()=>{
      setIsLoading(false);
    });
  }

  function onTableHeaderClick(headerId) {
    if (headerId === sortingHeaderId) {
      setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingHeaderId(headerId);
      setSortingOrder('asc');
    }
  }

  function onPageChangeClick(newPageIndex) {
    searchParams.set('page', parseInt(newPageIndex)+1);
    setSearchParams(searchParams);
  }

  function setPersonalOnly(val) {
    if (val === true) {
      searchParams.set('personalOnly', '1');
    } else if (val === false) {
      searchParams.delete('personalOnly');
    } else {
      searchParams.delete('personalOnly');
    }
    setSearchParams(searchParams);
  }

  const selectSecurityContextFilter = (securityContextId) => {
    if (securityContextId) {
      searchParams.set('securityContext', securityContextId);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('securityContext');
      setSearchParams(searchParams);
    }
  };

  function onRowClick(rowId) {
    const foundCompany = companiesList[rowId];
    if (foundCompany) {
      navigate(`/companies/${foundCompany.id}`);
    }
  }

  function onMoreActionClick(actionId, companiesListIndex) {
    console.log(actionId, companiesListIndex);
  }

  function onExcelDownloadClick() {
    setIsExporting(true);
    const sorting = {header: sortingHeaderId, direction: sortingOrder};
    getCompaniesListExport(
        searchTerm, sorting, currentGroup, personalOnly, currentPage, currentPageSize, securityContextId,
    ).then((file) => {
      setIsExporting(false);
      fileDownload(file, 'companies.xlsx');
    }).catch(()=>{
      setIsExporting(false);
    });
  }

  function afterNewCompanyAdded() {
    setCreatingNewCompany(false);
    refreshCompaniesList();
  }

  useEffect(function() {
    refreshCompaniesList();
  }, [searchTerm, sortingHeaderId, sortingOrder, currentGroup, personalOnly, currentPage, currentPageSize, securityContextId]);

  const headers = [
    {id: 'name', label: t('list.table.header.name')},
    {id: 'type', label: t('list.table.header.type')},
    {id: 'legalForm', label: t('list.table.header.legal')},
    {id: 'email', label: t('list.table.header.email')},
    {id: 'primaryPhone', label: t('list.table.header.phone')},
    {id: 'createdAt', label: t('list.table.header.date')},
  ];
  if (canSeeSecurityContext) {
    headers.push({id: 'securityContext', label: t('list.table.header.context')});
  }
  headers.find((item) => item.id === sortingHeaderId).sorted = sortingOrder;

  const rows = companiesList.map((companyRow) => companyToTableFields(companyRow, t, canSeeSecurityContext));

  return (
    <DefaultLayout>
      <div className="Companies">
        <TabbedNavigation
          paramName="group"
          tabs={[
            {id: 'all', text: t('list.group.all.text')},
            {id: 'investor', text: <><CompanyTypeDot type="investor" isPersonal={false} />{t('list.group.investors.text')}</>},
            {id: 'money_supplier', text: <><CompanyTypeDot type="money_supplier" isPersonal={false} />{t('list.group.money_suppliers.text')}</>},
            {id: 'intermediary', text: <><CompanyTypeDot type="intermediary" isPersonal={false} />{t('list.group.intermediaries.text')}</>},
            {id: 'client_personal', text: <><CompanyTypeDot type="client" isPersonal={true} />{t('list.group.clients_personal.text')}</>},
            {id: 'client_company', text: <><CompanyTypeDot type="client" isPersonal={false} />{t('list.group.clients_companies.text')}</>},
          ]}
          beforeParamsChanged={(params)=>(params.set('page', 1))}
        />
        <IndexPageHeader
          text={group2text(currentGroup, t)}
        />
        <div className="section">
          <div className="left">
            <Search callback={onSearch} placeholder={t('list.filters.search.placeholder')} />
            <SecurityContextDropdownSelector
              securityContextId={securityContextId}
              onChangeCallback={selectSecurityContextFilter}
            />
            {currentGroup !== 'client_personal' && currentGroup !== 'client_company' &&
              <FormControlLabel
                control={
                  <Checkbox
                    checked={personalOnly}
                    onChange={() => setPersonalOnly(!personalOnly)}
                  />
                }
                label={t('list.filters.personal.label')}
              />
            }
          </div>
          <div className="section-filler" />
          {user.primaryRole !== 'ROLE_VIEWONLY' &&
          <Button variant="contained" onClick={createNewAccount}>{t('page.add_relation_button.text')}</Button>
          }
        </div>
        <IndexTableManaged
          headers={headers}
          rows={rows}
          pagination={{
            page: currentPage-1,
            rowsPerPage: currentPageSize,
            items: companiesListSize,
          }}
          appendToFooter={!isExporting ?
            <IconButton onClick={onExcelDownloadClick}>
              <FileDownload />
            </IconButton> :
            <ProgressIndicator />
          }
          onHeaderClick={onTableHeaderClick}
          onMoreActionClick={onMoreActionClick}
          onPageChangeClick={onPageChangeClick}
          onRowClick={onRowClick}
          isLoading={isLoading}
        />
      </div>
      <AddCompanyModal
        isOpen={isCreatingNewCompany}
        onClose={afterNewCompanyAdded}
      />
    </DefaultLayout>
  );
}

function group2text(group, translator) {
  switch (group) {
    case 'client_personal': return translator('list.group.clients_personal.title');
    case 'client_company': return translator('list.group.clients_companies.title');
    case 'intermediary': return translator('list.group.intermediaries.title');
    case 'money_supplier': return translator('list.group.money_suppliers.title');
    case 'all': return translator('list.group.all.title');
    default: return '';
  }
}


function group2type(group) {
  switch (group) {
    case 'client_personal':
    case 'client_company':
      return 'client';
    case 'intermediary':
    case 'money_supplier':
      return group;
    default:
      return null;
  }
}
