import {Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AddingTaskModal from '../../../components/AddingTaskModal/AddingTaskModal';
import Api from '../../../helpers/api/Api';
import {useStateValue} from '../../../state/state';
import css from './index.module.scss';

function ActionsBlock() {
  const {t} = useTranslation('page_tasks');
  const [{user}] = useStateValue();

  const [fullUserData, setFullUserData] = useState(null);

  function refreshTaskList(a, b, c) {
    console.log('refreshTaskList', a, b, c);
  }

  useEffect(function() {
    const userId = user && user.id;
    if (userId) {
      Api.get(`/users/${userId}`).then((data) => {
        setFullUserData(data);
      });
    }
  }, [user]);

  const [isAddTaskModalOpen, setAddTaskModalOpen] = useState(false);

  return (
    <section className={css.ActionsBlock}>
      {user.primaryRole !== 'ROLE_VIEWONLY' &&
      <Button
        variant="contained"
        onClick={()=>{
          setAddTaskModalOpen(true);
        }}
      >
        {t('action.createTask.button')}
      </Button>}
      {user && fullUserData &&
        <AddingTaskModal
          isAddingTaskModalOpen={isAddTaskModalOpen}
          handleAddingTaskPopupClose={()=>{
            setAddTaskModalOpen(false);
          }}
          refreshTaskList={refreshTaskList}
          type="user"
          projectData={fullUserData}
        />
      }
    </section>
  );
}

export default ActionsBlock;
